// tslint:disable:no-console
import { makeSaga } from 'redux-utils';
import { registerStudioIframe } from '../actions';

export let studioIframe: HTMLIFrameElement | null = null;

export const registerStudioIframeSaga = makeSaga(
  { registerStudioIframe },
  function* ({ payload }) {
    try {
      studioIframe = payload;
    } catch (error) {
      console.error('registerStudioIframeSaga:', error);
    }
  },
);
