import invariant from 'invariant';

import { hasOwn } from 'utils';
import EventBuilder from './EventBuilder';

const VALID_ACTIONS = {
  cancel: true,
  complete: true,
  engage: true,
};

class SubscriptionEventBuilder extends EventBuilder {
  constructor() {
    super('subscription');
  }

  buildOntology(action, doc) {
    invariant(
      hasOwn(VALID_ACTIONS, action),
      `Invalid subscription action ${JSON.stringify(action)} for buildOntology`,
    );
    const { kind, id, title } = doc || {};

    invariant(
      kind,
      'subscription data or kind missing in buildOntology',
    );

    const base = {
      class: kind,
      phylum: action,
    };

    if (action === 'complete') {
      return {
        ...base,
        family: title,
        order: id,
      };
    }
    return base;
  }

  buildMetadata(action, doc, extra) {
    invariant(
      extra,
      'Missing subscription extra data for buildMetadata',
    );
    const { kind } = doc || {};

    switch (kind) {
      case 'subscribe': {
        const { title, videoId } = extra;
        return {
          video: title,
          video_id: videoId,
        };
      }
      case 'payment_success': {
        const { currency, period, price } = extra;
        return {
          currency,
          price,
          sub_type: period,
        };
      }
      case 'payment_incomplete': {
        return {};
      }
      default:
        invariant(
          false,
          `Invalid subscription kind for buildMetadata: ${kind}`,
        );
        return null; // Appease linter; invariant will throw
    }
  }
}

export default new SubscriptionEventBuilder();
