import { getTopLocationUrl } from 'url-utils';
import { createSelector } from 'reselect';
import { getUserAvatar } from 'services/user-profile/selectors';
import {
  getUserCreated,
  getUserEmail,
  getUserId,
  isLoggedIn,
  getUserName,
  getUserService,
  getUserUid,
  getUserUsername,
  isNewUser,
} from 'services/auth/selectors';

import {
  getPageCollection,
  getPageId,
  getPageName,
  getPageSlug,
  getPageType,
  getSiteDomain,
  getSiteId,
  getSitePackageId,
  getSitePackageVersion,
  getSiteSlug,
} from 'services/app/selectors/common';

import {
  getDeviceCreated,
  getDeviceNavigatorPlatform,
  getDeviceNewDevice,
  getDevicePermanentId,
  getDeviceScreenHeight,
  getDeviceScreenWidth,
  getDeviceTabId,
  getDeviceUserAgent,
} from 'services/device/selectors';
import IState from 'services/state';

const DEFAULT_INSIGHTS_ID = [
  'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ2I',
  'jowLCJkIjp7InVpZCI6InNpdGVfdG9rZW4iLCJfaWQ',
  'iOm51bGwsInNsdWciOm51bGwsImNyZWF0ZWQiOjE1MD',
  'M0MzU5MDMuMDUyfSwiaWF0IjoxNTAzNDM1OTAzfQ.nOr',
  'C_aFDksylltOZg6GnaoWu6kmxmsNIia-4L4rzkaU',
].join('');

export const getInsightsId = (state: IState) => {
  const { insightsId } = state.app.site?.settings?.services || {};
  if (!insightsId || process.env.NODE_ENV !== 'production') {
    return DEFAULT_INSIGHTS_ID;
  }
  return insightsId;
};

export const getActive = ({ insights }: IState) => insights.active;
export const getCountryCode = ({ insights }: IState) => insights.countryCode;
export const getCurrentPageId = ({ insights }: IState) => insights.pageId;
export const getInitialized = ({ insights }: IState) => insights.initialized;
export const getLastEventDeviceTime = ({ insights }: IState) => insights.lastEvent.deviceTime;
export const getLastEventSentTimeMs = ({ insights }: IState) => insights.session.lastEventSentTimeMs;
export const getSession = ({ insights }: IState) => insights.session;

export const getMaestroPath = (state: IState) => {
  const {
    router: {
      location: {
        pathname,
      },
    },
  } = state;
  return `/${getSiteDomain(state)}${pathname}`;
};
export const getMetadataInsightsVersion = ({ insights }: IState) => insights.meta.insightsVersion;
export const getReferrerDomain = ({ insights }: IState) => insights.referrer.domain;
export const getReferrerSource = ({ insights }: IState) => insights.referrer.source;
export const getReferrerUrl = ({ insights }: IState) => insights.referrer.url;
export const getSessionId = ({ insights }: IState) => insights.session.id;
export const getSessionStart = ({ insights }: IState) => insights.session.start;
export const getUser = ({ insights }: IState) => insights.user;
export const getClientGaId = ({ app }: IState) => app.site.settings?.services?.googleAnalyticsId;

export const getReferralUrl = (elementId: string) => {
  let url = getTopLocationUrl();

  if (url.includes('?')) {
    url += '&';
  } else {
    url += '?';
  }

  return `${url}r=${elementId}`;
};

export const getVideoReferralUrl = (videoId?: string | null) => getReferralUrl(`social_v_${videoId}`);

export const getInsightsDevice = createSelector(
  [
    getDeviceCreated,
    getDeviceNavigatorPlatform,
    getDeviceNewDevice,
    getDevicePermanentId,
    getDeviceScreenHeight,
    getDeviceScreenWidth,
    getDeviceTabId,
    getDeviceUserAgent,
  ], (
    deviceCreated,
    deviceNavigatorPlatform,
    deviceNewDevice,
    devicePermanentId,
    deviceScreenHeight,
    deviceScreenWidth,
    deviceTabId,
    deviceUserAgent,
  ) => ({
    d_ct: deviceCreated,
    d_nd: deviceNewDevice,
    d_np: deviceNavigatorPlatform,
    d_pid: devicePermanentId,
    d_sh: deviceScreenHeight,
    d_sw: deviceScreenWidth,
    d_tid: deviceTabId,

    // the following needs to be added in the saga
    // d_tim: deviceTime,
    // d_tof: deviceTimeOffset,
    d_ua: deviceUserAgent,
  }),
);

export const getInsightsInsights = createSelector(
  [getInsightsId],
  insightsId => ({ i_id: insightsId }),
);

export const getInsightsMetadata = createSelector(
  [getMetadataInsightsVersion],
  metadataInsightsVersion => ({ m_iv: metadataInsightsVersion }),
);

export const getInsightsPage = createSelector(
  [
    getPageCollection,
    getPageId,
    getPageName,
    getPageSlug,
    getPageType,
  ], (
    pageCollection,
    pageId,
    pageName,
    pageSlug,
    pageType,
  ) => ({
    p_c: pageCollection,
    p_id: pageId,
    p_n: pageName,
    p_s: pageSlug,
    p_t: pageType,
  }),
);

export const getInsightsReferrer = createSelector(
  [
    getReferrerDomain,
    getReferrerSource,
    getReferrerUrl,
  ], (
    referrerDomain,
    referrerSource,
    referrerUrl,
  ) => ({
    r_d: referrerDomain,
    r_s: referrerSource,
    r_u: referrerUrl,
  }),
);

export const getInsightsSession = createSelector(
  [getSessionId, getSessionStart],
  (sessionId, sessionStart) => ({ se_id: sessionId, se_s: sessionStart }),
);

export const getInsightsSite = createSelector(
  [
    getSiteDomain,
    getSiteId,
    getSitePackageId,
    getSitePackageVersion,
    getSiteSlug,
  ], (
    siteDomain,
    siteId,
    sitePackageId,
    sitePackageVersion,
    siteSlug,
  ) => ({
    si_d: siteDomain,
    si_id: siteId,
    si_pid: sitePackageId,
    si_pv: sitePackageVersion,
    si_s: siteSlug,
  }),
);

export const getInsightsUser = createSelector(
  [
    getUserAvatar,
    getUserCreated,
    getUserEmail,
    getUserId,
    isLoggedIn,
    getUserName,
    isNewUser,
    getUserService,
    getUserUid,
    getUserUsername,
  ], (
    userAvatar,
    userCreated,
    userEmail,
    userId,
    userIsLoggedIn,
    userName,
    userNewUser,
    userService,
    userUid,
    userUsername,
  ) => ({
    u_a: userAvatar,
    u_c: userCreated,
    u_e: userEmail,
    u_id: userId,
    u_li: userIsLoggedIn,
    u_n: userName,
    u_nu: userNewUser,
    u_s: userService,
    u_u: userUid,
    u_un: userUsername,
  }),
);

export const getEventBase = createSelector(
  [
    getInsightsDevice,
    getInsightsInsights,
    getInsightsMetadata,
    getInsightsPage,
    getInsightsReferrer,
    getInsightsSession,
    getInsightsSite,
    getInsightsUser,
  ],
  (device, insights, metadata, page, referrer, session, site, user) => ({
    ...device,
    ...insights,
    ...metadata,
    ...page,
    ...referrer,
    ...session,
    ...site,
    ...user,
  }),
);
