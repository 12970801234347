import { makeAction } from 'redux-utils';

export const SET_NAVIGATION_BLOCKED = 'navigation/SET_NAVIGATION_BLOCKED';
export const SET_IS_USER_ACTION_REQUESTED_TO_UNBLOCK_NAVIGATION = 'navigation/SET_IS_USER_ACTION_REQUESTED_TO_UNBLOCK_NAVIGATION';
export const DISMISS_PENDING_NAVIGATION_ACTIONS = 'navigation/DISMISS_PENDING_NAVIGATION_ACTIONS';
export const RESUME_PENDING_NAVIGATION_ACTIONS = 'navigation/RESUME_PENDING_NAVIGATION_ACTIONS';
export const ADD_PENDING_NAVIGATION_ACTION = 'navigation/ADD_NAVIGATION_ACTION';
export const CLEAR_PENDING_NAVIGATION_ACTIONS = 'navigation/CLEAR_PENDING_NAVIGATION_ACTIONS';

export const setNavigationBlocked = makeAction(
  SET_NAVIGATION_BLOCKED,
  (isBlocked: boolean) => ({ payload: isBlocked }),
);

export const setIsUserActionRequestedToUnblockNavigation = makeAction(
  SET_IS_USER_ACTION_REQUESTED_TO_UNBLOCK_NAVIGATION,
  (isRequested: boolean) => ({ payload: isRequested }),
);

export const dismissPendingNavigationActions = makeAction(
  DISMISS_PENDING_NAVIGATION_ACTIONS,
);

export const resumePendingNavigationActions = makeAction(
  RESUME_PENDING_NAVIGATION_ACTIONS,
);

export const addPendingNavigationAction = makeAction(
  ADD_PENDING_NAVIGATION_ACTION,
  (action: any) => ({ payload: action }),
);

export const clearPendingNavigationActions = makeAction(
  CLEAR_PENDING_NAVIGATION_ACTIONS,
);
