import { call, put, select, takeEvery } from 'redux-saga/effects';
import { getSiteId } from 'services/app/selectors';
import { getPrimaryToken } from 'services/auth/selectors';
import { LOG_OUT, setAccountName } from 'services/auth/actions';
import {
  clearUserProfileState,
  GET_USER_PROFILE,
  IUpdateUserProfileAction,
  setUserProfileState,
  UPDATE_USER_PROFILE,
  IUpdateUserPasswordAction,
  UPDATE_USER_PASSWORD,
  confirmPasswordUpdated,
  passwordUpdateError,
} from './actions';

import {
  getUserProfile,
  updatePassword,
  updateUserProfile,
} from './api';
import { AxiosResponse } from 'axios';
import { IUserProfile } from './models';
import IState from 'services/state';

export function* getUserProfileSaga() {
  const state: IState = yield select();
  const accessToken = getPrimaryToken(state);
  const siteId = getSiteId(state);

  if (!accessToken) {
    return;
  }
  try {
    const userProfileDataResponse: AxiosResponse<IUserProfile> = yield call(getUserProfile, accessToken, siteId);
    yield put(setUserProfileState(userProfileDataResponse.data));
  } catch (e) {
    // tslint:disable-next-line: no-console
    console.error(e, 'error in get user profile saga');
  }
}

export function* clearUserProfileSaga() {
  yield put(clearUserProfileState());
}

export function* updateUserProfileSaga(action: IUpdateUserProfileAction) {
  const { payload } = action;
  const state: IState = yield select();
  const accessToken = getPrimaryToken(state);
  const siteId = getSiteId(state);
  if (!accessToken) {
    return;
  }
  try {
    yield call(updateUserProfile, payload, accessToken, siteId);
    yield put(setAccountName(payload.name));
  } catch (e) {
    // tslint:disable-next-line: no-console
    console.error('userProfile saga error: updateUserProfileSaga');
  }
}

export function* updateUserPasswordSaga(action: IUpdateUserPasswordAction) {
  const { oldPassword, newPassword } = action.payload;
  const state: IState = yield select();
  const accessToken = getPrimaryToken(state);
  const siteId = getSiteId(state);

  if (!accessToken) {
    return;
  }

  try {
    yield call(updatePassword, { oldPassword, newPassword, siteId, accessToken });
    yield put(confirmPasswordUpdated());
   } catch (err) {
    // tslint:disable-next-line: no-console
    console.log(err);
    yield put(passwordUpdateError());
   }
}

export default function* userProfileSaga() {
  yield takeEvery(GET_USER_PROFILE, getUserProfileSaga);
  yield takeEvery(LOG_OUT, clearUserProfileSaga);
  yield takeEvery(UPDATE_USER_PROFILE, updateUserProfileSaga);
  yield takeEvery(UPDATE_USER_PASSWORD, updateUserPasswordSaga);
}
