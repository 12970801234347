import {
  IWelcomeScreenAction,
  SET_WELCOME_SCREEN_SEEN,
} from './actions';

export interface IWelcomeScreenState {
  welcomeScreenSeen: boolean;
}

export const getInitialState = (): IWelcomeScreenState => ({
  welcomeScreenSeen: false,
});

const welcomeScreenReducer = (
  state: IWelcomeScreenState = getInitialState(),
  payload: IWelcomeScreenAction): IWelcomeScreenState => {
  switch (payload.type) {
    case SET_WELCOME_SCREEN_SEEN: {
      return {
        ...state,
        welcomeScreenSeen: true,
      };
    }
    default:
      return state;
  }
};

export default welcomeScreenReducer;
