import { INITIAL_LOCALE } from 'config';
import {
  SDK_SET_IFRAMESDK_INIT_LOCALE,
  SDK_SET_PARENT_ORIGIN,
  IIframeAction,
} from './actions';

export interface IIFrameSDKState {
  initIFrameSDKLocale: null | string;
  parentOrigin: null | string;
}

export interface IIFrameState {
  sdk: IIFrameSDKState;
}

const INITIAL_STATE: IIFrameState = {
  sdk: {
    initIFrameSDKLocale: INITIAL_LOCALE,
    parentOrigin: null,
  },
};

export const getInitialState = () => ({
  ...INITIAL_STATE,
});

const iframeReducer = (state = INITIAL_STATE, action: IIframeAction) => {
  const { payload, type } = action;
  switch (type) {
    case SDK_SET_IFRAMESDK_INIT_LOCALE: {
      return {
        ...state,
        sdk: {
          ...state.sdk,
          initIFrameSDKLocale: payload,
        },
      };
    }
    case SDK_SET_PARENT_ORIGIN: {
      return {
        ...state,
        sdk: {
          ...state.sdk,
          parentOrigin: payload,
        },
      };
    }
    default:
      return state;
  }
};

export default iframeReducer;
