import invariant from 'invariant';

import { hasOwn } from 'utils';
import EventBuilder from './EventBuilder';

const VALID_ACTIONS = {
  complete: true,
  fail: true,
  view: true,
};

class GateEventBuilder extends EventBuilder {
  constructor() {
    super('gate');
  }

  buildOntology(action, doc) {
    invariant(
      hasOwn(VALID_ACTIONS, action),
      `Invalid gate action ${JSON.stringify(action)} for buildOntology`,
    );
    const { data } = doc || {};
    const { kind } = data || {};
    invariant(
      data && kind,
      'Gate data or kind missing in buildOntology',
    );
    return {
      /* eslint-disable sort-keys */
      phylum: action,
      class: kind,
      order: doc._id,
      family: data.title, // TODO: Re-evaluate; this somewhat matches Dobi prod, but is kinda wrong
      /* eslint-enable */
    };
  }

  buildMetadata(action, doc, extra) {
    invariant(
      extra,
      'Missing gate extra data for buildMetadata',
    );
    const { kind } = doc.data;
    const base = {
      pageKind: extra.pageKind,
    };
    switch (kind) {
      case 'admin':
        if (action === 'fail') {
          return { ...base, error: 'must be admin' };
        }
        return base;
      case 'age': {
        const meta = {
          ...base,
          minAge: doc.data.age,
        };
        if (action === 'view') {
          return meta;
        }
        const { date, month, year } = extra;
        return {
          ...meta,
          date,
          month,
          year,
        };
      }
      case 'password':
        if (action === 'fail') {
          return {
            ...base,
            error: 'wrong password',
          };
        }
        return base;
      case 'email':
        if (extra.email) {
          return {
            ...base,
            email: extra.email,
          };
        }
        return base;
      case 'login':
        return {
          ...base,
          service: extra.service, // TODO: Verify
        };
      case 'twitch_subscribe':
        return {
          ...base,
          ...extra, // TODO: Whitelist channel/error/username instead of blindly merging this in
        };
      default:
        invariant(
          false,
          `Invalid gate kind for buildMetadata: ${kind}`,
        );
        return null; // Appease linter; invariant will throw
    }
  }
}

export default new GateEventBuilder();
