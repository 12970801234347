import {
  IPollsAction,
  SET_PANEL_POLL_IDS,
  SET_UNSEEN_ITEM_VISITED,
} from './actions';

export interface IPollsSeen {
  questions: string[];
  unseenItem: {
    id?: string;
    visited?: boolean;
  };
}

export interface IPollsState {
  panels: {
    [panelId: string]: IPollsSeen;
  };
}

export const getInitialState = () => ({
  ...INITIAL_STATE,
});

const INITIAL_STATE: IPollsState = {
  panels: {},
};

const pollsReducer = (state: IPollsState = INITIAL_STATE, action: IPollsAction) => {
  switch (action.type) {
    case SET_PANEL_POLL_IDS: {
      const { panelId, pollIds } = action.payload;
      const questions = state.panels[panelId] && state.panels[panelId].questions || [];
      const unseenItemId = pollIds.find((id: string) => !questions.includes(id));
      return {
        ...state,
        panels: {
          ...state.panels,
          [panelId]: {
            ...state.panels[panelId],
            questions: pollIds,
            unseenItem: unseenItemId ? {
              id: unseenItemId,
              visited: false,
            } : {},
          },
        },
      };
    }
    case SET_UNSEEN_ITEM_VISITED: {
      const { panelId, pollId } = action.payload;
      const unseenItemId = (
        state.panels[panelId] &&
        state.panels[panelId].unseenItem &&
        state.panels[panelId].unseenItem.id
      );
      return {
        ...state,
        panels: {
          ...state.panels,
          [panelId]: {
            ...state.panels[panelId],
            ...(
              state.panels[panelId] &&
              pollId === unseenItemId &&
              { unseenItem: { ...state.panels[panelId].unseenItem, visited: true } }
            ),
          },
        },
      };
    }
    default:
      return state;
  }
};

export default pollsReducer;
