
import {
  CLEAR_USER_PROFILE,
  CONFIRM_PASSWORD_UPDATED,
  IUserProfileAction,
  PASSWORD_UPDATE_ERROR,
  SET_USER_PROFILE,
  UPDATE_USER_PROFILE,
  UPDATE_USER_PASSWORD,
} from './actions';
import { PasswordUpdateState } from './models';

interface IUserProfileStateInitial {
  accountId: null;
  icon: null;
  name: null;
  passwordUpdateState: PasswordUpdateState;
}

interface IUserProfileStateLoggedIn {
  accountId: string;
  icon: string | null;
  name: string;
  passwordUpdateState: PasswordUpdateState;
}

export type IUserProfileState = IUserProfileStateLoggedIn | IUserProfileStateInitial;

const INITIAL_STATE: IUserProfileStateInitial = {
  accountId: null,
  icon: null,
  name: null,
  passwordUpdateState: PasswordUpdateState.INITIAL,
};

export const getInitialState = () => ({
  ...INITIAL_STATE,
});

export default function userProfileReducer(state: IUserProfileState = INITIAL_STATE,
  action: IUserProfileAction): IUserProfileState {
  switch (action.type) {
    case CLEAR_USER_PROFILE:
      return {
        accountId: null,
        icon: null,
        name: null,
        passwordUpdateState: PasswordUpdateState.INITIAL,
      };
    case SET_USER_PROFILE:
      return {
        ...state,
        ...action.payload,
      };
    case UPDATE_USER_PROFILE:
      return {
        ...state,
        ...action.payload,
      };
    case UPDATE_USER_PASSWORD:
      return {
        ...state,
        passwordUpdateState: PasswordUpdateState.LOADING,
      };
    case CONFIRM_PASSWORD_UPDATED:
      return {
        ...state,
        passwordUpdateState: PasswordUpdateState.SUCCESS,
      };
    case PASSWORD_UPDATE_ERROR:
      return {
        ...state,
        passwordUpdateState: PasswordUpdateState.ERROR,
      };
    default:
      return state;
  }
}
