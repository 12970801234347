import {
  ADD_DEBUG,
  POST,
  SET_ACTIVE,
  SET_COUNTRY_CODE,
  SET_INITIALIZED,
  SET_PAGE_ID,
  SET_REFERRER,
  SET_SESSION_ID,
  SET_SESSION_START,
  TRACK,
  SET_NEXT_LONG_BEACON_TIME,
  SET_NEXT_SHORT_BEACON_TIME,
  SET_LAST_EVENT_SENT_TIME_MS,
} from './actions';

export const getInitialState = () => (
  {
    active: false,
    countryCode: null,
    debug: {},
    initialized: false,
    lastEvent: {
      kingdom: null,
      metadata: {},
      ontology: {
        class: null,
        family: null,
        genus: null,
        order: null,
        phylum: null,
        species: null,
      },
      time: null,
      url: null,
    },
    meta: {
      insightsVersion: '1.00.0',
    },
    pageId: false,
    post: {},
    referrer: {
      domain: null,
      source: null,
      url: null,
    },
    session: {
      id: null,
      lastEventSentTimeMs: null,
      nextLongBeaconTimeMs: null,
      nextShortBeaconTimeMs: null,
      start: null,
    },
  }
);

const reducer = (state = getInitialState(), { payload, type }) => {
  switch (type) {
    case ADD_DEBUG: {
      const event = payload;
      const sessionId = event.se_id;
      if (!sessionId) {
        return state;
      }
      const sessionData = state.debug[sessionId] || [];

      return {
        ...state,
        debug: {
          ...state.debug,
          [sessionId]: [...sessionData, event],
        },
      };
    }
    case SET_ACTIVE: {
      const active = payload.state;
      return {
        ...state,
        active,
      };
    }
    case SET_COUNTRY_CODE: {
      return {
        ...state,
        countryCode: payload,
      };
    }
    case SET_INITIALIZED: {
      const initialized = payload;
      return {
        ...state,
        initialized,
      };
    }
    case SET_LAST_EVENT_SENT_TIME_MS: {
      return {
        ...state,
        session: {
          ...state.session,
          lastEventSentTimeMs: payload,
        },
      };
    }
    case SET_NEXT_LONG_BEACON_TIME: {
      return {
        ...state,
        session: {
          ...state.session,
          nextLongBeaconTimeMs: payload,
        },
      };
    }
    case SET_NEXT_SHORT_BEACON_TIME: {
      return {
        ...state,
        session: {
          ...state.session,
          nextShortBeaconTimeMs: payload,
        },
      };
    }
    case SET_PAGE_ID: {
      const pageId = payload;
      return {
        ...state,
        pageId,
      };
    }
    case SET_SESSION_ID: {
      return {
        ...state,
        session: {
          ...state.session,
          id: payload,
        },
      };
    }
    case SET_REFERRER: {
      const { domain, source, url } = payload;
      return {
        ...state,
        referrer: {
          ...state.referrer,
          domain,
          source,
          url,
        },
      };
    }
    case SET_SESSION_START: {
      return {
        ...state,
        session: {
          ...state.session,
          start: payload,
        },
      };
    }
    case TRACK: {
      const { ontology = {}, metadata, url } = payload;
      const { kingdom, phylum, order, family, genus, species } = ontology;
      return {
        ...state,
        lastEvent: {
          metadata,
          ontology: {
            class: ontology.class,
            family,
            genus,
            kingdom,
            order,
            phylum,
            species,
          },
          url,
        },
      };
    }
    case POST: {
      const event = payload;
      return {
        ...state,
        post: event,
      };
    }
    default:
      return state;
  }
};

export default reducer;
