import { isEmpty } from 'lodash';
import { createSelector } from 'reselect';
import IState from 'services/state';

export type IOverlayGeneric = {
  [otherStrings:string]: any;
  broadcastId?: string;
  description?: string;
  duration?: number;
  imageUrl?: string;
  key?: string;
  name?: string;
  overlayId?: string;
  overlayType?: string;
  playerId?: string;
  poll?: any;
  questionId?: string;
  status?: any;
};

export const getOverlayById = createSelector(
  (state: IState, id: string) => state.overlays.list.find((item) => item.payload.data.broadcastId === id),
  (overlay) => overlay,
);

export const getOverlayByKey = createSelector(
  (state: IState, key: string) => state.overlays.list.find((item) => item.key === key),
  (overlay) => overlay,
);

export const getIsPendingOverlay = (state: IState) => state.overlays.isPending;

export const getLastOverlay = createSelector(
  // eslint-disable-next-line max-len
  (state: IState) => (state.overlays.list.length > 0 ? state.overlays.list[state.overlays.list.length - 1] : null),
  (overlay) => overlay,
);

export const getOverlayBeingPreviewed = createSelector(
  (state: IState) => state.overlays.preview,
  (preview) => preview,
);

export const getOverlayBeingEdited = createSelector(
  (state: IState) => state.overlays.editing,
  (editing) => editing,
);

export const getOverlays = (state: IState) => state.overlays.list;

export const getOverlayPanelDoc = createSelector(
  (renderer: any, broadcastId: string, questionId: string) => {
    const {
      payload: {
        data: { poll, kind },
      },
    } = renderer;
    const isResult = kind?.includes('_results');
    const questions = [
      {
        kind: 'poll',
        poll,
      },
    ];

    return {
      doc: {
        data: {
          broadcastId,
          isResult,
          kind: 'multiple_choice',
          questionId,
          questions,
        },
        overlayRenderer: renderer,
      },
    };
  },
  (panelDoc) => panelDoc,
);

export const haveOverlaysBeenDisplayed = createSelector(
  getOverlays, getOverlayBeingEdited, getOverlayBeingPreviewed,
  (overlays, editingOverlay, previewingOverlay) => {
    return !isEmpty(overlays) || Boolean(editingOverlay) || Boolean(previewingOverlay);
  },
);

export default {
  getIsPendingOverlay,
  getLastOverlay,
  getOverlayBeingEdited,
  getOverlayBeingPreviewed,
  getOverlayById,
  getOverlayByKey,
  getOverlayPanelDoc,
  getOverlays,
};
