import { fork } from 'redux-saga/effects';
import { takeEvery } from 'redux-utils';
import { SET_VIEW_MODE } from 'services/user-layout/actions';
import { handleViewModeUpdate } from './sagas/handleViewModeUpdate';
import {
  watchDisableDeviceLimitSaga,
  onDeviceLimitConfigChangeSaga,
} from './sagas/watchDisableDeviceLimitSaga';
import { UPDATE_DOCUMENT } from 'services/realtime/actions';

const videoSaga = function* () {
  yield takeEvery(SET_VIEW_MODE, handleViewModeUpdate);
  yield takeEvery(UPDATE_DOCUMENT, onDeviceLimitConfigChangeSaga);
  yield fork(watchDisableDeviceLimitSaga);
};

export default videoSaga;
