export enum UbisoftEnvironmentType {
  uat = 'uat',
  prod = 'prod',
}

export enum UbisoftPlatformType {
  pc = 'PC',
  xone = 'XONE',
  ps4 = 'PS4',
}

export type IUbisoftAdobe = {
  action?: string;
  country?: string;
  enablePrivacy?: boolean;
  environment?: UbisoftEnvironmentType;
  hostDomain?: string;
  language?: string;
  loggedIn?: boolean;
  loggedOut?: boolean;
  pageName?: string;
  platformSelection?: UbisoftPlatformType;
  siteName: string;
  siteSection?: string;
  siteSubSection?: string;
  siteType: string;
  ubisoftId?: string;
};

export enum Clients {
  Ubisoft = 'live.ubisoft.com',
}
