import { makeReducer } from 'redux-utils';
import * as actions from './actions';

export type IRealtimeState = {
  documents: {
    [key: string]: any;
  },
  listeners: {
    [key: string]: number;
  };
};

export const getInitialState = (): IRealtimeState => ({
  documents: {},
  listeners: {},
});

const realtimeReducer = makeReducer(
  actions,
  getInitialState,
  (state, action) => {
    switch (action.type) {
      case actions.CLEANUP_PATH: {
        const path = action.payload;
        const {
          [path]: without,
          ...otherDocuments
        } = state.documents;

        const {
          [path]: listener,
          ...otherListeners
        } = state.listeners;

        return {
          ...state,
          documents: otherDocuments,
          listeners: otherListeners,
        };
      }

      case actions.INCREMENT_LISTENER: {
        const path = action.payload;
        return {
          ...state,
          listeners: {
            ...state.listeners,
            [path]: (state.listeners[path] || 0) + 1,
          },
        };
      }

      case actions.DECREMENT_LISTENER: {
        const path = action.payload;
        return {
          ...state,
          listeners: {
            ...state.listeners,
            [path]: Math.max(state.listeners[path], 1) - 1,
          },
        };
      }

      case actions.UPDATE_DOCUMENT: {
        const { path, value } = action.payload;
        const { [path]: oldValue, ...others } = state.documents;

        if (oldValue === undefined && value === undefined) {
          // TODO: Log a warning
          return state;
        }

        return {
          ...state,
          documents: {
            ...others,
            ...(value !== undefined && { [path]: value }),
          },
        };
      }

      default:
        return state;
    }
  },
);

export default realtimeReducer;
