import { createSelector } from 'reselect';
import { ActionKey } from 'services/admin/constants';
import { getActiveSiteOverlays, getActiveSitePanels, getActiveSiteQuestActions } from 'services/app/selectors';
import { getActiveSiteFeatures } from 'services/app/selectors/common';
import IState from 'services/state';
import { Overlay, Panel, FeaturesWithOverlays, FeaturesWithPanels, Feature } from './constants';

export const isEnabled = (map: Record<string, string | boolean>, key: string) => Boolean(map[key]);

const kindExistInMap = (kindMap: Record<string, string | boolean>, kind: string) => {
  return Object.values(kindMap).includes(kind);
};

export function isFeatureEnabled(state: IState, feature: string) {
  const featureMap = getActiveSiteFeatures(state);
  return isEnabled(featureMap, feature);
}

export const getIsShopifyEnabled = createSelector(
  getActiveSiteFeatures,
  (features) => isEnabled(features, 'shopify'),
);

export const getIsSimpleModeEnabled = createSelector(
  getActiveSiteFeatures,
  (features) => isEnabled(features, Feature.SIMPLE_MODE),
);

export const getIsPagesV3Enabled = createSelector(
  getActiveSiteFeatures,
  (features) => isEnabled(features, Feature.PAGES_V3),
);

export const getIsFortniteLeaderboardEnabled = createSelector(
  getActiveSiteFeatures,
  (features) => isEnabled(features, Feature.FORTNITE_SINGLES_LEADERBOARD_V2),
);

export const getIsQuestsBlockEnabled = createSelector(
  getActiveSiteFeatures,
  (features) => isEnabled(features, Feature.QUESTS_BLOCK),
);

export const getIsRichTextEditorV3Enabled = createSelector(
  getActiveSiteFeatures,
  (features) => isEnabled(features, Feature.RICH_TEXT_EDITOR_V3),
);

export const isGoogleAnalyticsDisabled = createSelector(
  getActiveSiteFeatures,
  (features) => isEnabled(features, Feature.DISABLE_GTM),
);

export const shouldHideMenuItem = (state: IState, actionKey: ActionKey) => {
  switch (actionKey) {
    case ActionKey.achievements:
      return !isFeatureEnabled(state, Feature.ACHIEVEMENTS);
    case ActionKey.billing:
      return !isFeatureEnabled(state, Feature.BILLING);
    case ActionKey.developer:
      return !isFeatureEnabled(state, Feature.DEVELOPER);
    case ActionKey.thirdParty:
      return isFeatureEnabled(state, Feature.HIDE_THIRD_PARTY_TAB);
    case ActionKey.admins:
      return isFeatureEnabled(state, Feature.HIDE_ADMINS_TAB);
    case ActionKey.icons:
      return isFeatureEnabled(state, Feature.HIDE_ICONS_TAB);
    case ActionKey.login:
      return isFeatureEnabled(state, Feature.HIDE_LOGIN_TAB);
    case ActionKey.receipts:
      return isFeatureEnabled(state, Feature.HIDE_RECEIPTS_TAB);
    case ActionKey.userProfile:
      return isFeatureEnabled(state, Feature.HIDE_USER_PROFILES_TAB);
    case ActionKey.videoControls:
      return isFeatureEnabled(state, Feature.HIDE_VIDEO_PLAYER_TAB);
    default:
      return false;
  }
};

export function isQuestActionsEnabled(state: IState, questAction: string) {
  const questActionMap = getActiveSiteQuestActions(state);
  return isEnabled(questActionMap, questAction);
}

export function isPanelEnabled(state: IState, panelType: string) {
  if (FeaturesWithPanels.includes(panelType)) {
    return isFeatureEnabled(state, panelType);
  }
  if (!kindExistInMap(Panel, panelType)) {
    return true;
  }
  const panelMap = getActiveSitePanels(state);
  return isEnabled(panelMap, panelType);
}

export function isOverlayEnabled(state: IState, overlayType: string) {
  if (FeaturesWithOverlays.includes(overlayType)) {
    return isFeatureEnabled(state, overlayType);
  }
  if (!kindExistInMap(Overlay, overlayType)) {
    return true;
  }
  const overlayMap = getActiveSiteOverlays(state);
  return isEnabled(overlayMap, overlayType);
}
