import axios, { AxiosRequestConfig } from 'axios';

import { PAGE_SERVICE_V3_BASE_URL } from 'config';
import { kebabCase, snakeCase } from 'lodash';
import { IObject } from 'models';
import { transformKeysDeep } from 'shared/object-utils';
import { snakeify } from 'shared/string-utils';
import maestroApiRequest from 'services/maestro-api-client';

interface PageUpdateOptions {
  pageId: string,
  pageServiceURL: string,
  primaryToken: string,
  siteId: string,
  update: Partial<IObject>,
}

export async function pageUpdateRequest({ pageId, pageServiceURL, siteId, primaryToken, update }: PageUpdateOptions): Promise<boolean> {
  const config: AxiosRequestConfig = {
    headers: {
      'Authorization': `Bearer ${primaryToken}`,
      'Content-Type': 'application/json',
      'x-maestro-client-id': siteId,
    },
  };
  const body = transformKeysDeep(update, snakeCase);
  const { status } = await axios.patch(`${pageServiceURL}/${pageId}`, body, config);
  return status >= 200 && status <= 299;
}

export const fetchChannelsAndLandings = async (
  {
    captureCanceler,
    criteria = {},
    options = {},
    pageServiceURL,
    siteId,
  }: {
    captureCanceler?: (cancelFn: () => unknown) => unknown;
    criteria?: {};
    options?: {};
    pageServiceURL: string;
    siteId: string;
  },
): Promise<IObject[]> => {
  const { data } = await axios.get(
    `${pageServiceURL}/channel/channels-landings?options=${JSON.stringify(options)}&criteria=${JSON.stringify(criteria)}`,
    {
      headers: {
        'x-maestro-client-id': siteId,
      },
      cancelToken: new axios.CancelToken(
        (canceler) => captureCanceler?.(canceler),
      ),
    },
  );

  return data;
};

interface ClonePageOptions {
  pageId: string;
  pageName: string;
  pageServiceURL: string;
  pageSlug: string;
  primaryToken: string;
  siteId: string;
}

export async function requestPageClone({
  pageId,
  pageName,
  pageServiceURL,
  pageSlug,
  siteId,
  primaryToken,
}: ClonePageOptions): Promise<IObject> {
  const config: AxiosRequestConfig = {
    headers: {
      Authorization: `Bearer ${primaryToken}`,
      'x-maestro-client-id': siteId,
    },
  };
  const { data } = await axios.post(
    `${pageServiceURL}/clone/${pageId}?localizedTitle=${pageName}&localizedSlug=${kebabCase(pageSlug)}`
    , undefined, config,
  );
  return data;
}

export async function upsertPage({
  doc,
  primaryToken,
  siteId,
}: {
  doc: any;
  primaryToken: string;
  siteId: string;
}): Promise<IObject> {
  const config: AxiosRequestConfig = {
    headers: {
      Authorization: `Bearer ${primaryToken}`,
      'x-maestro-client-id': siteId,
    },
  };
  const { data } = await axios.post(
    `${PAGE_SERVICE_V3_BASE_URL}/upsert`, snakeify(doc), config,
  );
  return data;
}

export const embedBlockIframe = async ({
  primaryToken,
  htmlData,
  siteId,
  pageId,
  blockId,
}: {
    blockId: string;
    htmlData: string;
    pageId: string;
    primaryToken: string;
    siteId: string;
}): Promise<string> => {
  const { data } = await maestroApiRequest({
    freshInstance: true,
    primaryToken,
    siteId,
  })
    .post<{ url: string }>(
      `${PAGE_SERVICE_V3_BASE_URL}/content/embed-block/iframe`,
      { htmlData, pageId, blockId },
    );

  return data.url;
};

export const fetchEmbedBlockCode = async (url: string): Promise<string> => {
  const { data } = await axios.get<string>(url);
  return data;
};
