import { ITipSuggestedAmounts } from './models';
// declare all action strings

import { PaymentMethod, Stripe, StripeCardNumberElement } from '@stripe/stripe-js';

export const SET_TIP_AMOUNT = 'tipping/SET_TIP_AMOUNT';
export const SET_TIP_MESSAGE = 'tipping/SET_TIP_MESSAGE';
export const CHARGE_TIP = 'tipping/CHARGE_TIP';
export const CHARGE_TIP_SUCCESS = 'tipping/CHARGE_TIP_SUCCESS';
export const CHARGE_TIP_FAILURE = 'tipping/CHARGE_TIP_FAILURE';
export const CLOSE_SUCCESS_VIEW = 'tipping/CLOSE_SUCCESS_VIEW';
export const SET_TIP_SUGGESTED_AMOUNTS = 'tipping/SET_TIP_SUGGESTED_AMOUNTS';
export const TOGGLE_MESSAGE_VISIBILITY = 'tipping/TOGGLE_MESSAGE_VISIBILITY';
export const SET_IS_ADDING_NEW_PAYMENT_METHOD = 'tipping/SET_IS_ADDING_NEW_PAYMENT_METHOD';
export const REFRESH_IDEMPOTENCY_KEY = 'tipping/REFRESH_IDEMPOTENCY_KEY';

// declare all actions type
export type ITippingAction =
  | ISetTipAmountAction
  | ISetTipMessageAction
  | IChargeTipAction
  | IChargeTipSuccessAction
  | IChargeTipFailureAction
  | ICloseSuccessViewAction
  | ISetTipSuggestedAmounts
  | IToggleMessageVisibilityAction
  | IRefreshIdempotencyKeyAction;

// declare action interfaces
export interface IRefreshIdempotencyKeyAction {
  type: typeof REFRESH_IDEMPOTENCY_KEY;
}

export interface IToggleMessageVisibilityAction {
  payload: IToggleMessageVisibilityPayload;
  type: typeof TOGGLE_MESSAGE_VISIBILITY;
}

export interface ISetTipAmountAction {
  payload: ISetTipAmountPayload;
  type: typeof SET_TIP_AMOUNT;
}

export interface ISetTipMessageAction {
  payload: ISetTipMessagePayload;
  type: typeof SET_TIP_MESSAGE;
}

export interface IChargeTipAction {
  payload: IChargeTipPayload;
  type: typeof CHARGE_TIP;
}

export interface IChargeTipSuccessAction {
  type: typeof CHARGE_TIP_SUCCESS;
}

export interface ICloseSuccessViewAction {
  type: typeof CLOSE_SUCCESS_VIEW;
}

export interface ISetTipSuggestedAmounts {
  payload: ITipSuggestedAmounts;
  type: typeof SET_TIP_SUGGESTED_AMOUNTS;
}

export interface IChargeTipFailureAction {
  type: typeof CHARGE_TIP_FAILURE;
}

// declare payload interfaces
export interface IToggleMessageVisibilityPayload {
  id: string;
  panelId: string;
  toggle: boolean;
}

export interface ISetTipAmountPayload {
  amount: number;
}

export interface ISetTipMessagePayload {
  message: string;
}

export interface IChargeTipPayload {
  element?: StripeCardNumberElement;
  makePrimaryPaymentMethod?: boolean;
  paymentMethod?: PaymentMethod;
  savePaymentMethod?: boolean;
  stripe?: Stripe;
  usingPaymentRequestButtonForm: boolean;
}

// declare action functions
export const refreshIdempotencyKey = (): IRefreshIdempotencyKeyAction => {
  return {
    type: REFRESH_IDEMPOTENCY_KEY,
  };
};

export const setTipSuggestedAmounts = (payload: ITipSuggestedAmounts): ISetTipSuggestedAmounts => ({
  payload,
  type: SET_TIP_SUGGESTED_AMOUNTS,
});

export const toggleMessageVisibility = (payload: IToggleMessageVisibilityPayload): IToggleMessageVisibilityAction => ({
  payload,
  type: TOGGLE_MESSAGE_VISIBILITY,
});

export const setTipAmount = (payload: ISetTipAmountPayload): ISetTipAmountAction => ({
  payload,
  type: SET_TIP_AMOUNT,
});

export const setTipMessage = (payload: ISetTipMessagePayload): ISetTipMessageAction => ({
  payload,
  type: SET_TIP_MESSAGE,
});

export const chargeTip = (payload: IChargeTipPayload): IChargeTipAction => ({
  payload,
  type: CHARGE_TIP,
});

export const chargeTipSuccess = (): IChargeTipSuccessAction => ({
  type: CHARGE_TIP_SUCCESS,
});

export const closeSuccessView = (): ICloseSuccessViewAction => ({
  type: CLOSE_SUCCESS_VIEW,
});

export const chargeTipFailure = (): IChargeTipFailureAction => ({
  type: CHARGE_TIP_FAILURE,
});


