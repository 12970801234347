/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/camelcase */
import { createSelector } from 'reselect';
import IState from '../state';

export const getShopState = createSelector(
  (state: IState) => state.shopify,
  (shopify) => shopify,
);

export const getMoneyFormat = (state: IState) => state.shopify.moneyFormat;
export const getShopCurrencyCode = (state: IState) => state.shopify.currencyCode; // Default is USD

export const getBillingAddress = createSelector(
  (state: IState) => state.shopify.billingAddress,
  (billingAddress) => billingAddress,
);

export const getProducts = createSelector(
  (state: IState) => state.shopify.products,
  (products) => products,
);

const getCollections = createSelector(
  (state: IState) => state.shopify.collections,
  (collections) => collections,
);

export const getShop = (state: IState) => state.shopify.shop;

export const getShopRecurringChargeInfo = (state: IState) => state.shopify.recurringCharge;

export const getIsShopifyConnected = (state: IState) => Boolean(state.shopify.shop && state.shopify.shopAccessToken);

export const getConnectionError = (state: IState) => state.shopify.connection.error;

export const getConnectionRedirectionUrl = (state: IState) => state.shopify.connection.redirectionUrl;

export const getShopConnectionString = (state: IState) => state.shopify.connection.connectionString;

export const shopConnectionFlowTriggered = (state: IState) => state.shopify.connection.flowTriggered;

export const isConnectingShop = (state: IState) => state.shopify.connection.ongoing;

export const isProductLoading = (state: IState) => state.shopify.loadingProducts;

export const isCollectionLoading = (state: IState) => state.shopify.loadingCollections;

export const getProductsImages = createSelector([getProducts], (items) =>
  items.map((item) => ({
    alt: (item.images && item.images.edges && item.images.edges.length > 0 && item.images.edges[0].node.altText) || '',
    productId: item.id,
    src:
      (item?.images && item?.images?.edges && item?.images?.edges?.length > 0 && item?.images?.edges?.[0].node?.url) || '',
  })),
);

export const getProductVariants = createSelector([getProducts], (items) =>
  items.map((item) => ({
    productId: item.id,
    variants: item.variants.edges.map((variant) => variant.node),
  })),
);

export const getProductsCatalog = createSelector([getProducts], (items) =>
  items.map((item) => ({
    handle: item.handle,
    label: item.title,
    value: item.id,
  })),
);

export const getProductById = (state: IState, productId: string) => {
  const products = state.shopify.products;
  return products.find((product) => product.id === productId);
};

export const getProductsByIds = (state: IState, productIds: string[]) => {
  const products = state.shopify.products;
  return products.filter((product) => productIds.includes(product.id));
};

export const getProductsByVariantIds = (state: IState, variantIds: string[]) => {
  const products = state.shopify.products;
  return products.filter((product) => product.variants.edges.some(variant => variantIds.includes(variant.node.id)));
};

export const getCollectionsCatalog = createSelector([getCollections], (items) =>
  items.map((item) => ({
    handle: item.handle,
    label: item.title,
    value: item.id,
  })),
);

export const getShopifyCatalogs = createSelector(
  [getProductsCatalog, getCollectionsCatalog, getProductsImages, getProductVariants],
  (products, collections, images, variants) => {
    return {
      collections,
      images,
      products,
      variants,
    };
  },
);

export const getShopAccessToken = (state: IState) => state.shopify.shopAccessToken;

export const getShopifyPaymentsAccountId = (state: IState) => state.shopify.shopifyPaymentsAccountId;

export const isShopInfoLoading = (state: IState) => state.shopify.loadingShopCredentials;

export const getSingleUseSourceCard = (state: IState) => state.shopify.singleUseSourceCard.length > 0 ? state.shopify.singleUseSourceCard[0] : null;

export const getProductsPageInfo = (state: IState) => state.shopify.pageInfo.products;

export const getCollectionsPageInfo = (state: IState) => state.shopify.pageInfo.collections;

export const getBundleCheckout = (state: IState) => state.shopify.bundle.checkout;

export const getBundleLoading = (state: IState) => state.shopify.bundle.loading;

export const getBundleError = (state: IState) => state.shopify.bundle.error;

export const getBundleSuccess = (state: IState) => state.shopify.bundle.success;

export const getPaymentToken = (state: IState) => state.shopify.token;

