import { call, put, select, takeEvery, all } from 'redux-saga/effects';
import { SET_OBJECT } from 'services/app/actions';
import { getPageName, getPageType, getSiteSlug } from 'services/app/selectors';
import { getCountryCode } from 'services/insights/selectors';
import { SET_COUNTRY_CODE } from 'services/insights/actions';
import {
  OauthProvider,
  TRACKING_ACCEPTED,
  trackingAccepted,
} from 'services/auth/actions';
import {
  getProviderId,
  getThirdPartyAuthProviders,
  getUserEmail,
  getUserService,
  isLoggedIn,
} from 'services/auth/selectors';
import IState from 'services/state';
import {
  initializeUbisoft,
  IOnClaimSuccessAction,
  IOnQuestAccessTokenAction,
  IOnClickAction,
  ON_CLAIM_SUCCESS,
  ON_QUEST_ACCESS_TOKEN,
  ON_NAV_CLICK,
  ON_VIDEO_LINK_CLICK,
  setActive,
  updateUbisoft,
  UPDATE_UBISOFT,
  ON_SOCIAL_CLICK,
  ON_SOCIAL_SHARE_CLICK,
} from './actions';

import {
  callUbisoftTracker,
  initializeUbisoftSDK,
  loadUbisoft,
  waitForUbisoftPrivacyAccept,
} from './api';

import {
  Clients,
  IUbisoftAdobe,
} from './models';

import {
  getActive,
  getClient,
  getData,
} from './selectors';
import { LOG_IN_SUCCESS, LOG_OUT } from '../auth';
import { REPLACE, IReplaceAction } from 'services/app-router/actions';

const imediateInitialization = function* () {
  const state: IState = yield select();
  const siteSlug = getSiteSlug(state);
  switch (siteSlug) {
    case 'ubisoft-sandbox':
    case 'ubisoft-4': {
      yield put(initializeUbisoft());
      yield call(updateUbisoftGlobalSaga);
      yield call(loadUbisoft);
      yield all([call(initializeUbisoftSDK, window.wa_data), call(waitForUbisoftPrivacyAccept)]);
      yield put(setActive(true));
      yield put(trackingAccepted());
    }
    default: {
      break;
    }
  }
};

const initializeSaga = function* () {
  const state: IState = yield select();
  const siteSlug = getSiteSlug(state);
  switch (siteSlug) {
    case 'ubisoft-sandbox':
    case 'ubisoft-4': {
      if (!getActive(state)) {
        return;
      }
      try {
        const data = yield getData(state);
        const countryCode = yield getCountryCode(state);
        yield put(updateUbisoft({ ...window.wa_data, ...data, country: countryCode }));
        yield onAuthChangeSaga();
        yield onPageviewChangeSaga();
        // yield put(setActive(true));
      } catch (err) {
        yield put(setActive(false));
        // tslint:disable-next-line no-console
        console.error(`error loading ubisoft adobe: ${err}`);
      }
      break;
    }
    default: {
      yield put(setActive(false));
      break;
    }
  }
};

const updateUbisoftGlobalSaga = function* () {
  const data = yield select(getData);
  window.wa_data = { ...data };
};

const onPageviewChangeSaga = function* () {
  const state = yield select();
  const client = getClient(state);

  switch (client) {
    case Clients.Ubisoft: {
      const data = getData(state) as IUbisoftAdobe;
      const isActive = getActive(state);
      if (!isActive) {
        return;
      }
      const countryCode = getCountryCode(state);
      const siteSection = 'homepage';
      const siteSubSection = undefined;
      const pageName = 'main'; // Ubisoft now wants pageName to be constant
      yield put(updateUbisoft({
        ...data,
        country: countryCode ?? undefined,
        pageName,
        siteSection,
        siteSubSection,
      }));
      yield call(callUbisoftTracker, 'pageevent', window.wa_data as IUbisoftAdobe);
      break;
    }
    default: {
      return;
    }
  }
};

const onAuthChangeSaga = function* () {
  const state = yield select();
  const client = getClient(state);

  switch (client) {
    case Clients.Ubisoft: {
      const data = getData(state) as IUbisoftAdobe;
      const loggedIn = isLoggedIn(state);
      const countryCode = getCountryCode(state);
      let loginInfo: any = {
        loggedIn: false,
        loggedOut: true,
        ubisoftId: null,
      };
      if (loggedIn) {
        const service = getUserService(state);
        const email = getUserEmail(state);
        const providers = getThirdPartyAuthProviders(state);
        const providerID = service === 'email' ? email : getProviderId(
          providers!, service as OauthProvider,
        );
        if (providerID) {
          loginInfo = {
            loggedIn: true,
            loggedOut: false,
            ubisoftId: providerID,
          };
        }
      }
      yield put(updateUbisoft({ ...data, ...loginInfo, country: countryCode }));
      yield call(callUbisoftTracker, 'accountevent', window.wa_data as IUbisoftAdobe);
      break;
    }
    default: {
      return;
    }
  }
};

const onVideoChangeSaga = function* (action: IReplaceAction) {
  const videoId = action.payload.query?.v;
  if (!videoId) {
    return;
  }
  const state = yield select();
  const client = getClient(state);

  switch (client) {
    case Clients.Ubisoft: {
      const videoGame = 'unknown';
      const data = getData(state) as IUbisoftAdobe;
      yield put(updateUbisoft({
        ...data,
        pageName: videoId,
        siteSection: 'video pages',
        siteSubSection: videoGame,
      }));
      yield call(callUbisoftTracker, 'pageevent', window.wa_data as IUbisoftAdobe);
      break;
    }
    default: {
      return;
    }
  }
};

const onClickSaga = function* (action: IOnClickAction) {
  const { payload } = action;
  const state = yield select();
  const client = getClient(state);
  const active = getActive(state);
  if (!active) {
    return;
  }

  switch (client) {
    case Clients.Ubisoft: {
      if (payload.action === 'headerLogo') {
        payload.action = 'ubisoft logo';
      }
      const data = getData(state) as IUbisoftAdobe;
      yield put(updateUbisoft({
        ...data,
        ...payload,
      }));
      yield call(callUbisoftTracker, 'clickevent', window.wa_data as IUbisoftAdobe);
      break;
    }
    default: {
      return;
    }
  }
};

const thirdPartyAnalyticsSaga = function* () {
  yield takeEvery(SET_COUNTRY_CODE, imediateInitialization);
  yield takeEvery(TRACKING_ACCEPTED, initializeSaga);

  // page change
  yield takeEvery(SET_OBJECT, onPageviewChangeSaga);
  yield takeEvery([LOG_IN_SUCCESS, LOG_OUT], onAuthChangeSaga);
  yield takeEvery(REPLACE, onVideoChangeSaga);

  yield takeEvery([ON_NAV_CLICK, ON_SOCIAL_CLICK, ON_VIDEO_LINK_CLICK, ON_SOCIAL_SHARE_CLICK], onClickSaga);

  // specific playtform actions
  yield takeEvery(UPDATE_UBISOFT, updateUbisoftGlobalSaga);
};

export default thirdPartyAnalyticsSaga;
