import { useRef, useEffect, Dispatch, SetStateAction, useCallback } from 'react';
import { useSelector, useDispatch } from 'hooks';
import { subscribe, unsubscribe } from 'services/realtime';
import { TranscodingStatuses, IVideo } from 'models';

export const useWatchTranscodingVideo = (
  setVideos: Dispatch<SetStateAction<IVideo[]>>,
) => {
  const dispatch = useDispatch();
  const { documents: realtimeDocuments } = useSelector((state) => state.realtime);
  const transcodingVideoIds = useRef(new Set<string>());

  useEffect(
    // handles realtime updates at most once every second
    () => {
      const timeout = setTimeout(
        () => {
          const completedVideos = new Map<string, string>();

          transcodingVideoIds.current.forEach(
            (id) => {
              const path = `video/${id}`;
              const realtimeDoc = realtimeDocuments?.[path];
              if (realtimeDoc?.uploadStatus === TranscodingStatuses.complete) {
                dispatch(unsubscribe(path));
                transcodingVideoIds.current.delete(id);
                completedVideos.set(id, realtimeDoc.url);
              }
            },
          );

          setVideos(
            (prevVideos) => prevVideos.map(
              (v) => completedVideos.has(v._id!)
                ? {
                  ...v,
                  url: completedVideos.get(v._id!),
                  uploadStatus: TranscodingStatuses.complete,
                }
                : v,
            ),
          );
        },
        1000,
      );

      return () => clearTimeout(timeout);
    },
    [realtimeDocuments, setVideos],
  );

  const watchTranscodingVideo = useCallback(
    (videoId: string) => {
      if (transcodingVideoIds.current.has(videoId))
        return;

      transcodingVideoIds.current.add(videoId);
      dispatch(
        subscribe(`video/${videoId}`),
      );
    },
    [],
  );

  return {
    watchTranscodingVideo,
  };
};
