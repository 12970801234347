import { STATIC_BASE_URL } from 'config';
import { ThemeTypes, THEME_TYPES } from 'models/ITheme';
import ISubscription from 'models/ISubscription';
import { createObjectId, createSlugFromString, getDefaultRichTextEditorData } from 'utils';
import IGate, { GateKind } from 'models/IGate';
import { getDefaultEditorState, getDefaultTextNode } from 'components/ui/RichTextEditorV3/getDefaultEditorState';
import INavigation, { INavigationParent } from 'models/INavigation';
import { IObject } from 'models';

const LANDING_CONTENT_IMAGE_SRC = `${STATIC_BASE_URL}/media/624740802a777a0027863745/624777f2b1cb79009e2557d4.jpg`;

export interface ICreateBaseGateObject {
  channelName: string;
  eventDate: number | null;
  subscriptions: ISubscription;
}

interface IChannelData {
  gate: IGate;
  landing_content?: any[];
  name: string;
  theme?: IThemeObject;
}

interface IThemeObject {
  classic_theme_options: IClassicThemeOptions;
  id: string;
  type: ThemeTypes;
}

interface IClassicThemeOptions {
  accent_primary: string;
  accent_secondary: string;
}

interface IChannelSeo {
  title: string;
  [key: string]: any;
}

type ChannelTypes = 'channel' | 'landing';

export interface BaseSiteData {
  _id: string;
  active_renderers?: Record<string, boolean>;
  collection: string;
  created: number;
  data: IChannelData;
  last_modified: number;
  modified: number;
  renderers: any;
  seo: IChannelSeo;
  site_id: string;
  slug: string;
  type: ChannelTypes;
}

export interface BasePanelData {
  _id: string;
  collection: 'panels';
  created: number;
  last_modified: number;
  modified: number;
  renderer: Record<string, any>;
  site_id: string;
  slug: string;
  type: 'panel';
}

export interface ICreateBaseChannel {
  channelName: string;
  channelType: ChannelTypes;
  gate: IGate;
  panels?: BasePanelData[];
  playlistId?: string;
  siteId: string;
  themeId?: string;
}
export interface ICreateBasePanel {
  renderer: Record<string, any>;
  siteId: string;
}

export interface IUpdateSiteStructure {
  page: BaseSiteData;
  siteStructure: INavigation;
}

const createArrayId = (): string => {
  const arrayId: string[] = [];
  const possible = 'abcdefghijklmnopqrstuvwxyz0123456789';
  for (let i = 0; i < 9; i++) {
    arrayId.push(possible.charAt(Math.floor(Math.random() * possible.length)));
  }
  return arrayId.join('');
};

const getLandingContent = (playlistId: string) => ({
  landing_content: [
    {
      array_id: createArrayId(),
      data: {
        button_text: 'WATCH NOW!',
        image_gradient: false,
        image_src: LANDING_CONTENT_IMAGE_SRC,
        link_data: null,
        link_type: 'video',
        spotlight_text: 'Welcome to my video collection',
      },
      kind: 'spotlight',
    },
    {
      array_id: createArrayId(),
      kind: 'playlist',
      playlist: {
        _id: playlistId,
      },
    },
  ],
});

const getPanelsData = (panels: BasePanelData[]) => ({
  sidebar: {
    items: panels.map(panel => ({
      array_id: createArrayId(),
      id: panel._id,
    })),
  },
});

const getActiveRendererData = () => ({
  active_renderers: {
    sidebar: true,
  },
});

export const createBaseGateObject = ({
  subscriptions,
  channelName,
  eventDate = null,
}: ICreateBaseGateObject): IGate => {
  const subtitle = 'The subtitle for your gate goes here.  Tease your audience and share the value they will get if they pay for your content. Click “Access” on the nav bar to edit this area.';

  return {
    active: true,
    gate: {
      bundles: [],
      hiddenBundles: [],
      hiddenEntitlements: [],
      loginMessage: '',
      name: channelName,
      navigation: {
        state: 'on',
        items: [],
      },
      subscriptionSubtitle: subtitle,
      subscriptionTitle: channelName,
      subtitleRawData: getDefaultRichTextEditorData({
        text: channelName,
        fontSize: 14,
      }),
      titleRawData: getDefaultRichTextEditorData({
        text: channelName,
        fontSize: 30,
      }),
      titleRawDataV2: getDefaultEditorState([getDefaultTextNode({ text: channelName, fontSize: 30 })]),
      subtitleRawDataV2: getDefaultEditorState([getDefaultTextNode({ text: subtitle, fontSize: 14 })]),
      subscriptions: [subscriptions],
      subtitle,
      title: channelName,
      subscriptionTimestamp: eventDate,
    },
    kind: GateKind.SUBSCRIPTION,
  };
};

export const createBaseChannelObject = ({
  channelName,
  channelType,
  gate,
  panels,
  playlistId,
  siteId,
  themeId,
}: ICreateBaseChannel): BaseSiteData => {
  const isLandingPage = channelType === 'landing';
  const hasPlaylist = !!playlistId;
  const shouldHaveLandingContent = isLandingPage && hasPlaylist;
  const shouldHavePanels = !!panels && Array.isArray(panels) && !isLandingPage;

  const landingData = shouldHaveLandingContent ? getLandingContent(playlistId as string) : {};
  const activeRenderersData = shouldHavePanels ? getActiveRendererData() : {};
  const panelsData = shouldHavePanels ? getPanelsData(panels as BasePanelData[]) : {};

  return {
    _id: createObjectId(),
    collection: 'pages',
    created: Date.now(),
    data: {
      gate,
      name: channelName,
      theme: {
        classic_theme_options: { accent_primary: '#6E5FFB', accent_secondary: '#4C42AD' },
        id: themeId ? themeId : '6274067af74b66a544018dac',
        type: themeId ? THEME_TYPES.ADVANCED : THEME_TYPES.CLASSIC,
      },
      ...landingData,
    },
    last_modified: Date.now(),
    modified: Date.now(),
    renderers: {
      ...panelsData,
    },
    seo: {
      title: channelName,
    },
    site_id: siteId,
    slug: createSlugFromString(channelName),
    type: channelType,
    ...activeRenderersData,
  };
};

export const createBasePanelsObject = ({
  renderer,
  siteId,
}: ICreateBasePanel): BasePanelData => {
  const id = createObjectId();

  const newRenderer = {
    ...renderer,
    id,
  };

  return {
    _id: id,
    collection: 'panels',
    created: Date.now(),
    last_modified: Date.now(),
    modified: Date.now(),
    renderer: {
      ...newRenderer,
    },
    site_id: siteId,
    slug: `panel-${id}-generated`,
    type: 'panel',
  };
};

export const updateSiteStructure = ({
  siteStructure,
  page,
}: IUpdateSiteStructure): INavigation => {
  const newParentChannel: INavigationParent = {
    id: page._id,
    name: page.data.name!,
    private: false,
    slug: page.slug,
    type: page.type,
    children: [],
  };

  return {
    ...siteStructure,
    parents: [newParentChannel, ...siteStructure.parents],
  };
};
