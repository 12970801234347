import { Middleware } from 'redux';
import { isMobileLayout } from 'services/device';
import {
  addPendingNavigationAction,
  clearPendingNavigationActions,
  DISMISS_PENDING_NAVIGATION_ACTIONS,
  RESUME_PENDING_NAVIGATION_ACTIONS,
  setIsUserActionRequestedToUnblockNavigation,
  setNavigationBlocked,
} from 'services/navigation';
import { RootState } from 'services/root-reducer';
import IState from 'services/state';

const keyActions = [
  'admin/SET_ADMIN_MODE',
  'admin/SET_ACTIVE_ACTION_TYPE',
  'admin/SET_ACTIVE_TAB',
  'admin/SET_ACTIVE_ACTION',
  'admin/PUSH_ADMIN_BAR_SUB_MENU_KEY',
  'router/REPLACE',
  '@@router/CALL_HISTORY_METHOD',
  '@@router/LOCATION_CHANGE',
];

const navigationConfirmationMiddleware: Middleware<{}, RootState, any> =
  (store) => (next) => (action) => {
    const state = store.getState() as IState;
    const { navigation } = state;

    if (keyActions.includes(action?.type) && navigation.isBlocked && !isMobileLayout(state)) {
      store.dispatch(addPendingNavigationAction(action));
      if (!navigation.isUserActionRequested) {
        store.dispatch(setIsUserActionRequestedToUnblockNavigation(true));
      }
      return;
    }

    if (
      [RESUME_PENDING_NAVIGATION_ACTIONS, DISMISS_PENDING_NAVIGATION_ACTIONS].includes(action.type)
    ) {
      store.dispatch(setIsUserActionRequestedToUnblockNavigation(false));
      if (action.type === RESUME_PENDING_NAVIGATION_ACTIONS) {
        store.dispatch(setNavigationBlocked(false));
        navigation.pendingNavigationActions.forEach((pendingAction) => {
          store.dispatch(pendingAction);
        });
      }

      store.dispatch(clearPendingNavigationActions());

      return;
    }

    next(action);
  };

export default navigationConfirmationMiddleware;
