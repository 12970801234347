import IState from '../state';
import { IShippingAddress } from './models';

export const getShippingAddress = ({ shippingAddress }: IState): IShippingAddress => shippingAddress.shippingAddress;
export const getIsShippingAddressComplete = ({ shippingAddress: { shippingAddress: { address1, state, city, countryCode, postalCode, firstName, lastName } } }: IState): boolean => {
  const isAddressComplete = address1 !== '' && postalCode !== '' && firstName !== '' && lastName !== '';
  if (countryCode === 'US') {
    return isAddressComplete && state !== '' && city !== '';
  }

  return isAddressComplete;
};
