import { hasOwn } from 'utils';

const warnings = {};

const warn = (message) => {
  if (!hasOwn(warnings, message)) {
    warnings[message] = true;
    console.warn(message); // eslint-disable-line no-console
  }
};

const computeCardCta = (cta) => {
  if (!cta || !cta.kind) {
    return null;
  }
  switch (cta.kind) {
    case 'facebook_share':
      return {
        cardCtaType: cta.kind,
      };
    case 'open_link':
      return {
        cardCtaType: cta.kind,
        linkText: cta.text,
        linkUrl: cta.url,
        // TODO: `cta.location` should always be 'new_window'
      };
    case 'play_video':
      return {
        cardCtaType: cta.kind,
        text: cta.text,
        videoId: cta.video?._id || null,
      };
    case 'show_person':
      return {
        cardCtaType: cta.kind,
        personId: cta.person?._id || null,
        text: cta.text,
      };
    case 'show_stored_panel':
      return {
        cardCtaType: cta.kind,
        panelId: cta.panel?._id || null,
        text: cta.text,
      };
    case 'html':
      return {
        cardCtaType: cta.kind,
        html: cta.html,
      };
    case 'none':
      return null;
    default:
      if (process.env.NODE_ENV === 'development') {
        warn(`computeCardCta: unknown cta kind: ${JSON.stringify(cta.kind)}`);
      }
      return null;
  }
};

const getTitle = data => data.name || data.slug || '';

// TODO: Motherfucking CTA's
export const computeCardRendererFromDoc = (doc) => {
  if (!doc) {
    return null;
  }
  const { data, renderer } = doc;
  if (renderer) {
    return renderer;
  }
  if (!data) {
    return null;
  }
  const { callToAction: cta, kind } = data;
  switch (kind) {
    case 'image':
      return {
        cardType: kind,
        ctaRenderer: computeCardCta(cta),
        id: doc._id,
        imageUrl: data.image,
        title: getTitle(data),
      };
    case 'message':
      return {
        cardType: kind,
        ctaRenderer: computeCardCta(cta),
        id: doc._id,
        message: data.message || '',
        title: getTitle(data),
      };
    case 'tweet':
      return {
        cardType: kind,
        id: doc._id,
        title: getTitle(data),
        tweetUrl: data.status?.url || null,
      };
    default:
      // TODO: Implement the rest
      if (process.env.NODE_ENV === 'development') {
        warn(`computeCardRendererFromDoc: unknown card kind: ${JSON.stringify(kind)}`);
      }
      return null;
  }
};
