import invariant from 'invariant';

import EventBuilder from './EventBuilder';

class ErrorEventBuilder extends EventBuilder {
  constructor() {
    super('error');
  }

  validateTrack(action, doc, extra) {
    invariant(
      !action,
      'No action for error events',
    );
    invariant(
      !doc,
      'No doc for error events',
    );
    invariant(
      extra,
      'Missing extra for error event',
    );
    return true;
  }

  buildOntology(action, doc, extra) {
    return {
      /* eslint-disable sort-keys */
      phylum: extra.message,
      class: extra.url,
      order: null, // omitting line number bc of compilation + source maps
      family: null, // omitting line number bc of compilation + source maps
      genus: extra.stack, // source mapped!
      species: extra.userAgent,
      /* eslint-enable */
    };
  }

  buildMetadata(action, doc, extra) {
    const { customMessage } = extra;
    return { customMessage };
  }
}

export default new ErrorEventBuilder();
