import IState from 'services/state';

export const getThemeId = (state: IState) => state.themes.theme?._id;
export const getForkedTheme = (state: IState) => state.themes.forkedTheme;
export const getAdminTheme = (state: IState) => state.themes.adminTheme;
export const getSavedTheme = (state: IState) => state.themes.theme;
export const getSurface1 = (state: IState) => state.themes.theme.colors.surface1;
export const getSurface2 = (state: IState) => state.themes.theme.colors.surface2;
export const getSurface3 = (state: IState) => state.themes.theme.colors.surface3;
export const getSurface4 = (state: IState) => state.themes.theme.colors.surface4;
export const getSurface5 = (state: IState) => state.themes.theme.colors.surface5;
export const getSurface6 = (state: IState) => state.themes.theme.colors.surface6;
export const getText100 = (state: IState) => state.themes.theme.colors.text100;
export const getText200 = (state: IState) => state.themes.theme.colors.text200;
export const getText300 = (state: IState) => state.themes.theme.colors.text300;
export const getAccentSecondary = (state: IState) => state.themes.theme.colors.accentSecondary;
export const getAccentPrimary = (state: IState) => state.themes.theme.colors.accentPrimary;
export const getThemeTypography = (state: IState) => state.themes.theme?.typography;
export const getAlertError = (state: IState) => state.themes.theme.colors.alertError;
export const getAdminThemeLogo = (state: IState) => state.themes.adminTheme.logo;
export const getAdminText100 = (state: IState) => state.themes.adminTheme.colors.text100;
export const getAdminText200 = (state: IState) => state.themes.adminTheme.colors.text200;
export const getAdminText300 = (state: IState) => state.themes.adminTheme.colors.text300;
export const getAdminText400 = (state: IState) => state.themes.adminTheme.colors.text400;
export const getAdminText500 = (state: IState) => state.themes.adminTheme.colors.text500;
export const getAdminAlertWarning = (state: IState) => state.themes.adminTheme.colors.alertWarning;
export const getAdminAccentSecondary = (state: IState) => state.themes.adminTheme.colors.accentSecondary;
export const getAdminAccentPrimary = (state: IState) => state.themes.adminTheme.colors.accentPrimary;
export const getAdminThemeIcon = (state: IState) => state.themes.adminTheme.icon;
export const getAdminThemeBodyFont = (state: IState) => state.themes.adminTheme.typography.body;
export const getAdminThemeTypography = (state: IState) => state.themes.adminTheme.typography;
export const getAdminAlertError = (state: IState) => state.themes.adminTheme.colors.alertError;
export const getAdminSurface1 = (state: IState) => state.themes.adminTheme.colors.surface1;
export const getAdminSurface2 = (state: IState) => state.themes.adminTheme.colors.surface2;
export const getAdminSurface3 = (state: IState) => state.themes.adminTheme.colors.surface3;
export const getAdminSurface4 = (state: IState) => state.themes.adminTheme.colors.surface4;
export const getAdminSurface5 = (state: IState) => state.themes.adminTheme.colors.surface5;
export const getAdminSurface6 = (state: IState) => state.themes.adminTheme.colors.surface6;
export const getAdminChannelOfflineThumbnail = (state: IState) => state.themes.adminTheme.channelOfflineThumbnail;
