import { hasOwn } from 'utils';

const warnings = {};

const warn = (message) => {
  if (!hasOwn(warnings, message)) {
    warnings[message] = true;
    console.warn(message); // eslint-disable-line no-console
  }
};

export const computeOverlayCta = (cta) => {
  if (!cta || !cta.kind || cta.kind === 'none') {
    return null;
  }

  const { kind } = cta;
  switch (kind) {
    case 'facebook_share':
      return {
        overlayCtaType: kind,
      };
    case 'go_to_channel':
      return {
        channelSlug: cta.goToChannel.slug,
        overlayCtaType: kind,
      };
    case 'login':
      return {
        overlayCtaType: kind,
      };
    case 'open_link':
      return {
        linkText: cta.text,
        linkUrl: cta.url,
        overlayCtaType: kind,
      };
    case 'play_video':
      return {
        overlayCtaType: kind,
        text: cta.text,
        videoId: cta.video._id,
      };
    case 'show_player_stats':
      // TODO: make this multi-sport. right now it's hardcoded for sportradar/nba players
      return {
        overlayCtaType: kind,
        playerId: cta.playerId,
      };
    case 'show_group':
      return {
        groupId: cta.group,
        overlayCtaType: kind,
        text: cta.text,
      };
    case 'show_panel':
    case 'show_stored_panel':
      return {
        overlayCtaType: 'show_panel',
        panelId: cta.panel._id,
        text: cta.text,
      };
    case 'show_person':
      return {
        overlayCtaType: kind,
        personId: cta.person._id,
        text: cta.text,
      };
    case 'tweet':
      return {
        overlayCtaType: kind,
        tweetMessage: cta.tweetMessage,
      };
    default:
      if (process.env.NODE_ENV === 'development') {
        warn(`computeOverlayCta: unknown cta kind: ${JSON.stringify(kind)}`);
      }
      return null;
  }
};

export const computeOverlayRendererFromDoc = (doc) => {
  if (doc.renderer) {
    return doc.renderer;
  }
  const { data } = doc;
  let renderer;

  switch (data.kind) {
    case 'highlight': {
      renderer = {
        // this is hardcoded for this demo
        ctaRenderer: computeOverlayCta(data.callToAction),
        description: data.description,
        duration: data.duration,
        imageUrl: data.image,
        overlayId: data.overlayId,
        overlayType: data.kind,
        playerId: data.player,
      };
      break;
    }
    case 'message':
      renderer = {
        ctaRenderer: computeOverlayCta(data.callToAction),
        duration: data.duration,
        message: data.message,
        overlayId: data.overlayId,
        overlayType: data.kind,
      };
      break;

    case 'lower_third':
      renderer = {
        ctaRenderer: computeOverlayCta(data.callToAction),
        duration: data.duration,
        imageUrl: data.image,
        name: data.name,
        overlayId: data.overlayId,
        overlayType: data.kind,
      };
      break;

    case 'shopify':
      renderer = {
        ctaRenderer: computeOverlayCta(data.callToAction),
        duration: data.duration,
        imageUrl: data.image,
        name: data.name,
        overlayId: data.overlayId,
        overlayType: data.kind,
        price: data.variant.price,
        product: data.product,
      };
      break;

    case 'poll':
      renderer = {
        name: data.name,
        overlayId: data.overlayId,
        overlayType: data.kind,
        poll: data.poll,
      };
      break;

    case 'poll_results':
      renderer = {
        name: data.name,
        overlayId: data.overlayId,
        overlayType: data.kind,
        poll: data.poll,
        questionId: data.questionId,
      };
      break;

    case 'raffle':
      renderer = {
        name: data.name,
        overlayId: data.overlayId,
        overlayType: data.kind,
      };
      break;

    case 'raffle_active':
      renderer = {
        overlayId: data.overlayId,
        overlayType: data.kind,
      };
      break;

    case 'raffle_picking':
      renderer = {
        overlayId: data.overlayId,
        overlayType: data.kind,
      };
      break;

    case 'raffle_winner':
      renderer = {
        overlayId: data.overlayId,
        overlayType: data.kind,
      };
      break;

    case 'commerce':
      // Probably deprecating this soon
      renderer = null;
      break;

    case 'instagram':
      // Not P0
      renderer = null;
      break;

    case 'tweet':
      renderer = {
        overlayId: data.overlayId,
        overlayType: data.kind,
        status: data.status,
      };
      break;

    default:
      // TODO: Implement the rest
      if (process.env.NODE_ENV === 'development') {
        warn(`computeOverlayRendererFromDoc: unknown overlay kind: ${JSON.stringify(data.kind)}`);
      }
      renderer = null;
      break;
  }

  return renderer;
};

export const computeOverlayRendererFromBroadcast = (broadcast) => {
  const { payload } = broadcast;
  const { data } = payload;

  // if renderer is already set, fall through
  if (data.renderer) {
    return {
      broadcast,
      renderer: data.renderer,
    };
  }

  return {
    broadcast,
    renderer: computeOverlayRendererFromDoc(payload),
  };
};
