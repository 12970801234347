import { makeAction } from 'redux-utils';
import { IVideoUploadFile } from './state';

export const CREATE_FILES = 'video-upload/CREATE_FILES';
export const UPDATE_FILES = 'video-upload/UPDATE_FILES';
export const REMOVE_FILE = 'video-upload/REMOVE_FILE';

export const createFiles = makeAction(
  CREATE_FILES,
  (files: (Omit<IVideoUploadFile, 'bucketFileName' | 'uploadProgres'>)[]) => ({
    payload: files,
  }),
);

export const updateFiles = makeAction(
  UPDATE_FILES,
  (files: (Partial<IVideoUploadFile> & Pick<IVideoUploadFile, 'id'>)[]) => ({
    payload: files,
  }),
);

export const deleteFile = makeAction(
  REMOVE_FILE,
  (fileId: string) => ({
    payload: {
      fileId,
    },
  }),
);
