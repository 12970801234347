export const INIT_SEGMENT = 'segmentAnalytics/INIT_SEGMENT';
export const IDENTIFY = 'segmentAnalytics/IDENTIFY';
export const TRACK_EVENT = 'segmentAnalytics/TRACK_EVENT';
export const RESET_USER = 'segmentAnalytics/RESET_USER';

export type ISegmentAnalyticsAction = IInitSegmentAction | IIdentifyAction | IResetUserAction | ITrackEventAction;

interface IEventProperties {
  [key: string]: any;
}

export interface IInitSegmentAction {
  type: typeof INIT_SEGMENT;
}

export interface IIdentifyAction {
  payload: IIdentifyPayload;
  type: typeof IDENTIFY;
}

export interface ITrackEventAction {
  payload: ITrackEventPayload;
  type: typeof TRACK_EVENT;
}

export interface IResetUserAction {
  type: typeof RESET_USER;
}

export interface IIdentifyPayload {
  creatorType?: string;
  provider: string;
}

export interface ITrackEventPayload {
  event: string;
  properties: IEventProperties;
}

export const identify = (data: IIdentifyPayload): IIdentifyAction => ({
  payload: data,
  type: IDENTIFY,
});

export const trackEvent = (data: ITrackEventPayload): ITrackEventAction => ({
  payload: data,
  type: TRACK_EVENT,
});


export const resetSegmentUser = (): IResetUserAction => ({
  type: RESET_USER,
});
