import axios from 'axios';
import {
  SUBSCRIPTION_V2_BASE_URL,
  SITE_ID,
  ADMIN_SERVICE_BASE_URL,
  PANEL_SERVICE_BASE_URL,
} from 'config';
import ISubscription from 'models/ISubscription';
import IObject from 'models/IObject';
import { BasePanelData, BaseSiteData } from './utils';
import maestroApiRequest from 'services/maestro-api-client';
import { pageTypes } from 'services/app';
import { snakeify } from 'shared/string-utils';

const ADMIN_SAVE_URL = `${ADMIN_SERVICE_BASE_URL}/legacy/save`;
const ADMIN_GET_CHANNELS_URL = `${ADMIN_SERVICE_BASE_URL}/legacy/pages`;

type SubscriptionTypes = 'subscription' | 'ticket';

interface ICreateWizardSubscriptionParams {
  eventDate?: number;
  primaryToken: string;
  sku: string;
  subscriptionAmount: number;
  subscriptionName: string;
  subscriptionType: SubscriptionTypes;
}

interface ICreateWizardChannelPayload {
  objectId: string;
  primaryToken: string;
  siteId: string;
  value: BaseSiteData;
}

interface ICreateWizardPanelsPayload {
  objectId: string;
  primaryToken: string;
  siteId: string;
  value: BasePanelData;
}

export interface IGetAmountOfChannelsResponse {
  channels: number;
  pages: number;
  total: number;
}

export const createWizardSubscription = async ({
  primaryToken,
  sku,
  subscriptionAmount,
  subscriptionName,
  subscriptionType,
  eventDate,
}: ICreateWizardSubscriptionParams): Promise<ISubscription> => {
  const { data } = await axios.post<ISubscription>(
    `${SUBSCRIPTION_V2_BASE_URL}`,
    {
      eventDate,
      name: subscriptionName,
      prices: [
        {
          currency: 'usd',
          default: true,
          value: subscriptionAmount,
        },
      ],
      sku,
      subscriptionProvider: 'maestro',
      type: subscriptionType,
      recurrence: subscriptionType === 'subscription' ? 'month' : 'once',
    },
    {
      headers: {
        'x-maestro-client-id': SITE_ID,
        Authorization: `Bearer ${primaryToken}`,
      },
    },
  );
  return data;
};

export const createWizardChannel = async ({
  objectId,
  primaryToken,
  siteId,
  value,
}: ICreateWizardChannelPayload): Promise<IObject> => {
  const { data } = await axios.post(
    `${ADMIN_SAVE_URL}`,
    {
      key: `objects/${objectId}`,
      site_id: siteId,
      token: primaryToken,
      value: JSON.stringify(snakeify(value)),
    },
    {},
  );
  return data;
};

export const createWizardPanels = async ({
  objectId,
  primaryToken,
  siteId,
  value,
}: ICreateWizardPanelsPayload): Promise<IObject> => {
  const { data } = await axios.post(
    `${ADMIN_SAVE_URL}`,
    {
      key: `objects/${objectId}`,
      site_id: siteId,
      token: primaryToken,
      value: JSON.stringify(value),
    },
    {},
  );
  return data;
};

export const getAmountOfChannels = async ({
  primaryToken,
}: any): Promise<IGetAmountOfChannelsResponse> => {
  const { data } = await axios.get(
    `${ADMIN_GET_CHANNELS_URL}`,
    {
      headers: {
        'x-maestro-client-id': SITE_ID,
        Authorization: `Bearer ${primaryToken}`,
      },
    },
  );
  const channelCount = data?.results?.filter((item: IObject) => item.type === pageTypes.CHANNEL)?.length || 0;
  const landingCount = data?.results?.filter((item: IObject) => item.type === pageTypes.LANDING)?.length || 0;
  return {
    channels: channelCount,
    pages: landingCount,
    total: data.count,
  };
};


export const getSitePanelById = async ({
  primaryToken,
  id,
}: { id: string; primaryToken: string; }): Promise<any> => {
  const { data } = await maestroApiRequest({ primaryToken, siteId: SITE_ID }).get(`${PANEL_SERVICE_BASE_URL}/${id}`);
  return data;
};
