import type { IVideo } from 'models';

export type IVideoUploadFile = {
  bucketFileName?: string;
  id: string;
  localFileName: string;
  size: number;
  uploadingStatus: IVideoUploadJobStatus;
} & Pick<
  IVideo,
  | 'description'
  | 'player'
  | 'subscriptions'
  | 'tags'
  | 'thumbnail'
  | 'title'
>;

export enum IVideoUploadJobStatus {
  COMPLETE = 'complete',
  ERROR = 'error',
  UPLOADING = 'uploading',
  QUEUED = 'queued',
}

export const getDefaultState = () => ({
  files: [] as IVideoUploadFile[],
});

export type IVideoUploadState = ReturnType<typeof getDefaultState>;
