import EventBuilder from './EventBuilder';

class AdEventBuilder extends EventBuilder {
  constructor() {
    super('ad');
  }

  buildOntology(action, doc) {
    return {
      /* eslint-disable sort-keys */
      phylum: action,
      class: doc?.kind,
      order: doc?.id,
      family: doc?.title,
    };
  }

  buildMetadata(action, doc, extra) {
    return extra;
  }
}

export default new AdEventBuilder();
