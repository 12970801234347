import { buffers } from 'redux-saga';
import { actionChannel, call, fork, select, take, put } from 'redux-saga/effects';
import { getPrimaryToken } from 'services/auth/selectors';
import { getSiteId } from 'services/app/selectors';
import { createFinalEvent } from 'services/insights/saga'; // TODO: This is a hack, see watchClaims
import { getEventBase } from 'services/insights/selectors'; // TODO: This is a hack, see watchClaims
import { showModal } from 'services/modals/actions';
import { OWL_SITE_ID } from 'config';
import { camelify } from 'shared/string-utils';
import { trackQuest } from 'services/insights/actions';
import { onClaimSuccess, onQuestAccessToken } from 'services/third-party-analytics/actions';
import { CLAIM_QUEST, setQuestClaiming } from './actions';
import { getQuests } from './selectors';
import { battlenetClaim, legacyClaim } from './api';

const watchClaims = function* () {
  const claimChannel = yield actionChannel(CLAIM_QUEST, buffers.expanding());
  while (true) {
    const { payload: questId } = yield take(claimChannel);
    // start claiming process

    const token = yield select(getPrimaryToken);
    const siteId = yield select(getSiteId);

    const questData = (yield select(getQuests))[questId];
    const trackMetadata = {
      prize_mode: questData.rewardType || 'prize',
      // service: questData.data?.award?.mode || 'single-service',
    };
    try {
      yield put(trackQuest('claim', questData, {
        ...trackMetadata,
        type: 'attempt',
      }));

      /**
       * TODO: This is a huge hack that is necessary because the claim endpoint GCF expects a fully
       *       prepared tracking event. The only way to create one of those objects is to use these
       *       "private" functions from the Insights saga. While this is ugly, it's probably fine
       *       (TM) for now, since we're going to be moving quest claims off of GCF.
       */
      const eventBase = yield select(getEventBase);
      const blob = yield call(createFinalEvent, {
        event: {
          ontology: {
            family: questData.title,
            kingdom: 'quest',
            order: questId,
          },
          url: window.location.href,
        },
        eventBase,
      });

      yield put(setQuestClaiming(questId, true));

      // hardcode for owl claims, can fix later
      if (siteId === OWL_SITE_ID) {
        const productId = questData.webhookId;
        yield call(battlenetClaim, siteId, questId, productId, token, blob);
      } else {
        const response = yield call(legacyClaim, siteId, questId, token, blob);
        const modalData = {
          data: {
            questData,
            responseData: camelify(response.data),
          },
          kind: 'questClaim',
        };

        const claimSuccess = {
          rewardDescription: questData.rewardDescription,
          rewardType: questData.rewardType,
          title: questData.title,
        };
        // show popup
        yield put(showModal(modalData));
        yield put(onClaimSuccess(claimSuccess));
        if (questData.rewardType === 'prize') {
          yield put(onQuestAccessToken(response.data.access_token));
        }
      }
    } catch (e) {
      /* eslint-disable no-console */
      console.warn('claim call errored');
      console.warn(e);
      /* eslint-enable */
      yield put(trackQuest('claim', questData, {
        ...trackMetadata,
        error: e.response.data,
        type: 'fail',
      }));

      yield put(showModal({ data: { text: e.response.data }, kind: 'text' }));
    } finally {
      // update claiming to be false
      yield put(setQuestClaiming(questId, false));

      // track successful quest claim
      yield put(trackQuest('claim', questData, {
        ...trackMetadata,
        type: 'success',
      }));
    }
  }
};

// TODO: I don't think this actually works with fork/spawn, but I could be wrong - Andy
const restartSaga = function* (sagaOrArray, failOrArray) {
  const [saga, ...sagaParams] = Array.isArray(sagaOrArray) ? sagaOrArray : [sagaOrArray];
  const [failure, ...failParams] = Array.isArray(failOrArray) ? failOrArray : [failOrArray];
  while (true) {
    try {
      yield call(saga, ...sagaParams);
    } catch (e) {
      console.error(`saga failed: ${saga.name}`); // eslint-disable-line no-console
      console.error(e); // eslint-disable-line no-console
      if (failure) {
        yield call(failure, ...failParams);
      }
    }
  }
};

const processQuestSaga = function* () {
  yield fork(watchClaims);
};

const questSaga = function* () {
  yield call(restartSaga, processQuestSaga);
};

export default questSaga;
