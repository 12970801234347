import { buffers, eventChannel } from 'redux-saga';
import { actionChannel, call, delay, fork, put, select, take } from 'redux-saga/effects';

import { trackError } from 'services/insights/actions';
import { LOG_ERROR, logError } from './actions';
import { addErrorListener, mapErrorStack, removeErrorListener } from './api';

const createErrorChannel = () => eventChannel(
  (emit) => {
    const errorListener = event => emit({ error: event.error, event });
    addErrorListener(errorListener);
    return () => removeErrorListener(errorListener);
  },
  buffers.expanding(1),
);

const watchErrorsSaga = function* () {
  const errorChannel = yield call(createErrorChannel);
  while (true) { // eslint-disable-line no-constant-condition
    const { error } = yield take(errorChannel);
    yield put(logError(error, 'Uncaught error'));
  }
};

const printErrorSaga = function* (...args) {
  yield call([console, 'error'], ...args);
};

const EMPTY_OBJECT = {};

const logErrorsSaga = function* () {
  const logActionChannel = yield actionChannel(LOG_ERROR, buffers.sliding(10));
  let lastError = yield select(state => state.error.lastError);
  while (true) { // eslint-disable-line no-constant-condition
    try {
      const { payload: { customMessage, error: errorObject, url } } = yield take(logActionChannel);
      const error = errorObject || EMPTY_OBJECT; // Null for cross-origin errors; prevent crashes
      if (error === lastError) { // Prevent double-logging same error
        continue;
      }
      // Ignore falsy or empty object errors
      if (!error || Object.keys(error).length === 0) {
        continue;
      }
      lastError = error;

      yield call(printErrorSaga, 'LOGGING ERROR:');
      yield call(printErrorSaga, error);
      const stack = yield call(mapErrorStack, error.stack || '');
      const trackExtra = {
        customMessage,
        message: error.message,
        stack,
        url,
        userAgent: window.navigator.userAgent,
      };
      if (process.env.NODE_ENV === 'production') {
        yield put(trackError(null, null, trackExtra));
      } else {
        yield call(printErrorSaga, 'Dev mode detected. Skipping server call. Debug info:');
        yield call(printErrorSaga, JSON.stringify(trackExtra, null, 2));
      }
    } catch (error) {
      yield call(printErrorSaga, 'Error in the error saga (so meta!):');
      yield call(printErrorSaga, error);
    }
    yield delay(1000); // Avoid infinite error saga recursion...
  }
};

const errorSaga = function* () {
  yield fork(logErrorsSaga);
  yield fork(watchErrorsSaga);
};

export default errorSaga;
