import { IPoll, IPollResponse } from './models';

export const CREATE_POLL = 'polls/CREATE_POLL';
export const UPDATE_POLL = 'polls/UPDATE_POLL';
export const DELETE_POLL = 'polls/DELETE_POLL';
export const CREATE_POLL_RESPONSE = 'polls/CREATE_POLL_RESPONSE';
export const RESET_POLL_STATE = 'polls/RESET_POLL_STATE';
export const SET_PANEL_POLL_IDS = 'polls/SET_PANEL_POLL_IDS';
export const SET_UNSEEN_ITEM_VISITED = 'polls/SET_UNSEEN_ITEM_VISITED';

export interface ICreatePollAction {
  payload: { poll: IPoll };
  type: typeof CREATE_POLL;
}

export interface IUpdatePollAction {
  payload: { poll: IPoll };
  type: typeof UPDATE_POLL;
}

export interface IDeletePollAction {
  payload: { pollId: string };
  type: typeof DELETE_POLL;
}

export interface ICreatePollResponseAction {
  payload: { pollResponse: IPollResponse };
  type: typeof CREATE_POLL_RESPONSE;
}

export interface IResetPollStateAction {
  type: typeof RESET_POLL_STATE;
}

export interface ISetPanelPollIdsAction {
  payload: { panelId: string, pollIds: string[] };
  type: typeof SET_PANEL_POLL_IDS;
}

export interface ISetUnseenItemVisitedAction {
  payload: { panelId: string, pollId: string };
  type: typeof SET_UNSEEN_ITEM_VISITED;
}

export type IPollsAction = (
  ICreatePollAction |
  IUpdatePollAction |
  IDeletePollAction |
  ICreatePollResponseAction |
  IResetPollStateAction |
  ISetPanelPollIdsAction |
  ISetUnseenItemVisitedAction
);

export const createPoll = (poll: IPoll): ICreatePollAction => {
  return {
    payload: { poll },
    type: CREATE_POLL,
  };
};

export const updatePoll = (poll: IPoll): IUpdatePollAction => {
  return {
    payload: { poll },
    type: UPDATE_POLL,
  };
};

export const deletePoll = (pollId: string): IDeletePollAction => {
  return {
    payload: { pollId },
    type: DELETE_POLL,
  };
};

export const createPollResponse = (pollResponse: IPollResponse): ICreatePollResponseAction => {
  return {
    payload: { pollResponse },
    type: CREATE_POLL_RESPONSE,
  };
};

export const resetPollState = (): IResetPollStateAction => {
  return {
    type: RESET_POLL_STATE,
  };
};

export const setPanelPollIds = (panelId: string, pollIds: string[]): ISetPanelPollIdsAction => {
  return {
    payload: { panelId, pollIds },
    type: SET_PANEL_POLL_IDS,
  };
};

export const setUnseenItemVisited = (panelId: string, pollId: string): ISetUnseenItemVisitedAction => {
  return {
    payload: { panelId, pollId },
    type: SET_UNSEEN_ITEM_VISITED,
  };
};
