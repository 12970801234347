let iframe: HTMLIFrameElement | null = null;

export const setupHtmlParser = () => new Promise<void>(
  (res) => {
    if (iframe) {
      res();
      return;
    }

    iframe = document.createElement('iframe');
    iframe.id = 'maestroLocalizationHtmlParser';
    Object.assign(
      iframe.style,
      {
        position: 'fixed',
        top: '-999px',
        left: '-999px',
        width: '0px',
        height: '0px',
        opacity: 0,
      },
    );
    iframe.onload = () => {
      iframe!.contentDocument!.body.innerHTML = '';
      res();
    };
    iframe.setAttribute('sandbox', 'allow-same-origin');
    iframe.src = 'about:blank';
    document.body.append(iframe);
  },
);

/**
 * Safely parses `html`, replaces the first non empty text node
 * by `replaceText`, and returns a new, safe to inject, html string.
 *
 * **!! CAVEATS !!**
 * - Currently there's no support for nested formatting, so:
 *   ```ts
 *   translateRichTextHtml(
 *     `<p>
 *        <b>
 *          Hello, world! This html has
 *          <i>nested formatting.</i>
 *        </b>
 *      </p>`,
 *      'My Localized Text',
 *   ) === `
 *     <p>
 *       <b>
 *         My Localized Text
 *         <i></i> <!-- notice that the i tag still exists, but is now empty -->
 *       </b>
 *     </p>
 *   `;
 *   ```
 * - Check `allowedNodes` and `allowedAttrs` if you need to support
 * some other HTML feature. But keep in mind not to create a XSS vulnerability.
 */
export const translateRichTextHtml = (html: string, replaceText: string) => {
  if (!iframe || !iframe.contentDocument)
    return '';

  const inputContainer = iframe.contentDocument.createElement('div');
  inputContainer.innerHTML = html;

  const outputContainer = document.createElement('div');

  iterateDomTree(inputContainer, outputContainer, replaceText);

  return outputContainer.innerHTML;
};

const allowedNodes = ['#text', 'A', 'DIV', 'P', 'SPAN', 'I', 'B', 'U', 'STRONG', 'EM', 'IMG'];
const allowedAttrs = ['style', 'id', 'class', 'href', 'src'];

const iterateDomTree = (
  inputContainer: HTMLElement,
  outputContainer: HTMLElement,
  replaceText: string,
  parsingState = { textReplaced: false },
) => {
  if (!inputContainer.hasChildNodes())
    return;

  for (const node of inputContainer.childNodes) {
    if (!allowedNodes.includes(node.nodeName)) {
      continue;
    }

    if (node.nodeName === '#text') {
      const textContent = node.textContent?.trim();
      if (textContent && !parsingState.textReplaced) {
        parsingState.textReplaced = true;
        outputContainer.append(replaceText);
      }
      continue;
    }

    const el = document.createElement(node.nodeName.toLowerCase());

    for (const attr of allowedAttrs) {
      const sourceAttr = (node as HTMLElement).getAttribute(attr)?.trim();

      // strip out anything with a javascript: scheme
      if (sourceAttr && (sourceAttr).match(/[a-z]|\:/g)?.join('').indexOf('javascript:') !== 0) {
        el.setAttribute(attr, sourceAttr);
      }
    }

    iterateDomTree(node as HTMLElement, el, replaceText, parsingState);

    outputContainer.append(el);
  }
};
