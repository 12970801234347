import IState from 'services/state';
import { IExecuteWizardPayload } from './actions';
import { WizardFlowTypes, FlowSteps } from './models';

export const getCurrentStep = (state: IState): number => state.wizard.currentStep;

export const getMaxAmountOfSteps = (state: IState): number => state.wizard.totalAmountOfSteps;

export const getFlowSteps = (state: IState): FlowSteps[] => state.wizard.flowSteps;

export const getCurrentFlow = (state: IState): WizardFlowTypes | undefined => state.wizard.selectedFlow;

export const getIsWizardLoading = (state: IState): boolean => state.wizard.isWizardLoading;

export const getDraftChannelData = (state: IState): IExecuteWizardPayload | undefined => state.wizard.draftChannelData;
