import uuidv4 from 'uuid/v4';
import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';
import { buffers } from 'redux-saga';
import {
  actionChannel,
  all,
  call,
  delay,
  take,
  select,
  put,
  takeEvery,
  fork,
  spawn,
} from 'redux-saga/effects';
import warning from 'warning';

import {
  ENABLE_INSIGHTS_LOGGING,
  NAMESPACE,
  SITES_WITH_ANALYTICS_TURNED_OFF,
} from 'config';

import {
  getActivePanel,
  getPage,
  getPageId,
  getSiteId,
  getTimeOffset,
  isTimeOffsetLoaded,
} from 'services/app/selectors';
import {
  SET_ACTIVE_PANEL,
  SET_OBJECT,
  SET_TIME_OFFSET,
} from 'services/app/actions';

import { getDocument, isDocumentLoaded } from 'services/realtime/selectors';
import { logError } from 'services/error/actions';

import {
  getAutoLogin,
} from 'services/auth/selectors';

import {
  LOG_IN,
  LOG_IN_FAILURE,
  LOG_IN_SUCCESS,
  LOG_OUT,
  ANON_SESSION_INIT,
} from 'services/auth/actions';

import { CLOSE_PANEL } from 'services/custom-panels/actions';
import { isGoogleAnalyticsDisabled } from 'services/feature-gate/selectors';
import {
  postAction,
  loadGoogleAnalytics,
} from './api';

import * as eventBuilders from './event-builders';

import {
  getActive,
  getCurrentPageId,
  getEventBase,
  getInitialized,
  getMaestroPath,
  getReferrerDomain,
  getReferrerSource,
  getClientGaId,
  getLastEventSentTimeMs,
} from './selectors';

import {
  addDebug,
  BUILD,
  POST,
  postEvent,
  SET_ACTIVE,
  setActive,
  SET_COUNTRY_CODE_SAGA_ACTION,
  setCountryCodeSagaAction,
  setCountryCode,
  setInitialized,
  setPageId,
  setSessionId,
  setSessionStart,
  setReferrer,
  TRACK,
  trackAuth,
  trackPageview,
  trackPanel,
  LOAD_GA,
  setLastEventSentTime,
} from './actions';

// 5 minutes
const SESSION_TIMEOUT_MS = 1000 * 60 * 5;
const ONE_DAY_MS = 1000 * 60 * 60 * 24;

// merges event data in lastEvent with the selector
// in the selector to populate a insights event object to send in
export const createFinalEvent = function* ({ eventBase, event }) {
  const { ontology, metadata, url } = event || {};

  if (!ontology) {
    console.error('Missing ontology for event:', event); // eslint-disable-line no-console
    return false;
  }

  const { kingdom, phylum, order, family, genus, species } = ontology;
  const domain = NAMESPACE;

  // todo: allow these to go through tests
  // warning(
  //   kingdom,
  //   `event is missing a kingdom: ${JSON.stringify(event)}`,
  // );
  warning(
    url,
    `event is missing a url: ${JSON.stringify({ event, eventBase })}`,
  );

  if (!eventBase.se_id) {
    warning(
      true,
      `event is missing a session id: ${JSON.stringify({ event, eventBase })}`,
    );
    return false;
  }

  if (!kingdom) {
    console.error('Missing kingdom for event:', event); // eslint-disable-line no-console
    return false;
  }

  // add times
  const deviceTimeOffset = yield select(getTimeOffset);
  const deviceTime = Date.now();
  const time = deviceTime + deviceTimeOffset;
  const eM = omitBy(metadata, isNil);

  // update event data
  const finalEvent = {
    ...eventBase,
    d_tim: deviceTime,
    d_tof: deviceTimeOffset,
    e_d: domain,
    e_m: Object.values(eM).length > 0 ? JSON.stringify(eM) : null,
    e_t: time,
    e_u: url,
    e0: kingdom,
    e1: phylum,
    e2: ontology.class,
    e3: order,
    e4: family,
    e5: genus,
    e6: species,
    t: time,
  };

  if (kingdom === 'session' && phylum === 'start') {
    finalEvent.se_s = time;
  }

  return omitBy(finalEvent, isNil);
};

export const watchEventBuilders = function* () {
  const buffer = yield buffers.expanding(64);
  const buildChannel = yield actionChannel(BUILD, buffer);
  while (true) {
    const { payload: { action, doc, extra, kingdom } } = yield take(buildChannel);

    const builder = eventBuilders[kingdom];

    warning(
      builder?.buildEvent,
      `No event builder found for kingdom ${JSON.stringify(kingdom)}`,
    );

    const state = yield select();
    // Skip the event if validateTrack returns false OR throws

    try {
      if (builder?.validateTrack(action, doc, extra, state)) {
        yield put({ // TODO: Nice action creator that separates build from track
          payload: {
            ...builder?.buildEvent(action, doc, extra, state),
            url: window.location.href,
          },
          type: TRACK,
        });
      }
    } catch (error) {
      if (kingdom === 'error') { // Prevent infinite error loops
        /* eslint-disable no-console */
        console.error('Invalid error event:');
        console.error(error);
        /* eslint-enable */
      } else {
        yield put(logError(error));
      }
    }
  }
};

// takes all events from TRACK, waits for session to be active,
// creates event payload, sends to POST, and it posts it into
// the server
export const trackActionSaga = function* (event) {
  const state = yield select();
  const eventBase = getEventBase(state);

  const finalEvent = yield call(createFinalEvent, { event, eventBase });
  if (finalEvent) {
    // post event to action
    yield put(postEvent(finalEvent));
  } else {
    warning(true, 'trackActionSaga created invalid event');
  }
};

// saga to reset session after 24 hours
export const sessionExpiredSaga = function* () {
  const state = yield select();
  const isActive = getActive(state);
  if (isActive) {
    // track reset
    console.debug('session expired, resetting'); // eslint-disable-line no-console
    const eventBase = getEventBase(state);
    const event = yield call(createFinalEvent, {
      event: {
        ontology: {
          kingdom: 'session',
          phylum: 'one_day_reset',
        },
      },
      eventBase,
    });
    if (!event) {
      warning(true, 'sessionExpiredSaga created invalid event');
    } else {
      // eslint-disable-next-line no-use-before-define
      yield call(postSaga, event);
    }
    yield put(setActive({ authAction: null, state: false }));
  }
};

// posts all events to server, with retry flow.
// in development mode: adds a summary of the event to insights.debug state
export const postSaga = function* (event) {
  // if session timed out from inactivity, reset state.
  const eventTime = event.t;
  const eventSessionStart = event.se_s;
  const isActive = yield select(getActive);
  const lastEventSentMs = yield select(getLastEventSentTimeMs);
  const eventTimedOut = lastEventSentMs && (eventTime - lastEventSentMs) >= SESSION_TIMEOUT_MS;
  if (eventTimedOut) {
    if (isActive) {
      console.debug('session timeout, resetting'); // eslint-disable-line no-console
      yield put(setActive({ authAction: null, state: false }));
    }
    return;
  }

  if (ENABLE_INSIGHTS_LOGGING) {
    /* eslint-disable no-console */
    console.warn('[INSIGHTS] Posting raw event to server:');
    console.warn(event);
    /* eslint-enable */
  }

  let retryCount = 0;
  let resp;
  while (true) {
    // by keeping retry count at 18, we cap delay at 30 seconds
    retryCount = Math.min(++retryCount, 18);

    const backoff = 100 * (retryCount ** 2);
    try {
      resp = yield call(postAction, event);
      break;
    } catch (error) {
      console.warn(`Insights Error: ${error}`); // eslint-disable-line no-console
      yield delay(backoff);
    }
  }

  if (resp.headers['x-maestro-cc']) {
    yield put(setCountryCodeSagaAction(resp.headers['x-maestro-cc']));
  }

  if (process.env.NODE_ENV !== 'production') {
    // remove moment
    const time = (new Date().toString());
    yield put(addDebug({
      ...resp.data.event.event,
      se_id: event.se_id,
      time,
      u_id: event.u_id,
      u_li: event.u_li,
      u_nu: event.u_nu,
    }));

    // use for debugging
    // console.log(
    //   event.se_id,
    //   `${event.e0}:${event.e1}:${event.e2}:${event.e3}`,
    //   event.u_id,
    //   event.u_li,
    //   event.u_nu,
    // );

    // console.log('insights posted', { // eslint-disable-line no-console
    //   time, ...resp.data.event.event,
    // });
  }

  if (ENABLE_INSIGHTS_LOGGING) {
    /* eslint-disable no-console */
    try {
      const logData = { ...resp.data.event };
      logData.time = new Date(logData.time * 1000).toString();
      logData.sessionId = event.se_id;
      if (logData.event.metadata) {
        logData.event = {
          ...logData.event,
          metadata: JSON.parse(logData.event.metadata),
        };
      }
      console.debug(
        `[INSIGHTS] Event posted to server:\n${JSON.stringify(logData, null, 2)
        }`,
      );
    } catch (error) {
      console.warn(`[INSIGHTS] Error logging event:\n${error.message}`);
      console.warn(error);
    }
    /* eslint-enable no-console */

    // update the last event time
    yield put(setLastEventSentTime(eventTime));
    const sessionExpired = eventTime - eventSessionStart >= ONE_DAY_MS;
    const isOneDayResetEvent = event.e0 === 'session' && event.e1 === 'one_day_reset';
    if (sessionExpired && !isOneDayResetEvent) {
      yield call(sessionExpiredSaga);
    }
  }
};

// how to test buffer expanding: https://github.com/redux-saga/redux-saga/issues/727
export const watchTrackActions = function* () {
  const buffer = yield buffers.expanding(64);
  const trackChannel = yield actionChannel(TRACK, buffer);
  while (true) {
    const { payload: event } = yield take(trackChannel);

    // wait until event is active again
    let isActive = yield select(getActive);
    while (!isActive) {
      const { payload } = yield take(SET_ACTIVE);
      isActive = payload;
    }

    yield fork(trackActionSaga, event);
  }
};

export const watchPostActions = function* () {
  const buffer = yield buffers.expanding(64);
  const postChannel = yield actionChannel(POST, buffer);
  while (true) {
    const { payload: event } = yield take(postChannel);
    yield call(postSaga, event);
  }
};

export const analyzeReferrerSource = function (source, domain) {
  const event = {
    metadata: {},
    ontology: {
      kingdom: 'session',
      phylum: 'start',
    },
    url: window.location.href,
  };
  if (!source) {
    return event;
  }

  if (!source.includes('social_')) {
    return {
      metadata: {
        ...event.metadata,
        source,
      },
      ontology: {
        ...event.ontology,
        class: 'promotion',
      },
      url: event.url,
    };
  }

  const [, type, objectId] = source.split('_');

  switch (type) {
    case 'o': event.metadata.referral_type = 'overlay';
      break;
    case 'v': event.metadata.referral_type = 'video';
      break;
    case 'c': event.metadata.referral_type = 'card';
      break;
    default: event.metadata.referral_type = 'engagement';
  }
  event.metadata.referral_id = objectId;
  event.metadata.referral_domain = domain;
  event.ontology.class = 'platform';

  return event;
};

// track session start, required 1st event
export const trackSessionStartSaga = function* () {
  const state = yield select();
  // reset lastEventSetTime
  yield put(setSessionStart(null));
  yield put(setLastEventSentTime(null));
  const referrerSource = getReferrerSource(state);
  const referrerDomain = getReferrerDomain(state);
  const eventBase = getEventBase(state);

  const event = yield call(analyzeReferrerSource, referrerSource, referrerDomain);
  const finalEvent = yield call(createFinalEvent, { event, eventBase });
  if (!finalEvent) {
    warning(true, 'trackSessionStartSaga created invalid event');
    return;
  }
  yield put(setSessionStart(finalEvent.t));
  yield call(postSaga, finalEvent);
};

export const trackGAPageview = function* () {
  if (window.ga) {
    const [customId, maestroPath] = yield all([
      select(getClientGaId),
      select(getMaestroPath),
    ]);

    yield call([window, 'ga'], 'maestro.send', {
      hitType: 'pageview',
      page: maestroPath,
    });

    if (customId) {
      const { router: { location: { pathname: page } } } = yield select();
      yield call([window, 'ga'], 'client.send', {
        hitType: 'pageview',
        page,
      });
    }
  }
};

export const pageInjectionSaga = function* () {
  const state = yield select();
  const page = getPage(state);
  const { PAGE_INJECTION_FN: pageInjectionFn } = window;
  if (pageInjectionFn && typeof pageInjectionFn === 'function') {
    try {
      pageInjectionFn(page);
    } catch (err) {
      /* eslint-disable no-console */
      console.error('Invalid page injection:');
      console.error(err.stack);
      /* eslint-enable */
    }
  }
};

export const trackPanelviewSaga = function* () {
  const panelId = yield select(getActivePanel);
  if (!panelId) {
    return;
  }
  const doc = yield select(state => getDocument(state, 'objects', panelId));
  yield put(trackPanel('view', doc));
};

// track pageview, required for 2nd event
export const trackPageviewSaga = function* () {
  const state = yield select();
  const pageId = getPageId(state);
  yield put(setPageId(pageId));

  const eventBase = getEventBase(state);

  const event = yield call(createFinalEvent, {
    event: {
      ontology: {
        kingdom: 'pageview',
        phylum: 'view',
      },
      url: window.location.href,
    },
    eventBase,
  });

  if (!event) {
    warning(true, 'trackPageviewSaga created invalid event');
    return;
  }

  yield call(postSaga, event);
  yield call(trackGAPageview);

  // call custom
  yield call(pageInjectionSaga);
};

// track auth saga, required for 3rd event (optional)
export const trackAuthLogInSaga = function* ({ service }) {
  const state = yield select();
  const eventBase = getEventBase(state);
  const autoLogin = getAutoLogin(state);

  const event = yield call(createFinalEvent, {
    event: {
      metadata: {
        auto_login: autoLogin,
      },
      ontology: {
        class: service,
        kingdom: 'auth',
        order: 'success',
        phylum: 'login',
      },
      url: window.location.href,
    },
    eventBase,
  });
  if (!event) {
    warning(true, 'trackAuthLogInSaga created invalid event');
    return;
  }
  yield call(postSaga, event);
};

// track auth logout saga, required for 3rd event (optional)
export const trackAuthLogOutSaga = function* () {
  const state = yield select();
  const eventBase = getEventBase(state);
  const event = yield call(createFinalEvent, {
    event: {
      ontology: {
        kingdom: 'auth',
        order: 'success',
        phylum: 'logout',
      },
      url: window.location.href,
    },
    eventBase,
  });
  if (!event) {
    warning(true, 'trackAuthLogOutSaga created invalid event');
    return;
  }
  yield call(postSaga, event);
};

// wait for app/timeOffset and device/permanentId to load
export const ensureReadySaga = function* () {
  const timeOffsetLoaded = yield select(isTimeOffsetLoaded);
  if (!timeOffsetLoaded) {
    yield take(SET_TIME_OFFSET);
  }
};

// logic to start saga. WARNING, THE ORDER OF EVENTS HERE MATTERS.
export const startSessionSaga = function* (authAction) {
  const {
    payload,
    type: authType,
  } = authAction || {};
  yield call(ensureReadySaga);

  // set session id
  const sessionId = uuidv4();
  yield put(setSessionId(sessionId));

  // track session start, then pageview, then auth IN THAT ORDER
  yield call(trackSessionStartSaga);
  yield call(trackPageviewSaga);
  if (authType === LOG_IN_SUCCESS) {
    yield call(trackAuthLogInSaga, payload);
  } else if (authType === LOG_OUT) {
    yield call(trackAuthLogOutSaga);
  }

  // fire active, which begins insights
  yield put(setActive({ state: true }));
};

// resets session, sets insights.active to false, which will
// pause any track calls
export const authChangeSaga = function* (authAction) {
  const state = yield select();
  const isActive = getActive(state);
  const isInitialized = getInitialized(state);
  if (isInitialized && !isActive) {
    return;
  }
  yield put(setInitialized(true));
  yield put(setActive({ authAction, state: false }));
};

// listen to all pageview changes and track pageviews
export const pageviewSaga = function* ({ payload: { loaded, object } }) {
  const { _id } = object || {};
  const state = yield select();
  const isActive = getActive(state);
  if (!isActive) { return; }
  const lastPageId = getCurrentPageId(state);
  if (lastPageId !== _id && loaded) {
    yield put(trackPageview('view'));
    yield put(setPageId(_id));
    yield call(trackGAPageview);
  }
};

export const logInAttemptSaga = function* ({ payload: { provider } }) {
  const autoLogin = yield select(getAutoLogin);
  yield put(trackAuth('login', {}, {
    autoLogin,
    event: 'attempt',
    service: provider || 'email',
  }));
};

export const logInFailSaga = function* ({ payload: { message } }) {
  const autoLogin = yield select(getAutoLogin);
  yield put(trackAuth('login', {}, {
    autoLogin,
    error: message,
    event: 'fail',
    // TODO: service,
  }));
};

export const googleAnalyticsPingSaga = function* () {
  const customId = yield select(getClientGaId);

  while (true) {
    // send ping every 4:55 seconds
    yield delay((1000 * 60 * 4) + (1000 * 55));
    if (window.ga) {
      yield call([window, 'ga'], 'maestro.send', {
        eventAction: 'ping',
        eventCategory: 'maestro',
        hitType: 'event',
      });

      if (customId) {
        yield call([window, 'ga'], 'client.send', {
          eventAction: 'ping',
          eventCategory: 'maestro',
          hitType: 'event',
        });
      }
    }
  }
};

export const handleTrackClosePanel = function* () {
  const { customPanels } = yield select();

  const panel = customPanels[customPanels.length - 1];

  // sanity check
  if (panel?.id) {
    const loaded = yield select(state => isDocumentLoaded(state, 'objects', panel.id));
    if (!loaded) {
      return;
    }
    const doc = yield select(state => getDocument(state, 'objects', panel.id));
    yield put(trackPanel('close', doc));
  } else if (panel?.doc || panel?.renderer) {
    yield put(trackPanel('close', panel));
  }
};

export const loadGoogleAnalyticsSaga = function* () {
  const googleAnalyticsDisabled = yield select(isGoogleAnalyticsDisabled);

  if (googleAnalyticsDisabled) {
    return;
  }

  // will only load AFTER site loads and tracking consent is provided
  const isLoaded = yield select(getSiteId);
  if (isLoaded) {
    const customId = yield select(getClientGaId);
    yield call(loadGoogleAnalytics, customId);
    yield fork(googleAnalyticsPingSaga);
  }
};

// when active set to false, restart sessions
const setActiveSaga = function* (action) {
  const { authAction, state } = action.payload;
  if (state === false) {
    yield call(startSessionSaga, authAction);
  }
};

const countryCodeSaga = function* (action) {
  yield put(setCountryCode(action.payload));
};

// starts here
export const insightsSaga = function* () {
  const siteId = yield select(getSiteId);
  if (SITES_WITH_ANALYTICS_TURNED_OFF.includes(siteId)) {
    return;
  }
  // set referrer
  yield put(setReferrer());

  // listen to set active
  yield takeEvery(SET_ACTIVE, setActiveSaga);

  // listen to TRACK and POST actions
  yield fork(watchEventBuilders);
  yield fork(watchTrackActions);
  yield spawn(watchPostActions);

  // listen to log-inattempts
  yield takeEvery(LOG_IN, logInAttemptSaga);
  yield takeEvery(LOG_IN_FAILURE, logInFailSaga);

  // listen to pageviews
  yield takeEvery(SET_OBJECT, pageviewSaga);

  // listen to log-in/log-out hooks from auth
  yield takeEvery(LOG_IN_SUCCESS, authChangeSaga);
  yield takeEvery(LOG_OUT, authChangeSaga);

  // listen for anon session starts
  yield takeEvery(ANON_SESSION_INIT, authChangeSaga);

  // load ga
  yield takeEvery(LOAD_GA, loadGoogleAnalyticsSaga);

  // track for opened panels
  yield takeEvery(SET_ACTIVE_PANEL, trackPanelviewSaga);

  // track close panels - This is here and not in custom-panels because of a circ dep error.
  yield takeEvery(CLOSE_PANEL, handleTrackClosePanel);

  yield takeEvery(SET_COUNTRY_CODE_SAGA_ACTION, countryCodeSaga);
};

export default insightsSaga;
