import {
  AdminErrorPayload,
  ModalKinds,
  ModalPayload,
  TextPayload,
  GDPRPayload,
} from './types';

export const DISMISS_MODAL = 'modals/DISMISS_MODAL';
export const SHOW_MODAL = 'modals/SHOW_MODAL';
export const LOGIN_MODAL = 'modals/LOGIN_MODAL';
export const USER_SUBMITTED_GDPR_TERMS = 'modals/USER_SUBMITTED_GDPR_TERMS';

interface IDismissModalAction {
  payload?: string;
  type: typeof DISMISS_MODAL;
}

export type IModalAction = IDismissModalAction |
  ILoginModalAction |
  IShowModalAction |
  IShowTextAction |
  IShowAdminErrorModalAction |
  IShowGDPRModalAction |
  IGDPRSubmissionAction;

export const dismissModal = (kind?: string): IDismissModalAction => ({
  payload: kind,
  type: DISMISS_MODAL,
});

interface ILoginModalPayload {
  autoSubmit?: boolean;
  email?: string;
  page?: 'SIGNUP' | 'LOGIN' | 'ACCOUNTLOOKUP';
  provider?: string;
}

export interface ILoginModalAction {
  payload: ILoginModalPayload & { isIframe: boolean }
  type: typeof LOGIN_MODAL;
}

export const loginModal = (payload?: ILoginModalPayload): ILoginModalAction => ({
  payload: {
    ...payload,
    isIframe: window.parent !== window,
  },
  type: LOGIN_MODAL,
});

interface IShowModalAction {
  payload: ModalPayload;
  type: typeof SHOW_MODAL
}

export const showModal = (payload: ModalPayload): IShowModalAction => ({
  payload,
  type: SHOW_MODAL,
});

interface IShowTextAction {
  payload: TextPayload;
  type: typeof SHOW_MODAL
}

export const showTextModal = (text: string): IShowTextAction => ({
  payload: {
    data: { text },
    kind: ModalKinds.text,
  },
  type: SHOW_MODAL,
});

interface IShowAdminErrorModalAction {
  payload: AdminErrorPayload;
  type: typeof SHOW_MODAL;
}

export const showAdminErrorModal = (message: string, errorMessage?: string, errorKey?: string): IShowAdminErrorModalAction => ({
  payload: {
    data: { message, errorMessage, errorKey },
    kind: ModalKinds.adminError,
  },
  type: SHOW_MODAL,
});

export const showAdminErrorKey = (errorKey: string): IShowAdminErrorModalAction => ({
  payload: {
    data: { errorKey },
    kind: ModalKinds.adminError,
  },
  type: SHOW_MODAL,
});

interface IShowGDPRModalAction {
  payload: GDPRPayload;
  type: typeof SHOW_MODAL
}

export const showGDPRModal = (): IShowGDPRModalAction => ({
  payload: {
    kind: ModalKinds.gdpr,
  },
  type: SHOW_MODAL,
});

export interface IGDPRSubmissionAction {
  payload: { accepted?: boolean, kind: 'gdpr' }
  type: typeof USER_SUBMITTED_GDPR_TERMS;
}

export const GDPRAccepted = (): IGDPRSubmissionAction => ({
  payload: {
    kind: 'gdpr',
    accepted: true,
  },
  type: USER_SUBMITTED_GDPR_TERMS,
});

export const GDPRRejected = (): IGDPRSubmissionAction => ({
  payload: {
    kind: 'gdpr',
    accepted: false,
  },
  type: USER_SUBMITTED_GDPR_TERMS,
});


