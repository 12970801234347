import { IUserPassword, IUserProfile } from './models';
export const UPDATE_USER_PROFILE = 'profile/UPDATE_USER_PROFILE';
export const CLEAR_USER_PROFILE = 'profile/CLEAR_USER_PROFILE';
export const GET_USER_PROFILE = 'profile/GET_USER_PROFILE';
export const SET_USER_PROFILE = 'profile/SET_USER_PROFILE';
export const UPDATE_USER_PASSWORD = 'profile/UPDATE_USER_PASSWORD';
export const CONFIRM_PASSWORD_UPDATED = 'profile/CONFIRM_PASSWORD_UPDATED';
export const PASSWORD_UPDATE_ERROR = 'profile/PASSWORD_UPDATE_ERROR';

export type IUserProfileAction = (IGetUserProfileAction |
  IClearUserProfileAction |
  IUpdateUserProfileAction |
  ISetUserProfileAction |
  IUpdateUserPasswordAction |
  IConfirmPasswordUpdatedAction |
  IUpdateUserPasswordAction |
  IPasswordUpdateErrorAction
);

export interface IUpdateUserProfileAction {
  payload: IUserProfile;
  type: typeof UPDATE_USER_PROFILE;
}

export interface IClearUserProfileAction {
  payload: {};
  type: typeof CLEAR_USER_PROFILE;
}

export interface IGetUserProfileAction {
  payload: null;
  type: typeof GET_USER_PROFILE;
}

export interface ISetUserProfileAction {
  payload: IUserProfile;
  type: typeof SET_USER_PROFILE;
}

export interface ISetUserProfileAction {
  payload: IUserProfile;
  type: typeof SET_USER_PROFILE;
}

export interface IUpdateUserPasswordAction {
  payload: IUserPassword;
  type: typeof UPDATE_USER_PASSWORD;
}

export interface IConfirmPasswordUpdatedAction {
  type: typeof CONFIRM_PASSWORD_UPDATED;
}

export interface IPasswordUpdateErrorAction {
  type: typeof PASSWORD_UPDATE_ERROR;
}

export function updateUserProfileState(payload: IUserProfile): IUpdateUserProfileAction {
  return {
    payload: { ...payload },
    type: UPDATE_USER_PROFILE,
  };
}

export function setUserProfileState(payload: IUserProfile): ISetUserProfileAction {
  return {
    payload: { ...payload },
    type: SET_USER_PROFILE,
  };
}

export function clearUserProfileState(): IClearUserProfileAction {
  return {
    payload: {},
    type: CLEAR_USER_PROFILE,
  };
}

export function getUserProfile(): IGetUserProfileAction {
  return {
    payload: null,
    type: GET_USER_PROFILE,
  };
}

export function updateUserPassword(payload: IUserPassword): IUpdateUserPasswordAction {
  return {
    payload: { ... payload },
    type: UPDATE_USER_PASSWORD,
  };
}

export function confirmPasswordUpdated(): IConfirmPasswordUpdatedAction {
  return {
    type: CONFIRM_PASSWORD_UPDATED,
  };
}

export function passwordUpdateError(): IPasswordUpdateErrorAction {
  return {
    type: PASSWORD_UPDATE_ERROR,
  };
}
