import { makeAction, prefix } from 'redux-utils';
import { IObject, PageType } from 'models';
const action = prefix('channel');

export const CLONE_PAGE = action('CLONE_PAGE');
export const UPDATE_PAGE = action('UPDATE_PAGE');

export const updatePage = makeAction(UPDATE_PAGE, (pageId: string, update: Partial<IObject>) => ({
  payload: { pageId, update },
}));

export const clonePage = makeAction(CLONE_PAGE, (pageId: string, pageName: string, pageSlug: string, type: PageType) => ({
  payload: { pageId, pageName, pageSlug, type },
}));
