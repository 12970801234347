import { put } from 'redux-saga/effects';
import { makeSaga } from 'redux-utils';
import { unsubscribeCollection } from 'services/realtime/actions';
import { logOut } from 'services/auth';
import { pendingNotificationsCollection } from './constants';

export const cleanupNotificationListenerSaga = makeSaga(
  { logOut },
  function* () {
    yield put(
      unsubscribeCollection(pendingNotificationsCollection),
    );
  },
);
