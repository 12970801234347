import { PaymentMethod } from '@stripe/stripe-js';
import { TIPPING_BASE_URL } from 'config';
import { IPurchaseReferer } from 'services/billing';
import maestroApiRequest from 'services/maestro-api-client';
import { ITipResponse } from './models';

interface IBaseAPIParams {
  primaryToken: string;
  siteId: string;
}
export interface IChargeTipPayload {
  amount: number;
  billingInfo: PaymentMethod.BillingDetails;
  idempotencyKey: string;
  message: string;
  panelId: string;
  paymentMethod: PaymentMethod;
  presentmentCurrency: string;
  referer?: IPurchaseReferer;
  siteSlug: string;
  userAvatar: string;
}
export interface IChargeTipParams extends IBaseAPIParams {
  payload: IChargeTipPayload;
  primaryToken: string;
}

export interface IToggleMessageVisibilityParams extends IBaseAPIParams {
  payload: IToggleMessageVisibilityPayload;
  primaryToken: string;
}

export interface IToggleMessageVisibilityPayload {
  id: string;
  panelId: string;
  toggle: boolean;
}

export const chargeTip = async ({ payload, primaryToken, siteId }: IChargeTipParams): Promise<ITipResponse> => {
  const { data } = await maestroApiRequest({
    primaryToken,
    siteId,
  }).post<ITipResponse>(`${TIPPING_BASE_URL}/charge`, payload);
  return data;
};

interface IToggleResponseError {
  error: string;
  success: boolean;
}

export const toggleMessageVisibility =
  async ({ payload, primaryToken, siteId }: IToggleMessageVisibilityParams): Promise<null | IToggleResponseError> => {
    const { data } = await maestroApiRequest({
      primaryToken,
      siteId,
    }).post<null | IToggleResponseError>(`${TIPPING_BASE_URL}/toggle-tip`, payload);
    return data;
  };
