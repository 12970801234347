import { createSelector } from 'reselect';
import IState from 'services/state';
import { getQuery as getQuerystring } from 'services/app-router/selectors/common';
import { getEditingPageDoc } from 'services/admin/selectors';
import { EPGMap } from './interfaces';
import { IObject } from 'models';
import { playlistHasYoutubeOrTwitch, videoHasYoutubeOrTwitch } from './utils';
import {
  PLAYLIST,
  VIDEO_SPOTLIGHT,
} from 'components/admin-bridge/AdminGridController/constants';
import { pageTypes } from 'services/app';

type Nullable<T> = T | null;

export const getEPGMap = createSelector(
  (state: IState): EPGMap => state.video.epg,
  (epg) => epg,
);

export const forceMute = (state: IState): boolean =>
  Number((getQuerystring(state) as any).mute) === 1;

export const getVideoModalId = ({
  video: {
    videoModal: { modalId },
  },
}: IState): string | null => modalId;

export const getVodIdFromQuery = (state: IState): Nullable<string> =>
  (getQuerystring(state) as any).v || null;

export const getPlaylistIdFromQuery = (state: IState): Nullable<string> =>
  (getQuerystring(state) as any).p || null;

export const getTagFromQuery = (state: IState): Nullable<string> =>
  (getQuerystring(state) as any).tag || null;

/**
 * The terms of service of Youtube and Twitch don't allow gates in front of their content
 * This method verifies if channel or landing have any youtube or twitch video
 * for channel it will check schedule, live and offline content
 * for landing pages it will check on playlist and spotlight content
 * @param {IState} state - current state
 * @param {IObject} getEditingPageDoc - current doc
 * @return {boolean} true if it has youtube or twitch, false if not
 */
export const hasYoutubeOrTwitchVideoOnPageContent = createSelector(
  [(state: IState) => state,
    (state: IState) => getEditingPageDoc(state),
  ],
  (state, doc): boolean => {
    if (doc.type === pageTypes.CHANNEL) {
      return channelHasYoutubeOrTwitchContent(doc, state);
    }

    if (doc.type === pageTypes.LANDING) {
      return landingPageHasYoutubeOrTwitchContent(doc, state);
    }

    return false;
  },
  );

const channelHasYoutubeOrTwitchContent = (
  doc: IObject,
  state: IState,
): boolean => {
  // Checks for live streams
  const liveStreams = doc?.data?.content?.live?.liveStreams || [];
  const liveStreamsHasYoutubeOrTwitchVideo = liveStreams.some((i) =>
    videoHasYoutubeOrTwitch(i._id, state),
  );
  if (liveStreamsHasYoutubeOrTwitchVideo) {
    return true;
  }

  // Checks for live scheduled videos
  const liveScheduled = doc?.data?.content?.live?.schedule || [];
  const liveScheduledHasYoutubeOrTwitchVideo = liveScheduled.some(
    (i) => i.video && videoHasYoutubeOrTwitch(i.video._id, state),
  );
  if (liveScheduledHasYoutubeOrTwitchVideo) {
    return true;
  }

  // Checks for offline content video or playlist
  const videoId = doc?.data?.content?.offline?.video?._id;
  if (videoId) {
    const hasYoutubeOrTwitchVideo = videoHasYoutubeOrTwitch(videoId, state);
    if (hasYoutubeOrTwitchVideo) {
      return true;
    }
  }

  const playlistId = doc?.data?.content?.offline?.playlist?._id;

  if (playlistId) {
    const hasYoutubeOrTwitchVideo = playlistHasYoutubeOrTwitch(
      playlistId,
      state,
    );
    if (hasYoutubeOrTwitchVideo) {
      return true;
    }
  }

  return false;
};

const landingPageHasYoutubeOrTwitchContent = (
  doc: IObject,
  state: IState,
): boolean => {
  const landingContent = doc?.data?.landingContent;
  if (!landingContent) {
    return false;
  }

  // Checks for video spotlight block
  const videoSpotlightContent = landingContent.filter(
    (i) => i.kind === VIDEO_SPOTLIGHT,
  );
  const videoSpotlightHasYoutubeOrTwitchVideo = videoSpotlightContent.some(
    // @ts-ignore
    (i) => i.data && videoHasYoutubeOrTwitch(i.data.videoId, state),
  );

  if (videoSpotlightHasYoutubeOrTwitchVideo) {
    return true;
  }

  // Checks for playlist block
  const playlistContent = landingContent.filter((i) => i.kind === PLAYLIST);
  const playlistHasYoutubeOrTwitchVideo = playlistContent.some(
    // @ts-ignore
    (i) => i.playlist && playlistHasYoutubeOrTwitch(i.playlist._id, state),
  );
  return playlistHasYoutubeOrTwitchVideo;
};

export const isDeviceLimitEnabled = (state: IState) =>
  Boolean(state.video.viewer.deviceLimitEnabled);

export const getDeviceKickedAt = (state: IState) => state.video.deviceKickedAt;
