// tslint:disable:no-console
import { makeSaga } from 'redux-utils';
import { IState } from 'services/state';
import { call, put, select } from 'redux-saga/effects';
import { goLiveWithMaestroLivestream } from 'services/admin/actions';
import { trackAdmin } from 'services/insights/actions';
import { getPrimaryToken } from 'services/auth/selectors';
import { showAdminErrorKey } from 'services/modals/actions';
import { StreamSource } from '../constants';
import {
  getMaestroStreamId,
  getStreamSource,
} from '../selectors';
import { setLivestreamError } from '../actions';
import {
  startStream,
  RecordingResp,
} from '../api';
import { AdminActionEvents } from 'services/admin/models';
import { trackEvent } from 'services/segment-analytics';
import { makeStartEvent } from './helpers/makeStartEvent';
import { studioIframe } from './registerStudioIframe';

export const goLiveSaga = makeSaga({ goLiveWithMaestroLivestream }, function* () {
  try {
    const state: IState = yield select();
    const maestroStreamId = getMaestroStreamId(state);
    const token = getPrimaryToken(state);
    const source = getStreamSource(state);

    if (!token || !source) {
      return;
    }

    const response: RecordingResp = yield call(
      startStream,
      {
        insights: yield call(makeStartEvent),
        maestroStreamId,
        source,
        token,
        goLiveTime: (
          source === StreamSource.STUDIO
            ? Date.now()
            : undefined
        ),
      },
    );

    if (source === StreamSource.STUDIO)
      studioIframe?.contentWindow?.postMessage(
        {
          source: 'maestro:web:v1',
          command: 'start',
        },
        '*',
      );

    if (response.status === 'failed') {
      throw new Error('Failed status');
    }
    yield put(setLivestreamError(null));
    yield put(trackEvent({
      event: AdminActionEvents.ADMIN_WENT_LIVE,
      properties: {
        streamId: maestroStreamId,
      },
    }));
    yield put(trackAdmin(
      AdminActionEvents.STREAM,
      {
        id: maestroStreamId,
        kind: 'start',
      },
      {},
    ));
  } catch (error) {
    yield put(showAdminErrorKey('ADMIN_ERROR_STREAM_ONLINE_FAILED'));
    yield put(setLivestreamError(Date.now()));
  }
});
