import { RESOLVE_RESET_PASSWORD, IForgotPasswordAction } from './actions';

interface IForgotPasswordInitialState {
  email: string | null;
  emailSent: boolean | null;
}

export type IForgotPasswordState = IForgotPasswordInitialState;

const INITIAL_STATE: IForgotPasswordInitialState = {
  email: null,
  emailSent: null,
};

export const getInitialState = (): IForgotPasswordState => ({
  ...INITIAL_STATE,
});

export default function forgotPasswordReducer(
  state: IForgotPasswordState = INITIAL_STATE,
  action: IForgotPasswordAction,
): IForgotPasswordState {
  switch (action.type) {
    case RESOLVE_RESET_PASSWORD: {
      return {
        ...state,
        email: action.payload.email,
        emailSent: action.payload.emailSent,
      };
    }
    default:
      return state;
  }
}
