import { hasOwn } from 'utils';

import {
  ADD_QUEST,
  REMOVE_QUEST,
  INITIALIZE_QUEST_PROGRESS,
  UPDATE_QUEST_PROGRESS,
  RESET_ALL_QUEST_PROGRESS,
  SET_QUEST_CLAIMING,
} from './actions';

export const getInitialState = () => ({
  claimingQuestSet: {},
  listeners: {},
  progress: {},
});

const questReducer = (state = getInitialState(), { type, payload }) => {
  switch (type) {
    case ADD_QUEST: {
      const id = payload;
      return {
        ...state,
        listeners: {
          ...state.listeners,
          [id]: hasOwn(state.listeners, id) ? (state.listeners[id] + 1) : 1,
        },
      };
    }

    case INITIALIZE_QUEST_PROGRESS: {
      const { progress } = payload;
      const { progress: existingProgress } = state;
      const updatedBlob = {
        ...progress,
        ...existingProgress,
      };

      return {
        ...state,
        progress: updatedBlob,
      };
    }

    case RESET_ALL_QUEST_PROGRESS: {
      return {
        ...state,
        progress: {},
      };
    }

    // add individual quest progress data to state
    case UPDATE_QUEST_PROGRESS: {
      const { progress, questId } = payload;
      if (progress && questId) {
        const existingQuestProgress = state.progress[questId] || {};
        const mergedQuestProgress = {
          ...existingQuestProgress,
          ...progress,
        };
        const newProgress = {
          ...(state.progress || {}),
          [questId]: mergedQuestProgress,
        };

        return {
          ...state,
          progress: newProgress,
        };
      }
      return {
        ...state,
      };
    }

    case SET_QUEST_CLAIMING: {
      const { claiming, id } = payload;
      const claimingQuestSet = { ...state.claimingQuestSet };
      if (claiming) {
        claimingQuestSet[id] = true;
      } else {
        delete claimingQuestSet[id];
      }
      return {
        ...state,
        claimingQuestSet,
      };
    }

    case REMOVE_QUEST: {
      const id = payload;
      if (!hasOwn(state.listeners, id)) {
        return state; // TODO: Log a warning
      }
      const {
        [id]: oldCount,
        ...otherIds
      } = state.listeners;
      const count = oldCount - 1;
      const listeners = count <= 0 ? otherIds : { ...otherIds, [id]: count };

      return {
        ...state,
        listeners,
      };
    }
    default:
      return state;
  }
};

export default questReducer;
