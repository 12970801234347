import { BROADCAST_SERVICE_BASE_URL, OVERLAY_QUEUE, SERVICE_BASE_URL } from 'config';
import { db } from 'firebase-db';
import axios from 'axios';

export const pushToQueue = doc => db.database().ref(OVERLAY_QUEUE).push(doc);

export const sendBroadcast = ({ broadcast, primaryToken, siteId }) => (axios.post(
  BROADCAST_SERVICE_BASE_URL, {
    ...broadcast,
    siteId,
  }, {
    headers: {
      Authorization: `Bearer ${primaryToken}`,
      'x-maestro-client-id': siteId,
    },
  },
)
);

export const writeRaffleEntry = ({
  broadcastId,
  siteId,
  token,
  userId,
}) => {
  return axios.post(
    `${SERVICE_BASE_URL}/raffle/v1/entry`, {
      broadcastId,
      siteId,
      userId,
    }, {
      headers: {
        Authorization: `Bearer ${token}`,
        'x-maestro-client-id': siteId,
      },
    },
  );
};
