// tslint:disable:no-console
import { makeSaga } from 'redux-utils';
import { IState } from 'services/state';
import { put, select } from 'redux-saga/effects';
import { setObject } from 'services/app/actions';
import { isUserAdmin } from 'services/auth/selectors';
import { showModal, dismissModal } from 'services/modals/actions';
import { updateDocument, UPDATE_DOCUMENT } from 'services/realtime/actions';
import IStream from 'models/IStream';
import {
  getMaestroStreamId,
  getRealtimeStream,
  isPublishedWithMaestroStream,
} from '../selectors';
import { ModalKinds } from 'services/modals/types';
import { logInSuccess } from 'services/auth';

export const livestreamUpdateSaga = makeSaga(
  {
    logInSuccess,
    updateDocument,
    setObject,
  },
  function* (action) {
    try {
      const state: IState = yield select();
      const isAdmin = isUserAdmin(state);
      const maestroStreamId = getMaestroStreamId(state);
      const maestroStreamPublished = isPublishedWithMaestroStream(state);
      let stream: IStream | undefined | null;

      if (action.type === UPDATE_DOCUMENT) {
        const { path, value } = action.payload;

        if (!/^stream/.test(path)) {
          return;
        }

        const updateId = path.split('/').length >= 2 ? path.split('/')[1] : null;
        const streamUpdated = (obj: any): obj is IStream => (
          maestroStreamId && updateId &&
          maestroStreamId.match(updateId) &&
          obj
        );

        if (!streamUpdated(value))
          return;

        stream = value;
      } else {
        stream = getRealtimeStream(state);
      }

      if (!stream)
        return;

      if (
        isAdmin &&
        maestroStreamPublished &&
        stream.encoderConnected === false
      ) {
        yield put(dismissModal(ModalKinds.streamEncoderState));
        yield put(
          showModal({
            kind: ModalKinds.streamEncoderState,
            data: {
              backgroundStyle: {
                pointerEvents: 'none',
                zIndex: 'unset',
              },
              transparentBackground: true,
            },
          }),
        );
      }
    } catch (error) {
      console.error('livestreamUpdateSaga:', error);
    }
  },
);
