import { SUBSCRIPTION_V2_BASE_URL } from 'config';
import ISubscription from 'models/ISubscription';
import maestroApiRequest from 'services/maestro-api-client';

export const getSubscriptionById = async (id: string, siteId: string, primaryToken: string): Promise<ISubscription> => {
  const result = await maestroApiRequest({
    primaryToken,
    siteId,
  }).get<ISubscription>(`${SUBSCRIPTION_V2_BASE_URL}/${id}`);
  return result.data;
};
