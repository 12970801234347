import { serializeError } from 'serialize-error';
import { IErrorAction, LOG_ERROR } from './actions';

export interface IErrorState {
  lastError: null | {
    customMessage?: string;
    error: Record<string, any>;
    url: string;
  };
}

export const getInitialState = (): IErrorState => ({
  lastError: null,
});

const errorReducer = (
  state: IErrorState = getInitialState(),
  action: IErrorAction,
): IErrorState => {
  switch (action.type) {
    case LOG_ERROR:
      return {
        ...state,
        lastError: {
          ...action.payload,
          error: serializeError(action.payload.error),
        },
      };
    default:
      return state;
  }
};

export default errorReducer;
