export interface ITipSuggestedAmounts {
  conversionRate: number;
  currency: string;
  max: number;
  min: number;
  suggested1: number;
  suggested2: number;
  suggested3: number;
}

export interface ITipPanelItem {
  _id: string;
  accountId: string;
  created: number;
  currency: string;
  hide: boolean;
  message: string;
  modified: number;
  panelId: string;
  presentmentCurrencyAmount: number;
  settlementCurrencyAmount: number;
  userAvatar: string;
  userName: string;
}

export interface ITipPanel {
  _id: string;
  created: number;
  modified: number;
  panelId: string;
  siteId: string;
  tips: ITipPanelItem[];
  topSupporters: ITopSupporter[];
}

export interface ITopSupporter {
  accountId: string;
  aggregateAmount: {
    presentmentCurrency: number;
    settlementCurrency: number;
  };
  userAvatar: string;
  userName: string;
}

export interface ITipResponse {
  _id: string;
  amount: number;
  created: number;
  currency: string;
  modified: number;
  receiptNumber: string;
  siteId: string;
  siteSlug: string;
  status: string;
  tags: any[];
  userName: string;
}

export enum TipStatus {
  ACTIVE = 'active',
  CANCELED = 'canceled',
  PENDING = 'pending',
}

export interface IRealtimeTipDocument {
  _id: string;
  accountId: string;
  amount: number;
  created: number;
  currency: string;
  errorMessage?: string;
  message?: string;
  modified: number;
  panelId: string;
  siteId: string;
  siteSlug: string;
  status: TipStatus;
  userAvatar?: string;
  userName: string;
}

