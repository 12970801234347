import { IState } from 'services/state';
import { FACEBOOK_APP_ID } from './constants';

export const canUseFacebook = (state: IState) => (
  Boolean(state.socials.facebookReady)
);

// TODO: Default app id should come from an env var/config
export const getFacebookId = (state: IState) => {
  const { prod, test } = FACEBOOK_APP_ID;
  return state.app.site.settings?.services?.facebookAppId ||
    (window.location.port ? test : prod);
};

export const getFacebookPixelId = (state: IState) => {
  return state.app.site.settings?.services?.facebookPixelId;
};
