import IState from 'services/state';
import { call, select } from 'redux-saga/effects';
import { MaestroIFrameEvents } from '../models';
import { postMessageToIframeSDK } from '../api';
import { getMaestroReadyBasePayload } from '../selectors';

export const fireMaestroReadyEvent = function* () {
  const state: IState = yield select();

  yield call(
    postMessageToIframeSDK,
    {
      action: MaestroIFrameEvents.MAESTRO_READY,
      ...getMaestroReadyBasePayload(state),
    },
  );
};
