import { STATIC_BASE_URL } from 'config';
import { injectT } from 'hooks/use-translation';
import { lazy } from 'utils/lazy';

export const POWERED_BY_MAESTRO_LINK = 'https://info.maestro.io/';

export const DEFAULT_VIDEO_PLACEHOLDER_ICON = `${STATIC_BASE_URL}/media/video-thumbnail-fallback-image.jpeg`;

export const CROSS_CHANNEL_VALIDATION_MODAL = 'crossChannelEditWarning';

export const SUPPORT_BASE_URL = 'https://support.maestro.io';

export const MAESTRO_SHOPIFY_APP_URL = 'https://apps.shopify.com/maestro';

export const TERMS_OF_USE_URL = 'https://www.maestro.io/legal/tou';

export const TERMS_OF_SERVICE_URL = 'https://www.maestro.io/legal/tos';

export const PRIVACY_POLICY_URL = 'https://www.maestro.io/legal/privacy';

export const MAESTRO_NEW_SUPPORT_TICKET_URL = 'https://support.maestro.io/knowledge/kb-tickets/new';

export const DEFAULT_GATE_SUBTITLE = injectT(
  (t) => lazy(
    () => t('ADMIN_GATE_DEFAULT_SUBTITLE'),
  ),
);

export const DEFAULT_GATE_TITLE = injectT(
  (t) => lazy(
    () => t('ADMIN_GATE_DEFAULT_TITLE'),
  ),
);

export const GATE_SUBTITLE_MAX_LENGTH = 300;

export const GATE_TITLE_MAX_LENGTH = 80;
