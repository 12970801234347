import EventBuilder from './EventBuilder';

class PortalEventBuilder extends EventBuilder {
  constructor() {
    super('portal');
  }

  /* eslint-disable class-methods-use-this */
  buildOntology(action, doc, extra) {
    // TODO: Verify ontology doc; nothing from portal itself???
    if (action === 'engage') {
      return {
        /* eslint-disable sort-keys */
        kingdom: 'navigation',
        phylum: 'engage',
        class: extra.kind,
        /* eslint-enable */
      };
    }
    return {};
  }

  /* eslint-disable class-methods-use-this */
  buildMetadata(action, doc, extra) {
    if (extra?.kind === 'thumbnail') {
      return {
        channel_id: extra.channelId,
      };
    }
    return extra;
  }
}
/* eslint-enable class-methods-use-this */

export default new PortalEventBuilder();
