// TODO: Better error handling than console logging :(
import { call, delay, put, takeEvery, select } from 'redux-saga/effects';
import { SERVER_TIME } from 'firebase-db';

import { persistenceService } from 'services/persistence';
import {
  getUserId,
  getUserToken,
  getPrimaryToken,
} from 'services/auth/selectors';

import {
  getSiteId,
} from 'services/app/selectors';

import { camelFromSnake } from 'shared/string-utils';

import {
  addOverlay,
  BROADCAST_OVERLAY,
  RECEIVE_OVERLAY,
  ENGAGE_OVERLAY,
  ENTER_RAFFLE_REQUEST,
  removeOverlay,
  decreaseTime,
  enterRaffleSuccess,
  enterRaffleFailure,
  updateOverlay,
  updatePendingStatus,
} from './actions';

import {
  pushToQueue,
  sendBroadcast,
  writeRaffleEntry,
} from './api';

import {
  getOverlayById,
  getOverlayByKey,
} from './selectors';

const engageOverlay = function* ({ payload: { answerIndex, broadcastId, channel, type, data } }) {
  try {
    const state = yield select();
    const overlay = getOverlayById(state, broadcastId);
    if (!overlay?.engaged) {
      const sendData = {
        channel: `${channel}:${type}`,
        data: data || null,
        object_id: state.app.object?._id || null,
        site_id: state.app.site?._id || null,
        time: SERVER_TIME,
        token: getUserToken(state),
      };

      if (typeof answerIndex === 'number') {
        persistenceService('session').write(broadcastId, answerIndex);
      }
      yield call(pushToQueue, sendData);
      yield put(updateOverlay({ broadcastId, overlayData: { engaged: true } }));
    }
  } catch (error) {
    /* eslint-disable no-console */
    console.error('engageOverlay error');
    console.error(error);
    /* eslint-enable */
  }
};

export const receiveOverlay = function* ({ payload: overlay }) {
  let key;
  try {
    ({ key } = overlay);
    const {
      payload: {
        data: {
          duration = 1,
          durations: raffleDurations = {},
          kind,
        },
      },
    } = overlay;

    const actualDuration = kind.startsWith('raffle') ?
      raffleDurations[camelFromSnake(kind)] || 10 :
      duration;

    const newOverlay = {
      ...overlay,
      timeRemaining: actualDuration,
    };

    yield put(addOverlay(newOverlay));

    let { timeRemaining } = newOverlay;
    while (timeRemaining > 0) {
      yield delay(1000);
      yield put(decreaseTime(key));
      timeRemaining = (yield select(getOverlayByKey, key))?.timeRemaining || 0;
    }
  } catch (error) {
    /* eslint-disable no-console */
    console.error('receiveOverlay error');
    console.error(error);
    /* eslint-enable */
  } finally {
    if (key) {
      yield put(removeOverlay(key));
    }
  }
};

const enterRaffleSaga = function* ({ payload: broadcastId }) {
  try {
    const state = yield select();
    const userId = getUserId(state);
    const token = getPrimaryToken(state);
    const siteId = state.app.site._id;

    if (!userId) {
      // eslint-disable-next-line no-console
      console.error('No userId found for raffle entry. Is the user logged in?');
      yield put(enterRaffleFailure(broadcastId));
      return;
    }

    try {
      yield call(writeRaffleEntry, {
        broadcastId,
        siteId,
        token,
        userId,
      });
      yield put(enterRaffleSuccess(broadcastId));
    } catch (error) {
      /* eslint-disable no-console */
      console.error('Error entering raffle:');
      console.error(error);
      /* eslint-enable */
      yield put(enterRaffleFailure(broadcastId));
    }
  } catch (error) {
    yield put(enterRaffleFailure(broadcastId));
    /* eslint-disable no-console */
    console.error('enterRaffleSaga uncaught exception:');
    console.error(error);
    /* eslint-disable */
  }
};

export const broadcastOverlay = function* ({ payload: broadcast }) {
  const state = yield select();
  const primaryToken = getPrimaryToken(state);
  const siteId = getSiteId(state);
  try {
    yield put(updatePendingStatus(true));
    yield call(sendBroadcast, { broadcast, primaryToken, siteId });
    yield put(updatePendingStatus(false));
  } catch (error) {
    yield put(updatePendingStatus(false));
    // TODO: Handle errors
    /* eslint-disable no-console */
    console.error('broadcastOverlay sort of uncaught exception:');
    console.error(error);
    /* eslint-disable */
  }
};

const overlaysSaga = function* () {
  yield takeEvery(RECEIVE_OVERLAY, receiveOverlay);
  yield takeEvery(ENGAGE_OVERLAY, engageOverlay);
  yield takeEvery(ENTER_RAFFLE_REQUEST, enterRaffleSaga);
  yield takeEvery(BROADCAST_OVERLAY, broadcastOverlay);
};

export default overlaysSaga;
