import { ICreateSignupAction, resetIdempotencyKey, selfServiceSignupActionTypes, setSignupLoading, setSignupSuccessMsg } from './actions';
import { START_PLAN_ID } from './selfServicePlans';
import { call, delay, put, select, takeLeading } from 'redux-saga/effects';
import IState from 'services/state';
import { getAnonymousId } from 'services/segment-analytics/lib';
import { getIdempotencyKey } from './selectors';
import { ISubmitSignupPayload, isSiteReady, submitSignup } from './api';
import { createJwtToken } from 'services/auth/api';
import { CLIENT_JWT_PUBLIC_KEY, IS_SITE_READY_MAX_RETRIES } from 'config';
import { resetSegmentUser } from 'services/segment-analytics';
import { dismissModal, showModal } from 'services/modals';
import { ModalKinds } from 'services/modals/types';

export const createSignupSaga = function* ({
  payload: {
    email,
    password,
    siteName,
    acceptMarketingOpt,
    gclid,
    utmData,
    planId = START_PLAN_ID,
    recaptchaToken,
    paymentMethod,
  },
}: ICreateSignupAction) {
  try {
    // we are using the email to generate the username
    const partialEmail = email.split('@')[0];
    // remove special characters from the username, hubspot does not allow them
    const name = partialEmail.replace(/[^a-zA-Z0-9 ]/g, '');

    yield put(showModal({ kind: ModalKinds.settingUpSelfServiceSite, data: { locked: true } }));

    const siteSlug = (siteName || '').trim();
    const state: IState = yield select();
    const anonymousId: string = yield call(getAnonymousId);
    const idempotencyKey = `${getIdempotencyKey(state)}-${siteSlug}`;
    const submitSignupPayload: ISubmitSignupPayload = {
      anonymousId,
      accountDetails: {
        email: email.toLowerCase().trim(),
        name,
        password,
        username: name,
      },
      marketingOptIn: acceptMarketingOpt,
      idempotencyKey,
      planId,
      siteSlug,
      paymentMethod,
      ...utmData,
      gclid,
    };

    yield call(submitSignup, submitSignupPayload, recaptchaToken);

    // on successful signup, we need to reset the anonymousId
    yield put(resetSegmentUser());

    let siteCreated: boolean = yield call(isSiteReady, siteName);
    let tries = 1;
    let backOff = 0;
    // max retry time will be 7 minutes
    while (!siteCreated) {
      siteCreated = yield call(isSiteReady, siteName);
      tries = Math.min(++tries, IS_SITE_READY_MAX_RETRIES);
      if (tries === 10) {
        yield put(setSignupSuccessMsg({ message: '2' }));
      }
      if (tries >= IS_SITE_READY_MAX_RETRIES) {
        break;
      }
      backOff = 100 * tries ** 2;
      yield delay(backOff);
    }
    const token: string = yield call(createJwtToken, {
      payload: { email: email.toLowerCase().trim(), password },
      secretOrPrivateKey: CLIENT_JWT_PUBLIC_KEY,
      options: { expiresIn: '10m' },
    });

    const redirectSite = `${window.location.origin}/${siteName}?inline_login=${token}`;
    window.location.href = redirectSite;
  } catch (err) {
    // tslint:disable-next-line:no-console
    console.error('Failed to create site', err.message, err.stack);

    yield put(resetIdempotencyKey());
    yield put(dismissModal(ModalKinds.settingUpSelfServiceSite));
    yield put(
      showModal({
        kind: ModalKinds.errorModal,
        data: {
          promptStringKey: 'ADMIN_ERROR_GENERIC',
        },
      }),
    );
    yield put(setSignupLoading(false));
  }
};

export default function* selfServiceSignupSaga() {
  yield takeLeading(selfServiceSignupActionTypes.CREATE_SIGNUP, createSignupSaga);
}
