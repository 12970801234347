// tslint:disable:no-console
import maestroApiRequest from '../maestro-api-client';
import axios from 'axios';

import {
  CANIPLAY_SERVICE_BASE_URL,
  VIDEO_SERVICE_BASE_URL,
  PLAYLIST_SERVICE_BASE_URL,
} from 'config';
import { IVideo, IPlaylist } from 'models';
import { ICanIPlayParams, ICanIPlayResponse } from './interfaces';

// id is channel id for maestro livestreams, video id for everything else
export const canIPlay = async (
  { id, deviceId, kind, siteId, token }:
    ICanIPlayParams,
): Promise<ICanIPlayResponse | null> => {
  try {
    const { data } = await maestroApiRequest({ primaryToken: token, siteId })
      .get(
        `${CANIPLAY_SERVICE_BASE_URL}/${id}`,
        {
          headers: {
            'x-maestro-device-id': `${deviceId}`,
          },
          params: {
            kind,
          },
        },
      );

    if (data.deviceCheck) {
      return { ...data?.contentCheck, ...data?.deviceCheck };
    } else if (data.contentCheck) {
      return { ...data.contentCheck };
    }

    return data;
  } catch (error) {
    if (error.response?.status === 401) {
      console.warn('Account not authorized for content');
      throw error;
    }
    return null;
  }
};

export const removeDevice = async (
  deviceId: string,
  deviceIdToKick: string,
  primaryToken: string,
  siteId: string,
): Promise<any> => {
  const { data } = await maestroApiRequest({ primaryToken, siteId }).post(
    `${CANIPLAY_SERVICE_BASE_URL}/kick`, { deviceIdToKick }, {
    headers: {
      'x-maestro-client-id': siteId,
      'x-maestro-device-id': deviceId,
      'Authorization': `Bearer ${primaryToken}`,
    },
  });
  return data;
};

export const fetchPlaylist = async (
  { id, siteId, token }:
    { id: string, siteId: string, token: string },
): Promise<IPlaylist | null> => {
  try {
    const { data: playlist } = await maestroApiRequest({ primaryToken: token, siteId })
      .get(`${PLAYLIST_SERVICE_BASE_URL}/${id}`);
    return playlist;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const searchVideos = async (
  {
    filters,
    primaryToken,
    siteId,
  }: {
    filters: Record<string, string>,
    primaryToken: string,
    siteId: string,
  },
  captureCancellator?: (fn: () => void) => void,
) => {
  const { data } = await maestroApiRequest({ primaryToken, siteId })
    .get<{
      pagination: {};
      results: IVideo[];
    }>(
      VIDEO_SERVICE_BASE_URL,
      {
        cancelToken: captureCancellator && new axios.CancelToken(
          captureCancellator,
        ),
        params: filters,
      },
    );

  return data;
};

export const getAllTagsForSite = async (
  {
    primaryToken,
    siteId,
  }: {
    primaryToken: string;
    siteId: string;
  },
  captureCancellator?: (fn: () => void) => void,
) => {
  if (!primaryToken || !siteId)
    throw new Error('Missing primaryToken or siteId');

  const { data } = await maestroApiRequest({ primaryToken, siteId, freshInstance: true })
    .get<string[]>(
      `${VIDEO_SERVICE_BASE_URL}/tags/site`,
      {
        cancelToken: captureCancellator && new axios.CancelToken(
          captureCancellator,
        ),
      },
    );

  return data;
};

export const getVideosByTagName = async (
  {
    primaryToken,
    siteId,
    tag,
  }: {
    primaryToken: string;
    siteId: string;
    tag: string;
  },
) => {
  if (!primaryToken || !siteId || !tag) {
    throw new Error('Invalid params');
  }

  const { data } = await maestroApiRequest({ primaryToken, siteId, freshInstance: true })
    .get<IVideo[]>(
      `${VIDEO_SERVICE_BASE_URL}/tags/videos`,
      { params: { tagName: tag } },
    );

  return data;
};

export const fetchMetadata = async <T = IVideo>(
  {
    primaryToken,
    siteId,
    url,
  }: {
    primaryToken: string;
    siteId: string;
    url: string;
  },
  captureCancellator?: (fn: () => void) => void,
) => {
  if (!primaryToken || !siteId)
    throw new Error('Missing primaryToken or siteId');

  const { data } = await maestroApiRequest({
    primaryToken,
    siteId,
    freshInstance: true,
  })
    .get<T>(
      `${VIDEO_SERVICE_BASE_URL}/metadata`,
      {
        params: {
          url,
        },
        cancelToken: captureCancellator && new axios.CancelToken(
          captureCancellator,
        ),
      },
    );

  return data;
};
