import get from 'lodash/get';
import set from 'lodash/set';

export function asElementTypes<T>() {
  return <U>(et: { [K in keyof U]: T }) => et;
}

export function typeCheckSet<F extends {}, T extends {}>(
  type: string,
  from: F,
  fromPath: string,
  to: T,
  toPath = fromPath,
) {
  const valueToSet = get(from, fromPath);
  if (typeof valueToSet === type) {
    set(to, toPath, valueToSet);
  }
}

export type SetState<T> = React.Dispatch<React.SetStateAction<T>>;

export type SnakeCase<T extends string> = T extends `${infer Left}${infer Right}`
  ? Right extends `${infer Fst}${infer Rst}`
  ? Fst extends '-' | ' '
  ? `${SnakeCase<Left>}_${SnakeCase<Rst>}`
  : Right extends Uncapitalize<Right>
  ? `${Uncapitalize<Left>}${SnakeCase<Right>}`
  : `${Uncapitalize<Left>}_${SnakeCase<Right>}`
  : T extends Uppercase<T>
  ? `${Uncapitalize<T>}`
  : T extends `${infer First}${infer Rest}`
  ? `${First}${SnakeCase<Rest>}`
  : T
  : T;

export type UpperSnakeCase<T extends string> = Uppercase<SnakeCase<T>>;

export type DeepPartial<T> = T extends object ? {
  [P in keyof T]?: DeepPartial<T[P]>;
} : T;

export type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

export type ObjectValues<T> = T[keyof T];

export type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends (
  k: infer I,
) => void
  ? I
  : never;

export type UnionToOverloadedFn<U> = UnionToIntersection<
  U extends any ? (f: U) => void : never
>;

export type PopUnion<U> = UnionToOverloadedFn<U> extends (a: infer A) => void ? A : never;

export type IsUnion<T> = [T] extends [UnionToIntersection<T>] ? false : true;

export type UnionToArray<T, A extends unknown[] = []> = IsUnion<T> extends true
  ? UnionToArray<Exclude<T, PopUnion<T>>, [PopUnion<T>, ...A]>
  : [T, ...A];
