import EventBuilder from './EventBuilder';

class VideoEventBuilder extends EventBuilder {
  constructor() {
    super('video');
  }

  buildOntology(action, { _id, kind, spot, title }) {
    return {
      /* eslint-disable sort-keys */
      phylum: action,
      class: kind,
      order: _id,
      family: title,
      species: spot,
    };
  }

  buildMetadata(action, doc, { url, ...extra }) {
    return {
      ...extra,
      url: url || null,
    };
  }
}

export default new VideoEventBuilder();
