import { createSelector } from 'reselect';
import warning from 'warning';

import { isChannelGateEnabled, isGateActive } from 'services/gate/selectors/common';

import {
  ID_SIDEBAR,
} from './ids';

const getLegacySidebarRenderer = createSelector(
  [state => state.app.object, isChannelGateEnabled, isGateActive],
  (object, channelGateEnabled, gateActive) => {
    if (!object?.data) {
      return null;
    }
    const refList = object.data.sidebar || [];
    const idList = refList.map(ref => ref._id).filter(id => id);
    if (!idList.length) {
      return null;
    }
    const seenIds = {};
    const items = channelGateEnabled && gateActive ? [] : idList.map((id) => {
      const count = seenIds[id] || 0;
      seenIds[id] = count + 1;
      const arrayId = `${id}__${count}`;
      return {
        arrayId,
        id,
      };
    });

    const cardId = (object.data.card?.active && object.data.card.card?._id) || null;

    return {
      cardId,
      items,
    };
  },
);

export const getLegacyRenderer = (state, rendererId) => {
  switch (rendererId) {
    case ID_SIDEBAR:
      return getLegacySidebarRenderer(state);
    default:
      warning(
        false,
        `Invalid legacy renderer ID: ${rendererId}`,
      );
      return null;
  }
};
