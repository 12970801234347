// tslint:disable:no-console
import { makeSaga } from 'redux-utils';
import { IState } from 'services/state';
import {
  call,
  put,
  select,
} from 'redux-saga/effects';
import { getPrimaryToken } from 'services/auth/selectors';
import { showAdminErrorKey } from 'services/modals/actions';
import { MetadataStatus } from '../constants';
import { getLivestreamLiveBroadcastId } from '../selectors';
import {
  setLivestreamMetadata,
  setMetadataStatus,
} from '../actions';
import { postProviderStreamMetadata } from '../api';

export const addMetadataSaga = makeSaga({ setLivestreamMetadata }, function* ({ payload }) {
  try {
    const state: IState = yield select();
    const token: ReturnType<typeof getPrimaryToken> = getPrimaryToken(state);
    const liveBroadcastId = getLivestreamLiveBroadcastId(state);
    const { title, thumbnail } = payload;

    if (!token || !liveBroadcastId) {
      return;
    }

    yield put(setMetadataStatus(MetadataStatus.PENDING));
    const metadataParams = { title, thumbnail, token };

    yield call(
      postProviderStreamMetadata,
      {
        ...metadataParams,
        liveBroadcastId,
      },
    );
    yield put(setMetadataStatus(MetadataStatus.RESOLVED));
  } catch (e) {
    console.error('addMetadata error', e);
    yield put(setMetadataStatus(MetadataStatus.REJECTED));
    yield put(showAdminErrorKey('ADMIN_ERROR_ADD_STREAM_METADATA'));
  }
});
