import { IPersistenceService } from './interfaces';
import { formatKey } from './utils';
import { LOCAL_STORAGE, StorageType } from './utils';

export const makeLocalStoragePersistenceService = (storageType: StorageType): IPersistenceService => {
  const storage = storageType === LOCAL_STORAGE ? localStorage : sessionStorage;
  return {
    read: (key, isRawKey) => {
      const value = storage.getItem(formatKey(key, isRawKey));
      return Promise.resolve(value && JSON.parse(value));
    },
    write: (key, value, isRawKey) => {
      storage.setItem(formatKey(key, isRawKey), JSON.stringify(value));
      return Promise.resolve();
    },
    remove: (key, isRawKey) => {
      storage.removeItem(formatKey(key, isRawKey));
      return Promise.resolve();
    },
    readStatic: (key, isRawKey) => {
      const value = storage.getItem(formatKey(key, isRawKey));
      return value && JSON.parse(value);
    },
    refreshCache: () => Promise.resolve(),
  };
};
