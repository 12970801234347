import { IState } from './state';
import { combineReducers } from 'redux';
import { History } from 'history';
import { connectRouter } from 'connected-react-router';
import * as reducers from './reducers';

export const rootReducer = (history: History<unknown>) => combineReducers({
  router: connectRouter(history),
  ...reducers,
});

export type RootState = ReturnType<ReturnType<typeof rootReducer>> | IState;

export default rootReducer;
