import { IShippingAddressState } from './models';
import { IShippingAddressAction,
  RESET_SHIPPING_ADDRESS_REDUCER,
  SET_SHIPPING_ADDRESS_FIELD,
} from './actions';

const INITIAL_STATE: IShippingAddressState = {
  shippingAddress: {
    firstName: '',
    lastName: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    postalCode: '',
    countryCode: '',
    phoneNumber: '',
  },
};

export const getInitialState = () => ({
  ...INITIAL_STATE,
});

const shippingAddressReducer = (state: IShippingAddressState = INITIAL_STATE, action: IShippingAddressAction) => {
  switch(action.type) {
    case RESET_SHIPPING_ADDRESS_REDUCER: {
      return {
        ...state,
        shippingAddress: INITIAL_STATE.shippingAddress,
      };
    }
    case SET_SHIPPING_ADDRESS_FIELD: {
      const newState = {
        ...state,
        shippingAddress: {
          ...state.shippingAddress,
        },
      };

      Object.entries(action.payload).forEach(([key, value]) => {
        newState.shippingAddress[key] = value;
      });

      return newState;
    }
    default: {
      return state;
    }
  }
};

export default shippingAddressReducer;
