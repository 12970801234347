export const REQUEST_PASSWORD_RESET = 'forgotPassword/REQUEST_PASSWORD_RESET';
export const RESOLVE_RESET_PASSWORD = 'forgotPassword/RESOLVE_RESET_PASSWORD';

export type IForgotPasswordAction =
  | IRequestPasswordResetAction
  | IResolveResetPasswordAction;

export interface IRequestPasswordResetPayload {
  email: string;
}

export interface ISentEmailPayload {
  email: string | null;
  emailSent: boolean | null;
}

export interface IRequestPasswordResetAction {
  payload: IRequestPasswordResetPayload;
  type: typeof REQUEST_PASSWORD_RESET;
}

export interface IResolveResetPasswordAction {
  payload: ISentEmailPayload;
  type: typeof RESOLVE_RESET_PASSWORD;
}

export function requestPasswordReset(
  payload: IRequestPasswordResetPayload,
): IRequestPasswordResetAction {
  return {
    payload,
    type: REQUEST_PASSWORD_RESET,
  };
}

export function resolveResetPassword(
  payload: ISentEmailPayload,
): IResolveResetPasswordAction {
  return {
    payload,
    type: RESOLVE_RESET_PASSWORD,
  };
}
