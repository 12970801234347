import { ITrackAction } from 'services/insights/actions';
import { ValidateCrossChannelObjectResp } from 'services/admin/types';
import { IAccountDevices } from 'services/video/interfaces';
import { RewardType } from 'models/IQuest';
import { CSSProperties } from 'react';
import { IPurchaseSummary } from 'services/billing/models';
import { ITheme } from 'models';
import IBundle from 'models/IBundle';
import ISubscription from 'models/ISubscription';
import type { VideoMetadataModalV2Props } from 'components/modals/VideoMetadataModal';
import { IAddUserRoleModalProps } from 'components/modals/AddUserRoleModal';
import { VideoLibraryModalV2Props } from 'components/admin2/videoPanels/VideoLibraryModal/VideoLibraryModalV2';
import { AdminConfirmationModalProps } from 'components/modals/AdminConfirmationModal';
import { TranslationKey } from 'hooks/use-translation';
import { EventSettingsPayload } from 'components/page-blocks/EventCalendar/utils';
import { StudioInviteReceivedModalProps } from 'components/modals/StudioInviteReceivedModal';
import { TI18nKey } from 'components/i18n/TranslatedText/i18nKey';

export enum ModalKinds {
  chooseSelfServicePlan = 'chooseSelfServicePlan',
  settingUpSelfServiceSite = 'settingUpSelfServiceSite',
  selfServiceSignup = 'selfServiceSignup',
  addToCalendar = 'addToCalendar',
  addUserRole = 'addUserRole',
  adminError = 'adminError',
  adminSuccess = 'adminSuccess',
  bundlePurchase = 'bundlePurchase',
  chatPromptLogin = 'chatPromptLogin',
  checkEmail = 'checkEmail',
  confirmation = 'confirmation',
  countdown = 'countdown',
  crossChannelEditWarning = 'crossChannelEditWarning',
  deviceLimit = 'deviceLimit',
  emailVerification = 'emailVerification',
  forgotPassword = 'forgotPassword',
  gdpr = 'gdpr',
  image = 'image',
  imageLibrary = 'imageLibrary',
  instagram = 'instagram',
  login = 'login',
  purchasePlan = 'purchasePlan',
  questClaim = 'questClaim',
  resetPassword = 'resetPassword',
  resetPasswordSuccess = 'resetPasswordSuccess',
  selectableText = 'selectableText',
  shopify = 'shopify',
  text = 'text',
  twitter = 'twitter',
  twitterPanel = 'twitterPanel',
  errorModal = 'errorModal',
  invite = 'invite',
  adminConfirmation = 'adminConfirmation',
  setUpAccess = 'setUpAccess',
  subscriptionPurchase = 'subscriptionPurchase',
  supportContact = 'supportContact',
  streamEncoderState = 'streamEncoderState',
  receiptModal = 'receiptModal',
  activeOnPageModal = 'activeOnPageModal',
  haltGate = 'haltGate',
  whatTypeOfCreatorAreYou = 'whatTypeOfCreatorAreYou',
  wizardMain = 'wizardMain',
  calendarBlockEventSettings = 'calendarBlockEventSettings',
  wizardEnd = 'wizardEnd',
  wizardBeholdTheGate = 'wizardBeholdTheGate',
  activateThemeConfirmation = 'activateThemeConfirmation',
  videoMetadataV2 = 'videoMetadataV2',
  videoLibraryV2 = 'videoLibraryV2',
  upgradePlan = 'upgradePlan',
  upgradePlanSuccess = 'upgradePlanSuccess',
  studioInviteReceived = 'studioInviteReceived',
  tvAppsLogin = 'tvAppsLogin',
}

type Modal<T, U extends ModalKinds> = T extends undefined ?
  {
    kind: U;
    onDismiss?: () => void;
  } : {
    data: T;
    kind: U;
    onDismiss?: () => void;
  };

type ModalContainerOptions = {
  backgroundStyle?: CSSProperties;
  transparentBackground?: boolean;
};

type AddToCalendarPayload = Modal<{
  description: string;
  endTime: string | number;
  engagementTrackingAction?: ITrackAction;
  location: string;
  startTime: string | number;
  title: string;
}, ModalKinds.addToCalendar>;

type AddUserRolePayload = Modal<IAddUserRoleModalProps, ModalKinds.addUserRole>;

export type AdminErrorPayload = Modal<
  ({ errorKey: string } | { message: string } & { errorMessage?: string }),
  ModalKinds.adminError
>;

export type AdminSuccessPayload = Modal<
  ({ messageKey: string } | { message: string }),
  ModalKinds.adminSuccess
>;

export type ConfirmationPayload = Modal<{
  onConfirmClick: () => void;
  promptStringKey: string;
  subtitle?: string;
}, ModalKinds.confirmation>;

type CountdownPayload = Modal<{
  message: string;
  startTime: string;
}, ModalKinds.countdown>;

export type LoginPayload = Modal<
  ({ autoSubmit: boolean, email?: string; page: 'SIGNUP' | 'LOGIN' | 'ACCOUNTLOOKUP', provider?: string; } | undefined),
  ModalKinds.login
>;

type CrossChannelEditWarningPayload = Modal<
  { conflicts?: ValidateCrossChannelObjectResp },
  ModalKinds.crossChannelEditWarning
>;

type DeviceLimitPayload = Modal<
  {
    accountDevices?: IAccountDevices;
    locked: boolean;
    siteDeviceLimit?: number | null;
    success?: boolean;
  },
  ModalKinds.deviceLimit
>;

type ImagePayload = Modal<{ src: string }, ModalKinds.image>;
type InstagramPayload = Modal<{ postUrl: string }, ModalKinds.instagram>;
type ChatPromptPayload = Modal<{ anonUsername: string }, ModalKinds.chatPromptLogin>;
type SelectableTextPayload = Modal<{ text?: string }, ModalKinds.selectableText>;
export type TextPayload = Modal<{ text: string }, ModalKinds.text>;

type QuestClaimPayload = Modal<{
  questData: {
    claimedMessage?: string;
    rewardImage?: string;
    rewardType: RewardType;
  }
  responseData: {
    accessToken: string;
    webhookClaimText?: string;
  };
}, ModalKinds.questClaim>;

type ResetPasswordPayload = Modal<
  {
    email?: string
    ga_token?: string;
    token?: string;
  },
  ModalKinds.resetPassword
>;

type TwitterPayload = Modal<
  {
    mediaUrl: string;
    overlayData: unknown
    retweetUrl: string;
  },
  ModalKinds.twitter
>;

type InvitePayload = Modal<
  ({ text: string } | undefined),
  ModalKinds.invite
>;

export type BundlePurchasePayload = Modal<{ bundle: IBundle; locked: boolean; }, ModalKinds.bundlePurchase>;
export type ImageLibraryModalPayload = Modal<{ locked: boolean; onSelect: (url: string) => void; }, ModalKinds.imageLibrary>;
export type SubscriptionPurchasePayload = Modal<{ locked: boolean; subscription: ISubscription; }, ModalKinds.subscriptionPurchase>;
export type ReceiptModalPayload = Modal<{ purchaseSummary: IPurchaseSummary; }, ModalKinds.receiptModal>;
type EmailVerificationPayload = Modal<undefined, ModalKinds.emailVerification>;
type ForgotPasswordPayload = Modal<undefined, ModalKinds.forgotPassword>;
export type GDPRPayload = Modal<undefined, ModalKinds.gdpr>;
type ResetPasswordSuccessPayload = Modal<undefined, ModalKinds.resetPasswordSuccess>;
type ShopifyPayload = Modal<undefined, ModalKinds.shopify>;
type TwitterPanelPayload = Modal<unknown, ModalKinds.twitterPanel>;
export type ErrorModalPayload = Modal<{ promptStringKey: TI18nKey; }, ModalKinds.errorModal>;
type AdminConfirmationPayload = Modal<AdminConfirmationModalProps['data'], ModalKinds.adminConfirmation>;
export type CalendarBlockEventSettingsPayload = Modal<EventSettingsPayload, ModalKinds.calendarBlockEventSettings>;
type SupportContactPayload = Modal<undefined, ModalKinds.supportContact>;
type PurchasePlanPayload = Modal<{ locked: boolean }, ModalKinds.purchasePlan>;
export type ActiveOnPagePayload = Modal<{ feature: 'navigation' | 'theme', pages: string[]; }, ModalKinds.activeOnPageModal>;
type HaltGatePayload = Modal<undefined, ModalKinds.haltGate>;
type WizardMainPayload = Modal<{ locked: boolean }, ModalKinds.wizardMain>;
type WizardBeholdTheGatePayload = Modal<{ locked: boolean }, ModalKinds.wizardBeholdTheGate>;
type WizardEndPayload = Modal<undefined, ModalKinds.wizardEnd>;
type WhatTypeOfCreatorAreYouPayload = Modal<undefined, ModalKinds.whatTypeOfCreatorAreYou>;
export type ActivateThemeConfirmationPayload = Modal<{ theme: ITheme }, ModalKinds.activateThemeConfirmation>;
export type UpgradePlanPayload = Modal<{ locked?: boolean, planWarningMessage?: TranslationKey, preSelectedPlan?: string }, ModalKinds.upgradePlan>;
export type UpgradePlanSuccessPayload = Modal<{ planName: string }, ModalKinds.upgradePlanSuccess>;
export type SetUpAccessModalPayload = Modal<undefined, ModalKinds.setUpAccess>;
export type SelfServiceSignupModalPayload = Modal<{ email: string; locked: boolean; }, ModalKinds.selfServiceSignup>;
export type SettingUpSelfServiceSiteModalPayload = Modal<{ locked: boolean; }, ModalKinds.settingUpSelfServiceSite>;
export type ChooseSelfServicePlanModalPayload = Modal<undefined, ModalKinds.chooseSelfServicePlan>;

export type StreamEncoderStatePayload = Modal<
  ModalContainerOptions,
  ModalKinds.streamEncoderState
>;

export type StudioInviteReceivedPayload = Modal<
  ModalContainerOptions & StudioInviteReceivedModalProps,
  ModalKinds.studioInviteReceived
>;

export type VideoMetadataV2ModalPayload = Modal<
  VideoMetadataModalV2Props['data'],
  ModalKinds.videoMetadataV2
>;

export type VideoLibraryV2ModalPayload = Modal<
  VideoLibraryModalV2Props['data'],
  ModalKinds.videoLibraryV2
>;

export type TVAppsLoginModalPayload = Modal<
  ModalContainerOptions &
  { prefilledLoginCode?: string },
  ModalKinds.tvAppsLogin
>;

export type ModalPayload =
  | AddToCalendarPayload
  | AdminErrorPayload
  | AdminSuccessPayload
  | ConfirmationPayload
  | CountdownPayload
  | LoginPayload
  | CrossChannelEditWarningPayload
  | DeviceLimitPayload
  | EmailVerificationPayload
  | ForgotPasswordPayload
  | GDPRPayload
  | ImagePayload
  | InstagramPayload
  | ChatPromptPayload
  | QuestClaimPayload
  | ResetPasswordPayload
  | ResetPasswordSuccessPayload
  | SelectableTextPayload
  | ShopifyPayload
  | TextPayload
  | TwitterPayload
  | TwitterPanelPayload
  | InvitePayload
  | ErrorModalPayload
  | AdminConfirmationPayload
  | SupportContactPayload
  | CalendarBlockEventSettingsPayload
  | StreamEncoderStatePayload
  | ReceiptModalPayload
  | PurchasePlanPayload
  | ActiveOnPagePayload
  | HaltGatePayload
  | WizardMainPayload
  | WizardEndPayload
  | WizardBeholdTheGatePayload
  | WhatTypeOfCreatorAreYouPayload
  | ActivateThemeConfirmationPayload
  | BundlePurchasePayload
  | SubscriptionPurchasePayload
  | ImageLibraryModalPayload
  | VideoMetadataV2ModalPayload
  | AddUserRolePayload
  | VideoLibraryV2ModalPayload
  | UpgradePlanPayload
  | UpgradePlanSuccessPayload
  | StudioInviteReceivedPayload
  | SetUpAccessModalPayload
  | SelfServiceSignupModalPayload
  | SettingUpSelfServiceSiteModalPayload
  | ChooseSelfServicePlanModalPayload
  | TVAppsLoginModalPayload;
