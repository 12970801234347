// tslint:disable:no-console
import { makeSaga } from 'redux-utils';
import { IState } from 'services/state';
import {
  call,
  put,
  select,
} from 'redux-saga/effects';
import { setAdminMode } from 'services/admin/actions';
import { ModeTypes } from 'services/admin/constants';
import { getPrimaryToken } from 'services/auth/selectors';
import { showAdminErrorModal } from 'services/modals/actions';
import { getMaestroStreamId } from '../selectors';
import { activateStream } from '../api';
import { flattenCalls } from 'utils';

export const activateStreamSaga = (() => {
  const activateStreamFlattened = flattenCalls(activateStream);
  return makeSaga({ setAdminMode }, function* ({ payload }) {
    try {
      if (payload?.modeName !== ModeTypes.MAESTRO_STREAM)
        return;

      const state: IState = yield select();
      const token = getPrimaryToken(state)!;
      const maestroStreamId = getMaestroStreamId(state);

      if (!maestroStreamId)
        return;

      yield call(activateStreamFlattened, { token, maestroStreamId });
    } catch (error) {
      yield put(
        showAdminErrorModal('ADMIN_STREAM_KEY_GET_ERROR', error?.message || 'UNKNOWN'),
      );
    }
  });
})();
