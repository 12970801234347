import EventBuilder from './EventBuilder';

class GridEventBuilder extends EventBuilder {
  constructor() {
    super('grid');
  }

  buildOntology(action, data) {
    return {
      /* eslint-disable sort-keys */
      phylum: action,
      class: data?.kind,
      order: data?.id,
      family: data?.title,
      /* eslint-enable */
    };
  }

  buildMetadata(action, doc, extra) {
    return extra;
  }
}

export default new GridEventBuilder();
