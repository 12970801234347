import React, { Fragment, ReactNode } from 'react';

export const interpolateTranslation = (template: string, params: Record<string, ReactNode>) => {
  const replacerMap: Record<number, string> = {};
  const result: ReactNode[] = [];

  Object.keys(params).forEach(
    (param) => {
      let startAt = 0;
      const token = `{{${param}}}`;

      while (true) {
        const index = template.indexOf(token, startAt);

        if (index === -1)
          break;

        replacerMap[index] = param;
        startAt = index + token.length;
      }
    },
  );

  let lastPosition = 0;

  Object.keys(replacerMap).forEach(
    (index) => {
      const chunk = template.substring(lastPosition, Number(index));
      lastPosition = Number(index) + replacerMap[index].length + 4;

      if (chunk)
        result.push(chunk);

      const tree = (
        <Fragment key={index}>
          {params[replacerMap[index]]}
        </Fragment>
      );

      result.push(tree);
    },
  );

  if (lastPosition < template.length)
    result.push(template.substring(lastPosition));

  return result;
};
