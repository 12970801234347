export interface IUserProfile {
  accountId: string;
  icon: string | null;
  name: string;
}

export interface IUserPassword {
  newPassword: string;
  oldPassword: string;
}

export enum PasswordUpdateState {
  INITIAL,
  LOADING,
  SUCCESS,
  ERROR,
}
