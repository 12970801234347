import invariant from 'invariant';

import EventBuilder from './EventBuilder';

class PageviewEventBuilder extends EventBuilder {
  constructor() {
    super('pageview');
  }

  buildOntology(action) {
    invariant(
      action === 'view',
      `Invalid pageview action in buildOntology: ${action}`,
    );
    return {
      phylum: 'view',
    };
  }

  buildMetadata() {
    return null;
  }
}

export default new PageviewEventBuilder();
