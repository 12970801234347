import invariant from 'invariant';

import EventBuilder from './EventBuilder';

class SessionEventBuilder extends EventBuilder {
  constructor() {
    super('session');
  }

  buildOntology(action, doc) {
    invariant(
      action === 'start' || action === 'beacon',
      `Invalid session action: ${action}`,
    );
    return {
      /* eslint-disable sort-keys */
      phylum: action,
      class: typeof doc === 'string' ? doc : undefined,
      /* eslint-enable */
      // TODO: "class" for browser notifications and Twitch chat bot
    };
  }

  buildMetadata(action, doc, extra) {
    // TODO: Build metadata for browser notifications and Twitch chat bot
    return { ...extra };
  }
}

export default new SessionEventBuilder();
