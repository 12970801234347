import { spawn } from 'redux-saga/effects';
import { takeEvery } from 'redux-utils';
import { UPDATE_DOCUMENT } from 'services/realtime/actions';
import { LOG_IN, LOG_OUT } from 'services/auth';
import { setupNotificationListenerSaga } from './setupNotificationListener';
import { cleanupNotificationListenerSaga } from './cleanupNotificationListener';
import { notficationReceivedSaga } from './notficationReceived';

export function* notficationSaga() {
  yield spawn(setupNotificationListenerSaga);
  yield takeEvery([LOG_IN], setupNotificationListenerSaga);
  yield takeEvery([LOG_OUT], cleanupNotificationListenerSaga);
  yield takeEvery([UPDATE_DOCUMENT], notficationReceivedSaga);
}
