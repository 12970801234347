import Color from 'color';
import { isHexColor } from 'components/admin2/ColorPicker/utils';
import { SITE_ID } from 'config';
import { cloneDeep } from 'lodash';
import memo from 'memoizee';
import { ITheme } from 'models';
import { THEME_MODES, THEME_TYPES } from 'models/ITheme';
import { MAESTRO_BLACK, MAESTRO_PURPLE, MAESTRO_WHITE } from 'style/constants';
import { BASE_LIGHT_THRESHOLD, LIGHT_MODE_TEXT_COLORS, DARK_MODE_TEXT_COLORS } from './constants';
import { ISurfaceList, IAccentColorList, IHighlightColorList, IGetTextColors, IGetTextColorsParams } from './models';
interface IHSBToRGBResult {
  b: number;
  g: number;
  r: number;
}

interface IRGBToHSBResult {
  b: number;
  h: number;
  s: number;
}

export const convertRGBToHSB = (r: number, g: number, b: number): IRGBToHSBResult => {
  r /= 255;
  g /= 255;
  b /= 255;
  const v = Math.max(r, g, b);
  const n = v - Math.min(r, g, b);
  const h =
    n === 0
      ? 0
      : n && v === r
      ? (g - b) / n
      : v === g
      ? 2 + (b - r) / n
      : 4 + (r - g) / n;
  return {
    h: Math.round(60 * (h < 0 ? h + 6 : h)),
    s: Math.round(v && (n / v) * 100),
    b: Math.round(v * 100),
  };
};

export const convertHSBToRGB = (h: number, s: number, b: number): IHSBToRGBResult => {
  s /= 100;
  b /= 100;
  const k = (n: number) => (n + h / 60) % 6;
  const f = (n: number) =>
    b * (1 - s * Math.max(0, Math.min(k(n), 4 - k(n), 1)));
  return {
    r: Math.round(255 * f(5)),
    g: Math.round(255 * f(3)),
    b: Math.round(255 * f(1)),
  };
};

export const createSurfaceColors = memo(
  (hex: string): ISurfaceList => {
    if (!isHexColor(hex)) {
      throw new Error('Invalid hex color');
    }
    const color = Color(hex).rgb().object();
    const colorHSB = convertRGBToHSB(color.r, color.g, color.b);
    const brightnessInverter = colorHSB.b >= BASE_LIGHT_THRESHOLD ? -1 : 1;
    const surface2RGB = convertHSBToRGB(colorHSB.h, colorHSB.s, colorHSB.b + (5 * brightnessInverter));
    const surface3RGB = convertHSBToRGB(colorHSB.h, colorHSB.s, colorHSB.b + (10 * brightnessInverter));
    const surface4RGB = convertHSBToRGB(colorHSB.h, colorHSB.s, colorHSB.b + (15 * brightnessInverter));
    const surface5RGB = convertHSBToRGB(colorHSB.h, colorHSB.s, colorHSB.b + (25 * brightnessInverter));
    const surface6RGB = convertHSBToRGB(colorHSB.h, colorHSB.s, colorHSB.b + (30 * brightnessInverter));
    return {
      surface1: hex,
      surface2: Color({ ...surface2RGB }).hex(),
      surface3: Color({ ...surface3RGB }).hex(),
      surface4: Color({ ...surface4RGB }).hex(),
      surface5: Color({ ...surface5RGB }).hex(),
      surface6: Color({ ...surface6RGB }).hex(),
    };
  },
  { max: 500, primitive: true },
);

export const createAccentColors = memo(
  (hex: string): IAccentColorList => {
    if (!isHexColor(hex)) {
      throw new Error('Invalid hex color');
    }
    const color = Color(hex).rgb().object();
    const colorHSB = convertRGBToHSB(color.r, color.g, color.b);
    const brightnessOffset = colorHSB.b <= 30 ? 30 : -30;
    const colorRGB = convertHSBToRGB(colorHSB.h, colorHSB.s, colorHSB.b + brightnessOffset);

    return {
      accentPrimary: hex,
      accentSecondary: Color({ ...colorRGB }).hex(),
      accentTertiary: isLightColor(hex) ? MAESTRO_WHITE : MAESTRO_BLACK,
    };
  },
  { max: 500, primitive: true },
);

export const createHighlightColors = memo(
  (hex: string): IHighlightColorList => {
    if (!isHexColor(hex)) {
      throw new Error('Invalid hex color');
    }
    const color = Color(hex).rgb().object();
    const colorHSB = convertRGBToHSB(color.r, color.g, color.b);
    const brightnessInverter = colorHSB.b >= 50 ? -1 : 1;
    const highlightSecondaryRGB = convertHSBToRGB(colorHSB.h, colorHSB.s, colorHSB.b + (35 * brightnessInverter));
    const highlightTertiaryRGB = convertHSBToRGB(colorHSB.h, colorHSB.s, colorHSB.b + (95 * brightnessInverter));

    return {
      highlightPrimary: hex,
      highlightSecondary: Color({ ...highlightSecondaryRGB }).hex(),
      highlightTertiary: Color({ ...highlightTertiaryRGB }).hex(),
    };
  },
  { max: 500, primitive: true },
);

export const createTextColors = memo(
  (hex: string): IGetTextColors => {
    if (!isHexColor(hex)) {
      throw new Error('Invalid hex color');
    }
    const color = Color(hex).rgb().object();
    const colorHSB = convertRGBToHSB(color.r, color.g, color.b);


    const text100Dark = rgbObjectToTuple(convertHSBToRGB(colorHSB.h, 5, 5));
    const text100Light = rgbObjectToTuple(convertHSBToRGB(colorHSB.h, 5, 100));

    const text100RGBArr = picMaxContrast(
      Color(hex).rgb().array() as RgbTuple,
      text100Dark,
      text100Light,
    )!;

    const shouldUseDarkTextColors = text100RGBArr === text100Dark;

    const text100RGB: IHSBToRGBResult = {
      r: text100RGBArr[0],
      g: text100RGBArr[1],
      b: text100RGBArr[2],
    };
    const text200RGB = convertHSBToRGB(colorHSB.h, 5, shouldUseDarkTextColors ? 40 : 80);
    const text300RGB = convertHSBToRGB(colorHSB.h, 5, 60);
    const text400RGB = convertHSBToRGB(colorHSB.h, 5, shouldUseDarkTextColors ? 80 : 40);
    const text500RGB = convertHSBToRGB(colorHSB.h, 5, shouldUseDarkTextColors ? 100 : 5);

    return {
      text100: Color({ ...text100RGB }).hex(),
      text200: Color({ ...text200RGB }).hex(),
      text300: Color({ ...text300RGB }).hex(),
      text400: Color({ ...text400RGB }).hex(),
      text500: Color({ ...text500RGB }).hex(),
    };
  },
  { max: 500, primitive: true },
);

export const getTextColors = (params: IGetTextColorsParams): IGetTextColors => {
  if (params.themeType !== THEME_TYPES.CLASSIC) {
    return createTextColors(params.hex);
  }
  return params.mode === THEME_MODES.LIGHT ? DARK_MODE_TEXT_COLORS : LIGHT_MODE_TEXT_COLORS;
};

export const createCustomThemeFromClassicOrAdvanced = (theme: ITheme): ITheme => {
  const customTheme = cloneDeep(theme);
  delete customTheme._id;
  delete customTheme.mode;
  delete customTheme.tags;
  delete customTheme.created;
  delete customTheme.modified;

  customTheme.type = THEME_TYPES.CUSTOM;
  customTheme.siteId = SITE_ID;

  return customTheme;
};

export const isLightColor = (colorHSB: IRGBToHSBResult | string): boolean => {
  if (typeof colorHSB === 'string') {
    const color = Color(colorHSB).rgb().object();
    colorHSB = convertRGBToHSB(color.r, color.g, color.b);
  }

  return colorHSB.b >= BASE_LIGHT_THRESHOLD;
};

/**
 * Contrast criteria functions based on [ISO-9241-3] and [ANSI-HFES-100-1988] standards
 * http://www.w3.org/TR/2008/REC-WCAG20-20081211/#relativeluminancedef
 * http://www.w3.org/TR/WCAG20/#contrast-ratiodef
 * https://www.w3.org/TR/WCAG21/#contrast-minimum
 */
export const getLuminance = (rgb: number[]) => {
  const [r, g, b] = rgb.map(colorValue => {
    colorValue /= 255;
    return colorValue <= 0.03928 ? colorValue / 12.92 : ((colorValue + 0.055) / 1.055) ** 2.4;
  });
  return 0.2126 * r + 0.7152 * g + 0.0722 * b;
};

export const getContrastRatio = (surfaceColor: number[], textColor: number[]) => {
  const lum1 = getLuminance(surfaceColor);
  const lum2 = getLuminance(textColor);
  return (Math.max(lum1, lum2) + 0.05) / (Math.min(lum1, lum2) + 0.05);
};

export const createTextContrastOnBackgroundColor = (surfaceColor: string, lightColor: string = MAESTRO_WHITE, darkColor: string = MAESTRO_BLACK): string => {
  if (!surfaceColor || !isHexColor(surfaceColor) || surfaceColor === MAESTRO_PURPLE) {
    return MAESTRO_WHITE;
  }
  const background = Color(surfaceColor).rgb().array();
  const light = Color(lightColor).rgb().array();
  const dark = Color(darkColor).rgb().array();

  const lightContrast = getContrastRatio(background, light);
  const darkContrast = getContrastRatio(background, dark);

  const result = lightContrast > darkContrast ? lightColor : darkColor;
  return Color(result).hex();
};

type RgbTuple = [r: number, g: number, b: number];

const picMaxContrast = (refColor: RgbTuple, ...colors: RgbTuple[]) => {
  let maxContrast = 0;
  let maxContrastColor: RgbTuple | null = null;

  for (const color of colors) {
    const contrast = getContrastRatio(refColor, color);

    if (contrast > maxContrast) {
      maxContrast = contrast;
      maxContrastColor = color;
    }
  }

  return maxContrastColor;
};

// I refuse to type RGB as BGR 😡
// tslint:disable:next-line member-ordering
const rgbObjectToTuple = ({ r, g, b }: { r: number; g: number; b: number }): RgbTuple =>
  [r,g,b];
