import { IAccount } from 'services/auth/models';
export const GET_USER_ROLES = 'GET_USER_ROLES';
export const GET_SITE_ROLES = 'GET_SITE_ROLES';
export const SET_SITE_ROLES_STATE = 'SET_SITE_ROLES_STATE';
export const SET_USER_ROLES_STATE = 'SET_USER_ROLES_STATE';
export const UPDATE_USER_ROLES = 'UPDATE_USER_ROLES';

export interface ISiteRoles extends IAccount {
  icon?: string;
}

export type IRoleAction = IGetSiteRoles | ISetRolesState;

export interface IGetSiteRoles {
  payload: null;
  type: typeof GET_SITE_ROLES;
}

export interface ISetRolesState {
  payload: ISiteRoles[];
  type: typeof SET_SITE_ROLES_STATE;
}

export const setSiteRolesState = (payload: ISiteRoles[]): ISetRolesState => {
  return {
    payload,
    type: SET_SITE_ROLES_STATE,
  };
};

export const getSiteRolesAction = (): IGetSiteRoles => {
  return {
    payload: null,
    type: GET_SITE_ROLES,
  };
};
