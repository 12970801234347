export const SDK_SET_IFRAMESDK_INIT_LOCALE = 'iframe/SDK_SET_IFRAMESDK_INIT_LOCALE';
export const SDK_SET_PARENT_ORIGIN = 'iframe/SDK_SET_PARENT_URL';

export interface ISetInitIFrameSDK {
  payload: string;
  type: typeof SDK_SET_IFRAMESDK_INIT_LOCALE;
}

export interface ISetParentOrigin {
  payload: string;
  type: typeof SDK_SET_PARENT_ORIGIN;
}

export const setInitIframeSDKLocale = (locale: string): ISetInitIFrameSDK => ({
  payload: locale,
  type: SDK_SET_IFRAMESDK_INIT_LOCALE,
});

export const setParentOrigin = (payload: string): ISetParentOrigin => ({
  payload,
  type: SDK_SET_PARENT_ORIGIN,
});

export type IIframeSDKAction = ISetInitIFrameSDK | ISetParentOrigin;
export type IIframeAction = IIframeSDKAction;
