import { makeAction } from 'redux-utils';
import { IRemoveDevicePayload, IVideoModal } from './interfaces';
import { Size } from 'hooks/use-element-size';

export const SET_VIDEO_SIZE = 'videoPlayer/SET_VIDEO_SIZE';
export const SET_PLAYLIST_DATA = 'videoPlayer/SET_PLAYLIST_DATA';
export const ENABLE_DEVICE_LIMIT = 'videoPlayer/ENABLE_DEVICE_LIMIT';
export const SHOW_VIDEO_MODAL = 'videoPlayer/SHOW_VIDEO_MODAL';
export const DEVICE_KICKED_AT = 'videoPlayer/DEVICE_KICKED_AT';

export const setVideoSize = makeAction(SET_VIDEO_SIZE, (payload: Size) => ({
  payload,
}));

export const showVideoModal = makeAction(
  SHOW_VIDEO_MODAL,
  (payload: IVideoModal) => ({ payload }),
);

export const setDeviceLimitEnabled = makeAction(
  ENABLE_DEVICE_LIMIT,
  (payload: boolean) => ({ payload }),
);

export const setDeviceKickedAt = makeAction(
  DEVICE_KICKED_AT,
  (time: number) => ({
    payload: time,
  }),
);
