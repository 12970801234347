import { makeReducer } from 'redux-utils';
import * as actions from './actions';
import { MetadataStatus } from './constants';

export const getInitialState = () => ({
  error: null as number | null,
  metadataStatus: null as MetadataStatus | null,
  studioGuestModeInviteCode: null as string | null,
  studioSessionReady: false,
  pendingGuestAccountId: null as string | null,
});

const livestreamReducer = makeReducer(actions, getInitialState, (state, action) => {
  switch (action.type) {
    case actions.SET_LIVESTREAM_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case actions.SET_METADATA_STATUS:
      return {
        ...state,
        metadataStatus: action.payload,
      };
    case actions.SET_STUDIO_SESSION_READY:
      return {
        ...state,
        studioSessionReady: action.payload,
      };
    case actions.SET_STUDIO_GUEST_MODE_INVITE_CODE:
      return {
        ...state,
        studioGuestModeInviteCode: action.payload,
      };
    case actions.INVITE_GUEST_TO_STUDIO:
      return {
        ...state,
        pendingGuestAccountId: action.payload,
      };
    case actions.CLEAR_PENDING_STUDIO_GUEST_INVITATION:
      return {
        ...state,
        pendingGuestAccountId: null,
      };
    default:
      return state;
  }
});

export type ILivestreamState = ReturnType<typeof getInitialState>;

export default livestreamReducer;
