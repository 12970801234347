import { PaymentMethod } from '@stripe/stripe-js';
import Axios from 'axios';
import { CUSTOMER_SERVICE_BASE_URL, SIGNUP_SERVICE_BASE_URL, SITE_SERVICE_BASE_URL } from 'config';

export interface AvailabilityResponse {
  message: string;
  success: boolean;
}

export const checkAvailabilitySite = async (siteName: string): Promise<AvailabilityResponse | undefined> => {
  try {
    const { data: result } = await Axios.get<AvailabilityResponse>(`${SITE_SERVICE_BASE_URL}/availability/${siteName}`);
    return result;
  } catch (error) {
    return {
      message: error?.message,
      success: false,
    };
  }
};

export const validateEmailDomain = async (email: string): Promise<boolean> => {
  try {
    const { data } = await Axios.get<{ success: boolean }>(`${CUSTOMER_SERVICE_BASE_URL}/validate-email-domain?email=${email}`);
    return data.success;
  } catch (error) {
    return false;
  }
};

export interface ISubmitSignupPayload {
  accountDetails: {
    email: string;
    name: string; // get this from the email, everything before @
    password: string;
    username: string; // get this from the email, everything before @
  };
  anonymousId: string | null;
  gclid: string;
  idempotencyKey: string;
  marketingOptIn: boolean;
  paymentMethod?: PaymentMethod; // required for paid plans.
  planId: string;
  siteSlug: string;
}

export const submitSignup = async (
  payload: ISubmitSignupPayload,
  recaptchaToken: string,
): Promise<any> => {
  const { data } = await Axios.post(`${SIGNUP_SERVICE_BASE_URL}/signup`, payload,
    {
      headers: {
        'x-recaptcha-token': recaptchaToken,
      },
    },
  );
  return data;
};

export const isSiteReady = async (site: string): Promise<boolean> => {
  try {
    const data = await Axios.get(`${window.location.origin}/${site}`);
    return data.status === 200;
  } catch (e) {
    return false;
  }
};
