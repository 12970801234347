export enum PollTypes {
  Poll = 'poll',
  Prediction = 'prediction',
  Trivia = 'trivia',
}

export interface IPollResponse {
  _id?: string;
  answerId: string;
  pollId: string;
  siteId: string;
  userId: string;
}

export interface IPollAnswer {
  _id?: string;
  answer: string;
  arrayId?: string;
  correct?: boolean;
  imageUrl?: string;
  index?: number;
  pollId?: string;
}

export interface IPopulatedAnswer extends IPollAnswer {
  count?: number;
  total?: number;
}

export interface IPoll {
  _id?: string;
  answers?: IPopulatedAnswer[];
  correctAnswerId?: string;
  modified?: number;
  name: string;
  open: boolean;
  question: string;
  showCount?: boolean;
  siteId: string;
  type: PollTypes;
}
