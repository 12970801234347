import { put, select } from 'redux-saga/effects';
import { getSiteId } from 'services/app/selectors';
import { isListening } from 'services/realtime/selectors';
import { subscribe, updateDocument } from 'services/realtime/actions';
import IState from 'services/state';
import { makeSaga } from 'redux-utils';
import { setDeviceLimitEnabled } from '../../actions';

export const watchDisableDeviceLimitSaga = function* () {
  const state: IState = yield select();
  const siteId = getSiteId(state);

  if (!isListening(state, 'disable-device-limit', siteId)) {
    yield put(subscribe(`disable-device-limit/${siteId}`));
  }
};

export const onDeviceLimitConfigChangeSaga = makeSaga(
  { updateDocument },
  function* ({ payload }) {
    const { path, value } = payload;

    const isDeviceLimitDisabledFlagUpdate = /^disable-device-limit/.test(path);

    if (isDeviceLimitDisabledFlagUpdate && typeof value === 'boolean') {
      yield put(setDeviceLimitEnabled(!value));
    }
  },
);
