import axios, { AxiosResponse } from 'axios';
import { USER_PROFILE_SERVICE_BASE_URL, ACCOUNTS_SERVICE_BASE_URL } from 'config';
import { IUserProfile } from './models';

export function getUserProfile(accessToken: string, siteId: string): Promise<AxiosResponse> {
  const headers = {
    'Authorization': `Bearer ${accessToken}`,
    'x-maestro-client-id': siteId,
  };
  return axios({
    headers,
    method: 'GET',
    url: `${USER_PROFILE_SERVICE_BASE_URL}`,
  });
}

export function updateUserProfile(profile: IUserProfile, accessToken: string, siteId: string): Promise<AxiosResponse> {
  const headers = {
    'Authorization': `Bearer ${accessToken}`,
    'x-maestro-client-id': siteId,
  };
  return axios({
    data: profile,
    headers,
    method: 'PUT',
    url: `${USER_PROFILE_SERVICE_BASE_URL}`,
  });
}

interface IPropsUpdatePassword {
  accessToken: string,
  newPassword: string,
  oldPassword: string,
  siteId: string
}

export const updatePassword = async (options: IPropsUpdatePassword): Promise<any> => {
  const RESET_PASSWORD_URL = `${ACCOUNTS_SERVICE_BASE_URL}/password`;

  return axios({
      data: {
        password: options.newPassword,
        oldPassword: options.oldPassword,
      },
      headers: {
        Authorization: `Bearer ${options.accessToken}`,
        'x-maestro-client-id': options.siteId,
      },
      method: 'PUT',
      url: RESET_PASSWORD_URL,
    });
};
