import IState from '../state';
import { IModalsState } from './reducer';
import { promptStringKeys } from './constants';
import { ConfirmationPayload } from './types';
import { createSelector } from 'reselect';


export const hasGdpr = ({ modals }: IState): boolean => {
  for (const { kind } of modals) {
    if (kind === 'gdpr') {
      return true;
    }
  }
  return false;
};

export const hasDeviceLimitModal = ({ modals }: IState): boolean => {
  for (const { kind } of modals) {
    if (kind === 'deviceLimit') {
      return true;
    }
  }
  return false;
};

export const isSidebarModalOpened = ({ modals }: IState): boolean => {
  for (const { data } of modals as ConfirmationPayload[]) {
    const key = data?.promptStringKey;
    if (promptStringKeys.includes(key)) {
      return true;
    }
  }
  return false;
};

export const getStateModals = createSelector(({ modals }: IState): IModalsState => modals,(modals)=>modals) ;


export const isModalOpen = ({ modals }: IState) => !!modals.length;

export const isModalKindOpen = ({ modals }: IState, modalId: string) => (
  !!modals.find(modal => modal.kind === modalId)
);
