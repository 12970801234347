import EventBuilder from './EventBuilder';

class HeaderEventBuilder extends EventBuilder {
  constructor() {
    super('header');
  }

  buildOntology(action, doc) {
    return {
      /* eslint-disable sort-keys */
      phylum: action,
      class: doc?.kind,
      order: doc?.id,
      family: doc?.title,
      species: doc?.kind === 'navigation' && doc?.displayKind,
    };
  }

  buildMetadata(action, doc, extra) {
    return extra;
  }
}

export default new HeaderEventBuilder();
