import IState from 'services/state';
import { SELF_SERVICE_PLANS, START_PLAN } from './selfServicePlans';

export const getSignupAccount = (state: IState) => state.selfServiceSignup;
export const getSignupSuccessVariantMsg = (state: IState) =>
  state.selfServiceSignup.signupSuccessMsgVariant;

export const getIdempotencyKey = (state: IState): string =>
  state.selfServiceSignup.idempotencyKey;

export const getSelectedPlanId = (state: IState): string =>
  state.selfServiceSignup.selectedPlanId;

export const getSelectedPlan = (state: IState) => {
  const planId = getSelectedPlanId(state);
  return SELF_SERVICE_PLANS.find(plan => plan.id === planId) || START_PLAN;
};
