import { difference } from 'lodash';
import IModel from 'models/IModel';
import ISubscription from 'models/ISubscription';
import { IEntitlementAccess, IPurchaseSummary } from 'services/billing/models';

export enum AccountEntitlementStatus {
  active = 'active',
  expired = 'expired',
  canceled = 'canceled',
  cancelScheduled = 'cancelScheduled',
}

export enum GrantedMethod {
  accessCode = 'accessCode',
  purchased = 'purchased',
  customerService = 'customerService',
}

export interface IAccountEntitlement extends IModel {
  accountId: string;
  currentPeriodEnd?: number;
  dateCanceled?: number;
  dateGranted?: number;
  dateRefunded?: number;
  entitlement: ISubscription;
  errorMessage?: string;
  grantedMethod: GrantedMethod;
  presentmentCurrency?: string;
  purchaseSummary?: IPurchaseSummary; // firebase only
  siteId: string;
  status: AccountEntitlementStatus;
}

export const isAccountEntitlement = (obj: any): obj is IEntitlementAccess => {
  const entitlementKeys = [
    'accountId',
    'entitlement',
    'grantedMethod',
    'siteId',
    'status',
  ];

  return objectContainsAllKeys(obj, entitlementKeys);

  function objectContainsAllKeys(object: any, keys: any[]): boolean {
     // all 'keys' entries are into 'Object.keys(obj)'
    return difference(keys, Object.keys(object)).length === 0;
  }
};
