import { call, takeEvery, put } from 'redux-saga/effects';
import { LOG_OUT } from 'services/auth/actions';
import { resetShippingAddressReducer } from './actions';

export const resetShippingAddressSaga = function* () {
  yield put(resetShippingAddressReducer());
};

export default function* shippingAddressSaga () {
  yield takeEvery(LOG_OUT, resetShippingAddressSaga);
}
