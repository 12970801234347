import IState from 'services/state';
import { getStudioGuestModeInviteCode } from 'services/livestream/selectors';
import { isUserAdmin as getUserAdmin } from 'services/auth/selectors';
import { createSelector } from 'reselect';
import { getObjectId } from 'services/app';
import {
  GateKind,
} from 'models/IGate';
import { getCurrentGateData } from '.';

const getGate = (state: IState) => state.gate;

export const isChannelGateEnabled = createSelector(
  (state: IState) => getCurrentGateData(state),
  (gateData): boolean => gateData?.active || false,
);

export const isGateActive = createSelector(
  (state: IState) => getObjectId(state),
  getGate,
  (channelId, gate): boolean => {
    return gate?.activeGateMap[channelId] || false;
  },
);

export const shouldRenderChannelGate = createSelector([
  (state: IState) => getUserAdmin(state),
  isChannelGateEnabled,
  isGateActive,
  (state: IState) => getStudioGuestModeInviteCode(state),
], (isUserAdmin: boolean, gateEnabled: boolean, gateActive: boolean, studioInviteCode: string | null) => {
  return !isUserAdmin && gateEnabled && gateActive && !studioInviteCode;
});

export const getAccountEntitlementsLoaded = (state: IState) => state.gate.accountEntitlementsLoaded;

export const isPayWallGated = createSelector(
  [isChannelGateEnabled, getCurrentGateData],
  (gateEnabled, gateData) => (gateEnabled && gateData.kind === GateKind.SUBSCRIPTION),
);
