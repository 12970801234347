import { call, put, select, takeEvery } from 'redux-saga/effects';
import { LOAD_GA } from 'services/insights/actions';
import { authWithFacebookToken } from 'services/auth-legacy/actions';
import { isGoogleAnalyticsDisabled } from 'services/feature-gate/selectors';
import { facebookReady, FB_LOGIN, LOAD_SOCIALS } from './actions';
import { fbLogin, loadFacebook, loadGTM } from './api';
import { canUseFacebook, getFacebookPixelId, getFacebookId } from './selectors';
import { isGdprAccepted } from '../auth';

const setupFacebook = function* () {
  const facebookId = yield select(getFacebookId);
  const facebookPixelId = yield select(getFacebookPixelId);
  const gdprAccepted = yield select(isGdprAccepted);
  yield loadFacebook(facebookId, facebookPixelId, gdprAccepted);
  yield put(facebookReady());
};

export function* handleFBLoginSaga() {
  const isFbReady = yield select(canUseFacebook);
  if (!isFbReady) {
    yield call(setupFacebook);
  }
  try {
    const fbResponse = yield call(fbLogin);
    if (typeof fbResponse !== 'undefined' && !fbResponse?.error) {
      yield put(authWithFacebookToken(fbResponse));
    } else {
      throw new Error('Error in facebook login response');
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e, 'error in socials saga: handleFBLogin');
  }
}

export const loadGTMSaga = function* () {
  const googleAnalyticsDisabled = yield select(isGoogleAnalyticsDisabled);

  if (googleAnalyticsDisabled) {
    return;
  }

  yield call(loadGTM);
};

export default function* socialSaga() {
  yield takeEvery(FB_LOGIN, handleFBLoginSaga);
  yield takeEvery(LOAD_SOCIALS, setupFacebook);
  // load GTM only after tracking consent is provided
  yield takeEvery(LOAD_GA, loadGTMSaga);
}
