export enum MetadataStatus {
  IDLE = 'idle',
  PENDING = 'pending',
  RESOLVED = 'resolved',
  REJECTED = 'rejected',
}

// insights eM.source from snake_case

export const StreamSource = {
  STUDIO: 'studio',
  THIRD_PARTY: 'thirdPartySoftware',
} as const;

export type StreamSource = typeof StreamSource[keyof typeof StreamSource];
