import axios from 'axios';

import {
  QUEST_SERVICE_OVERRIDE,
  SERVICE_BASE_URL,
  CLAIM_STATUS_SERVICE_BASE_URL,
  LEGACY_QUEST_CLAIM_BASE_URL,
} from 'config';

const QUEST_BASE_URL = QUEST_SERVICE_OVERRIDE || `${SERVICE_BASE_URL}/quest/v1`;

export const legacyClaim = (siteId, questId, token, blob) => {
  return axios.post(`${LEGACY_QUEST_CLAIM_BASE_URL}/${questId}/claim`, { blob }, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const battlenetClaim = (siteId, questId, productId, token, blob) => {
  return axios.post(`${QUEST_BASE_URL}/claim/${siteId}/battlenet`, {
    blob,
    productId,
    questId,
  }, {
    headers: {
      Authorization: `Bearer ${token}`,
      'x-forwarded-for': '104.178.219.103',
      'x-maestro-client-id': siteId,
    },
  });
};

export const getClaimStatusObject = (siteId, questId, productId, token) => {
  return axios({
    headers: {
      Authorization: `Bearer ${token}`,
      'x-forwarded-for': '104.178.219.103',
      'x-maestro-client-id': siteId,
    },
    method: 'GET',
    params: {
      productId,
      questId,
    },
    url: `${CLAIM_STATUS_SERVICE_BASE_URL}`,
  });
};

