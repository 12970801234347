// tslint:disable:no-console
import { makeSaga } from 'redux-utils';
import { inviteGuestToStudio } from '../actions';
import { studioIframe } from './registerStudioIframe';

export const inviteGuestToStudioSaga = makeSaga(
  { inviteGuestToStudio },
  function* () {
    try {
      studioIframe?.contentWindow?.postMessage(
        {
          source: 'maestro:web:v1',
          command: 'requestInviteCode',
        },
        '*',
      );
    } catch (error) {
      console.error('inviteGuestToStudioSaga:', error);
    }
  },
);
