import { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { IVideo, VideoTypes, PlayerTypes, TranscodingStatuses } from 'models';
import { getPrimaryToken, isUserAdmin } from 'services/auth';
import { getSiteId } from 'services/app/selectors';
import { useSelector, useDispatch } from 'hooks';
import { createFiles, deleteFile, updateFiles } from '../actions';
import { createObjectId, batchedPromises, flattenCalls } from 'utils';
import { IVideoUploadJobStatus } from '../state';
import { useApis } from 'contexts/ApisContext';
import { updateOrDeleteVideoUploadProgress } from './useVideoUploadProgress';
import { useWatchTranscodingVideo } from './useWatchTranscodingVideo';

const MAXIMUM_CONCURRENT_UPLOADS = 10;
const UPDATE_VIDEO_BATCH_SIZE = 10;

const defaultFilters = {
  limit: '9999',
  sortBy: 'created',
  sortDirection: '-1',
};

export const useVideoUploadService = () => {
  const {
    video: {
      searchVideos,
    },
    videoEditor: {
      upsertVideo,
    },
    videoUpload: {
      uploadVideoFile,
      transcodeVideo,
    },
  } = useApis();

  // cancels any pending requests before doing a new one
  const searchVideosFlat = useMemo(
    (): typeof searchVideos => {
      let abort = () => {
        // placeholder
      };

      return (params) => {
        abort();
        return searchVideos(
          params,
          (canceller) => {
            abort = canceller;
          },
        );
      };
    },
    [searchVideos],
  );

  const upsertVideoBatched = useMemo(
    () => flattenCalls(
      batchedPromises(upsertVideo, UPDATE_VIDEO_BATCH_SIZE),
    ),
    [upsertVideo],
  );

  const [loading, setLoading] = useState(true);
  const [videos, setVideos] = useState<IVideo[]>([]);
  const [filters, setFilters] = useState<Record<string, string>>(
    defaultFilters,
  );
  const blobs = useRef<Record<string, File>>({});
  const uploads = useRef<Record<string, () => unknown>>({});

  const files = useSelector(state => state.videoUpload.files);
  const primaryToken = useSelector(getPrimaryToken);
  const isAdmin = useSelector(isUserAdmin);
  const siteId = useSelector(getSiteId);
  const dispatch = useDispatch();

  const upsertVideosOptimistically = useCallback(
    (items: (Pick<Parameters<typeof upsertVideo>[0], 'newId' | 'video'>)[]) => {
      if (!primaryToken)
        throw new Error('User must be logged in.');

      if (!items.length)
        return;

      const promises: ReturnType<typeof upsertVideoBatched>[] = [];

      setVideos(
        (prevVideos) => {
          const newVideos: IVideo[] = [];

          for (const { newId, video } of items) {
            promises.push(
              upsertVideoBatched({
                newId,
                primaryToken,
                siteId,
                video,
              }),
            );

            if (newId) {
              newVideos.push(
                {
                  ...video,
                  durationSeconds: 0,
                  siteId,
                  _id: newId,
                },
              );
            }
          }

          prevVideos.forEach(
            (v) => {
              const edited = items.find(
                (t) => t.video._id === v._id,
              );

              if (!edited) {
                newVideos.push(v);
                return;
              }

              newVideos.push({
                ...v,
                ...edited.video,
              });
            },
          );

          return newVideos;
        },
      );

      return Promise.all(promises)
        .catch((err) => {
          // tslint:disable-next-line:no-console
          console.error('Error creating videos', err);
        });
    },
    [primaryToken, siteId],
  );

  const addFiles = useCallback(
    (newFiles: (Partial<Parameters<typeof createFiles>[0][0]> & { blob: File })[]) => {
      dispatch(
        createFiles(
          newFiles.map(
            (file) => {
              const id = createObjectId();
              blobs.current[id] = file.blob;

              return {
                description: file.description || '',
                id,
                localFileName: file.localFileName || file.blob.name,
                player: PlayerTypes.file,
                size: file.blob.size,
                tags: file.tags || [],
                title: file.title || file.blob.name,
                uploadingStatus: IVideoUploadJobStatus.QUEUED,
              };
            },
          ),
        ),
      );
    },
    [],
  );

  const removeFile = useCallback(
    (fileId: string) => {
      delete blobs.current[fileId];
      dispatch(deleteFile(fileId));
      uploads.current[fileId]?.();
      delete uploads.current[fileId];
    },
    [],
  );

  const retryUpload = useCallback(
    (fileId: string) => dispatch(
      updateFiles([{
        id: fileId,
        uploadingStatus: IVideoUploadJobStatus.QUEUED,
      }]),
    ),
    [],
  );

  const editVideoMetadata = useCallback(
    async (
      items: (
        (
          | (Partial<IVideo> & { _id: string })
          | Parameters<typeof updateFiles>[0][0]
        ) & { newId?: string }
      )[],
    ) => {
      const fileUpdates: Parameters<typeof updateFiles>[0] = [];
      const videoUpdates: Parameters<typeof upsertVideosOptimistically>[0] = [];

      for (const { newId, ...fileOrVideo } of items) {
        if ('id' in fileOrVideo && files.some((f) => f.id === fileOrVideo.id)) {
          fileUpdates.push(fileOrVideo);
        } else {
          videoUpdates.push({
            newId,
            video: fileOrVideo as IVideo,
          });
        }
      }

      if (fileUpdates.length)
        dispatch(
          updateFiles(fileUpdates),
        );

      if (videoUpdates.length)
        await upsertVideosOptimistically(videoUpdates);
    },
    [upsertVideosOptimistically, files],
  );

  const resetFilters = useCallback(
    () => setFilters(defaultFilters),
    [],
  );

  const queryVideos = useCallback(
    async () => {
      if (!primaryToken || !isAdmin)
        return;

      const { results: filteredVideos } = await searchVideosFlat(
        {
          filters,
          primaryToken,
          siteId,
        },
      );

      setVideos(filteredVideos);
    },
    [filters, primaryToken, isAdmin, siteId, searchVideosFlat],
  );

  useEffect(
    // refetch videos when filters change
    () => {
      setLoading(true);
      setVideos([]);

      const timeout = setTimeout(
        async () => {
          await queryVideos();
          setLoading(false);
        },
        300,
      );

      return () => clearTimeout(timeout);
    },
    [queryVideos],
  );

  const lowPriorityFileUpdates = useRef<Parameters<typeof updateFiles>[0]>([]);

  useEffect(
    // optimization to reduce the update frequency of less important updates
    () => {
      const checkInterval = setInterval(
        () => {
          if (!lowPriorityFileUpdates.current.length)
            return;

          const { current: updates } = lowPriorityFileUpdates;
          lowPriorityFileUpdates.current = [];

          dispatch(
            updateFiles(updates),
          );
        },
        1000,
      );

      return () => clearInterval(checkInterval);
    },
    [],
  );

  useEffect(
    // listen for added files and start upload
    () => {
      (
        async () => {
          if (!primaryToken)
            return;

          let uploadingFiles = 0;

          // need to batch updates for performance reasons
          const updates: Parameters<typeof updateFiles>[0] = [];

          await (
            async () => {
              for (const file of files) {
                if (file.uploadingStatus === IVideoUploadJobStatus.UPLOADING)
                  uploadingFiles += 1;

                if (uploadingFiles === MAXIMUM_CONCURRENT_UPLOADS)
                  return;
              }

              for (const file of files) {
                if (file.uploadingStatus !== IVideoUploadJobStatus.QUEUED)
                  continue;

                uploadingFiles += 1;
                updates.push({
                  id: file.id,
                  uploadingStatus: IVideoUploadJobStatus.UPLOADING,
                });

                updateOrDeleteVideoUploadProgress(file.id, 0);
                uploads.current[file.id] = await uploadVideoFile(
                  blobs.current[file.id],
                  async (bucketFileName) => {
                    updateOrDeleteVideoUploadProgress(file.id);
                    lowPriorityFileUpdates.current.push({
                      bucketFileName,
                      id: file.id,
                      uploadingStatus: IVideoUploadJobStatus.COMPLETE,
                    });
                  },
                  (error, message) => {
                    // tslint:disable-next-line:no-console
                    console.error({ file, error, message });
                    lowPriorityFileUpdates.current.push({
                      id: file.id,
                      uploadingStatus: IVideoUploadJobStatus.ERROR,
                    });
                  },
                  (progress) => updateOrDeleteVideoUploadProgress(file.id, progress),
                );

                if (uploadingFiles === MAXIMUM_CONCURRENT_UPLOADS)
                  return;
              }
            }
          )();

          if (updates.length)
            dispatch(
              updateFiles(updates),
            );
        }
      )();
    },
    [files, primaryToken],
  );

  const { watchTranscodingVideo } = useWatchTranscodingVideo(setVideos);

  useEffect(
    // after files are done uploading, create video documents
    // and request transcoding
    () => {
      const timeout = setTimeout(
        async () => {
          if (!primaryToken)
            return;

          let updated = false;
          const newVideos: Parameters<typeof upsertVideosOptimistically>[0] = [];
          const fileIdBucketPathMap = new Map<string, string>();

          for (const file of files) {
            if (!file.bucketFileName)
              continue;

            fileIdBucketPathMap.set(file.id, file.bucketFileName);
            removeFile(file.id);
            updated = true;

            newVideos.push({
              newId: file.id,
              video: {
                description: file.description,
                durationSeconds: 0,
                subscriptions: file.subscriptions,
                tags: file.tags,
                thumbnail: file.thumbnail,
                title: file.title,
                type: VideoTypes.vod,
                url: 'https://pending.transcode.maestro.io',
                player: file.player,
                uploadStatus: TranscodingStatuses.pending,
                siteId,
              },
            });
          }

          await upsertVideosOptimistically(newVideos);

          await Promise.all(
            files.map(
              async (file) => {
                const bucketFileName = fileIdBucketPathMap.get(file.id);

                if (!bucketFileName)
                  return;

                watchTranscodingVideo(file.id);
                await transcodeVideo(file.id, bucketFileName);
              },
            ),
          );

          if (updated)
            queryVideos();
        },
        300,
      );

      return () => clearTimeout(timeout);
    },
    [
      files,
      primaryToken,
      removeFile,
      queryVideos,
      upsertVideosOptimistically,
      watchTranscodingVideo,
    ],
  );

  return {
    addFiles,
    editVideoMetadata,
    files,
    loading,
    removeFile,
    filters,
    setFilters,
    videos,
    refresh: queryVideos,
    resetFilters,
    retryUpload,
  };
};
