import { createSelector } from 'reselect';
import IState from '../state';

export const getLoadingInviteSelector = (state: IState) => state.invite.fetching;

export const getInviteURLSelector = (state: IState) => state.invite.inviteUrl;

export const getInviteSelector = (state: IState) => state.invite.invite;

export const getPendingInvitesSelector = createSelector(
  (state: IState) => state.invite.pendingInvites,
  (pendingInvites) => pendingInvites,
);

export const isInvalidInviteSelector = (state: IState) => state.invite.invalidInvite;

export const isSuccessEmailSelector = (state: IState) => state.invite.successEmail;

export const isEditingInviteSelector = (state: IState) => state.invite.editing;

export const accountExistsSelector = (state: IState) => state.invite.accountExist;

export const resentSelector = (state: IState) => state.invite.resent;
