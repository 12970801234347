import Plan, { ISelfServicePlan } from 'models/IPlan';

export type IKeepPercentage = { name: string; percentage: string; };

export type SelfServicePlan = Pick<
  ISelfServicePlan,
  'description' | 'name' | 'term'
> & {
  displayName: string;
  id: string;
  keep: IKeepPercentage[];
  price: number;
};

export const SELF_SERVICE_PLANS: SelfServicePlan[] = [
  {
    id: '645541ecba9a9808a403c3ae',
    description:
      'Start growing your video business with no risk or investment.',
    name: 'Start',
    displayName: 'Start',
    price: 0.0,
    term: 'monthly',
    keep: [
      {
        percentage: '60%',
        name: 'Tickets & Subs',
      },
      {
        percentage: '95%',
        name: 'Shopify',
      },
      {
        percentage: '80%',
        name: 'Tips',
      },
    ],
  },
  {
    id: '645541ecba9a9808a403c3af',
    description:
      'Continue to grow your video business with a small monthly investment.',
    name: `Growth Monthly`,
    displayName: 'Growth',
    price: 23.0,
    term: 'monthly',
    keep: [
      {
        percentage: '75%',
        name: 'Tickets & Subs',
      },
      {
        percentage: '95%',
        name: 'Shopify',
      },
      {
        percentage: '85%',
        name: 'Tips',
      },
    ],
  },
  {
    id: '645541ecba9a9808a403c3b0',
    description:
      'Continue to grow your video business with a small monthly investment.',
    name: `Growth Annual`,
    displayName: 'Growth',
    price: 228.0,
    term: 'annually',
    keep: [
      {
        percentage: '75%',
        name: 'Tickets & Subs',
      },
      {
        percentage: '95%',
        name: 'Shopify',
      },
      {
        percentage: '85%',
        name: 'Tips',
      },
    ],
  },
  {
    id: '645541edba9a9808a403c3b1',
    description:
      'Scale your video business with white-label streaming on a custom domain.',
    name: `Scale Monthly`,
    displayName: 'Scale',
    price: 119.0,
    term: 'monthly',
    keep: [
      {
        percentage: '100%*',
        name: 'Tickets & Subs',
      },
      {
        percentage: '95%',
        name: 'Shopify',
      },
      {
        percentage: '90%',
        name: 'Tips',
      },
    ],
  },
  {
    id: '645541edba9a9808a403c3b2',
    description:
      'Scale your video business with white-label streaming on a custom domain.',
    name: `Scale Annual`,
    displayName: 'Scale',
    price: 1188.0,
    term: 'annually',
    keep: [
      {
        percentage: '100%*',
        name: 'Tickets & Subs',
      },
      {
        percentage: '95%',
        name: 'Shopify',
      },
      {
        percentage: '90%',
        name: 'Tips',
      },
    ],
  },
];

export const START_PLAN = SELF_SERVICE_PLANS[0];
export const START_PLAN_ID = START_PLAN.id;
export const GROWTH_PLAN = SELF_SERVICE_PLANS[2];
export const SCALE_PLAN = SELF_SERVICE_PLANS[4];

export type ISelfServicePlanAllData = Plan & {
  displayName: string;
  price: number | string;
};

export const SELF_SERVICE_PLANS_ALL_DATA: ISelfServicePlanAllData[] = [
  {
    _id: '645541ecba9a9808a403c3ae',
    description:
      'Start growing your video business with no risk or investment.',
    name: 'Start',
    type: 'selfservice',
    planFeatures: {
      cloudDataExport: false,
      customDomain: false,
      customerSuccessManager: false,
      developerTools: false,
      eventSupport: false,
      freeEvents: false,
      maxAmountOfAdmins: 1,
      removeMaestroBranding: false,
      serviceLevelAgreement: false,
      viewerSupport: false,
      maxAmountOfChannels: 1,
      maxAmountOfPages: 1,
    },
    planItems: [
      {
        name: 'Start',
        unit: 'saas',
        unitPrice: 0,
        stripePriceId: 'price_1N3AGWBH7IwBUn8JYVGU3mTZ',
        type: 'general',
      },
    ],
    payoutTerms: {
      entitlements: {
        revenueShare: 0.4,
        viewerServiceCharge: 0,
      },
      shopify: {
        revenueShare: 0.05,
        viewerServiceCharge: 0,
      },
      tips: {
        revenueShare: 0.2,
        viewerServiceCharge: 0,
      },
    },
    displayName: 'Start',
    price: 'Free Forever',
    term: 'monthly',
  },
  {
    _id: '645541ecba9a9808a403c3af',
    description:
      'Continue to grow your video business with a small monthly investment.',
    name: `Growth Monthly`,
    type: 'selfservice',
    planFeatures: {
      cloudDataExport: false,
      customDomain: false,
      customerSuccessManager: false,
      developerTools: false,
      eventSupport: false,
      freeEvents: false,
      maxAmountOfAdmins: 2,
      removeMaestroBranding: false,
      serviceLevelAgreement: false,
      viewerSupport: false,
      maxAmountOfChannels: 1,
      maxAmountOfPages: 2,
    },
    planItems: [
      {
        name: 'Growth Monthly',
        unit: 'saas',
        unitPrice: 23,
        stripePriceId: 'price_1N3AGWBH7IwBUn8JYVGU3mTZ',
        type: 'general',
      },
    ],
    payoutTerms: {
      entitlements: {
        revenueShare: 0.25,
        viewerServiceCharge: 0,
      },
      shopify: {
        revenueShare: 0.05,
        viewerServiceCharge: 0,
      },
      tips: {
        revenueShare: 0.15,
        viewerServiceCharge: 0,
      },
    },
    displayName: 'Growth',
    price: 23.0,
    term: 'monthly',
  },
  {
    _id: '645541ecba9a9808a403c3b0',
    description:
      'Continue to grow your video business with a small monthly investment.',
    name: `Growth Annual`,
    type: 'selfservice',
    planFeatures: {
      cloudDataExport: false,
      customDomain: false,
      customerSuccessManager: false,
      developerTools: false,
      eventSupport: false,
      freeEvents: false,
      maxAmountOfAdmins: 2,
      removeMaestroBranding: false,
      serviceLevelAgreement: false,
      viewerSupport: false,
      maxAmountOfChannels: 1,
      maxAmountOfPages: 2,
    },
    planItems: [
      {
        name: 'Growth Annual',
        unit: 'saas',
        unitPrice: 228,
        stripePriceId: 'price_1N3AJsBH7IwBUn8JJqL3Hb5j',
        type: 'general',
      },
    ],
    payoutTerms: {
      entitlements: {
        revenueShare: 0.25,
        viewerServiceCharge: 0,
      },
      shopify: {
        revenueShare: 0.05,
        viewerServiceCharge: 0,
      },
      tips: {
        revenueShare: 0.15,
        viewerServiceCharge: 0,
      },
    },
    displayName: 'Growth',
    price: 228.0,
    term: 'annually',
  },
  {
    _id: '645541edba9a9808a403c3b1',
    description:
      'Scale your video business with white-label streaming on a custom domain.',
    name: `Scale Monthly`,
    type: 'selfservice',
    planFeatures: {
      cloudDataExport: false,
      customDomain: true,
      customerSuccessManager: false,
      developerTools: false,
      eventSupport: false,
      freeEvents: false,
      maxAmountOfAdmins: 4,
      removeMaestroBranding: true,
      serviceLevelAgreement: false,
      viewerSupport: false,
      maxAmountOfChannels: 200,
      maxAmountOfPages: 200,
    },
    planItems: [
      {
        name: 'Scale Monthly',
        unit: 'saas',
        unitPrice: 119,
        stripePriceId: 'price_1N3AMBBH7IwBUn8JKqQkDQzR',
        type: 'general',
      },
    ],
    payoutTerms: {
      entitlements: {
        revenueShare: 0,
        viewerServiceCharge: 0.15,
      },
      shopify: {
        revenueShare: 0.05,
        viewerServiceCharge: 0,
      },
      tips: {
        revenueShare: 0.1,
        viewerServiceCharge: 0,
      },
    },
    displayName: 'Scale',
    price: 119.0,
    term: 'monthly',
  },
  {
    _id: '645541edba9a9808a403c3b2',
    description:
      'Scale your video business with white-label streaming on a custom domain.',
    name: `Scale Annual`,
    type: 'selfservice',
    planFeatures: {
      cloudDataExport: false,
      customDomain: true,
      customerSuccessManager: false,
      developerTools: false,
      eventSupport: false,
      freeEvents: false,
      maxAmountOfAdmins: 4,
      removeMaestroBranding: true,
      serviceLevelAgreement: false,
      viewerSupport: false,
      maxAmountOfChannels: 200,
      maxAmountOfPages: 200,
    },
    planItems: [
      {
        name: 'Scale Annual',
        unit: 'saas',
        unitPrice: 1188,
        stripePriceId: 'price_1N3AOSBH7IwBUn8J5nwnbdVr',
        type: 'general',
      },
    ],
    payoutTerms: {
      entitlements: {
        revenueShare: 0,
        viewerServiceCharge: 0.15,
      },
      shopify: {
        revenueShare: 0.05,
        viewerServiceCharge: 0,
      },
      tips: {
        revenueShare: 0.1,
        viewerServiceCharge: 0,
      },
    },
    displayName: 'Scale',
    price: 1188.0,
    term: 'annually',
  },
  {
    _id: 'No Id For Me',
    description:
      'Access tools  for streaming with greater customizability and support.',
    name: `Custom`,
    type: 'enterprise',
    planFeatures: {
      cloudDataExport: true,
      customDomain: true,
      customerSuccessManager: true,
      developerTools: true,
      eventSupport: true,
      freeEvents: true,
      maxAmountOfAdmins: 200,
      removeMaestroBranding: true,
      serviceLevelAgreement: true,
      viewerSupport: true,
      maxAmountOfChannels: 200,
      maxAmountOfPages: 200,
    },
    planItems: [
      {
        name: 'Custom',
        unit: 'saas',
        unitPrice: 5000,
        stripePriceId: 'n/a',
        type: 'general',
      },
    ],
    payoutTerms: {
      entitlements: {
        revenueShare: 0,
        viewerServiceCharge: 0.15,
      },
      shopify: {
        revenueShare: 0.05,
        viewerServiceCharge: 0,
      },
      tips: {
        revenueShare: 0.1,
        viewerServiceCharge: 0,
      },
    },
    displayName: 'Custom',
    price: "Let's Talk",
    term: 'monthly',
  },
];
