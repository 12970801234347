import { postMessageToIframeSDK } from '../api';
import { MaestroIFrameEvents } from '../models';
import { findAncestor } from 'utils/findAncestor';
import { isHtmlElement } from 'utils/isHtmlElement';

export const setupNavigationEventListeners = (window: Window) => {
  window.addEventListener(
    'beforeunload',
    () => {
      const { activeElement } = document;
      const href = (activeElement && isHtmlElement('A', activeElement))
        ? activeElement.href
        : undefined;

      postMessageToIframeSDK({
        action: MaestroIFrameEvents.NAVIGATION_INITIADED,
        href,
      });
    },
  );

  window.addEventListener(
    'click',
    (ev) => {
      const { target: targetEl } = ev;

      if (!(targetEl instanceof Element))
        return;

      const anchor = isHtmlElement('A', targetEl)
        ? targetEl
        : findAncestor('A', targetEl);

      if (!anchor)
        return;

      const { href, target } = anchor;

      postMessageToIframeSDK({
        action: MaestroIFrameEvents.NAVIGATION_INITIADED,
        href,
        target,
      });
    },
  );
};
