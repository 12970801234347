export const FACEBOOK_READY = 'socials/facebookReady';
export const LOAD_SOCIALS = 'socials/LOAD_SOCIALS';
export const FB_LOGIN = 'socials/FB_LOGIN';

interface ILoadSocialsAction {
  type: typeof LOAD_SOCIALS;
}

interface IFacebookReadyAction {
  type: typeof FACEBOOK_READY;
}

interface IHandleFBLoginAction {
  type: typeof FB_LOGIN;
}

export type ISocialsActions = (
  ILoadSocialsAction |
  IFacebookReadyAction |
  IHandleFBLoginAction
);

export const loadSocials = (): ILoadSocialsAction => ({
  type: LOAD_SOCIALS,
});

export const facebookReady = (): IFacebookReadyAction => ({
  type: FACEBOOK_READY,
});

export const handleFBLogin = (): IHandleFBLoginAction => ({
  type: FB_LOGIN,
});