import invariant from 'invariant';
import camelCase from 'lodash/camelCase';
import capitalize from 'lodash/capitalize';
import snakeCase from 'lodash/snakeCase';
import startCase from 'lodash/startCase';
import memo from 'memoizee';
import { SubscriptionType } from 'models/ISubscription';

const REGEX_CAMEL_CASE = /^[a-z0-9]+([A-Z0-9][a-z0-9]*)*$/;
const REGEX_SNAKE_CASE = /^[a-z0-9]+(_[a-z0-9]+)+$/;

export const isCamelCase = (s: string): boolean => REGEX_CAMEL_CASE.test(s);
export const isSnakeCase = (s: string): boolean => REGEX_SNAKE_CASE.test(s);

const computeCamelFromSnake = (s: string): string =>
  isSnakeCase(s) ? camelCase(s) : s;
const computeSnakeFromCamel = (s: string): string =>
  isCamelCase(s) ? snakeCase(s) : s;

type ObjectTransformFn = (value: string) => string;
export const camelFromSnake = memo<ObjectTransformFn>(computeCamelFromSnake, {
  max: 500,
  primitive: true,
});
export const snakeFromCamel = memo<ObjectTransformFn>(computeSnakeFromCamel, {
  max: 500,
  primitive: true,
});

export function withPrefix<T extends string, P extends string>(text: T, prefix: P): `${P}${T}` {
  return `${prefix}${text}`;
}

export const displayFromCase = (str: string) => {
  if (isCamelCase(str)) {
    return str
      .replace(/([a-z\d])([A-Z])/g, '$1 $2')
      .replace(/([A-Z]+)([A-Z][a-z\d]+)/g, '$1 $2')
      .toLowerCase()
      .split(' ')
      .map(capitalize)
      .join(' ');
  }
  if (isSnakeCase(str)) {
    return str.split('_').map(capitalize).join(' ');
  }
  return capitalize(str);
};

export const escapeFirebaseKey = (str: string) => {
  if (typeof str !== 'string') {
    throw new Error('bad firebase key');
  }
  let replacedStr = str;
  replacedStr = replacedStr.replace(/\./g, ',');
  replacedStr = replacedStr.replace(/\//g, '?');
  return replacedStr;
};

// https://stackoverflow.com/questions/6312993/javascript-seconds-to-time-string-with-format-hhmmss
export const secondsToTime = (secondsInput: string) => {
  let seconds = parseInt(secondsInput, 10);
  const hours = Math.floor(seconds / 3600);
  let minutes: string | number = Math.floor((seconds - hours * 3600) / 60);
  seconds = seconds - hours * 3600 - minutes * 60;
  let time = '';

  if (hours !== 0) {
    time = `${hours}:`;
  }
  if (minutes !== 0 || time !== '') {
    minutes = minutes < 10 && time !== '' ? `0${minutes}` : String(minutes);
    time += `${minutes}:`;
  }
  if (time === '') {
    time = `${seconds}s`;
  } else {
    time += seconds < 10 ? `0${seconds}` : String(seconds);
  }
  return time;
};
const transformKeys = (
  target: object | any[],
  transform: (value: string) => string,
): object => {
  if (!target || typeof target !== 'object') {
    return target;
  }
  if (Array.isArray(target)) {
    return target.map((item) => transformKeys(item, transform));
  }
  const result = Object.create(Object.getPrototypeOf(target));
  for (const [key, value] of Object.entries(target)) {
    result[transform(key)] = transformKeys(value, transform);
  }
  return result;
};

export const camelify = (target: object): object =>
  transformKeys(target, camelFromSnake);
export const snakeify = (target: object): object =>
  transformKeys(target, snakeFromCamel);

export const castToPxString = (widthPx: string | number): string => {
  if (typeof widthPx === 'string') {
    return widthPx;
  }
  if (typeof widthPx === 'number') {
    return `${widthPx}px`;
  }
  invariant(
    false,
    'castToPxString only accepts string and numbers! Returning the input.',
  );
  return widthPx as string;
};

// ordinal suffix is 'th', 'rd', etc
export const getOrdinalSuffix = (value: number): string => {
  const onesDigit = value % 10;

  const tensDigit = value % 100;
  if (onesDigit === 1 && tensDigit !== 11) {
    return 'st';
  }
  if (onesDigit === 2 && tensDigit !== 12) {
    return 'nd';
  }
  if (onesDigit === 3 && tensDigit !== 13) {
    return 'rd';
  }
  return 'th';
};

export const displayNumber = (value: number, toFixed?: number) =>
  (value && toFixed !== undefined ? value.toFixed(toFixed) : value || 0)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const displayEarningsNumber = (value: number) => {
  const prefix = value < 0 ? '-$' : '$';
  const absoluteValue = value ? Math.abs(value).toFixed(2) : '0';
  return prefix + absoluteValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

const numberFormatter = new Intl.NumberFormat(undefined, {
  style: 'currency',
  currency: 'USD',
});

export const displayAccounting = (
  value: number,
  customFormatter?: Intl.NumberFormat,
) =>
  customFormatter
    ? customFormatter.format(value)
    : numberFormatter.format(value);

export const titlelize = (value: string) => startCase(value.toLowerCase());

export const SIFormatter = (num: number): string => {
  if (num === 0) {
    return '$' + num.toFixed(2);
  }

  const systemOfUnits = [
    { value: 1e18, symbol: 'E' },
    { value: 1e15, symbol: 'P' },
    { value: 1e12, symbol: 'T' },
    { value: 1e9, symbol: 'G' },
    { value: 1e6, symbol: 'M' },
    { value: 1e3, symbol: 'k' },
    { value: 1, symbol: '' },
  ];

  const unit =
    systemOfUnits.find((u) => Math.abs(num) >= u.value) ||
    systemOfUnits[systemOfUnits.length - 1];

  const compressedValue = num / unit.value;
  const formattedPrice = Math.abs(compressedValue).toFixed(getDigits(compressedValue));
  return `${num < 0 ? '-$' : '$'}${formattedPrice}${unit.symbol}`;

  function getDigits(value: number): number {
    return 3 - String(Math.round(Math.abs(value))).length;
  }
};
export const padZero = (digit: number) => digit.toString().padStart(2, '0');

export const compareToSort = (a: any, b: any): number => {
  const aItem = typeof a === 'string' ? String(a).toLowerCase() : a;
  const bItem = typeof b === 'string' ? String(b).toLowerCase() : b;
  return aItem > bItem ? 1 : -1;
};

export const getTypeValue = (recurrence: string) =>
  recurrence === 'once' ? SubscriptionType.ticket : SubscriptionType.subscription;

export const getWords = (text: string) =>
  text.trim().split(/[^a-zA-Z0-9-'’_]+|\s+/);

export const getAcronym = (text: string, maxLength: number = 0) => {
  const words = getWords(text);
  const length = Math.min(maxLength || words.length, words.length);
  return words
    .slice(0, length)
    .map((word) => word.charAt(0).toLocaleUpperCase())
    .join('');
};

export const getCurrencySymbol = (currency: string) => {
  return (0).toLocaleString('en-US', {
    style: 'currency',
    currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).replace(/\d/g, '').trim();
};

// CurrencyRegex extracted from: https://stackoverflow.com/questions/25910808/javascript-regex-currency-symbol-in-a-string
export const CURRENCY_REGEX = /[\$\xA2-\xA5\u058F\u060B\u09F2\u09F3\u09FB\u0AF1\u0BF9\u0E3F\u17DB\u20A0-\u20BD\uA838\uFDFC\uFE69\uFF04\uFFE0\uFFE1\uFFE5\uFFE6]/;

export const getCurrencyIndex = (formattedPrice: string) => formattedPrice.search(CURRENCY_REGEX);

/**
 * Intl.NumberFormat() function returns currencies in three different formats: symbol, label, label + symbol
 * Example: $100.00, ALL 100.00, MX$100.00
 * For our purposes we are going to use this format label + symbol (if exists) + value
 * MX $100, JP ¥100.
 */
export const formatPrice = (
  currency: string,
  value: number,
) => {
  const formattedPrice = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
    maximumFractionDigits: 2,
  }).format(value);
  const symbolIndex = getCurrencyIndex(formattedPrice);
  if (symbolIndex === -1) return formattedPrice; // Currency doesn't have symbol (ALL 100.00)
  if (symbolIndex === 0) return `${currency.toString().toUpperCase()} ${formattedPrice}`; // Currency doesn't have label ($100.00)
  return formattedPrice.replace(CURRENCY_REGEX, ` ${formattedPrice[symbolIndex]}`); // Currency has symbol + label (MX$100.00)
};

export const MINIMUM_PRICE_CURRENCIES = ['eur', 'usd', 'gbp'];

export const ZERO_DECIMAL_CURRENCIES = [
  'bif', 'clp', 'djf', 'gnf', 'jpy', 'kmf', 'krw', 'mga',
  'pyg', 'rwf', 'ugx', 'vnd', 'vuv', 'xaf', 'xof', 'xpf',
]; // https://stripe.com/docs/currencies#zero-decimal

export const formatSmallestUnit = (currency: string, value: number) => {
  if (ZERO_DECIMAL_CURRENCIES.includes(currency)) {
    return parseInt(String(value), 10);
  }
  return parseInt(String(value * 100), 10);
};

export const formatToHumanReadableCurrency = (currency: string, lowestDenominatedValue: number) => {
  if (ZERO_DECIMAL_CURRENCIES.includes(currency)) {
    return lowestDenominatedValue;
  }
  return lowestDenominatedValue / 100;
};

export const findDefaultPrice = (prices = []) => {
  const defaultPrice: any = prices?.find((price: any) => price.default);
  return defaultPrice ? formatPrice(defaultPrice.currency, defaultPrice.value) : '';
};

export const slugify = (text: string) => {
  return text.toLowerCase().replace(/[^a-z0-9-]/gi, '-');
};
