
export const KINGDOM_SET = Object.freeze({
  ad: true,
  admin: true,
  auth: true,
  card: true,
  channel_select: true, //eslint-disable-line
  error: true,
  footer: true,
  gate: true,
  grid: true,
  header: true,
  navigaton: true,
  overlay: true,
  pageview: true,
  panel: true,
  portal: true,
  quest: true,
  session: true,
  subscription: true,
  video: true,
});

export const VIEW_KINGDOMS = Object.keys({
  ad: true,
  card: true,
  gate: true,
  header: true,
  overlay: true,
  panel: true,
  quest: true,
});
