import { call, put, select, takeEvery } from 'redux-saga/effects';
import { SITE_ID } from 'config';
import { getPrimaryToken } from 'services/auth';
import IState from 'services/state';
import { showAdminErrorModal } from 'services/modals/actions';
import { updateRefreshKey } from 'services/admin/actions';

import {
  CREATE_POLL,
  CREATE_POLL_RESPONSE,
  DELETE_POLL,
  ICreatePollAction,
  ICreatePollResponseAction,
  IDeletePollAction,
  IUpdatePollAction,
  UPDATE_POLL,
} from './actions';
import { createPoll, createPollResponse, deletePoll, updatePoll } from './api';

export const createPollSaga = function*({ payload }: ICreatePollAction) {
  const { poll } = payload;
  const state: IState = yield select();
  const accessToken: string | null = getPrimaryToken(state);

  if (!accessToken) {
    return;
  }

  try {
    yield call(createPoll, accessToken, poll, SITE_ID);
    yield put(updateRefreshKey());
  } catch (e) {
    // tslint:disable-next-line: no-console
    console.error(e, 'error creating poll in saga');
    yield put(showAdminErrorModal(`Unable to create Poll: "${poll.name}"`));
  }
};

export const deletePollSaga = function*({ payload }: IDeletePollAction) {
  const { pollId } = payload;
  const state: IState = yield select();
  const accessToken: string | null = getPrimaryToken(state);

  if (!accessToken) {
    return;
  }

  try {
    yield call(deletePoll, accessToken, pollId, SITE_ID);
  } catch (e) {
    // tslint:disable-next-line: no-console
    console.error(e, 'error deleting poll in saga');
  }
};

export const updatePollSaga = function*({ payload }: IUpdatePollAction) {
  const { poll } = payload;
  const state: IState = yield select();
  const accessToken: string | null = getPrimaryToken(state);

  if (!accessToken) {
    return;
  }

  try {
    yield call(updatePoll, accessToken, poll, SITE_ID);
    yield put(updateRefreshKey());
  } catch (e) {
    // tslint:disable-next-line: no-console
    console.error(e, 'error updating poll in saga');
    yield put(showAdminErrorModal(`Unable to create Poll: "${poll.name}"`));
  }
};

export const createPollResponseSaga = function*({ payload }: ICreatePollResponseAction) {
  const { pollResponse } = payload;
  const state: IState = yield select();
  const accessToken: string | null = getPrimaryToken(state);

  if (!accessToken) {
    return;
  }

  try {
    yield call(createPollResponse, accessToken, pollResponse, SITE_ID);
  } catch (e) {
    // tslint:disable-next-line: no-console
    console.error(e, 'error creating poll response in saga');
  }
};

export const pollsSaga = function*() {
  yield takeEvery(CREATE_POLL, createPollSaga);
  yield takeEvery(DELETE_POLL, deletePollSaga);
  yield takeEvery(UPDATE_POLL, updatePollSaga);
  yield takeEvery(CREATE_POLL_RESPONSE, createPollResponseSaga);
};

export default pollsSaga;
