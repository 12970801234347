import { IShippingAddress } from './models';

export const SET_SHIPPING_ADDRESS_FIELD = 'shipping/SET_SHIPPING_ADDRESS_FIELD';
export const RESET_SHIPPING_ADDRESS_REDUCER = 'shipping/RESET_SHIPPING_ADDRESS_REDUCER';

export type IShippingAddressAction =
  IResetShippingAddressReducerAction |
  ISetShippingAddressFieldAction;

interface IResetShippingAddressReducerAction {
  type: typeof RESET_SHIPPING_ADDRESS_REDUCER;
}

interface ISetShippingAddressFieldAction {
  payload: Partial<Record<keyof IShippingAddress, string>>;
  type: typeof SET_SHIPPING_ADDRESS_FIELD,
}

export const resetShippingAddressReducer = (): IResetShippingAddressReducerAction => {
  return {
    type: RESET_SHIPPING_ADDRESS_REDUCER,
  };
};

export const setShippingAddressField = (payload: Partial<Record<keyof IShippingAddress, string>>): ISetShippingAddressFieldAction => {
  return {
    payload,
    type: SET_SHIPPING_ADDRESS_FIELD,
  };
};
