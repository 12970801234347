import { v4 } from 'uuid';
import {
  selfServiceSignupActionTypes,
  ISignupAction,
  ICreateSignupPayload,
  ISetSignupSuccessMsgPayload,
  IPersistFormDataPayload,
} from './actions';
import { START_PLAN_ID } from './selfServicePlans';

export const INITIAL_STATE: ISelfServiceSignupState = {
  email: '',
  idempotencyKey: v4(),
  password: '',
  siteName: '',
  planId: '',
  loading: false,
  acceptMarketingOpt: true,
  signupSuccessMsgVariant: '1',
  selectedPlanId: START_PLAN_ID,
};

export const getInitialState = () => ({
  ...INITIAL_STATE,
});


export interface ISelfServiceSignupState {
  acceptMarketingOpt: boolean;
  email: string;
  idempotencyKey: string;
  loading: boolean;
  password: string;
  planId: string;
  selectedPlanId: string;
  signupSuccessMsgVariant: '1' | '2';
  siteName: string;
}

const selfServiceSignupReducer = (state: ISelfServiceSignupState = INITIAL_STATE, action: ISignupAction) => {
  switch (action.type) {
    case selfServiceSignupActionTypes.PERSIST_FORM_DATA: {
      const { email, password, siteName } =
        action.payload as IPersistFormDataPayload;
      return {
        ...state,
        email,
        password,
        siteName,
      };
    }
    case selfServiceSignupActionTypes.CREATE_SIGNUP: {
      const { email, password, siteName, planId, acceptMarketingOpt } =
        action.payload as ICreateSignupPayload;
      return {
        ...state,
        email,
        password,
        siteName,
        planId,
        acceptMarketingOpt,
        loading: true,
      };
    }
    case selfServiceSignupActionTypes.SET_SIGNUP_SUCCESS_MSG: {
      const { message } = action.payload as ISetSignupSuccessMsgPayload;
      return {
        ...state,
        signupSuccessMsgVariant: message,
      };
    }
    case selfServiceSignupActionTypes.SET_SIGNUP_LOADING: {
      const payload = action.payload as boolean;
      return {
        ...state,
        loading: payload,
      };
    }
    case selfServiceSignupActionTypes.SET_SELECTED_PLAN_ID: {
      const payload = action.payload as string;
      return {
        ...state,
        selectedPlanId: payload,
      };
    }
    case selfServiceSignupActionTypes.RESET_IDEMPOTENCY_KEY: {
      return {
        ...state,
        idempotencyKey: v4(),
      };
    }
    default: {
      return state;
    }
  }
};

export default selfServiceSignupReducer;
