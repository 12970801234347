import { exitFullscreen } from 'components/Video/video-controls/fullScreenUtils';
import { makeSaga } from 'redux-utils';
import { setViewMode } from 'services/user-layout/actions';

export const handleViewModeUpdate = makeSaga(
  { setViewMode },
  function* ({ payload }) {
    if (payload === null) {
      exitFullscreen('');
    }
  },
);
