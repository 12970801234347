import axios, { AxiosResponse } from 'axios';
import {
  ADMIN_SERVICE_BASE_URL,
  ACCESSCODE_SERVICE_BASE_URL,
  SUBSCRIPTION_V2_BASE_URL,
  ACCOUNTS_SERVICE_BASE_URL,
  TOKEN_SERVICE_BASE_URL,
} from 'config';
import { persistenceService } from 'services/persistence';
import { ISetAmazonBenefitCheckGatePayload, ISetAmazonBenefitRedemptionGatePayload, ISubmitAcessCodePayload } from './actions';
import { IAccountEntitlement } from './models';
import maestroApiRequest from 'services/maestro-api-client';

const getStorageKey = (channelId: string) => {
  return `channel:${channelId}:gate:channel:pwd`;
};

export const getSubscriptionValue = async (
  sku: string,
  siteId: string,
): Promise<number> => {
  let response = 0;
  try {
    response = await axios({
      headers: {
        'x-maestro-client-id': siteId,
      },
      method: 'GET',
      url: `${SUBSCRIPTION_V2_BASE_URL}/sku/${sku}`,
    }).then((resp: AxiosResponse) => {
      return resp.data.price;
    });
  } catch (e) {
    // tslint:disable-next-line
    console.error(e, 'error retrieving subscription value');
  }
  return response;
};

export const isSubscriptionUsed = async (
  primaryToken: string,
  siteId: string,
  sku: string,
): Promise<boolean> => {
  let response = true;
  try {
    response = await axios({
      headers: {
        'x-maestro-client-id': siteId,
        Authorization: `Bearer ${primaryToken}`,
      },
      method: 'GET',
      url: `${ACCOUNTS_SERVICE_BASE_URL}?subscriptions.sku=${sku}`,
    }).then((resp: AxiosResponse) => {
      return resp.data.length > 0;
    });
  } catch (e) {
    // tslint:disable-next-line
    console.error(e, 'error retrieving subscription data');
  }
  return response;
};

export const submitCode = async ({
  accessCode,
  primaryToken,
  siteId,
  deviceId,
}: ISubmitAcessCodePayload & {
  primaryToken: string;
  siteId: string;
}): Promise<any> => {
  return axios({
    headers: {
      'x-maestro-client-id': siteId,
      Authorization: `Bearer ${primaryToken}`,
      'x-maestro-device-id': deviceId,
    },
    method: 'GET',
    url: `${ACCESSCODE_SERVICE_BASE_URL}/consume/${accessCode}`,
  }).then((response: AxiosResponse<{ success: boolean }>) => {
    return response.data;
  });
};

export const setStoredPassword = (
  channelId: string,
  password: string,
): void => {
  const key = getStorageKey(channelId);
  persistenceService().write(key, password);
};

export const getStoredPassword = async (channelId: string): Promise<string> => {
  const key = getStorageKey(channelId);
  const password = await persistenceService().read<string>(key);
  return password || '';
};

export const getChannelPassword = async(pageId: string, siteId: string, primaryToken: string) => {
  const { data } = await maestroApiRequest({
    primaryToken,
    siteId,
  }).get<{ password: string }>(`${ADMIN_SERVICE_BASE_URL}/channel-password/${pageId}`);
  return data.password;
};

interface ICheckChannelPassword {
  channelId: string;
  password: string;
}

export const checkChannelPassword = async ({
  channelId,
  password,
}: ICheckChannelPassword): Promise<boolean> => {
  try {
    await axios.post(`${ADMIN_SERVICE_BASE_URL}/channel-password/check`, {
      channelId,
      password,
    });
  } catch (err) {
    return false;
  }
  return true;
};

interface ISetChannelPasswordParams {
  channelId: string, password: string, primaryToken: string
siteId: string, }

export const setChannelPassword = async ({
  channelId,
  siteId,
  password,
  primaryToken,
}: ISetChannelPasswordParams): Promise<boolean> => {
  try {
    await maestroApiRequest({ primaryToken, siteId })
      .post(`${ADMIN_SERVICE_BASE_URL}/channel-password`, {
        channelId, password,
      });
    return true;
  } catch (error) {
    return false;
  }
};

export const getAccountEntitlements = async (
  siteId: string,
  token: string,
): Promise<IAccountEntitlement[]> => {
  const { data: accountEntitlements } = await axios.get<IAccountEntitlement[]>(
    `${SUBSCRIPTION_V2_BASE_URL}/account`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'x-maestro-client-id': siteId,
      },
    },
  );
  return accountEntitlements || [];
};

export interface CheckBenefitResponse {
  available: boolean;
  message: string;
}

export const checkAmazonBenefitToken = async (
  authToken: string,
  benefitId: string,
  siteId: string,
  ): Promise<ISetAmazonBenefitCheckGatePayload> => {
    try {
      const { data } = await axios.get<any>(`${TOKEN_SERVICE_BASE_URL}/amazon/benefit/${benefitId}`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          'x-maestro-client-id': siteId,
        },
      });
      return { available: data.available, message: data.message || data.error };
    } catch (e) {
      return {
        available: false,
        message: e.response.data.message || e.response.data.error,
      };
    }
};

export const redeemAmazonBenefitToken = async (
  authToken: string,
  benefitId: string,
  siteId: string,
): Promise<ISetAmazonBenefitRedemptionGatePayload> => {
    const { data } = await axios.post<any>(`${TOKEN_SERVICE_BASE_URL}/amazon/benefit/redeem`, {
      benefitId,
    }, {
      headers: {
        Authorization: `Bearer ${authToken}`,
        'x-maestro-client-id': siteId,
      },
    });
    return { message: data.message || data.error, success: data.success };
};
