import { useCallback, useEffect, useState } from 'react';
import type { IEPGAsset } from 'models';
import { useApis } from 'contexts/ApisContext';

export const useEpg = (channelId?: string | null) => {
  const { epg } = useApis();
  const [epgContent, setEpgContent] = useState<IEPGAsset>();
  const [nextEpgContent, setNextEpgContent] = useState<IEPGAsset>();
  const [epgLoaded, setEpgLoaded] = useState(false);

  useEffect(() => {
    const cleanup: (() => void)[] = [];

    setEpgContent(undefined);
    setNextEpgContent(undefined);
    setEpgLoaded(false);

    if (!channelId) {
      return;
    }

    let requestAborted = false;

    let cancelEpgRequest = () => {
      // placeholder
    };

    /**
     * Freshly created channels may not have an EPG document in the database
     * (and that's ok). So we shouldn't rely solely on the Firebase updates
     * to determine the EPG state for a channel
     */
    epg
      .getEpg(
        channelId,
        (abortFn) => {
          cancelEpgRequest = () => {
            requestAborted = true;
            abortFn();
          };
          cleanup.push(cancelEpgRequest);
        },
      )
      .then(({ asset, nextAsset }) => {
        setEpgContent(asset);
        setNextEpgContent(nextAsset);
        setEpgLoaded(true);
      })
      .catch((err) => {
        if (requestAborted) {
          // fail silently when aborting due to Firebase responding faster
          return;
        }

        // tslint:disable-next-line:no-console
        console.error('Failure fetching epg for channel', channelId, err);
      });

    epg
      .onEpgChangeAtChannel(channelId, (asset, nextAsset) => {
        cancelEpgRequest();
        setEpgContent(asset);
        setNextEpgContent(nextAsset);
        setEpgLoaded(true);
      })
      .then((cancelEpgListener) => {
        cleanup.push(cancelEpgListener);
      });

    return () => {
      for (const fn of cleanup) {
        fn();
      }
    };
  }, [epg, channelId]);

  const activateNextAsset = useCallback(
    () => {
      setEpgContent(nextEpgContent);
      setNextEpgContent(undefined);
    },
    [nextEpgContent],
  );

  return {
    activateNextAsset,
    epgContent,
    epgLoaded,
  };
};

export const isScheduledAsset = (asset: IEPGAsset | undefined): asset is (IEPGAsset & { startTime: number }) => {
  return Boolean(asset?.startTime);
};
