import invariant from 'invariant';
import warning from 'warning';

import { hasOwn } from 'utils';
import { snakeify } from 'shared/string-utils';
import { KINGDOM_SET } from '../constants';

export default class EventBuilder {
  constructor(kingdom) {
    invariant(
      hasOwn(KINGDOM_SET, kingdom),
      `Invalid kingdom for event builder: ${JSON.stringify(kingdom)}`,
    );
    this.kingdom = kingdom;
  }

  validateTrack(action, doc, extra) { // eslint-disable-line no-unused-vars
    return true;
  }

  buildOntology(action, doc, extra) { // eslint-disable-line no-unused-vars
    warning(
      false,
      `Event builder for kingdom ${this.kingdom} must implement buildOntology`,
    );
    return null;
  }

  buildMetadata(action, doc, extra) { // eslint-disable-line no-unused-vars
    warning(
      false,
      `Event builder for kingdom ${this.kingdom} must implement buildMetadata`,
    );
    return null;
  }

  buildEvent(action, doc, extra) {
    let ontology;
    let metadata;
    try {
      ontology = this.buildOntology(action, doc, extra);
    } catch (error) {
      console.error(error); // eslint-disable-line no-console
      ontology = null;
    }
    if (!ontology) {
      warning(
        false,
        `${this.constructor.name}: buildOntology threw or returned falsy, skipping buildMetadata`,
      );
    } else {
      try {
        metadata = snakeify(this.buildMetadata(action, doc, extra)) || null;
      } catch (error) {
        console.error(error); // eslint-disable-line no-console
        warning(
          false,
          `${this.constructor.name}: buildMetadata threw, setting metadata to null`,
        );
        metadata = null;
      }
    }
    return {
      metadata,
      ontology: {
        kingdom: this.kingdom,
        ...ontology,
      },
    };
  }
}
