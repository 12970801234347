import { IFrameSDKPostMessagePayload } from './models';

/**
 * Posts a message to the parent window if the current page is embedded within an iframe.
 * @param {IFrameSDKPostMessagePayload} payload - The message payload to send to the parent window.
 */
export function postMessageToIframeSDK<T>(payload: IFrameSDKPostMessagePayload<T>) {
  const isEmbedded = window.parent !== window;
  if (!isEmbedded) {
    return;
  }
  window.parent.postMessage(payload, '*');
}
