import {
  REQUEST_PASSWORD_RESET,
  resolveResetPassword,
  IRequestPasswordResetAction,
} from './actions';

import {
  sendForgotPasswordEmail,
} from './api';

import { getSiteId, getSiteSlug, getPageSlug } from 'services/app';


import IState from '../state';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { getChannelFullPath } from 'services/navigationv2/utils';

const MAESTRO_HOSTNAME_REGEX = /([A-Za-z0-9]+.)*maestro.(io|tv)/gm;

export const passwordRetrieveSaga = function* ({ payload: { email } }: IRequestPasswordResetAction) {
  const state: IState = yield select();
  let baseUrl = '';
  const siteId = getSiteId(state);
  const siteSlug = getSiteSlug(state);
  const hostName = window.location.host;
  const channelPath = getChannelFullPath(siteSlug);

  if (!!hostName.match(MAESTRO_HOSTNAME_REGEX)?.length || hostName.startsWith('localhost')) {
    if (channelPath !== '') {
      baseUrl = `https://${hostName}/${siteSlug}/${channelPath}`;
    } else {
      baseUrl = `https://${hostName}/${siteSlug}`;
    }
  } else {
    if (channelPath !== '') {
      baseUrl = `https://${hostName}/${channelPath}`;
    } else {
      baseUrl = `https://${hostName}`;
    }
  }

  try {
    yield (call(sendForgotPasswordEmail, { email, siteId, baseUrl }));
    yield (put(resolveResetPassword({ email, emailSent: true })));
  } catch (error) {
    yield (put(resolveResetPassword({ email, emailSent: false })));
  }

};


export default function* forgotPasswordSaga() {
  yield takeLatest(REQUEST_PASSWORD_RESET, passwordRetrieveSaga);
}
