import { IApplyThemePayload } from 'services/themes';
import {
  ADVANCE_STEPS,
  IWizardAction,
  REGRESS_STEPS,
  RESET_WIZARD_FLOW,
  SET_FLOW_STEPS,
  SET_IS_WIZARD_LOADING,
  SET_WIZARD_FLOW_TYPE,
  SET_CHANNEL_DATA,
  IExecuteWizardPayload,
} from './actions';
import { WizardFlowTypes, FlowSteps } from './models';

export interface IWizardInitialState {
  currentStep: number;
  draftChannelData?: IExecuteWizardPayload;
  flowSteps: FlowSteps[];
  isWizardLoading: boolean;
  selectedFlow?: WizardFlowTypes;
  selectedTheme?: IApplyThemePayload;
  totalAmountOfSteps: number;
}

export type IWizardState = IWizardInitialState;

const INITIAL_STATE: IWizardInitialState = {
  currentStep: 0,
  draftChannelData: undefined,
  flowSteps: [FlowSteps.HOME],
  isWizardLoading: false,
  selectedTheme: undefined,
  totalAmountOfSteps: 2,
};

export const getInitialState = (): IWizardState => ({
  ...INITIAL_STATE,
});

export default function wizardReducer(
  state: IWizardState = INITIAL_STATE,
  action: IWizardAction,
): IWizardState {
  switch (action.type) {
    case ADVANCE_STEPS: {
      return {
        ...state,
        currentStep:
          state.currentStep < state.totalAmountOfSteps
            ? state.currentStep + 1
            : state.currentStep,
      };
    }
    case REGRESS_STEPS: {
      return {
        ...state,
        currentStep:
          state.currentStep > 0 ? state.currentStep - 1 : state.currentStep,
      };
    }
    case SET_FLOW_STEPS: {
      return {
        ...state,
        flowSteps: [FlowSteps.HOME, ...action.payload],
      };
    }
    case SET_WIZARD_FLOW_TYPE: {
      return {
        ...state,
        selectedFlow: action.payload,
      };
    }
    case SET_IS_WIZARD_LOADING: {
      return {
        ...state,
        isWizardLoading: action.payload,
      };
    }
    case RESET_WIZARD_FLOW: {
      return {
        ...INITIAL_STATE,
      };
    }
    case SET_CHANNEL_DATA: {
      return {
        ...state,
        draftChannelData: action.payload,
      };
    }
    default:
      return state;
  }
}
