import { makeReducer } from 'redux-utils';
import * as actions from './actions';
import { getDefaultState } from './state';

export const videoUploadReducer = makeReducer(
  actions,
  getDefaultState,
  (state, action) => {
    switch (action.type) {
      case actions.CREATE_FILES: {
        return {
          ...state,
          files: [
            ...state.files,
            ...action.payload,
          ],
        };
      }
      case actions.REMOVE_FILE: {
        return {
          ...state,
          files: state.files.filter(
            ({ id }) => id !== action.payload.fileId,
          ),
        };
      }
      case actions.UPDATE_FILES: {
        return {
          ...state,
          files: state.files.map(
            (file) => {
              const updated = action.payload.find(
                (t) => t.id === file.id,
              );

              if (!updated)
                return file;

              return {
                ...file,
                ...updated,
              };
            },
          ),
        };
      }
      default: {
        return state;
      }
    }
  },
);
