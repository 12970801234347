import {
  SET_PENDING_INVITES,
  SEND_INVITE,
  SEND_INVITE_SUCCESS,
  SEND_INVITE_ERROR,
  GET_INVITE_LINK,
  GET_INVITE_LINK_SUCCESS,
  GET_INVITE_LINK_ERROR,
  UPDATE_INVITE,
  DELETE_INVITE,
  DELETED_INVITE,
  DELETE_INVITE_SUCCESS,
  EDIT_INVITE,
  CLEAR_INVITE,
  RESEND_INVITE,
  RESEND_INVITE_SUCCESS,
  ACCOUNT_EXISTS,
  ACCOUNT_EXISTS_SUCCESS,
} from './actions';
import { IInviteState } from './models';

const INITIAL_STATE: IInviteState = {
  accountExist: false,
  error: null,
  fetching: false,
  inviteUrl: null,
  invite: {},
  invalidInvite: false,
  pendingInvites: [],
  resent: null,
  successEmail: null,
  editing: false,
};

export const getInitialState = () => ({
  ...INITIAL_STATE,
});

const inviteReducer = (state = INITIAL_STATE, { payload, type }: { payload: any, type: string }): IInviteState => {
  switch (type) {
    case SET_PENDING_INVITES:
      return {
        ...state,
        pendingInvites: payload,
      };
    case SEND_INVITE:
      return {
        ...state,
        error: null,
        successEmail: null,
        fetching: true,
        resent: false,
      };
    case SEND_INVITE_SUCCESS:
      return {
        ...state,
        successEmail: true,
        fetching: false,
        invite: payload,
      };
    case SEND_INVITE_ERROR:
      return {
        ...state,
        successEmail: false,
        fetching: false,
      };
    case GET_INVITE_LINK:
      return {
        ...state,
        fetching: true,
      };
    case GET_INVITE_LINK_SUCCESS:
      return {
        ...state,
        inviteUrl: payload.inviteUrl,
        invite: payload.invite,
        fetching: false,
      };
    case GET_INVITE_LINK_ERROR:
      return {
        ...state,
        invalidInvite: payload,
        fetching: false,
      };
    case UPDATE_INVITE:
    case DELETE_INVITE:
      return {
        ...state,
        fetching: true,
        accountExist: false,
        resent: false,
      };
    case DELETED_INVITE:
      const index = state.pendingInvites.findIndex((i: {_id: string}) => i._id === payload);

      const newPendingInvites = [...state.pendingInvites];

      newPendingInvites[index] = {
        ...newPendingInvites[index],
        deleted: true,
      };

      return {
        ...state,
        pendingInvites: newPendingInvites,
        fetching: false,
      };
    case DELETE_INVITE_SUCCESS:
      const invitesWithoutDeleted = state.pendingInvites.filter((i: {_id: string}) => i._id !== payload);

      return {
        ...state,
        pendingInvites: invitesWithoutDeleted,
      };
    case EDIT_INVITE:
      return {
        ...state,
        invite: payload,
        editing: true,
        error: null,
        invalidInvite: false,
        successEmail: null,
        accountExist: false,
        resent: false,
      };
    case CLEAR_INVITE:
      return {
        ...state,
        invite: {},
        error: null,
        invalidInvite: false,
        successEmail: null,
        accountExist: false,
        inviteUrl: null,
        editing: false,
        resent: false,
      };
    case RESEND_INVITE:
      return {
        ...state,
        fetching: true,
        resent: false,
        accountExist: false,
      };
    case RESEND_INVITE_SUCCESS:
      return {
        ...state,
        fetching: false,
        resent: true,
      };
    case ACCOUNT_EXISTS:
      return {
        ...state,
        fetching: false,
        accountExist: payload,
      };
    case ACCOUNT_EXISTS_SUCCESS:
      const invitesWithoutExisting = state.pendingInvites.filter((i: {_id: string}) => i._id !== payload);

      return {
        ...state,
        accountExist: false,
        pendingInvites: invitesWithoutExisting,
      };
    default:
      return state;
  }
};

export default inviteReducer;
