import { IVideo, PlayerTypes } from 'models';
import { getDocument } from 'services/realtime/selectors';
import IState from 'services/state';

export const shouldAutoplay = (): boolean => {
  return true;
};

// update this when a new provider is added
const maestroCDNBaseUrls: RegExp[] = [
  /^https:\/\/storage.googleapis.com\/maestro_video_uploads\//,
  /^https:\/\/storage.googleapis.com\/maestro-video-uploads-prod\//,
  /^https:\/\/maestro-videos-transcoded.storage.googleapis.com\//,
  /^https:\/\/maestro-videos-transcoded-prod.storage.googleapis.com\//,
  /^https:\/\/static\.gcp\.maestro\.io\//,
  /^https:\/\/static\.maestro\.io\//,
  /^https:\/\/stream\.mux\.com\//,
  /^https:\/\/phenixrts.com\/video\/maestro\.io\//,
  /^https:\/\/cdn3\.wowza\.com/,
  /^https:\/\/static\.ma\.gettr\.com\//,
  /^https:\/\/static\.gcp\.videostreamer\.net\//,
];

// all StreamProviders that are useAccount: true
const nonBillableProviderCDNUrls: RegExp[] = [
  // example: https://bcovlive-a.akamaihd.net/
  /^https:\/\/bcovlive-[a-z0-9]*\.akamaihd\.net/,
];

export const isMaestroHostedVideo = (value: string): boolean => (
  maestroCDNBaseUrls.some((regex) => regex.test(value))
);

export const isNonBillableProvider = (value: string): boolean => (
  nonBillableProviderCDNUrls.some(re => re.test(value))
);

export const videoHasYoutubeOrTwitch = (id: string, state: IState) => {
  if (!id) {
    return false;
  }
  const videoDoc = getDocument(state, 'video', id);
  return videoDoc && [PlayerTypes.youtube, PlayerTypes.twitch].includes(videoDoc?.player);
};

export const playlistHasYoutubeOrTwitch = (id: string, state: IState) => {
  if (!id) {
    return false;
  }

  const playlistDoc = getDocument(state, 'playlist', id);
  if (!playlistDoc?.members?.length) {
    return false;
  }

  for (const video of playlistDoc.members) {
    if (videoHasYoutubeOrTwitch(video.id, state)) {
      return true;
    }
  }

  return false;
};

export const youtubeVideoFallbackThumbnail = <
  T extends (
    Pick<IVideo, 'player' | 'url'> & {
      thumbnail?: string | null;
    }
  ) | null | undefined
>(video: T) => {
  try {
    if (!video || video.thumbnail || video.player !== PlayerTypes.youtube || !video.url)
      return video;

    const youtubeVideoId = new URL(video.url).searchParams.get('v');
    return {
      ...video,
      thumbnail: `https://i.ytimg.com/vi/${youtubeVideoId}/sddefault.jpg`,
    };
  } catch {
    return video;
  }
};
