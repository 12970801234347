import { call, put, select, takeEvery } from 'redux-saga/effects';
import { getSiteId } from 'services/app/selectors';
import {
  getPrimaryToken,
} from 'services/auth/selectors';
import {
  IGetSiteRolesResponse,
} from './models';

import {
  GET_SITE_ROLES,
  ISiteRoles,
  setSiteRolesState,
} from './actions';

import {
  getSiteRoles,
} from './api';

import { ACCOUNT_EXISTS } from 'services/invite';

import IState from 'services/state';

export function* getSiteRolesSaga({ type }: { type: string }) {
  const state: IState = yield select();
  const accessToken: string | null = getPrimaryToken(state);
  const siteId: string = getSiteId(state);

  if (!accessToken) {
    return;
  }

  try {
    const cacheBust = type === ACCOUNT_EXISTS ? true : false;
    const siteRoleResponse: IGetSiteRolesResponse = yield call(getSiteRoles, accessToken, siteId, cacheBust);
    const { accounts, userProfiles } = siteRoleResponse;
    const payload: ISiteRoles[] = [...accounts];
    for (const userId in userProfiles) {
      if (userProfiles[userId].icon) {
        const profile = userProfiles[userId];
        payload.forEach((account) => {
          if (account._id === profile.accountId && profile.icon) {
            account.icon = profile.icon;
          }
        });
      }
    }
    yield put(setSiteRolesState(payload));
  } catch (err) {
    // tslint:disable-next-line: no-console
    console.log(err.message);
  }
}

export default function* rolesSaga() {
  yield takeEvery([GET_SITE_ROLES, ACCOUNT_EXISTS], getSiteRolesSaga);
}
