import { createSelector } from 'reselect';

import { getRealtimeDocuments } from 'services/realtime/selectors';
import { EMPTY_OBJECT, EMPTY_ARRAY } from 'services/utils';
import { getDocumentPath, hasOwn } from '../../utils';

const getPanelPath = (id) => getDocumentPath('objects', id);
const getQuestPath = (id) => getDocumentPath('quest', id);

const getQuestListeners = ({ quest: { listeners } }) => listeners;

const getQuestIds = createSelector([getQuestListeners], (listeners) => Object.keys(listeners));

export const getProgress = createSelector(
  (state) => state.quest.progress,
  progress => progress || EMPTY_OBJECT,
);

export const shouldInitialize = (state, id) => !state.quest.progress[id];

export const getQuests = createSelector(
  [getQuestIds, getRealtimeDocuments],
  (ids, documents) => ids.reduce((acc, id) => {
    const questPath = getQuestPath(id);
    const quest = hasOwn(documents, questPath) && documents[questPath];
    if (quest) {
      return {
        ...acc,
        [id]: quest,
      };
    }
    return { ...acc };
  }, EMPTY_OBJECT),
);

export function isQuestClaimable(state, questId) {
  const progress = state.quest.progress[questId];
  if (!progress?.actions?.length) {
    return false;
  }

  const allActionsComplete = Object.values(progress.actions).every((action) => action.completed);
  return allActionsComplete && progress.claimed !== true;
}

export function isQuestPanelClaimable(state, panelId) {
  const documents = getRealtimeDocuments(state);
  const panel = documents[getPanelPath(panelId)];
  const renderer = panel?.renderer;
  if (!renderer || renderer.panelType !== 'quests') {
    return false;
  }
  const quests = renderer.quests || EMPTY_ARRAY;
  return quests.some((item) => isQuestClaimable(state, item.questId));
}

export function isQuestClaiming(state, questId) {
  const set = state.quest.claimingQuestSet;
  return hasOwn(set, questId) ? Boolean(set[questId]) : false;
}

export const getQuestsFromPanel = createSelector(
  (state, panelId) => {
    const documents = getRealtimeDocuments(state);
    const panel = documents[getPanelPath(panelId)];
    const renderer = panel?.renderer;
    if (!renderer || renderer.panelType !== 'quests') {
      return false;
    }
    return renderer.quests || EMPTY_ARRAY;
  },
  quests => quests,
);
