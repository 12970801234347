import { makeAction } from 'redux-utils';

export const SUBSCRIBE = 'realtime/SUBSCRIBE';
export const UNSUBSCRIBE = 'realtime/UNSUBSCRIBE';
export const UNSUBSCRIBE_COLLECTION = 'realtime/UNSUBSCRIBE_COLLECTIO';
export const INCREMENT_LISTENER = 'realtime/INCREMENT_LISTENER';
export const DECREMENT_LISTENER = 'realtime/DECREMENT_LISTENER';
export const UPDATE_DOCUMENT = 'realtime/UPDATE_DOCUMENT';
export const CLEANUP_PATH = 'realtime/CLEANUP_PATH';

export const incrementListener = makeAction(
  INCREMENT_LISTENER,
  (path: string) => ({
    payload: path,
  }),
);

export const decrementListener = makeAction(
  DECREMENT_LISTENER,
  (path: string) => ({
    payload: path,
  }),
);

export const cleanupPath = makeAction(
  CLEANUP_PATH,
  (path: string) => ({
    payload: path,
  }),
);

// Public actions
export const subscribe = makeAction(
  SUBSCRIBE,
  (path: string) => ({
    payload: path,
  }),
);

export type ISubscribeAction = ReturnType<typeof subscribe>;

export const unsubscribe = makeAction(
  UNSUBSCRIBE,
  (path: string) => ({
    payload: path,
  }),
);

export type IUnsubscribeAction = ReturnType<typeof unsubscribe>;

export const unsubscribeCollection = makeAction(
  UNSUBSCRIBE_COLLECTION,
  (collection: string) => ({
    payload: collection,
  }),
);

export type IUnsubscribeCollectionAction = ReturnType<typeof unsubscribeCollection>;

// Private actions
export const updateDocument = makeAction(
  UPDATE_DOCUMENT,
  ({ path, value }: { path: string; value: any }) => ({
    payload: { path, value },
  }),
);

export type IUpdateDocumentAction = ReturnType<typeof updateDocument>;
