// tslint:disable:no-console
import { makeSaga } from 'redux-utils';
import { IState } from 'services/state';
import { call, put, select } from 'redux-saga/effects';
import { setObject, setSite } from 'services/app/actions';
import { getPrimaryToken, isUserAdmin } from 'services/auth/selectors';
import { subscribe, updateDocument, UPDATE_DOCUMENT } from 'services/realtime/actions';
import { isListening } from 'services/realtime/selectors';
import { createChannelStream } from '../api';
import { getObject, getStreamingProviderAccountId } from 'services/app/selectors';
import { IObject, PageType } from 'models';
import { getRealtimeStream, isLivestreamPublished } from '../selectors';
import { getSite } from 'services/app/selectors';
import { Feature, isFeatureEnabled } from 'services/feature-gate';
import { flattenCalls } from 'utils';
import { requestNewStream, MANUALLY_REQUEST_NEW_STREAM } from '../actions';

export const createChannelStreamFlattened = flattenCalls(createChannelStream);

export const prepareChannelStreamSaga = makeSaga(
  {
    setObject,
    setSite,
    updateDocument,
    requestNewStream,
  },
  function* ({ type, payload }) {
    try {
      const state: IState = yield select();
      const userIsAdmin = isUserAdmin(state);
      const token = getPrimaryToken(state);
      const channel = getObject(state) as IObject;
      const streamingProviderAccountId = getStreamingProviderAccountId(state);

      if (!token || !userIsAdmin || !channel)
        return;

      if (
        !!streamingProviderAccountId
        && !isListening(state, 'streamprovideraccount', streamingProviderAccountId)
      ) {
        yield put(
          subscribe(`streamprovideraccount/${streamingProviderAccountId}`),
        );
      }

      if (type === UPDATE_DOCUMENT && payload.path.indexOf('stream/') !== 0)
        return;

      if (channel.type !== PageType.CHANNEL)
        return;

      const channelId = channel._id;
      const livestreamRenderer = channel.renderers?.livestream;
      const currentStream = getRealtimeStream(state);
      const site = getSite(state);
      const streamingProvider = site.settings.defaultStreamingProvider || 'mux';
      const isGenerateCaptionsFlagEnabled = isFeatureEnabled(state, Feature.GENERATE_CAPTIONS);
      const isLive = isLivestreamPublished(state);

      const canCreateStream = (
        isFeatureEnabled(state, Feature.STREAMING)
        && livestreamRenderer?.status !== 'creating'
        && !isLive
      );

      // we could use compact logical syntax here but i think
      // this way it's easier to understand. the conditions
      // really are complex
      const isCurrentStreamOutOfSync = (() => {
        if (!currentStream) {
          return false;
        }

        if (!!streamingProviderAccountId && currentStream.providerAccountId !== streamingProviderAccountId)
          return true;

        if (currentStream.provider !== streamingProvider) {
          return true;
        }

        if (streamingProvider === 'wowza') {
          if (isGenerateCaptionsFlagEnabled && currentStream.baseLanguage !== (channel as any).baseLanguage) {
            return true;
          }

          if (isGenerateCaptionsFlagEnabled !== !!currentStream.hasCaptions) {
            return true;
          }
        }

        if (streamingProvider === 'mux') {
          if (!!currentStream.isSecure !== isFeatureEnabled(state, Feature.SECURE_STREAMS)) {
            return true;
          }
        }

        return false;
      })();

      const streamId = livestreamRenderer?.streamId;

      const channelHasNoStream = !livestreamRenderer || (
        livestreamRenderer.status !== 'error'
        && !streamId
      );

      const shouldCreateStream = canCreateStream && (
        isCurrentStreamOutOfSync
        || channelHasNoStream
        || type === MANUALLY_REQUEST_NEW_STREAM
      );

      if (shouldCreateStream) {
        yield call(
          createChannelStreamFlattened,
          {
            channelId,
            token,
          },
        );

        return;
      }

      if (!!streamId && !isListening(state, 'stream', streamId)) {
        yield put(subscribe(`stream/${streamId}`));
      }
    } catch (error) {
      console.log('prepareChannelStream() error', error);
    }
  },
);
