import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { SET_OBJECT } from 'services/app/actions';
import { getCurrentPageId } from 'services/insights/selectors';
import { getPageId, getSiteId, getPageSlug } from 'services/app/selectors';
import {
  LOG_OUT,
  LOG_IN_SUCCESS,
  refreshUser,
  CHECK_GATE,
  checkGate,
  REFRESH_USER,
} from 'services/auth/actions';
import { getWalletData } from 'services/billing/actions';
import {
  getPrimaryToken,
  isUserLoggedIn,
  isUserAdmin,
  getUserId,
  getUserTokenData,
} from 'services/auth/selectors';
import { showAdminErrorModal } from 'services/modals/actions';
import IState from 'services/state';
import {
  displayAmazonBenefitGate,
  displayLoginGate,
  displayPasswordGate,
  displaySuscriptionGate,
} from 'services/user-layout/selectors';
import {
  MaestroIFrameEvents,
  postMessageToIframeSDK,
  EventSource,
} from 'services/iframe';
import { getDevicePermanentId } from 'services/device/selectors';
import { UPDATE_DOCUMENT } from 'services/realtime/actions';
import { IUpdateDocumentAction } from 'services/realtime/actions';
import { confirmPaymentStatus } from 'services/billing/api';
import {
  watchEntitlementAccess,
  completePaymentProcess,
  CANCEL_SUBSCRIPTION,
  ICancelSubscriptionAction,
} from 'services/billing';
import {
  ISubmitPasswordAction,
  setGateActive,
  setPreview,
  setPasswordEntryValid,
  setApiLoading,
  ISubmitAcessCodeAction,
  SUBMIT_PASSWORD,
  SUBMIT_ACCESS_CODE,
  SET_PREVIEW,
  setAccountEntitlements,
  updateAccountEntitlement,
  setAccessCodeEntryValid,
  setAccountEntitlementsLoaded,
  setAmazonBenefitCheckGate,
  setAmazonBenefitRedemptionGate,
  clearAmazonBenefitGateStatus,
} from './actions';
import {
  checkAmazonBenefitToken,
  CheckBenefitResponse,
  checkChannelPassword,
  getAccountEntitlements,
  getStoredPassword,
  redeemAmazonBenefitToken,
  setStoredPassword,
  submitCode,
} from './api';
import {
  getCurrentGateData,
  getGatePreview,
  userHasSubscriptions,
  getFixedGateData,
  getAccountEntitlements as getAccountEntitlementsSelector,
  getChannelAmazonBenefitId,
  isGateActive,
} from './selectors';
import {
  IAccountEntitlement,
  AccountEntitlementStatus,
  GrantedMethod,
} from './models';
import IGate, { GateKind } from 'models/IGate';
import { persistenceService } from 'services/persistence';
import { showModal } from 'services/modals/actions';
import { ModalKinds } from '../modals/types';
import { getT } from 'services/localization';
import type { ResolvedReturnType } from 'utils';
import { getActiveSiteFeatures } from 'services/app/selectors/common';
import { Feature } from 'services/feature-gate';

export const loginGateSaga = function* () {
  const state: IState = yield select();
  const loggedIn = isUserLoggedIn(state);
  const channelId = getPageId(state);
  const showGate = displayLoginGate(state);
  if (!channelId) {
    return;
  }
  if (!loggedIn) {
    yield call(postMessageToIframeSDK, { action: MaestroIFrameEvents.LOGIN_REQUIRED, eventSource: EventSource.LOGIN_GATE });
  }
  const gateActive = (showGate && !loggedIn);
  yield put(setGateActive(channelId, gateActive));
};

// TODO: tracking maybe.
export const passwordGateSaga = function* () {
  const state: IState = yield select();
  const channelId: string = getPageId(state);
  const showGate = displayPasswordGate(state);
  const isAdmin = isUserAdmin(state);
  if (!channelId || isAdmin) {
    return;
  }

  let isValid = false;
  const storedPassword: string = yield call(getStoredPassword, channelId);
  if (storedPassword) {
    isValid = yield call(checkChannelPassword, {
      channelId,
      password: storedPassword,
    });
  }

  const gateActive = (showGate && !isValid);

  yield put(setGateActive(channelId, gateActive));
};

export const submitPasswordSaga = function* ({
  payload: password,
}: ISubmitPasswordAction) {
  const channelId: string = yield select(getPageId);
  if (!channelId || !password) {
    return;
  }
  yield put(setApiLoading(true));
  const isValid: boolean = yield call(checkChannelPassword, {
    channelId,
    password,
  });
  yield put(setApiLoading(false));
  if (isValid) {
    yield call(
      setStoredPassword, channelId, password);
    yield put(setGateActive(channelId, false));
  }
  yield put(setPasswordEntryValid(channelId, isValid));
};

export const subscriptionGateSaga = function* () {
  const t: ResolvedReturnType<typeof getT> = yield call(getT);
  const state: IState = yield select();
  const channelId = getPageId(state);
  const loggedIn = isUserLoggedIn(state);
  const showGate = displaySuscriptionGate(state);
  const accountId = getUserId(state);
  const channelSlug = getPageSlug(state);
  const { subscriptions: subscriptionData, bundles: bundlesData } = getFixedGateData(t)(state);
  if (!channelId) {
    return;
  }

  const hasAccess = userHasSubscriptions(t)(state);
  persistenceService().write('userHasEntitlement', hasAccess);
  yield put(setGateActive(channelId, !hasAccess));

  if (!loggedIn) {
    yield call(postMessageToIframeSDK, { action: MaestroIFrameEvents.LOGIN_REQUIRED, eventSource: EventSource.SUBSCRIPTION_GATE });
  }

  // We only need to watch entitlement access if the user is logged in.
  if (accountId) {
    for (const sub of subscriptionData) {
      yield put(watchEntitlementAccess(`${accountId}/${sub.sku}`));
    }

    for (const bundle of bundlesData) {
      const bundleTickets = bundle.items.filter(item => item.type === 'ticket');
      for (const bundleTicket of bundleTickets) {
        yield put(watchEntitlementAccess(`${accountId}/${bundleTicket.sku}`));
      }
    }
  }

  if (!hasAccess && loggedIn) {
    yield call(postMessageToIframeSDK, {
      action: MaestroIFrameEvents.SUBSCRIPTION_REQUIRED,
      subscriptionDetails: {
        subscriptions: subscriptionData.map((sub: any) => sub.sku),
        channelSlug,
        accountId,
      },
      eventSource: EventSource.SUBSCRIPTION_GATE,
    });
  }
  const hasPreviewOn = getGatePreview(state);
  const gateActive = (showGate && !hasAccess && !hasPreviewOn);
  yield put(setGateActive(channelId, gateActive));
};

export const amazonBenefitGateSaga = function* () {
  const state: IState = yield select();
  const channelId = getPageId(state);
  const siteId = getSiteId(state);
  const token: string | null = getPrimaryToken(state);
  const userToken = getUserTokenData(state);
  const loggedIn = isUserLoggedIn(state);
  const amazonBenefitId = getChannelAmazonBenefitId(state);
  if (!channelId) {
    return;
  }

  if (!amazonBenefitId) {
    yield put(setAmazonBenefitCheckGate({
      available: false,
      message: 'No Amazon Benefit configured.',
    }));
    yield put(setGateActive(channelId, true));
    return;
  }

  if((!loggedIn || !token) || userToken?.service !== 'amazon') {
    yield put(setGateActive(channelId, true));
    return;
  }

  yield put(setGateActive(channelId, true));
  yield put(setApiLoading(true));
  const hasToken: CheckBenefitResponse = yield call(checkAmazonBenefitToken, token, amazonBenefitId, siteId);

  if (hasToken && hasToken.available) {
    const redemption = yield call(redeemAmazonBenefitToken, token, amazonBenefitId, siteId);
    if (redemption.success) {
      yield put(setApiLoading(false));
      yield put(setGateActive(channelId, false));
      return;
    } else {
      yield put(setAmazonBenefitRedemptionGate(redemption));
      yield put(setApiLoading(false));
      yield put(setGateActive(channelId, true));
      return;
    }
  } else {
    yield put(setAmazonBenefitCheckGate(hasToken));
    yield put(setApiLoading(false));
    yield put(setGateActive(channelId, true));
    return;
  }
};

export const checkGateSaga = function* () {
  const state: IState = yield select();
  const gateData: IGate = getCurrentGateData(state);
  const featureGates = getActiveSiteFeatures(state);
  const channelId = getPageId(state);
  const gateActiveMap = state.gate.activeGateMap[channelId];

  if (!(channelId)) {
    return;
  }

  if (!gateData?.active) {
    if (gateActiveMap) {
      yield put(setGateActive(channelId, false));
    }
    return;
  }

  switch (gateData?.kind) {
    case GateKind.LOGIN: {
      yield call(loginGateSaga);
      return;
    }
    case GateKind.PASSWORD: {
      yield call(passwordGateSaga);
      return;
    }
    case GateKind.ACCESSCODE:
    case GateKind.SUBSCRIPTION: {
      yield call(subscriptionGateSaga);
      return;
    }
    case GateKind.AMAZONBENEFIT: {
      if (!featureGates[Feature.AMAZON_BENEFIT]) {
        yield put(setGateActive(channelId, false));
        return;
      }
      yield call(amazonBenefitGateSaga);
      return;
    }
    default: {
      yield put(setGateActive(channelId, false));
      return;
    }
  }
};

export const setPreviewSaga = function* ({ payload, type }: any) {
  const state: IState = yield select();
  const loggedIn = isUserLoggedIn(state);
  const lastPageId = getCurrentPageId(state);
  const preview = getGatePreview(state);

  if (!loggedIn) {
    if (preview) {
      yield put(setPreview({ preview: false }));
    }

    return;
  }
  if (type !== LOG_IN_SUCCESS) {
    const { _id } = payload?.object || {};
    const { loaded } = payload;
    if (lastPageId !== _id && loaded && preview) {
      yield put(setPreview({ preview: false }));
    }
  }
  return;
};

export const submitAccessCodeSaga = function* (action: ISubmitAcessCodeAction) {
  const state: IState = yield select();
  const primaryToken = getPrimaryToken(state);
  const siteId = getSiteId(state);
  const deviceId = getDevicePermanentId(state);

  if (!(primaryToken && siteId)) {
    return;
  }

  const channelId = getPageId(state);

  if (!channelId) {
    // tslint:disable-next-line
    console.warn('channel id is not found');
    return;
  }

  yield put(setApiLoading(true));
  try {
    const { success } = yield call(submitCode, {
      ...action.payload,
      primaryToken,
      siteId,
      deviceId,
    });
    yield put(setAccessCodeEntryValid(true));
    yield put(refreshUser());
    yield put(setGateActive(channelId, !success));
  } catch (err) {
    yield put(setAccessCodeEntryValid(false));
  }
  yield put(setApiLoading(false));
};

export const entitlementAccessUpdateSaga = function* ({ payload }: IUpdateDocumentAction) {
  const { path } = payload;
  if (/^entitlementaccess/.test(path) && payload.value) {
    const state: IState = yield select();
    const siteId = getSiteId(state);
    const accessToken: string | null = getPrimaryToken(state);
    if (!accessToken) {
      return;
    }
    const value: IAccountEntitlement = payload.value;
    switch (value.status) {
      case AccountEntitlementStatus.active: {
        yield put(refreshUser());
        yield put(getWalletData());

        if (payload.value.purchaseSummary) {
          yield put(showModal({ kind: ModalKinds.receiptModal, data: { purchaseSummary: payload.value.purchaseSummary } }));
        }

        yield call(confirmPaymentStatus, accessToken, siteId, payload.value);
        yield put(completePaymentProcess());
        break;
      }
      case AccountEntitlementStatus.canceled: {
        const message = value.errorMessage ? 'DYNAMIC_ERROR_MESSAGE' : 'PAYMENT_ERROR_MESSAGE';
        const errorKey = value.errorMessage ? undefined : 'PAYMENT_ERROR_MESSAGE';
        yield put(showAdminErrorModal(message, value.errorMessage, errorKey));
        yield put(checkGate());
        yield put(completePaymentProcess());
        yield call(confirmPaymentStatus, accessToken, siteId, payload.value);
        break;
      }
    }
  }
};

export const setAccountEntitlementsSaga = function* () {
  try {
    const state: IState = yield select();
    const siteId = getSiteId(state);
    const token: string | null = getPrimaryToken(state);
    const accountEntitlements = getAccountEntitlementsSelector(state);
    if (!token) {
      return;
    }
    const entitlements: IAccountEntitlement[] = yield call(getAccountEntitlements, siteId, token);
    yield put(setAccountEntitlementsLoaded(true));
    if (!accountEntitlements.length && !entitlements.length) {
      return;
    }
    yield put(setAccountEntitlements(entitlements));
  } catch (err) {
    // tslint:disable-next-line
    console.log(`There was an error in setAccountEntitlementsSaga ==> ${err.message}`);
  }
};

const clearAccountEntitlementsSaga = function* () {
  yield put(setAccountEntitlements([]));
  yield put(setAccountEntitlementsLoaded(false));
};

const updateAccountEntitlementSaga = function* ({ payload }: ICancelSubscriptionAction) {
  const state: IState = yield select();
  const accountEntitlement = getAccountEntitlementsSelector(state).find((ac) => ac._id === payload.accountEntitlementId);

  if (!accountEntitlement) return;

  const status = (
    accountEntitlement.grantedMethod !== GrantedMethod.purchased ?
      AccountEntitlementStatus.canceled :
      AccountEntitlementStatus.cancelScheduled);

  const dateCanceled = (
    status === AccountEntitlementStatus.cancelScheduled ?
      accountEntitlement.currentPeriodEnd :
      Date.now() / 1000);

  const updatedAccountEntitlement = {
    ...accountEntitlement,
    status,
    dateCanceled,
  };

  yield put(updateAccountEntitlement(updatedAccountEntitlement));
};

/*
  The checkGateSaga will update the gate state based on the currently active gate.
  This is fired when pages are changed, someone logs out, or check_gate action is called.
  checkGate() is fired when account is updated.
 */
const gateSaga = function* () {
  yield takeEvery([SET_OBJECT, SET_PREVIEW, LOG_IN_SUCCESS, CHECK_GATE, LOG_OUT, REFRESH_USER], checkGateSaga);
  yield takeEvery(SET_OBJECT, setPreviewSaga);
  yield takeEvery(LOG_IN_SUCCESS, setPreviewSaga);
  yield takeEvery(LOG_OUT, setPreviewSaga);
  yield takeEvery(LOG_OUT, clearAccountEntitlementsSaga);
  yield takeLatest(UPDATE_DOCUMENT, entitlementAccessUpdateSaga);
  yield takeEvery<ISubmitAcessCodeAction>(SUBMIT_ACCESS_CODE, submitAccessCodeSaga);
  yield takeEvery<ISubmitPasswordAction>(SUBMIT_PASSWORD, submitPasswordSaga);
  yield takeEvery([REFRESH_USER, LOG_IN_SUCCESS], setAccountEntitlementsSaga);
  yield takeEvery(CANCEL_SUBSCRIPTION, updateAccountEntitlementSaga);
};

export default gateSaga;
