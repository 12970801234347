import { v4 } from 'uuid';
import { IUserTraits } from './models';

export const trackEvent = (event: string, properties?: unknown, options?: unknown) => {
  if (window.analytics) {
    window.analytics.track(event, properties, options);
  }
};

export const trackPage = (page: string, properties?: unknown, options?: unknown) => {
  if (window.analytics) {
    window.analytics.page(page, properties, options);
  }
};

export const identifyUser = (userId: string, traits?: IUserTraits) => {
  if (window.analytics) {
    window.analytics.identify(userId, traits);
  }
};

export const resetSegmentUser = () => {
  if (window.analytics) {
    window.analytics.reset();
  }
};

export const getAnonymousId = (): string => {
  if (!window.analytics) {
    return v4();
  }
  return window.analytics.user().anonymousId();
};
