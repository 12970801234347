export const LOG_ERROR = 'error/LOG_ERROR';

interface ILogErrorAction {
  payload: {
    customMessage?: string;
    error: Error;
    url: string;
  };
  type: typeof LOG_ERROR;
}

export type IErrorAction = ILogErrorAction;

export const logError = (error: Error, customMessage?: string): ILogErrorAction => ({
  // TODO: Reading from window.location here is weird, but it's the most accurate way to get the
  //       page URL without passing it into the action creator each time.
  payload: { customMessage, error, url: window.location.href },
  type: LOG_ERROR,
});
