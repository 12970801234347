import { ITheme } from 'models';

export const SET_ACCENT_COLOR = 'themes/SET_ACCENT_COLOR';
export const SET_THEME = 'themes/SET_THEME';
export const APPLY_THEME = 'themes/APPLY_THEME';
export const SET_DEFAULT_SITE_THEME = 'themes/SET_DEFAULT_SITE_THEME';
export const DELETE_THEME = 'themes/DELETE_THEME';
export const SET_FORKED_THEME = 'themes/SET_FORKED_THEME';

export type IThemesActions =
  ISetAccentColorAction |
  ISetThemeAction |
  IDeleteThemeAction |
  ISetForkedThemeAction |
  IApplyThemeAction;

export interface ISetForkedThemePayload {
  theme: ITheme | null;
}
export interface ISetAccentColorPayload {
  color: string;
}

export interface IApplyThemePayload {
  theme: ITheme;
}
export interface ISetThemePayload {
  theme: ITheme;
}

export interface IDeleteThemePayload {
  themeId: string;
}

export interface ISetDefaultSiteThemePayload {
  themeId: string;
}

export interface IApplyThemeAction {
  payload: IApplyThemePayload;
  type: typeof APPLY_THEME;
}
export interface ISetThemeAction {
  payload: ISetThemePayload;
  type: typeof SET_THEME;
}

export interface IDeleteThemeAction {
  payload: IDeleteThemePayload;
  type: typeof DELETE_THEME;
}

export interface ISetForkedThemeAction {
  payload: ISetForkedThemePayload;
  type: typeof SET_FORKED_THEME;
}

export interface ISetAccentColorAction {
  payload: ISetAccentColorPayload;
  type: typeof SET_ACCENT_COLOR;
}

export interface ISetDefaultSiteThemeAction {
  payload: ISetDefaultSiteThemePayload;
  type: typeof SET_DEFAULT_SITE_THEME;
}

export function setAccentColor(payload: ISetAccentColorPayload): ISetAccentColorAction {
  return {
    payload,
    type: SET_ACCENT_COLOR,
  };
}

export function setDefaultSiteTheme(payload: ISetDefaultSiteThemePayload): ISetDefaultSiteThemeAction {
  return {
    payload,
    type: SET_DEFAULT_SITE_THEME,
  };
}

export function applyTheme(payload: IApplyThemePayload): IApplyThemeAction {
  return {
    payload,
    type: APPLY_THEME,
  };
}

export function setTheme(payload: ISetThemePayload): ISetThemeAction {
  return {
    payload,
    type: SET_THEME,
  };
}

export function deleteTheme(payload: IDeleteThemePayload): IDeleteThemeAction {
  return {
    payload,
    type: DELETE_THEME,
  };
}

export function setForkedTheme(payload: ISetForkedThemePayload): ISetForkedThemeAction {
  return {
    payload,
    type: SET_FORKED_THEME,
  };
}
