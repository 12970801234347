import { useDispatch, useSelector } from 'react-redux';
import { v4 } from 'uuid';
import {
  trackSession,
  setNextLongBeaconTime,
  setNextShortBeaconTime,
} from './actions';
import { useEffect, useState } from 'react';
import { getActive, getSession } from './selectors';
import { IBeaconMetadata, PlayerSpot, WatchSessionType } from 'services/video';
import { makeFuture } from 'utils/future';
import { sleepMs } from 'utils';
import { getViewerId, getViewerState } from 'services/auth';
import { IVideo, PlayerTypes, VideoTypes } from 'models';
import {
  isMaestroHostedVideo,
  isNonBillableProvider,
} from 'services/video/utils';
import { isPayWallGated } from 'services/gate';

const FIFTEEN_SECONDS = 1000 * 15;
const FIFTEEN_MINUTES = 1000 * 60 * 15;

type UseVideoBeaconArgs = {
  isVideoPlaying: boolean;
  scheduledVideoStartTime: number | null;
  spot: PlayerSpot;
  video: IVideo;
};

export const useVideoBeacon = ({
  video,
  isVideoPlaying,
  scheduledVideoStartTime,
  spot,
}: UseVideoBeaconArgs) => {
  const dispatch = useDispatch();

  const { nextLongBeaconTimeMs, nextShortBeaconTimeMs } =
    useSelector(getSession);
  const viewerId = useSelector(getViewerId);
  const viewerState = useSelector(getViewerState);
  const isInsightsActive = useSelector(getActive);
  const paywallGated = useSelector(isPayWallGated);

  useEffect(() => {
    dispatch(setNextLongBeaconTime(Date.now() + FIFTEEN_MINUTES));
    dispatch(setNextShortBeaconTime(Date.now() + FIFTEEN_SECONDS));
  }, []);

  const [watchSessionId, setWatchSessionId] = useState(v4());
  const [lastPausedAt, setLastPausedAt] = useState<number | null>(null);

  useEffect(() => {
    if (!isVideoPlaying) {
      setLastPausedAt(Date.now());
    }
  }, [isVideoPlaying]);

  useEffect(() => {
    if (isVideoPlaying && lastPausedAt !== null) {
      // when resuming, reset session if it has been paused for 5 minutes or more
      if (Date.now() - lastPausedAt >= 300_000) {
        setWatchSessionId(v4());
      }

      setLastPausedAt(null);
    }
  }, [isVideoPlaying, lastPausedAt]);

  useEffect(() => {
    if (!isInsightsActive) {
      return;
    }

    const abortFuture = makeFuture<'aborted'>();

    (async () => {
      while (true) {
        if (
          (await Promise.race([abortFuture.promise, sleepMs(50)])) === 'aborted'
        ) {
          break;
        }

        if (!(nextLongBeaconTimeMs && nextShortBeaconTimeMs)) {
          return;
        }

        const metadata = (() => {
          if (
            watchSessionId &&
            isVideoPlaying &&
            video?.type &&
            video?._id &&
            video?.player &&
            video?.url
          ) {
            const isBillableLivestream =
              video.type === VideoTypes.livestream &&
              video.player !== PlayerTypes.twitch &&
              !isNonBillableProvider(video.url);

            const isBillableVod =
              video.type === VideoTypes.vod && isMaestroHostedVideo(video.url);

            // is billable rule: livestream or vod served from maestro or provider, except if provider.useAccount = true
            const isBillable = isBillableLivestream || isBillableVod;

            const watchSessionType =
              video.type === VideoTypes.livestream ||
                (video.type === VideoTypes.vod &&
                  scheduledVideoStartTime !== null)
                ? WatchSessionType.livestream
                : WatchSessionType.vod;

            const output: IBeaconMetadata = {
              assetStartTime: scheduledVideoStartTime,
              contentMetadataVersion: '2024-08-13',
              isBillable,
              paywall_gated: paywallGated || false,
              playerSpot: spot || null,
              playlistId: null,
              playlistIndex: null,
              videoHasSubscriptions: Boolean(video.subscriptions?.length),
              videoId: video._id,
              videoPlayer: video.player,
              videoType: video.type,
              videoUrl: video.url,
              viewerId,
              viewerState,
              watchSessionId,
              watchSessionType,
            };
            return output;
          } else {
            return {};
          }
        })();

        const now = Date.now();

        if (now >= nextLongBeaconTimeMs) {
          dispatch(trackSession('beacon', 'fifteen_minutes', metadata));
          dispatch(setNextLongBeaconTime(Date.now() + FIFTEEN_MINUTES));
          dispatch(setNextShortBeaconTime(Date.now() + FIFTEEN_SECONDS));
        } else if (now >= nextShortBeaconTimeMs) {
          dispatch(trackSession('beacon', 'frequent', metadata));
          dispatch(setNextShortBeaconTime(Date.now() + FIFTEEN_SECONDS));
        }
      }
    })();

    return () => {
      abortFuture.resolve('aborted');
    };
  }, [
    nextLongBeaconTimeMs,
    nextShortBeaconTimeMs,
    watchSessionId,
    viewerId,
    viewerState,
    isInsightsActive,
    video,
    isVideoPlaying,
    scheduledVideoStartTime,
    spot,
  ]);
};
