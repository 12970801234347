import { START_SHOPIFY_CONNECTION } from 'config';
import {
  FAIL_SHOP_REMOVAL,
  SUCCESS_SHOP_REMOVAL,
  REMOVE_SHOP,
  CONNECTING_SHOP,
  SUCCESS_CONNECTING_SHOP,
  FAIL_CONNECTING_SHOP,
  SUCCESS_SHOP_INFO,
  FAIL_SHOP_INFO,
  SHOP_INFO,
  LOADING_PRODUCTS,
  LOADING_COLLECTIONS,
  REMOVE_SINGLE_USE_SOURCE_CARD,
  SET_SINGLE_USE_SOURCE_CARD,
  SET_PRODUCTS,
  SUCCESS_PRODUCTS,
  FAIL_PRODUCTS,
  SET_COLLECTIONS,
  SUCCESS_COLLECTIONS,
  FAIL_COLLECTIONS,
  SET_BUNDLE_LOADING,
  SET_BUNDLE_ERROR,
  SET_BUNDLE_CHECKOUT,
  SET_PAYMENT_TOKEN,
  SET_BUNDLE_SUCCESS,
  RESET_BUNDLE,
  SHOP_RECURRING_CHARGE,
} from './actions';

import { IShopifyState } from './models';
import { INITIAL_BILLING_ADDRESS, INITIAL_STOREFRONT_CHECKOUT, INITIAL_STATE_PAYMENT } from './constants';

const INITIAL_STATE: IShopifyState = {
  accountSettings: '',
  billingAddress: INITIAL_BILLING_ADDRESS,
  carts: {},
  connection: {
    connectionString: START_SHOPIFY_CONNECTION || '',
    error: null,
    flowTriggered: !!START_SHOPIFY_CONNECTION || false,
    ongoing: false,
    redirectionUrl: '',
  },
  collections: [],
  currencyCode: 'USD',
  error: null,
  loadingShopCredentials: false,
  loadingProducts: false,
  loadingCollections: false,
  pageInfo: {
    products: {
      hasNextPage: true,
      cursor: null,
    },
    collections: {
      hasNextPage: true,
      cursor: null,
    },
  },
  moneyFormat: '',
  products: [],
  shop: '',
  recurringCharge: { active: false, confirmationUrl: '' },
  shopAccessToken: '',
  shopifyPaymentsAccountId: '',
  singleUseSourceCard: [],
  bundle: {
    loading: false,
    success: null,
    checkout: null,
    error: null,
  },
  token: null,
};

export const getInitialState = () => ({
  ...INITIAL_STATE,
});

const shopifyReducer = (state = INITIAL_STATE, { type, payload }: { payload: any, type: string }) => {
  switch (type) {
    case CONNECTING_SHOP:
      return {
        ...state,
        connection: {
          ...state.connection,
          ongoing: true,
        },
      };
    case SUCCESS_CONNECTING_SHOP:
      return {
        ...state,
        connection: {
          ...state.connection,
          connectionString: '',
          flowTriggered: false,
          ongoing: false,
          redirectionUrl: payload.confirmationUrl,
        },
        shop: payload.shop.shop,
        shopAccessToken: payload.shop.storefrontAccessToken,
      };
    case FAIL_CONNECTING_SHOP:
      return {
        ...state,
        connection: {
          ...state.connection,
          connectionString: '',
          error: payload,
          flowTriggered: false,
          ongoing: false,
        },
      };
    case REMOVE_SHOP:
      return {
        ...state,
        error: null,
      };
    case LOADING_PRODUCTS:
      return {
        ...state,
        loadingProducts: true,
      };
    case SET_PRODUCTS:
      return {
        ...state,
        products: [...state.products, ...payload.products],
        pageInfo: {
          ...state.pageInfo,
          products: payload.pageInfo,
        },
      };
    case SUCCESS_PRODUCTS:
      return {
        ...state,
        loadingProducts: false,
      };
    case FAIL_PRODUCTS:
      return {
        ...state,
        loadingProducts: false,
      };
    case LOADING_COLLECTIONS:
      return {
        ...state,
        loadingCollections: true,
      };
    case SET_COLLECTIONS:
      return {
        ...state,
        collections:[...state.collections, ...payload.collections],
        pageInfo: {
          ...state.pageInfo,
          collections: payload.pageInfo,
        },
      };
    case SUCCESS_COLLECTIONS:
      return {
        ...state,
        loadingCollections: false,
      };
    case FAIL_COLLECTIONS:
      return {
        ...state,
        loadingProducts: false,
      };
    case FAIL_SHOP_REMOVAL:
      return {
        ...state,
        error: payload,
      };
    case SUCCESS_SHOP_REMOVAL:
      return {
        ...state,
        collections: [],
        error: null,
        products: [],
        shop: '',
      };
    case SHOP_RECURRING_CHARGE:
      return {
        ...state,
        recurringCharge: {
          active: payload.active,
          confirmationUrl: payload.confirmationUrl,
        },
      };
    case SHOP_INFO:
      return {
        ...state,
        loadingShopCredentials: true,
      };
    case SUCCESS_SHOP_INFO:
      return {
        ...state,
        loadingShopCredentials: false,
        moneyFormat: payload.moneyFormat,
        shop: payload.shop,
        shopAccessToken: payload.shopAccessToken,
        shopifyPaymentsAccountId: payload.shopifyPaymentsAccountId,
        currencyCode: payload.currencyCode,
      };
    case FAIL_SHOP_INFO:
      return {
        ...state,
        loadingShopCredentials: false,
        moneyFormat: '',
        shop: '',
        shopAccessToken: '',
      };
    case SET_SINGLE_USE_SOURCE_CARD:
      return {
        ...state,
        singleUseSourceCard: [...state.singleUseSourceCard, payload],
      };
    case REMOVE_SINGLE_USE_SOURCE_CARD:
      return {
        ...state,
        singleUseSourceCard: state.singleUseSourceCard.filter(item => item !== payload),
      };
    case SET_BUNDLE_LOADING:
      return {
        ...state,
        bundle: {
          ...state.bundle,
          loading: payload,
        },
      };
    case SET_BUNDLE_ERROR:
      return {
        ...state,
        bundle: {
          ...state.bundle,
          error: payload,
        },
      };
    case SET_BUNDLE_CHECKOUT:
      return {
        ...state,
        bundle: {
          ...state.bundle,
          checkout: payload,
        },
      };
      case SET_PAYMENT_TOKEN:
      return {
        ...state,
        token: payload,
      };
      case SET_BUNDLE_SUCCESS:
      return {
        ...state,
        bundle: {
          ...state.bundle,
          success: payload,
        },
      };
      case RESET_BUNDLE:
      return {
        ...state,
        bundle: INITIAL_STATE.bundle,
      };
    default:
      return state;
  }
};

export default shopifyReducer;
