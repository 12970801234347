import {
  IGateAction,
  SET_GATE_TRACKED,
  SET_GATE_USER_AGE,
  SET_IS_GATE_ACTIVE,
  SET_PASSWORD_ENTRY_VALID,
  SET_ACCESS_CODE_ENTRY_VALID,
  SET_API_LOADING,
  SET_PREVIEW_DURATION,
  SET_PREVIEW,
  SET_ACCOUNT_ENTITLEMENTS,
  UPDATE_ACCOUNT_ENTITLEMENT,
  SET_ACCOUNT_ENTITLEMENTS_LOADED,
  SET_AMAZON_BENEFIT_CHECK_GATE,
  SET_AMAZON_BENEFIT_REDEMPTION_GATE,
  CLEAR_AMAZON_BENEFIT_GATE_STATUS,
} from './actions';
import { IAccountEntitlement } from './models';

export interface IGateState {
  accessCodeEntryValid: boolean | null;
  accountEntitlements: IAccountEntitlement[];
  accountEntitlementsLoaded: boolean;
  activeGateMap: {
    [channelId: string]: boolean;
  };
  amazonBenefitGate: {
    hasCheckError: boolean;
    hasRedemptionError: boolean;
    message: string | null;
  }
  apiLoading: boolean;
  gateTracked: {
    [docId: string]: {
      [action: string]: boolean,
    },
  };
  passwordEntryValid: {
    [docId: string]: boolean;
  };
  paymentPending: boolean;
  preview: boolean;
  previewDurationSeconds: number;
  userAge: {
    date?: number;
    month?: number;
    year?: number;
  };
  userEmail: string | null;
}

export const getInitialState = (): IGateState => ({
  apiLoading: false,
  amazonBenefitGate: {
    hasCheckError: false,
    hasRedemptionError: false,
    message: null,
  },
  accessCodeEntryValid: null,
  accountEntitlements: [],
  accountEntitlementsLoaded: false,
  activeGateMap: {},
  gateTracked: {},
  passwordEntryValid: {},
  paymentPending: false,
  preview: false,
  previewDurationSeconds: 0,
  userAge: {},
  userEmail: null,
});

const gateReducer = (
  state: IGateState = getInitialState(),
  action: IGateAction,
): IGateState => {
  switch (action.type) {
    case SET_PREVIEW_DURATION: {
      const { previewDurationSeconds } = action.payload;
      return {
        ...state,
        previewDurationSeconds,
      };
    }
    case SET_PREVIEW: {
      const { preview } = action.payload;
      return {
        ...state,
        preview,
      };
    }
    case SET_API_LOADING: {
      return {
        ...state,
        apiLoading: action.payload,
      };
    }
    case SET_IS_GATE_ACTIVE: {
      const { channelId, isActive } = action.payload;
      return {
        ...state,
        activeGateMap: {
          ...state.activeGateMap,
          [channelId]: isActive,
        },
      };
    }
    case SET_GATE_USER_AGE: {
      const userAge = action.payload;
      return {
        ...state,
        userAge,
      };
    }
    case SET_PASSWORD_ENTRY_VALID: {
      const { channelId, valid } = action.payload;
      return {
        ...state,
        passwordEntryValid: {
          ...state.passwordEntryValid,
          [channelId]: valid,
        },
      };
    }
    case SET_ACCESS_CODE_ENTRY_VALID: {
      const { valid } = action.payload;
      return {
        ...state,
        accessCodeEntryValid: valid,
      };
    }
    case SET_GATE_TRACKED: {
      const { action: actionString, docId } = action.payload;
      return {
        ...state,
        gateTracked: {
          ...state.gateTracked,
          [docId]: {
            ...state.gateTracked[docId],
            [actionString]: true,
          },
        },
      };
    }
    case SET_ACCOUNT_ENTITLEMENTS: {
      const accountEntitlements = action.payload;
      return {
        ...state,
        accountEntitlements,
      };
    }
    case UPDATE_ACCOUNT_ENTITLEMENT: {
      const { payload } = action;
      return {
        ...state,
        accountEntitlements: [
          ...state.accountEntitlements.filter((accountEntitlement) => accountEntitlement._id !== payload._id),
          payload,
        ],
      };
    }
    case SET_ACCOUNT_ENTITLEMENTS_LOADED: {
      return {
        ...state,
        accountEntitlementsLoaded: action.payload,
      };
    }
    case SET_AMAZON_BENEFIT_CHECK_GATE: {
      return {
        ...state,
        amazonBenefitGate: {
          ...state.amazonBenefitGate,
          hasCheckError: !action.payload.available,
          message: action.payload.message,
        },
      };
    }
    case SET_AMAZON_BENEFIT_REDEMPTION_GATE: {
      return {
        ...state,
        amazonBenefitGate: {
          ...state.amazonBenefitGate,
          hasRedemptionError: !action.payload.success,
          message: action.payload.message,
        },
      };
    }
    case CLEAR_AMAZON_BENEFIT_GATE_STATUS: {
    return {
        ...state,
        amazonBenefitGate: {
          hasCheckError: false,
          hasRedemptionError: false,
          message: null,
        },
      };
    }
    default:
      return state;
  }
};

export default gateReducer;
