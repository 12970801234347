export const INITIAL_STATE_CHECKOUT = {
  applied_discount: null,
  billing_address: {
    address1: '',
    address2: null,
    city: '',
    company: null,
    country: '',
    country_code: '',
    first_name: '',
    id: '',
    last_name: '',
    phone: '',
    province: '',
    province_code: '',
    zip: '',
  },
  completed_at: null,
  created_at: '',
  credit_card: null,
  currency: '',
  customer_id: '',
  customer_locale: '',
  device_id: null,
  discount_code: null,
  email: '',
  gift_cards: [],
  legal_notice_url: null,
  line_items: [
    {
      applied_discounts: [],
      compare_at_price: null,
      fulfillment_service: '',
      gift_card: false,
      grams: 0,
      id: '',
      image_url: '',
      key: '',
      line_price: '',
      price: '',
      product_id: '',
      properties: {},
      quantity: 0,
      requires_shipping: true,
      sku: '',
      taxable: true,
      title: '',
      variant_id: '',
      variant_title: '',
      vendor: '',
    },
  ],
  location_id: null,
  name: '',
  note_attributes: {},
  note: '',
  order_id: null,
  order_status_url: null,
  order: null,
  payment_due: '',
  payment_url: '',
  payments: [],
  phone: null,
  presentment_currency: '',
  privacy_policy_url: null,
  refund_policy_url: null,
  requires_shipping: true,
  reservation_time: null,
  reservation_time_left: 0,
  shipping_address: {
    address1: '',
    address2: null,
    city: '',
    company: null,
    country: '',
    country_code: '',
    first_name: '',
    id: '',
    last_name: '',
    phone: '',
    province: '',
    province_code: '',
    zip: '',
  },
  shipping_line: null,
  shipping_policy_url: null,
  shipping_rate: null,
  shopify_payments_account_id: '',
  source_identifier: null,
  source_name: '',
  source_url: null,
  subscription_policy_url: null,
  subtotal_price: '',
  tax_exempt: false,
  tax_lines: [],
  tax_manipulations: [],
  taxes_included: false,
  terms_of_sale_url: null,
  terms_of_service_url: null,
  token: '',
  total_line_items_price: '',
  total_price: '',
  total_tax: '',
  total_tip_received: '',
  updated_at: '',
  user_id: null,
  web_url: '',
};

export const INITIAL_STATE_SHIPPING_RATES = {
  checkout: {
    subtotal_price: '',
    total_price: '',
    total_tax: '',
  },
  delivery_range: null,
  handle: '',
  id: '',
  phone_required: false,
  price: '',
  title: '',
};

export const INITIAL_STATE_PAYMENT = {
  id: '',
  creditCard: {
    maskedNumber: '',
  },
  errorMessage: '',
  nextActionUrl: '',
  ready: false,
  test: false,
  transaction: {
    amount: {
      amount: 0.0,
      currencyCode: '',
    },
    kind: '',
    statusV2: '',
    test: false,
  },
};

export const INITIAL_STOREFRONT_CHECKOUT = {
  availableShippingRates: {
    ready: false,
    shippingRates: [],
  },
  completedAt: null,
  createdAt: '',
  currencyCode: '',
  customer_id: '',
  email: '',
  id: '',
  lineItems: [],
  lineItemsSubtotalPrice: '',
  note: '',
  order: {},
  orderStatusUrl: null,
  paymentDueV2: {
    amount: 0,
    currencyCode: '',
  },
  phone: null,
  ready: false,
  requiresShipping: false,
  shippingAddress: {},
  shippingLine: null,
  subtotalPriceV2: {
    amount: 0,
    currencyCode: '',
  },
  taxesIncluded: false,
  taxExempt: false,
  totalPriceV2: {
    amount: 0,
    currencyCode: '',
  },
  totalTaxV2: {
    amount: 0,
    currencyCode: '',
  },
  updatedAt: '',
  webUrl: '',
};

export const INITIAL_BILLING_ADDRESS = {
  address1: '',
  city: '',
  country: '',
  firstName: '',
  lastName: '',
  phone: '',
  province: '',
  zip: '',
};

/**
 * Shopify Adds this title to a variant if it doesn't have one.
 */
export const SHOPIFY_DEFAULT_TITLE = 'default title';
