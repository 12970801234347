import {
  FORTNITE_DUOS_LEADERBOARD_ID,
  FORTNITE_DUOS_MATCH_ID,
  FORTNITE_PLAY_BY_PLAY_ID,
  FORTNITE_SINGLES_MATCH_ID,
  PLAY_BY_PLAY_ID,
  PUBG_MATCH_ID,
  TIPS_ID,
} from 'components/objects/PanelV2/constants';
import camelCase from 'lodash/camelCase';

import {
  SEA_OF_THIEVES_KILL_PLAYER_ID,
  SEA_OF_THIEVES_KILL_SKELETON_ID,
  SEA_OF_THIEVES_VISIT_ISLAND_ID,
} from 'components/objects/Quest/QuestAction/constants';

// These are all objects that CAN be gated
// everything else enabled by default
export const Feature = {
  ADS: 'ads',
  ACCESS_CODE: 'accessCode',
  ACHIEVEMENTS: 'achievements',
  AMAZON_BENEFIT: 'amazonBenefit',
  APPLE_PAY: 'applePay',
  BILLING: 'billing',
  DEVELOPER: 'developer',
  EARNINGS: 'earnings',
  GENERATE_CAPTIONS: 'generateCaptions',
  GOOGLE_PAY: 'googlePay',
  HIDE_ADS_PLAYER: 'hideAdsPlayer',
  HIDE_COPYRIGHT: 'hideCopyright',
  HIDE_GDPR: 'hideGdpr',
  HIDE_MAESTRO_LOGO: 'hideMaestroLogo',
  INSIGHTS_ANALYTICS: 'insightsAnalytics',
  MAESTRO_SUPPORT: 'maestroSupport',
  SECURE_STREAMS: 'secureStreams',
  SECURE_VODS: 'secureVods',
  SHOPIFY: 'shopify',
  STREAMING: 'streaming',
  STREAM_PROVIDER_ACCOUNT: 'streamProviderAccount',
  STUDIO_STREAM: 'studioStream',
  VOD_UPLOADS: 'vodUploads',
  PAGES_V3: 'pagesV3',
  END_USER_LOCALIZATION: 'endUserLocalization',
  HIDE_THIRD_PARTY_TAB: 'hideThirdPartyTab',
  HIDE_ADMINS_TAB: 'hideAdminsTab',
  HIDE_ICONS_TAB: 'hideIconsTab',
  HIDE_LOGIN_TAB: 'hideLoginTab',
  HIDE_RECEIPTS_TAB: 'hideReceiptsTab',
  HIDE_USER_PROFILES_TAB: 'hideUserProfilesTab',
  HIDE_VIDEO_PLAYER_TAB: 'hideVideoPlayerTab',
  SIMPLE_MODE: 'simpleMode',
  RESET_STREAM_KEY: 'resetStreamKey',
  RICH_TEXT_EDITOR_V3: 'richTextEditorV3',
  FORTNITE_SINGLES_LEADERBOARD_V2: 'fortniteSinglesLeaderboardV2',
  QUESTS_BLOCK: 'questsBlock',
  STATIC_RENDERING: 'staticRendering',
  DISABLE_GTM: 'disableGtm',
  VIDEOGAME_STREAMS: 'videogameStreams',
};

// used for toggling multiple pieces of a feature with one toggle
export const FeaturesWithPanels = [
  Feature.SHOPIFY,
];

// used for toggling multiple pieces of a feature with one toggle
export const FeaturesWithOverlays = [
  Feature.SHOPIFY,
];

export const Panel = {
  FORTNITE_DUOS_LEADERBOARD: FORTNITE_DUOS_LEADERBOARD_ID,
  FORTNITE_DUOS_MATCH: FORTNITE_DUOS_MATCH_ID,
  FORTNITE_PLAY_BY_PLAY: FORTNITE_PLAY_BY_PLAY_ID,
  FORTNITE_SINGLES_MATCH: FORTNITE_SINGLES_MATCH_ID,
  PLAY_BY_PLAY: PLAY_BY_PLAY_ID,
  PUBG_MATCH: PUBG_MATCH_ID,
  TIPS: TIPS_ID,
};

// using camel case here because quest actions have snake case.
export const QuestActions = {
  SEA_OF_THIEVES_KILL_PLAYER: camelCase(SEA_OF_THIEVES_KILL_PLAYER_ID),
  SEA_OF_THIEVES_KILL_SKELETON: camelCase(SEA_OF_THIEVES_KILL_SKELETON_ID),
  SEA_OF_THIEVES_VISIT_ISLAND: camelCase(SEA_OF_THIEVES_VISIT_ISLAND_ID),
};

export const Overlay = {
  HIGHLIGHT: 'highlight',
};
