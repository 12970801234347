import { makeAction } from 'redux-utils';
import { MetadataStatus } from './constants';

export const SET_LIVESTREAM_ERROR = 'livestream/SET_LIVESTREAM_ERROR';
export const SET_LIVESTREAM_METADATA = 'livestream/SET_LIVESTREAM_METADATA';
export const SET_METADATA_STATUS = 'livestream/SET_METADATA_STATUS';
export const SET_STUDIO_SESSION_READY = 'livestream/SET_STUDIO_SESSION_READY';
export const REGISTER_STUDIO_IFRAME = 'livestream/REGISTER_STUDIO_IFRAME';
export const SET_STUDIO_GUEST_MODE_INVITE_CODE = 'livestream/SET_STUDIO_GUEST_MODE_INVITE_CODE';
export const MANUALLY_REQUEST_NEW_STREAM = 'livestream/MANUALLY_REQUEST_NEW_STREAM';
export const INVITE_GUEST_TO_STUDIO = 'livestream/INVITE_GUEST_TO_STUDIO';
export const CLEAR_PENDING_STUDIO_GUEST_INVITATION = 'livestream/CLEAR_PENDING_STUDIO_GUEST_INVITATION';

export const setLivestreamError = makeAction(
  SET_LIVESTREAM_ERROR,
  (value: null | number) => ({
    payload: value,
  }),
);

export const setLivestreamMetadata = makeAction(
  SET_LIVESTREAM_METADATA,
  (metadata: {
    thumbnail: string | null,
    title: string,
  }) => ({
    payload: metadata,
  }),
);

export const setMetadataStatus = makeAction(
  SET_METADATA_STATUS,
  (status: MetadataStatus | null) => ({
    payload: status,
  }),
);

// maestro stream has been added as output destination
export const setStudioSessionReady = makeAction(
  SET_STUDIO_SESSION_READY,
  (ready: boolean) => ({
    payload: ready,
  }),
);

export const registerStudioIframe = makeAction(
  REGISTER_STUDIO_IFRAME,
  (iframe: HTMLIFrameElement | null) => ({
    payload: iframe,
  }),
);

export const setStudioGuestModeInviteCode = makeAction(
  SET_STUDIO_GUEST_MODE_INVITE_CODE,
  (inviteCode: string | null) => ({
    payload: inviteCode,
  }),
);

export const requestNewStream = makeAction(MANUALLY_REQUEST_NEW_STREAM);

export const inviteGuestToStudio = makeAction(
  INVITE_GUEST_TO_STUDIO,
  (accountId: string) => ({
    payload: accountId,
  }),
);

export const clearPendingStudioGuestInvitation = makeAction(CLEAR_PENDING_STUDIO_GUEST_INVITATION);
