import moment from 'moment-timezone';
import range from 'lodash/range';
import { padZero } from 'shared/string-utils';

export const msIn = {
  day: 24 * 60 * 60 * 1000,
  hour: 60 * 60 * 1000,
  minute: 60 * 1000,
  second: 1000,
};

export const HOUR_OPTIONS_12 = range(1, 13).map((hour) => ({ label: padZero(hour), value: hour }));

export const MINUTE_OPTIONS = range(0, 60).map((minute) => ({ label: padZero(minute), value: minute }));

export const getMinuteOptionsFractionated = (fraction: number) => {
  return MINUTE_OPTIONS.filter((minute) => minute.value % fraction === 0);
};

export const PERIOD_OPTIONS = [
  {
    label: 'AM',
    value: 'am',
  },
  {
    label: 'PM',
    value: 'pm',
  },
];

export const to24Hours = (hour: number, period: string) => {
  if (period === 'am') {
    return hour < 12 ? hour : 0;
  }
  return hour < 12 ? hour + 12 : 12;
};

export const to12Hours = (hour: number) => {
  return hour < 12 ? { hour: hour || 12, period: 'am' } : { hour: (hour - 12) || 12, period: 'pm' };
};

export const parseTime = (time: string) => {
  const date = moment(time, ['h:m:s a', 'H:m:s']);
  return {
    hour: date.hours() || 0,
    minute: date.minutes() || 0,
    second: date.seconds() || 0,
  };
};
