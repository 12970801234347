import {
  ADD_OVERLAY,
  ADD_PREVIEW_OVERLAY,
  DECREASE_TIME,
  EDIT_OVERLAY,
  ENTER_RAFFLE_FAILURE,
  ENTER_RAFFLE_REQUEST,
  ENTER_RAFFLE_SUCCESS,
  REMOVE_OVERLAY,
  REMOVE_PREVIEW_OVERLAY,
  UPDATE_OVERLAY,
  UPDATE_PENDING_STATUS,
} from './actions';

const INITIAL_STATE = {
  editing: null,
  isPending: false,
  list: [] as any[],
  preview: null,
};

export type IOverlaysState = typeof INITIAL_STATE;

export const getInitialState = () => ({
  ...INITIAL_STATE,
});

const RAFFLE_KIND_ORDER = [
  'raffle',
  'raffle_active',
  'raffle_picking',
  'raffle_winner',
];

const RAFFLE_STATUS_MAP = {
  [ENTER_RAFFLE_FAILURE]: 'failure',
  [ENTER_RAFFLE_REQUEST]: 'request',
  [ENTER_RAFFLE_SUCCESS]: 'success',
};

const overlayReducer = (state = INITIAL_STATE, { payload, type }) => {
  switch (type) {
    case REMOVE_OVERLAY: {
      return {
        ...state,
        list: state.list.filter(item => item.key !== payload),
        preview: null,
      };
    }

    case EDIT_OVERLAY: {
      return {
        ...state,
        editing: payload,
      };
    }

    case ADD_PREVIEW_OVERLAY: {
      return {
        ...state,
        editing: null,
        preview: payload,
      };
    }

    case REMOVE_PREVIEW_OVERLAY: {
      return {
        ...state,
        preview: null,
      };
    }

    case DECREASE_TIME: {
      const nextList = state.list.slice();
      const index = nextList.findIndex(item => (
        item.key === payload
      ));

      if (nextList[index]) {
        nextList[index] = {
          ...nextList[index],
          timeRemaining: nextList[index].timeRemaining - 1,
        };
      }
      return {
        ...state,
        list: nextList,
      };
    }

    case ADD_OVERLAY: {
      const overlay = payload;
      const { data = {} } = overlay.payload;
      const { broadcastId, kind } = data;
      let list = [...state.list, overlay];

      // TODO: we should clean this up
      const raffleKindIndex = RAFFLE_KIND_ORDER.indexOf(kind);
      if (raffleKindIndex !== -1) {
        const kindsToRemove = RAFFLE_KIND_ORDER.slice(0, raffleKindIndex);
        list = list.filter((currentOverlay) => {
          const { data: currentData } = currentOverlay.payload;
          return currentData?.broadcastId !== broadcastId ||
            kindsToRemove.indexOf(currentData?.kind) < 0;
        });
      }

      return {
        ...state,
        list: list.slice(-1), // we blow away the others for now
      };
    }

    case UPDATE_OVERLAY: {
      const { broadcastId, overlayData } = payload;
      const list = state.list.map((item) => {
        if (item.payload.data.broadcastId !== broadcastId) {
          return item;
        }

        return {
          ...item,
          ...overlayData,
        };
      });

      return {
        ...state,
        list,
      };
    }

    case ENTER_RAFFLE_REQUEST:
    case ENTER_RAFFLE_SUCCESS:
    case ENTER_RAFFLE_FAILURE: {
      const enteredBroadcastId = payload;
      const raffleStatus = RAFFLE_STATUS_MAP[type];
      const list = state.list.map((overlay) => {
        if (overlay.payload.data.broadcastId === enteredBroadcastId) {
          return {
            ...overlay,
            raffleStatus,
          };
        }
        return overlay;
      });
      return {
        ...state,
        list,
      };
    }

    case UPDATE_PENDING_STATUS: {
      return {
        ...state,
        isPending: payload,
      };
    }

    default:
      return state;
  }
};

export default overlayReducer;
