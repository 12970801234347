import path from 'path';
import axios  from 'axios';
import invariant from 'invariant';
import { StreamSource } from './constants';
import {
  SITE_ID,
  VIDEO_SERVICE_BASE_URL,
  STREAM_SERVICE_BASE_URL,
  STREAM_PROVIDER_SERVICE_BASE_URL,
} from 'config';
import IStream from 'models/IStream';
import { Sensitive } from 'models/ISensitive';
import { IStreamProviderAccount } from 'models/IStreamProviderAccount';
import maestroApiRequest from 'services/maestro-api-client';

interface IBaseParams {
  maestroStreamId?: string;
  token: string;
}

export interface StreamAdditionalData {
  goLiveTime?: number;
  insights: unknown;
  source: StreamSource;
}

export type RecordingResp = { status: 'ok' | 'failed' };

export const startStream = async (
  {
    maestroStreamId,
    source,
    token,
    insights,
    goLiveTime,
  }: IBaseParams & StreamAdditionalData,
) => {
  invariant(maestroStreamId, 'missing stream id');
  const url = `${STREAM_SERVICE_BASE_URL}${path.join('/start/', maestroStreamId)}`;
  const headers = {
    Authorization: `Bearer ${token}`,
    'x-maestro-client-id': SITE_ID,
  };
  const response = await axios.post<RecordingResp>(
    url,
    {
      goLiveTime,
      insights,
      source,
    },
    { headers },
  );
  return response.data;
};

export const stopStream = async (
  {
    maestroStreamId,
    source,
    token,
  }: IBaseParams & Pick<StreamAdditionalData, 'source'>,
): Promise<RecordingResp> => {
  invariant(maestroStreamId, 'missing stream id');
  const url = `${STREAM_SERVICE_BASE_URL}${path.join('/stop/', maestroStreamId)}`;
  const headers = {
    Authorization: `Bearer ${token}`,
    'content-type': 'application/json; charset=utf-8',
    'x-maestro-client-id': SITE_ID,
  };
  const response = await axios.post(
    url,
    { source },
    { headers },
  );
  return response.data;
};

export interface IAddStreamMetadataParams {
  thumbnail: string | null,
  title: string,
  token: string,
}

interface IProviderStreamMetadata {
  liveBroadcastId: string,
}

export const postProviderStreamMetadata = async (
  {
    liveBroadcastId,
    title,
    thumbnail,
    token,
  }:
    IAddStreamMetadataParams &
    IProviderStreamMetadata,
): Promise<void> => {
  const endpoint = `${VIDEO_SERVICE_BASE_URL}/metadata/${liveBroadcastId}`;
  await axios.post(
    endpoint,
    {
      title,
      thumbnail,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'x-maestro-client-id': SITE_ID,
      },
    },
  );
};

type StudioParams = {
  channelId: string;
  sessionId: string;
  token: string;
};

export const updateStudioSessionId = async (
  {
    channelId,
    sessionId,
    token,
  }: StudioParams,
) => {
  await axios.post(
    `${STREAM_SERVICE_BASE_URL}/studio/session`,
    {
      channelId,
      sessionId,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'x-maestro-client-id': SITE_ID,
      },
    },
  );
};

export const registerProviderAccount = async (
  req: {
    credentials: Record<string, string>;
    providerName: string;
  },
  token: string,
) => {
  const { data } = await axios.post(
    `${STREAM_PROVIDER_SERVICE_BASE_URL}/account`,
    req,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'x-maestro-client-id': SITE_ID,
      },
    },
  );
  return data;
};

export const testProviderAccount = async (
  req: {
    credentials: Record<string, string>;
    providerName: string;
  },
  token: string,
) => {
  const { data } = await axios.post(
    `${STREAM_PROVIDER_SERVICE_BASE_URL}/verifyAccount`,
    req,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'x-maestro-client-id': SITE_ID,
      },
    },
  );

  if (!data?.result)
    throw new Error('Validation failed');

  return data;
};

export const listUserAccountProviders = async () => {
  type TResponse = {
    results: {
      accountCredentials: Record<string, string>;
      name: string;
    }[];
  };

  const { data } = await axios.get<TResponse>(
    `${STREAM_PROVIDER_SERVICE_BASE_URL}/canUseAccount`,
    {
      headers: {
        'x-maestro-client-id': SITE_ID,
      },
    },
  );

  return data.results;
};

export const activateStream = async (
  {
    maestroStreamId,
    token,
  }: IBaseParams,
) => {
  await axios.post(
    `${STREAM_SERVICE_BASE_URL}/activate/${maestroStreamId}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'x-maestro-client-id': SITE_ID,
      },
    },
  );
};

export const getSecurePlaybackUrl = async (
  {
    maestroStreamId,
    token,
  }: IBaseParams,
) => {
  const { data } = await axios.get<{ signedUrl: string }>(
    `${STREAM_SERVICE_BASE_URL}/secureUrl/${maestroStreamId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'x-maestro-client-id': SITE_ID,
      },
    },
  );
  return data.signedUrl;
};

export const createChannelStream = async (
  {
    channelId,
    token,
  }: {
    channelId: string,
    token: string,
  },
) => {
  await axios.post(
    `${STREAM_SERVICE_BASE_URL}/create/${channelId}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'x-maestro-client-id': SITE_ID,
      },
    },
  );
};

export const decryptStreamData = async (
  streamId: string,
  token: string,
  encryptedData: Sensitive,
) => {
  const { data } = await axios.post<Pick<IStream, 'ingestUrl' | 'streamKey' | 'playbackUrl'>>(
    `${STREAM_SERVICE_BASE_URL}/decrypt/${streamId}`,
    encryptedData,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'x-maestro-client-id': SITE_ID,
      },
    },
  );

  return data;
};

export const decryptProviderAccountData = async (
  accountId: string,
  token: string,
  encryptedData: Sensitive,
) => {
  const { data } = await axios.post<IStreamProviderAccount['credentials']>(
    `${STREAM_PROVIDER_SERVICE_BASE_URL}/account/decrypt/${accountId}`,
    encryptedData,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'x-maestro-client-id': SITE_ID,
      },
    },
  );

  return data;
};

export const pollStream = async ({
  providerStreamId,
  token,
  provider,
}: {
  provider: string;
  providerStreamId: string;
  token: string;
}) => {
  await maestroApiRequest({
    primaryToken: token,
    siteId: SITE_ID,
  }).post(
    `${STREAM_SERVICE_BASE_URL}/poll/${providerStreamId}`,
    { provider },
  );
};
