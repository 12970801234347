import {
  getProviderId,
  getThirdPartyAuthProviders,
  getUserService,
  isUserChatModeratorRole,
  isUserAdmin,
} from 'services/auth/selectors';
import { OauthProvider } from 'services/auth/actions';
import { createSelector } from 'reselect';
import { UBISOFT_APP_ID } from 'config';
import IState from '../state';

import defaultPerson from 'assets/default-person.svg';

import { getSiteProfileImages } from 'services/app/selectors/common';

export const getUserProfileState = createSelector((state:IState)=>state.userProfile,(profile)=> profile);

export const getUserProfileIcon = (state: IState) => state.userProfile.icon;

export const isActiveIcon = (state: IState) => {
  const avatar = state.userProfile.icon;
  const currentProfileImages = getSiteProfileImages(state);

  for (const i in currentProfileImages) {
    if (currentProfileImages[i].url === avatar &&
      (!currentProfileImages[i].isAdmin || isUserAdmin(state) || isUserChatModeratorRole(state))) {
      return true;
    }
  }
  return false;
};

export const getUserAccountId = (state: IState) => {
  return state.userProfile.accountId;
};

export const getUserAvatar = (state:IState)=>{
  const authProviders = getThirdPartyAuthProviders(state);
  if(authProviders && getUserService(state) === OauthProvider.UBISOFT){
    const userId = getProviderId(authProviders, OauthProvider.UBISOFT);
    if(userId) return `https://ubisoft-avatars.akamaized.net/${userId}/default_256_256.png?appId=${UBISOFT_APP_ID}`;
  } else if (authProviders && OauthProvider.GETTR.includes(getUserService(state) || '')) {
    return getUserProfileIcon(state);
  }

  if(isActiveIcon(state)) return getUserProfileIcon(state);
  return defaultPerson;
};

export const getUserName = (state: IState) => {
  const userProfileName = state.userProfile.name;
  if (userProfileName) { return userProfileName; } else {
    return state.auth.account ? state.auth.account.name : '';
  }
};

export const getPasswordUpdateState = (state: IState) => {
  return state.userProfile.passwordUpdateState || null;
};

export const getExpandedStorageId = (state:IState)=> `${getUserAccountId(state)}:adminbar:expanded`;
