// tslint:disable:no-console
import { makeSaga } from 'redux-utils';
import set from 'lodash/set';
import { IState } from 'services/state';
import { Channel } from 'redux-saga';
import {
  call,
  put,
  select,
  take,
} from 'redux-saga/effects';
import { setObject } from 'services/app/actions';
import { replace } from 'services/app-router';
import { getPrimaryToken, isUserAdmin } from 'services/auth/selectors';
import { getObject } from 'services/app';
import { getCurrentChannelId } from 'services/app/selectors';
import {
  createPostMessageEmbedChannel,
  IFrameMessageSource,
  MaestroStudioActions,
  IMaestroStudioMessage,
} from 'services/iframe';
import { getPendingStudioGuestInviteAccountId, getStudioSessionId, isPublishedWithStudioStream } from '../selectors';
import { clearPendingStudioGuestInvitation, setStudioSessionReady, setLivestreamError } from '../actions';
import { updateStudioSessionId } from '../api';
import { IObject } from 'models';
import cloneDeep from 'lodash/cloneDeep';
import { setStudioGuestModeInviteCode } from 'services/livestream/actions';
import { sendNotification } from 'services/notification/api';
import { goOfflineWithMaestroLivestream } from 'services/admin/actions';
import { ModalKinds } from 'services/modals/types';
import { showModal } from 'services/modals';

export const maestroStudioMessageSaga = makeSaga({}, function* () {
  const embedChannel: Channel<IMaestroStudioMessage> = yield call(
    createPostMessageEmbedChannel,
    IFrameMessageSource.MAESTRO_STUDIO,
  );

  while (true) {
    try {
      const { data: response } = yield take(embedChannel);
      const state: IState = yield select();
      const { action } = response;
      const token = getPrimaryToken(state);
      const channelId = getCurrentChannelId(state);
      const isAdminOrProducer = isUserAdmin(state);

      switch (action) {
        case MaestroStudioActions.STUDIO_SESSION_STALLED: {
          if (isPublishedWithStudioStream(state) && isAdminOrProducer) {
            yield put(setLivestreamError(Date.now()));
            yield put(goOfflineWithMaestroLivestream());
            yield put(
              showModal({
                kind: ModalKinds.adminError,
                data: {
                  errorKey: 'STUDIO_STREAM_STALLED_ERROR_MESSAGE',
                },
              }),
            );
          }
          break;
        }
        case MaestroStudioActions.END_STUDIO_SESSION_ACTION: {
          yield put(setStudioGuestModeInviteCode(null));
          yield put(replace({ query: { studio_invite: undefined } }));
          break;
        }
        case MaestroStudioActions.STUDIO_SESSION_READY_ACTION: {
          if (!token || !channelId) {
            return;
          }

          const { sessionId } = response;
          const currentStudioSessionId = getStudioSessionId(state);

          if (currentStudioSessionId !== sessionId) {
            const object = getObject(state) as IObject;
            const newObject = cloneDeep(object);
            set(newObject, 'renderers.livestream.studioSessionId', sessionId);
            yield put(setObject(newObject, true));
            yield call(updateStudioSessionId, { token, sessionId, channelId });
          }

          yield put(setStudioSessionReady(true));
          break;
        }
        case MaestroStudioActions.STUDIO_SESSION_NOT_READY_ACTION: {
          yield put(setStudioSessionReady(false));
          break;
        }
        case MaestroStudioActions.STUDIO_INVITE_CODE_AVAILABLE: {
          if (!token || !channelId) {
            return;
          }

          const { inviteCode } = response;
          const guestAccountId = getPendingStudioGuestInviteAccountId(state);

          if (guestAccountId) {
            yield call(
              sendNotification,
              token,
              guestAccountId,
              {
                type: 'joinStudioStageInvite',
                studioInviteCode: inviteCode,
              },
              Date.now() + 5 * 60 * 1000, // invite expires five minutes from now
            );
            yield put(clearPendingStudioGuestInvitation());
          }

          break;
        }
        default: {
          break;
        }
      }
    } catch (error) {
      console.error('maestroStudioMessageSaga:', error);
    }
  }
});
