import { DEV_MODE, INITIAL_LOCALE } from 'config';
import {
  Clients,
  IUbisoftAdobe,
  UbisoftEnvironmentType,
} from './models';
import {
  INITIALIZE_UBISOFT,
  IThirdPartyAnalyticsAction,
  SET_ACTIVE,
  UPDATE_UBISOFT,
} from './actions';

interface IUbisoftState {
  active: boolean;
  client: Clients.Ubisoft;
  data: IUbisoftAdobe;
}

interface IInitialState {
  active: boolean;
  client: null;
  data: {};
}

export type IThirdPartyAnalyticsState = IInitialState | IUbisoftState;

export const getInitialState = (): IThirdPartyAnalyticsState => ({
  active: false,
  client: null,
  data: {},
});

const thirdPartyAnalyticsReducer = (
  state: IThirdPartyAnalyticsState = getInitialState(),
  action: IThirdPartyAnalyticsAction,
): IThirdPartyAnalyticsState => {
  switch (action.type) {
    case INITIALIZE_UBISOFT: {
      return {
        ...state,
        client: Clients.Ubisoft,
        data: {
          enablePrivacy: true,
          environment: DEV_MODE ? UbisoftEnvironmentType.uat : UbisoftEnvironmentType.prod,
          siteName: 'ubisoft live',
          siteType: 'maestro',
          hostDomain: 'maestro',
          language: String(INITIAL_LOCALE).toUpperCase(),
          loggedIn: false,
          loggedOut: false,
        },
      };
    }
    case SET_ACTIVE: {
      return {
        ...state,
        active: action.payload,
      };
    }
    case UPDATE_UBISOFT: {
      if (state.client !== Clients.Ubisoft) {
        return state;
      }
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
        },
      };
    }
    default: {
      return state;
    }
  }
};

export default thirdPartyAnalyticsReducer;
