import { call, takeEvery, select, put, delay, takeLatest } from 'redux-saga/effects';
import IState from 'services/state';
import {
  MOUNT_FLOW_STEPS,
  IMountFlowStepsAction,
  advanceSteps,
  resetWizardFlow,
  setFlowSteps,
  setIsWizardLoading,
  setWizardFlowType,
  EXECUTE_WIZARD,
  IExecuteWizardAction,
  IExecuteWizardPayload,
  setChannelData,
} from './actions';
import { getCurrentFlow, getCurrentStep, getDraftChannelData } from './selectors';
import { FlowSteps, WizardFlowTypes } from './models';
import { dismissModal, showModal } from 'services/modals';
import { ModalKinds } from 'services/modals/types';
import { getPrimaryToken } from 'services/auth';
import {
  createWizardChannel,
  createWizardPanels,
  createWizardSubscription,
  getAmountOfChannels,
  getSitePanelById,
} from './api';
import {
  BasePanelData,
  BaseSiteData,
  createBaseChannelObject,
  createBaseGateObject,
  createBasePanelsObject,
  updateSiteStructure,
} from './utils';
import { getSiteId } from 'services/app';
import { replace } from 'services/app-router';
import {
  setPreviewPanelRenderer,
  setCurrentSidebarPage,
  setActiveAction,
} from 'services/admin';
import { getRenderer } from 'services/renderer/selectors';
import shortid from 'shortid';
import { camelify } from 'shared/string-utils';
import { trackEvent } from 'services/segment-analytics';
import {
  AdminActionEvents,
  ITrackChannelPublishedEventData,
  ITrackPagePublishedEventData,
} from 'services/admin/models';
import { IObject } from 'models';
import ISubscription from 'models/ISubscription';
import { createSlugFromString } from 'utils';
import { IAccessTicketCreatedData } from 'hooks/use-track-ticket-created';
import { IAccessSubscriptionCreatedData } from 'hooks/use-track-subscription-created';
import { ActionKey } from 'services/admin/constants';
import { setActiveActionType, enablePanels, navigateToAdminBarAction, setRegionRendererDraft, publishPagePendingChanges } from 'services/admin/actions';
import { ActionType } from 'components/admin-bridge/AdminBar/constants';
import { ID_SIDEBAR } from 'services/renderer';
import { applyTheme } from 'services/themes';
import { siteCanUseEntitlementGate } from 'services/admin/api';
import { getIsLegacyPlan, getIsUnlimitedPlan, getPlanFeatures } from 'services/billing';
import { MAX_AMOUNT_OF_CHANNELS, MAX_AMOUNT_OF_PAGES } from 'models/IPlan';
import { getDefaultNavigation, updateNavigation } from 'services/navigationv2';
import { INavigationParent } from 'models/INavigation';
import { DEFAULT_SHOPIFY } from 'components/page-blocks/Shopify/utils';
import { SHOPIFY_BLOCK_PANEL_ID } from 'components/objects/PanelV2/constants';

export const CHAT_PANEL_RENDERER = {
  icon_name: 'chat',
  panel_name: 'Chat',
  panel_type: 'chat',
};

export const SHOPIFY_PANEL_RENDERER = {
  panel_type: SHOPIFY_BLOCK_PANEL_ID,
  icon_name: 'shopify',
  block_data: DEFAULT_SHOPIFY,
};

export function* setWizardFlowSaga({ payload }: IMountFlowStepsAction) {
  const state: IState = yield select();
  const flowType = payload;
  yield put(setWizardFlowType(flowType));
  const primaryToken = getPrimaryToken(state);
  const planFeatures = getPlanFeatures(state);
  const isLegacyPlan = getIsLegacyPlan(state);
  const isUnlimitedPlan = getIsUnlimitedPlan(state);
  const maxAmountOfPages = planFeatures?.maxAmountOfPages || MAX_AMOUNT_OF_PAGES;
  const maxAmountOfChannels = planFeatures?.maxAmountOfChannels || MAX_AMOUNT_OF_CHANNELS;
  const planHavePageLimits = !isLegacyPlan && !isUnlimitedPlan;
  const amountOfChannels = yield call(getAmountOfChannels, { primaryToken });
  const isSkipFlow = [WizardFlowTypes.I_DONT_WANNNA_MAKE_MONEY, WizardFlowTypes.EXPLORE_ON_MY_OWN].includes(flowType);
  const haveExceededChannelLimit =
  flowType === WizardFlowTypes.SUBSCRIPTION_VOD
    ? amountOfChannels.pages >= maxAmountOfPages
    : amountOfChannels.channels >= maxAmountOfChannels;

  /*
  * Pricing V2: If the user is on a plan that has page limits and they have reached the limit,
  * show the upgrade plan modal and halt channel creation.
  */
  if (!isSkipFlow && planHavePageLimits && haveExceededChannelLimit) {
    yield put(showModal({
      kind: ModalKinds.upgradePlan,
      data: {
        planWarningMessage: 'ADMIN_UPGRADE_PLAN_EXCEEDED_MAX_AMOUNT_OF_CHANNELS',
      },
    }));
    return;
  }

  switch (flowType) {
    case WizardFlowTypes.SUBSCRIPTION_VOD: {
      yield put(
        setFlowSteps([
          FlowSteps.SET_UP_SUBSCRIPTION_VOD,
          FlowSteps.PICK_A_THEME,
        ]),
      );
      yield put(advanceSteps());
      break;
    }
    case WizardFlowTypes.LIVE_SHOPPING: {

      yield put(
        setFlowSteps([
          ...(amountOfChannels.total > 1 ? [FlowSteps.SELECT_CHANNEL] : []),
          FlowSteps.PICK_A_THEME,
        ]),
      );
      yield put(advanceSteps());
      break;
    }
    case WizardFlowTypes.SUBSCRIBER_ONLY_LIVESTREAMS: {
      yield put(
        setFlowSteps([
          FlowSteps.SET_UP_SUBSCRIPTION_ONLY_LIVESTREAM,
          FlowSteps.PICK_A_THEME,
        ]),
      );
      yield put(advanceSteps());
      break;
    }
    case WizardFlowTypes.TICKETED_EVENT: {
      yield put(
        setFlowSteps([
          FlowSteps.SET_UP_TICKETED_EVENT,
          FlowSteps.PICK_A_THEME,
        ]),
      );
      yield put(advanceSteps());
      break;
    }
    case WizardFlowTypes.I_DONT_WANNNA_MAKE_MONEY:
    case WizardFlowTypes.EXPLORE_ON_MY_OWN: {
      yield put(setActiveAction(null));
      yield put(dismissModal(ModalKinds.wizardMain));
      yield put(showModal({ kind: ModalKinds.wizardEnd }));
      break;
    }
  }
}

export function* setupSubscriptionVodChannelSaga(payload: IExecuteWizardPayload) {
  const { name, subscriptionAmount, subscriptionName, themeId, siteStructure } = payload;
  const siteId: string = yield select(getSiteId);
  const primaryToken: string = yield select(getPrimaryToken);

  if (!subscriptionName || !subscriptionAmount) {
    throw new Error('Missing subscription parameters');
  }
  const sku: string = yield call(createSlugFromString, subscriptionName);

  yield call(siteCanUseEntitlementGate, primaryToken, siteId);
  const subscriptions: ISubscription = yield call(createWizardSubscription, {
    subscriptionName,
    primaryToken,
    sku,
    subscriptionAmount,
    subscriptionType: 'subscription',
  });

  const accessSubscriptionCreatedData: IAccessSubscriptionCreatedData = {
    additionalPrices: [],
    billingCycle: 'annually',
    defaultCurrency: 'usd',
    defaultPrice: String(subscriptionAmount),
    sku,
    subscriptionName,
  };
  yield put(trackEvent({
    event: AdminActionEvents.ACCESS_SUBSCRIPTION_CREATED,
    properties: accessSubscriptionCreatedData,
  }));

  const subscriberGate: ReturnType<typeof createBaseGateObject> = yield call(
    createBaseGateObject,
    {
      subscriptions,
      channelName: name,
      eventDate: null,
    },
  );

  const baseChannelObject: BaseSiteData = yield call(
    createBaseChannelObject,
    {
      channelName: name,
      channelType: 'landing',
      gate: subscriberGate,
      siteId,
      themeId,
    },
  );

  yield call(createWizardChannel, {
    objectId: baseChannelObject._id,
    primaryToken,
    siteId,
    value: baseChannelObject,
  });

  // append the channel to the site structure
  const updatedSiteStructure = yield call(updateSiteStructure, { siteStructure: siteStructure!, page: baseChannelObject });
  yield put(updateNavigation(updatedSiteStructure));

  const properties: ITrackPagePublishedEventData = {
    channelId: baseChannelObject._id,
    channelName: name,
    newPage: true,
  };
  const currentStep: number = yield select(getCurrentStep);

  yield put(
    trackEvent({
      event: AdminActionEvents.PAGE_PUBLISHED,
      properties,
    }),
  );

  yield put(
    trackEvent({
      event: AdminActionEvents.WIZARD_FORWARD_STEP,
      properties: { flow: WizardFlowTypes.SUBSCRIPTION_VOD, currentStep },
    }),
  );
}

export function* setupSubscriberOnlyChannelSaga(payload: IExecuteWizardPayload) {
  const { name, subscriptionAmount, subscriptionName, themeId, siteStructure } = payload;
  const siteId: string = yield select(getSiteId);
  const primaryToken: string = yield select(getPrimaryToken);

  const sku: string = yield call(createSlugFromString, name);

  yield call(siteCanUseEntitlementGate, primaryToken, siteId);
  // @ts-ignore
  const subscriptions = yield call(createWizardSubscription, {
    subscriptionName,
    primaryToken,
    sku,
    subscriptionAmount,
    subscriptionType: 'subscription',
  });

  const subscriberGate: ReturnType<typeof createBaseGateObject> = yield call(createBaseGateObject, {
    subscriptions,
    channelName: name,
    eventDate: null,
  });

  const chatPanelBaseObject: BasePanelData = yield call(createBasePanelsObject, {
    renderer: CHAT_PANEL_RENDERER,
    siteId,
  });
  yield call(createWizardPanels, {
    objectId: chatPanelBaseObject._id,
    primaryToken,
    siteId,
    value: chatPanelBaseObject,
  });

  const baseChannelObject: BaseSiteData = yield call(createBaseChannelObject, {
    channelName: name,
    channelType: 'channel',
    gate: subscriberGate,
    panels: [chatPanelBaseObject],
    siteId,
    themeId,
  });

  yield call(createWizardChannel, {
    objectId: baseChannelObject._id,
    primaryToken,
    siteId,
    value: baseChannelObject,
  });

  // append the channel to the site structure
  const updatedSiteStructure = yield call(updateSiteStructure, { siteStructure: siteStructure!, page: baseChannelObject });
  yield put(updateNavigation(updatedSiteStructure));

  const channelPublishedData: ITrackChannelPublishedEventData = {
    channelId: baseChannelObject._id,
    channelName: name,
    newPage: true,
  };

  const currentStep: number = yield select(getCurrentStep);

  yield put(
    trackEvent({
      event: AdminActionEvents.CHANNEL_PUBLISHED,
      properties: channelPublishedData,
    }),
  );
  yield put(
    trackEvent({
      event: AdminActionEvents.WIZARD_FORWARD_STEP,
      properties: { flow: WizardFlowTypes.SUBSCRIBER_ONLY_LIVESTREAMS, currentStep },
    }),
  );
}

export function* setupLiveShoppingSaga() {
  const state: IState = yield select();
  yield delay(1000);

  const primaryToken = getPrimaryToken(state) || '';
  const siteId: string = getSiteId(state);

  const renderer = getRenderer<Record<string, any>>(state, ID_SIDEBAR);
  const { items: oldPanels } = renderer || {};
  const currentPanels = oldPanels || [];

  let shopifyPanelRenderer = SHOPIFY_PANEL_RENDERER;
  let hasShopifyPanel = false;

  // if current channel has panels, check if one of them is shopify
  for (const panel of currentPanels) {
    // @ts-ignore
    const result: any = yield call(getSitePanelById, {
      primaryToken,
      id: panel.id,
    });

    if (result?.renderer?.panel_type === SHOPIFY_BLOCK_PANEL_ID) {
      hasShopifyPanel = true;
      shopifyPanelRenderer = result.renderer;
      break;
    }
  }

  // Create a brand new Shopify panel
  if (!hasShopifyPanel) {
    const shopifyPanelBaseObject: BasePanelData = yield call(createBasePanelsObject, {
      renderer: SHOPIFY_PANEL_RENDERER,
      siteId,
    });
    yield call(createWizardPanels, {
      objectId: shopifyPanelBaseObject._id,
      primaryToken,
      siteId,
      value: shopifyPanelBaseObject,
    });

    const items = [
      ...currentPanels,
      {
        arrayId: shortid.generate(),
        id: shopifyPanelBaseObject._id,
      },
    ];
    const updatedSidebarRenderer = {
      ...(renderer || {}),
      items,
    };

    // publish new panel to sidebar
    yield put(setRegionRendererDraft(ID_SIDEBAR, updatedSidebarRenderer));
    yield put(publishPagePendingChanges());
  }

  const currentStep: number = yield select(getCurrentStep);

  yield put(navigateToAdminBarAction({ actionKey: ActionKey.panels }));
  yield put(enablePanels());
  yield put(setActiveActionType(ActionType.openAdminMode));
  yield put(setPreviewPanelRenderer(camelify(shopifyPanelRenderer)));
  yield put(setCurrentSidebarPage('panel'));

  yield put(
    trackEvent({
      event: AdminActionEvents.WIZARD_FORWARD_STEP,
      properties: { flow: WizardFlowTypes.LIVE_SHOPPING, currentStep },
    }),
  );

}

export function* setupTicketedChannelSaga(payload: IExecuteWizardPayload) {
  const { name, eventDate, subscriptionAmount, themeId, siteStructure } = payload;
  const siteId: string = yield select(getSiteId);
  const primaryToken: string = yield select(getPrimaryToken);

  if (!eventDate || !subscriptionAmount) {
    throw new Error('Missing required fields');
  }
  const sku: string = yield call(createSlugFromString, name);

  yield call(siteCanUseEntitlementGate, primaryToken, siteId);
  // @ts-ignore
  const subscriptions = yield call(createWizardSubscription, {
    subscriptionName: name,
    primaryToken,
    sku,
    subscriptionAmount,
    eventDate,
    subscriptionType: 'ticket',
  });

  const accessTicketCreatedData: IAccessTicketCreatedData = {
    additionalPrices: [],
    defaultCurrency: 'usd',
    defaultPrice: String(subscriptionAmount),
    eventDate: new Date(eventDate).toString(),
    sku,
    ticketName: sku,
  };
  yield put(trackEvent({
    event: AdminActionEvents.ACCESS_TICKET_CREATED,
    properties: accessTicketCreatedData,
  }));

  const subscriberGate: ReturnType<typeof createBaseGateObject> = yield call(
    createBaseGateObject,
    {
      subscriptions,
      channelName: name,
      eventDate,
    },
  );

  const chatPanelBaseObject: BasePanelData = yield call(createBasePanelsObject, {
    renderer: CHAT_PANEL_RENDERER,
    siteId,
  });
  yield call(createWizardPanels, {
    objectId: chatPanelBaseObject._id,
    primaryToken,
    siteId,
    value: chatPanelBaseObject,
  });

  const baseChannelObject: BaseSiteData = yield call(createBaseChannelObject, {
    channelName: name,
    channelType: 'channel',
    gate: subscriberGate,
    panels: [chatPanelBaseObject],
    siteId,
    themeId,
  });

  const channel: IObject = yield call(createWizardChannel, {
    objectId: baseChannelObject._id,
    primaryToken,
    siteId,
    value: baseChannelObject,
  });

  // append the channel to the site structure
  const updatedSiteStructure = yield call(updateSiteStructure, { siteStructure: siteStructure!, page: baseChannelObject });
  yield put(updateNavigation(updatedSiteStructure));

  const properties: ITrackChannelPublishedEventData = {
    channelId: baseChannelObject._id,
    channelName: name,
    newPage: true,
  };
  const currentStep: number = yield select(getCurrentStep);

  yield put(
    trackEvent({
      event: AdminActionEvents.CHANNEL_PUBLISHED,
      properties,
    }),
  );

  yield put(
    trackEvent({
      event: AdminActionEvents.WIZARD_FORWARD_STEP,
      properties: { flow: WizardFlowTypes.TICKETED_EVENT, currentStep },
    }),
  );
}

export function* displayTheGateSaga(channelSlug: string) {
  yield put(replace({ path: `/${channelSlug}` }));
  yield delay(1000);
  yield put(navigateToAdminBarAction({ actionKey: ActionKey.access }));
  yield put(showModal({ kind: ModalKinds.wizardBeholdTheGate, data: { locked: true } }));
}

export function* handleWizardError(error: any) {
  yield put(resetWizardFlow());

  const isSupportContactMissed = String(error.response?.data?.message).match(/forbidden to enable entitlement gate/);
  if (isSupportContactMissed) {
    yield put(showModal({ kind: ModalKinds.supportContact }));
    yield put(dismissModal(ModalKinds.wizardMain));
    return;
  }
  yield put(
    showModal({
      kind: ModalKinds.errorModal,
      data: { promptStringKey: 'ADMIN_ERROR_GENERIC' },
    }),
  );
}

export function* executeWizardSaga({
  payload,
}: IExecuteWizardAction) {
  try {
    const { theme } = payload;
    const state: IState = yield select();
    const flowType = getCurrentFlow(state);
    const draftPayload = getDraftChannelData(state) || {
      name: '',
    };
    const siteStructure = getDefaultNavigation(state);
    const wizardPayload = { ...draftPayload, themeId: theme?._id, siteStructure };
    yield put(setIsWizardLoading(true));
    switch (flowType) {
      case WizardFlowTypes.LIVE_SHOPPING:
        yield put(applyTheme({ theme }));
        yield call(setupLiveShoppingSaga);
        break;
      case WizardFlowTypes.TICKETED_EVENT:
        yield call(setupTicketedChannelSaga, wizardPayload);
        break;
      case WizardFlowTypes.SUBSCRIBER_ONLY_LIVESTREAMS:
        yield call(setupSubscriberOnlyChannelSaga, wizardPayload);
        break;
      case WizardFlowTypes.SUBSCRIPTION_VOD:
        yield call(setupSubscriptionVodChannelSaga, wizardPayload);
        break;
      default:
        break;
    }

    yield put(setIsWizardLoading(false));
    yield put(dismissModal(ModalKinds.wizardMain));
    yield put(applyTheme({ theme }));

    if (flowType === WizardFlowTypes.LIVE_SHOPPING) {
      yield put(showModal({ kind: ModalKinds.wizardEnd }));
    } else {
      const slug: string = yield call(createSlugFromString, draftPayload.name);
      yield call(displayTheGateSaga, slug);
    }

    yield put(setChannelData(undefined));
  } catch (error) {
    yield call(handleWizardError, error);
  }
}

export default function* WizardSaga() {
  yield takeEvery(MOUNT_FLOW_STEPS, setWizardFlowSaga);
  yield takeLatest(EXECUTE_WIZARD, executeWizardSaga);
}
