import { prefix } from 'redux-utils';

const action = prefix('overlays');

export const ADD_OVERLAY = action('ADD_OVERLAY');
export const UPDATE_OVERLAY = action('UPDATE_OVERLAY');
export const ADD_PREVIEW_OVERLAY = action('ADD_PREVIEW_OVERLAY');
export const ENGAGE_OVERLAY = action('ENGAGE_OVERLAY');
export const ENTER_RAFFLE_REQUEST = action('ENTER_RAFFLE_REQUEST');
export const ENTER_RAFFLE_FAILURE = action('ENTER_RAFFLE_FAILURE');
export const ENTER_RAFFLE_SUCCESS = action('ENTER_RAFFLE_SUCCESS');
export const RECEIVE_OVERLAY = action('RECEIVE_OVERLAY');
export const DECREASE_TIME = action('DECREASE_TIME');
export const REMOVE_OVERLAY = action('REMOVE_OVERLAY');
export const REMOVE_PREVIEW_OVERLAY = action('REMOVE_PREVIEW_OVERLAY');
export const EDIT_OVERLAY = action('EDIT_OVERLAY');
export const BROADCAST_OVERLAY = action('BROADCAST_OVERLAY');
export const UPDATE_PENDING_STATUS = action('UPDATE_PENDING_STATUS');

/* Private actions */
export const engageOverlay = overlay => ({ payload: overlay, type: ENGAGE_OVERLAY });
export const addOverlay = overlay => ({ payload: overlay, type: ADD_OVERLAY });
export const updateOverlay = payload => ({ payload, type: UPDATE_OVERLAY });
export const enterRaffleSuccess = broadcastId => ({
  payload: broadcastId,
  type: ENTER_RAFFLE_SUCCESS,
});

export const enterRaffleFailure = broadcastId => ({
  payload: broadcastId,
  type: ENTER_RAFFLE_FAILURE,
});
export const updatePendingStatus = status => ({ payload: status, type: UPDATE_PENDING_STATUS });

/* Public actions */
export const broadcastOverlay = overlay => ({ payload: overlay, type: BROADCAST_OVERLAY });
export const receiveOverlay = overlay => ({ payload: overlay, type: RECEIVE_OVERLAY });
export const decreaseTime = key => ({ payload: key, type: DECREASE_TIME });
export const removeOverlay = key => ({ payload: key, type: REMOVE_OVERLAY });
export const enterRaffle = broadcastId => ({ payload: broadcastId, type: ENTER_RAFFLE_REQUEST });
export const addPreviewOverlay = overlay => ({ payload: overlay, type: ADD_PREVIEW_OVERLAY });
export const removePreviewOverlay = () => ({ type: REMOVE_PREVIEW_OVERLAY });
export const engageOverlayCta = broadcastId => (
  engageOverlay({
    broadcastId,
    channel: `broadcasts:${broadcastId}`,
    data: { ':inc': 'engaged' },
    type: 'stats',
  })
);
export const engageMultipleChoiceOverlay = (payload) => {
  const { broadcastId, index } = payload;
  return engageOverlay({
    answerIndex: index,
    broadcastId,
    channel: `broadcasts:${broadcastId}`,
    data: { ':inc': index + 1 },
    type: 'poll:response',
  });
};
export const editOverlay = overlay => ({ payload: overlay, type: EDIT_OVERLAY });
