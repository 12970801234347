import { prefix } from 'redux-utils';

const action = prefix('quest');

export const ADD_QUEST = action('ADD_QUEST');
export const REMOVE_QUEST = action('REMOVE_QUEST');
export const CLAIM_QUEST = action('CLAIM_QUEST');
export const RESET_ALL_QUEST_PROGRESS = action('RESET_ALL_QUEST_PROGRESS');
export const INITIALIZE_QUEST_PROGRESS = action('INITIALIZE_QUEST_PROGRESS');
export const SET_QUEST_CLAIMING = action('SET_QUEST_CLAIMING');
export const UPDATE_QUEST_PROGRESS = action('UPDATE_QUEST_PROGRESS');

// Public actions

export const addQuest = id => ({
  payload: id,
  type: ADD_QUEST,
});

export const claimQuest = id => ({
  payload: id,
  type: CLAIM_QUEST,
});

export const removeQuest = id => ({
  payload: id,
  type: REMOVE_QUEST,
});

export const resetAllQuestProgress = () => ({
  type: RESET_ALL_QUEST_PROGRESS,
});

export const initializeAllQuestProgress = progress => ({
  payload: { progress },
  type: INITIALIZE_QUEST_PROGRESS,
});

// update progress for single quest
export const updateQuestProgress = ({ questId, progress }) => ({
  payload: { progress, questId },
  type: UPDATE_QUEST_PROGRESS,
});

export const setQuestClaiming = (id, claiming) => ({
  payload: { claiming, id },
  type: SET_QUEST_CLAIMING,
});
