import EventBuilder from './EventBuilder';

class ChannelNavigationEventBuilder extends EventBuilder {
  constructor() {
    super('channel_select');
  }

  // TODO: Add validation
  buildOntology(action, doc) {
    return {
      /* eslint-disable sort-keys */
      phylum: action,
      class: doc?.data.kind,
      family: doc?.data.name,
      /* eslint-enable */
    };
  }

  buildMetadata(action, doc, extra) {
    return extra;
  }
}

export default new ChannelNavigationEventBuilder();
