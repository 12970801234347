import { DEV_MODE } from 'config';
import warning from 'warning';
import { IUbisoftAdobe } from './models';

export const callUbisoftTracker = (value: string, data: IUbisoftAdobe) => {
  if (window.adobeAnalytics) {
    try {
      window.adobeAnalytics.track(value, data);
    } catch (err) {
      warning(false, `error calling adobeAnalytics: ${err.message}`);
    }
  } else {
    warning(false, 'missing adobeAnalytics on window');
  }
};

export const waitForUbisoftPrivacyAccept = () => {
  return new Promise((resolve) => {
    window.addEventListener('message', (event) => {
      if (event.data === 'PRIVACY_POLICY_ACCEPTED' || window.sCode) {
        resolve(true);
      }
    }, false);
  });
};

export const initializeUbisoftSDK = (data: IUbisoftAdobe) => {
  return new Promise((resolve) => {
    // Can't move on until SCode is ready. If there is a better way please change.
    window.addEventListener('message', (event) => event.data === 'S_CODE_READY' && resolve(true), false);
    if (window.adobeAnalytics) {
      try {
        window.adobeAnalytics.init(data);
      } catch (err) {
        warning(false, `error calling adobeAnalytics: ${err.message}`);
      }
    } else {
      warning(false, 'missing adobeAnalytics on window');
    }
  });
};

const loadScriptToHead = (scriptId: string, prodScriptUrl: string, devScriptUrl?: string): Promise<void> => {
  return new Promise((resolve) => {
    const scriptUrl = (DEV_MODE && devScriptUrl) ? devScriptUrl : prodScriptUrl;
    const fjs = document.getElementsByTagName('script')[0];
    if (document.getElementById(scriptId)) { return; }
    const js = document.createElement('script');
    js.id = scriptId;
    js.src = scriptUrl;
    document.head.append(js, fjs);
    js.onload = () => resolve(); // script needs to be loaded before we call _satellite.pageBottom()
  });
};

export const loadUbisoft = async () => {
  await loadScriptToHead(
    'adobe-ubisoft-sdk',
    'https://ubistatic-a.akamaihd.net/0106/prod/global/tracking/analyticssdk.js',
    'https://ubistatic-a.akamaihd.net/0106/uat/global/tracking/analyticssdk.js',
  );
};
