
import { IFRAME_SDK_URL } from 'config';
import { makeLocalStoragePersistenceService } from './localStorage';
import { makeSdkPersistenceService } from './sdk';
import { StorageType, LOCAL_STORAGE } from './utils';
import type { IPersistenceService } from './interfaces';

/**
 * Creates a persistence service that allows data storage and retrieval either in an iframe/sdk or browser storage.
 * @param {StorageType} storageType - The type of browser storage to use. Defaults to 'local'.
 * @returns {IPersistenceService} An object containing methods for data storage and retrieval.
 */
export const persistenceService = (storageType: StorageType = LOCAL_STORAGE): IPersistenceService => {
  const isRunningInIframe = IFRAME_SDK_URL === 'iframe';

  if (isRunningInIframe) {
    return makeSdkPersistenceService(storageType);
  } else {
    return makeLocalStoragePersistenceService(storageType);
  }
};
