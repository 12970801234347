export const SET_WELCOME_SCREEN_SEEN = 'welcomeScreen/SET_WELCOME_SCREEN_SEEN';

export type IWelcomeScreenAction = ISetWelcomeScreenSeenAction;

interface ISetWelcomeScreenSeenAction {
  type: typeof SET_WELCOME_SCREEN_SEEN;
}

export const setWelcomeScreenSeen = (): ISetWelcomeScreenSeenAction => ({
  type: SET_WELCOME_SCREEN_SEEN,
});
