import IPanel from 'models/IPanel';
import { makeAction, prefix } from 'redux-utils';
const action = prefix('layout');

export const SET_VIEW_MODE = action('SET_VIEW_MODE');
export const SET_THEATER_MODE = action('SET_THEATER_MODE');
export const SET_GDPR = action('SET_GDPR');
export const SET_SUBSCRIPTION_GATE = action('SET__SUBSCRIPTION_GATE');
export const SET_LOGIN_GATE = action('SET_LOGIN_GATE');
export const SET_PASSWORD_GATE = action('SET_PASSWORD_GATE');
export const SET_SIDEBAR_WIDTH = action('SET_SIDEBAR_WIDTH');
export const SET_ACTIVE_PANELS = action('SET_ACTIVE_PANELS');
export const SET_AMAZON_BENEFIT_GATE = action('SET_AMAZON_BENEFIT_GATE');
export const SET_ORIENTATION_LOCKED = 'layout/SET_ORIENTATION_LOCKED';

export const setOrientationLocked = makeAction(
  SET_ORIENTATION_LOCKED,
  (payload: boolean) => ({
    payload,
  }),
);

export type ISetViewModePayload = 'fullscreen' | 'theater' | null;

export interface ISetSidebarWidth {
  payload: number;
  type: typeof SET_SIDEBAR_WIDTH;
}

export interface ISetViewModeAction {
  payload: ISetViewModePayload;
  type: typeof SET_VIEW_MODE;
}

export interface ISetTheaterMode {
  payload: boolean;
  type: typeof SET_THEATER_MODE;
}

export interface ISetGDPR {
  payload: boolean;
  type: typeof SET_GDPR;
}

export interface ISetSubscriptionGate {
  payload: boolean;
  type: typeof SET_SUBSCRIPTION_GATE;
}

export interface ISetPasswordGate {
  payload: boolean;
  type: typeof SET_PASSWORD_GATE;
}

export interface ISetLoginGate {
  payload: boolean;
  type: typeof SET_LOGIN_GATE;
}

export interface ISetAmazonBenefitGate {
  payload: boolean;
  type: typeof SET_AMAZON_BENEFIT_GATE;
}

export interface ISetActivePanels {
  payload: IPanel<{}>[];
  type: typeof SET_ACTIVE_PANELS;
}

export type IUserLayoutAction = (
  ISetViewModeAction |
  ISetTheaterMode |
  ISetGDPR |
  ISetSubscriptionGate |
  ISetPasswordGate |
  ISetLoginGate |
  ISetSidebarWidth |
  ISetActivePanels |
  ISetAmazonBenefitGate |
  ReturnType<typeof setOrientationLocked>
);

export const setViewMode = (mode: ISetViewModePayload): ISetViewModeAction => ({
  payload: mode,
  type: SET_VIEW_MODE,
});

export const setTheaterMode = (isTheater: boolean): ISetTheaterMode => ({
payload: isTheater,
type: SET_THEATER_MODE,
});

export const setGDPR = (displayGDPR: boolean): ISetGDPR => ({
  payload: displayGDPR,
  type: SET_GDPR,
  });

export const setSubscriptionGate = (displayGate: boolean): ISetSubscriptionGate => ({
  payload: displayGate,
  type: SET_SUBSCRIPTION_GATE,
  });

export const setLoginGate = (displayGate: boolean): ISetLoginGate => ({
  payload: displayGate,
  type: SET_LOGIN_GATE,
  });

export const setAmazonBenefitGate = (displayGate: boolean): ISetAmazonBenefitGate => ({
  payload: displayGate,
  type: SET_AMAZON_BENEFIT_GATE,
  });

export const setPasswordGate = (displayGate: boolean): ISetPasswordGate => ({
  payload: displayGate,
  type: SET_PASSWORD_GATE,
  });

export const setSidebarWidth = (width: number): ISetSidebarWidth => ({
  payload: width,
  type: SET_SIDEBAR_WIDTH,
});

export const setActivePanels = (panels: IPanel<{}>[]): ISetActivePanels => ({
  payload: panels,
  type: SET_ACTIVE_PANELS,
});
