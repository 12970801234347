import { ITheme } from 'models';
import { SET_FORKED_THEME, IThemesActions, SET_THEME } from './actions';

interface IThemesInitialState {
  adminTheme: ITheme;
  forkedTheme: ITheme | null;
  theme: ITheme;
}

export type IThemesState = IThemesInitialState;

const INITIAL_STATE: IThemesInitialState = {
  theme: window?.INIT?.THEME,
  adminTheme: window?.INIT?.ADMIN_THEME,
  forkedTheme: null,
};

export const getInitialState = (): IThemesState => ({
  ...INITIAL_STATE,
});

export default function themesReducer(state: IThemesState = INITIAL_STATE, action: IThemesActions): IThemesState {
  switch (action.type) {
    case SET_THEME: {
      return {
        ...state,
        theme: action.payload.theme,
      };
    }
    case SET_FORKED_THEME: {
      return {
        ...state,
        forkedTheme: action.payload.theme,
      };
    }
    default:
      return state;
  }
}
