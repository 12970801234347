// tslint:disable:no-console
import { call, fork, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { INCLUDE_DUPLICATED_PAGE_TO_DEFAULT_NAVIGATION, UPDATE_NAVIGATION, includeDuplicatedPageToDefaultNavigation, updateNavigation, setNavigationSelectedInTheDropdown, CREATE_CUSTOM_NAVIGATION, createCustomNavigation, setNavigations, deleteNavigation, DELETE_NAVIGATION, addItemToCustomNavigation, addFolderToCustomNavigation, ADD_ITEM_TO_CUSTOM_NAVIGATION, ADD_FOLDER_TO_CUSTOM_NAVIGATION, removeItemFromCustomNavigation, ADD_LINK_TO_NAVIGATION, addLinkToNavigation, removeLinkFromNavigation, REMOVE_LINK_FROM_NAVIGATION, setCreateCustomNavigationLoading, REMOVE_ITEM_FROM_CUSTOM_NAVIGATION, fetchAllNavigations, FETCH_ALL_NAVIGATIONS, SET_NAVIGATION_APPLIED_IN_THE_PAGE, setNavigationAppliedInThePage, removeItemFromNavigations, REMOVE_ITEM_FROM_NAVIGATIONS, updatePageInNavigations, UPDATE_PAGE_IN_NAVIGATIONS } from './actions';
import { makeSaga } from 'redux-utils';
import IState from 'services/state';
import { getPrimaryToken } from 'services/auth';
import { SET_OBJECT, getSiteId, setObject } from 'services/app';
import { IDeleteCustomNavigationResponse, applyNavigationToPageAPI, createCustomNavigationAPI, deleteCustomNavigationAPI, fetchAllNavigationsAPI, fetchDefaultNavigationAPI, fetchNavigationAPI, updateNavigationAPI } from './api';
import { findPageInNavigation, getCustomNavigationName, getFolderName, treatNavItemName, NAV_NAME_MAX_LENGTH, removeItemFromNavigation } from './utils';
import INavigation, { NAVIGATION_PARENT_TYPE, NAVIGATION_TYPE, pageTypeToNavigationChildType, pageTypeToNavigationParentType } from 'models/INavigation';
import { getDefaultNavigation, getNavigationAppliedInThePage, getNavigations, getNavigationSelectedInTheDropdown } from './selectors';
import { generateRandomHexID } from 'utils';
import { showModal } from 'services/modals';
import { ModalKinds } from 'services/modals/types';
import { slugify } from 'shared/string-utils';

export const updateNavigationSaga = makeSaga({
  updateNavigation,
}, function* ({ payload }) {
  try {
    const state: IState = yield select();
    const primaryToken = getPrimaryToken(state)!;
    const siteId = getSiteId(state);

    // BE triggers a re-render whenever we call this:
    yield call(updateNavigationAPI, { primaryToken, siteId, navigation: payload });
  } catch (e) {
    console.error('updateNavigationSaga failed. error:', e);
  }
});

// fired when a landing/channel page is duplicated.
// this saga will include the duplicated page to the default navigation.
export const includeDuplicatedPageToDefaultNavigationSaga = makeSaga({
  includeDuplicatedPageToDefaultNavigation,
}, function* ({ payload: { originalPageId, clonePage } }) {
  try {
    const state: IState = yield select();
    const defaultNavigation = getDefaultNavigation(state);
    const updatedDefaultNavigation = structuredClone(defaultNavigation) as INavigation;

    const result = findPageInNavigation(updatedDefaultNavigation, originalPageId);
    if (!result || !result.found) return;

    const { item: originalNavigationItem, isParent } = result;

    const newNavigationItemBase = {
      id: clonePage._id,
      name: clonePage.data.name!,
      slug: clonePage.slug,
    };

    if (isParent) {
      const newNavigationItem = {
        ...newNavigationItemBase,
        type: pageTypeToNavigationParentType[clonePage.type],
        children: [],
      };
      updatedDefaultNavigation.parents.unshift(newNavigationItem);
    } else {
      const newNavigationItem = {
        ...newNavigationItemBase,
        type: pageTypeToNavigationChildType[clonePage.type],
      };
      const parent = updatedDefaultNavigation.parents.find(navParent => navParent.children.some(child => child.id === originalNavigationItem.id));
      if (!parent) return;
      parent.children.unshift(newNavigationItem);
    }

    yield put(updateNavigation(updatedDefaultNavigation));
  } catch (e) {
    console.error('includeDuplicatedPageToDefaultNavigationSaga failed. error:', e);
  }
});

export const setNavigationAppliedInThePageSaga = makeSaga({
  setNavigationAppliedInThePage,
}, function* ({ payload }) {
  try {
    const state: IState = yield select();
    const primaryToken = getPrimaryToken(state)!;
    const siteId = getSiteId(state);

    const { navigationId, pageId } = payload;
    yield call(applyNavigationToPageAPI, {
      primaryToken,
      siteId,
      navigationId,
      pageId,
    });
  } catch (e) {
    console.error('setNavigationAppliedInThePageSaga failed. error:', e);
  }
});

export const addLinkToNavigationSaga = makeSaga({
  addLinkToNavigation,
}, function* ({ payload: { navigationId, link } }) {
  try {
    const state: IState = yield select();
    const navigations = getNavigations(state);
    const navigation = navigations.find(nav => nav._id === navigationId);
    if (!navigation) return;

    const newNavigation = structuredClone(navigation);

    newNavigation.externalLinks.push(link);
    yield put(updateNavigation(newNavigation));
  } catch (e) {
    console.error('addLinkToNavigationSaga failed. error:', e);
  }
});

export const removeLinkFromNavigationSaga = makeSaga({
  removeLinkFromNavigation,
}, function* ({ payload: { navigationId, linkId } }) {
  try {
    const state: IState = yield select();
    const navigations = getNavigations(state);
    const navigation = navigations.find(nav => nav._id === navigationId);
    if (!navigation) return;

    const newNavigation = structuredClone(navigation);

    newNavigation.externalLinks = newNavigation.externalLinks.filter(externalLink => externalLink.id !== linkId);
    yield put(updateNavigation(newNavigation));
  } catch (e) {
    console.error('removeLinkFromNavigationSaga failed. error:', e);
  }
});

export const createCustomNavigationSaga = makeSaga({
  createCustomNavigation,
}, function* ({ payload: { navName, navigationToCopyDataFrom } }) {
  try {
    yield put(setCreateCustomNavigationLoading(true));

    const state: IState = yield select();
    const primaryToken = getPrimaryToken(state)!;
    const siteId = getSiteId(state);
    const navigations = getNavigations(state);

    const namePrefix = navigationToCopyDataFrom ?
      `Copy of ${navigationToCopyDataFrom.name}`.slice(0, NAV_NAME_MAX_LENGTH) :
      navName || 'Custom Navigation';

    const name = getCustomNavigationName(navigations, namePrefix);

    const newCustomNavigation: INavigation = {
      name,
      siteId,
      parents: navigationToCopyDataFrom?.parents || [],
      externalLinks: navigationToCopyDataFrom?.externalLinks || [],
      type: NAVIGATION_TYPE.custom,
    };

    const createdNavigation = yield call(createCustomNavigationAPI, { primaryToken, siteId, navigation: newCustomNavigation });
    yield put(setNavigations([...navigations, createdNavigation]));
    yield put(setNavigationSelectedInTheDropdown(createdNavigation._id));
  } catch (e) {
    console.error('createCustomNavigationSaga failed. error:', e);
  } finally {
    yield put(setCreateCustomNavigationLoading(false));
  }
});

export const deleteNavigationSaga = makeSaga({
  deleteNavigation,
}, function* ({ payload: navigationId }) {
  try {
    const state: IState = yield select();
    const primaryToken = getPrimaryToken(state)!;
    const siteId = getSiteId(state);
    const navigations = getNavigations(state);

    const response: IDeleteCustomNavigationResponse =  yield call(deleteCustomNavigationAPI, { primaryToken, siteId, navigationId });
    if (!response.result && response.pages.length) {
      yield put(showModal({ data: { pages: response.pages, feature: 'navigation' }, kind: ModalKinds.activeOnPageModal }));
      return;
    }
    yield put(setNavigations(navigations.filter(nav => nav._id !== navigationId)));
  } catch (e) {
    console.error('deleteNavigationSaga failed. error:', e);
  }
});

/**
 * @description - Fetch default navigation always. Even though it may already exist in the INIT.
 */
export const fetchDefaultNavigationSaga = function* () {
  try {
    const state: IState = yield select();
    const primaryToken = getPrimaryToken(state)!;
    const siteId = getSiteId(state);
    const navigations = getNavigations(state);

    const defaultNavigation = yield call(fetchDefaultNavigationAPI, { primaryToken, siteId });
    const customNavigations = navigations.filter(nav => nav.type !== NAVIGATION_TYPE.default);
    yield put(setNavigations([defaultNavigation, ...customNavigations]));
  } catch (e) {
    console.error('initializeNavigationSaga failed. error:', e);
  }
};

export const addItemToCustomNavigationSaga = makeSaga({
  addItemToCustomNavigation,
}, function* ({ payload: { newParent, navigationId } }) {
  try {
    const state: IState = yield select();
    const navigations = getNavigations(state);
    const customNavigation = navigations.find(nav => nav._id === navigationId);
    if (!customNavigation) return;

    const newCustomNavigation = structuredClone(customNavigation);
    newCustomNavigation.parents.push(newParent);
    yield put(updateNavigation(newCustomNavigation));
  } catch (e) {
    console.error('addItemToCustomNavigationSaga failed. error:', e);
  }
});

export const addFolderToCustomNavigationSaga = makeSaga({
  addFolderToCustomNavigation,
}, function* ({ payload: folderName }) {
  try {
    const state: IState = yield select();
    const selectedNavigation = getNavigationSelectedInTheDropdown(state);

    const newFolderRandomId = generateRandomHexID();
    const newFolderName = getFolderName(selectedNavigation, folderName ? treatNavItemName(folderName) : 'New Folder');

    const newNavigation = structuredClone(selectedNavigation);

    newNavigation.parents.push({
      id: newFolderRandomId,
      name: newFolderName,
      slug: slugify(newFolderName),
      type: NAVIGATION_PARENT_TYPE.folder,
      children: [],
    });

    yield put(updateNavigation(newNavigation));
  } catch (e) {
    console.error('addFolderToCustomNavigationSaga failed. error:', e);
  }
});

export const removeItemFromCustomNavigationSaga = makeSaga({
  removeItemFromCustomNavigation,
}, function* ({ payload: { navigationId, itemId } }) {
  try {
    const state: IState = yield select();
    const navigations = getNavigations(state);
    const customNavigation = navigations.find(nav => nav._id === navigationId);
    if (!customNavigation) return;

    const newCustomNavigation = removeItemFromNavigation(structuredClone(customNavigation), itemId);

    yield put(updateNavigation(newCustomNavigation));
  } catch (e) {
    console.error('removeItemFromCustomNavigationSaga failed. error:', e);
  }
});

export const fetchAllNavigationsSaga = makeSaga({
  fetchAllNavigations,
}, function* () {
  try {
    const state: IState = yield select();
    const primaryToken = getPrimaryToken(state)!;
    const siteId = getSiteId(state);
    const appliedNavigation = getNavigationAppliedInThePage(state);

    const navigations = yield call(fetchAllNavigationsAPI, { primaryToken, siteId });
    const sortedNavigations = navigations.sort((a: INavigation, b: INavigation) => {
      if (a._id === appliedNavigation._id) return -1;
      if (b._id === appliedNavigation._id) return 1;

      if (a.type === NAVIGATION_TYPE.default) return -1;
      if (b.type === NAVIGATION_TYPE.default) return 1;

      return 0;
    });
    yield put(setNavigations(sortedNavigations));
    yield put(setNavigationSelectedInTheDropdown(appliedNavigation._id!));
  } catch (e) {
    console.error('fetchAllNavigations failed. error:', e);
  }
});

export const removeItemFromNavigationsSaga = makeSaga({
  removeItemFromNavigations,
}, function* ({ payload: pageId }) {
  try {
    const state: IState = yield select();
    const navigations = getNavigations(state);
    if (!navigations || !navigations.length) return;

    const updatedNavigations = navigations.map(navigation => {
      return removeItemFromNavigation(structuredClone(navigation), pageId);
    });
    yield put(setNavigations(updatedNavigations));
  } catch (e) {
    console.error('removeItemFromNavigationsSaga failed. error:', e);
  }
});

export const updatePageInNavigationsSaga = makeSaga({
  updatePageInNavigations,
}, function* ({ payload: { pageId, newName, newSlug, newPrivate } }) {
  try {
    const state: IState = yield select();
    const navigations = getNavigations(state);
    if (!navigations || !navigations.length) return;

    const updatedNavigations = navigations.map(navigation => {
      return {
        ...navigation,
        parents: navigation.parents.map(parent => {
          if (parent.id === pageId) {
            return {
              ...parent,
              name: newName,
              slug: newSlug,
              private: newPrivate,
            };
          }
          return {
            ...parent,
            children: parent.children.map(child => {
              if (child.id === pageId) {
                return {
                  ...child,
                  name: newName,
                  slug: newSlug,
                  private: newPrivate,
                };
              }
              return child;
            }),
          };
        }),
      };
    });
    yield put(setNavigations(updatedNavigations));
  } catch (e) {
    console.error('updatePageInNavigationsSaga failed. error:', e);
  }
});

export const handlePageSwitchSaga = makeSaga({
  setObject,
}, function* ({ payload }) {
  try {
    const state: IState = yield select();
    const { object, loaded } = payload;
    if (!object || !loaded) return;
    if (object.collection !== 'pages') return;

    const navigationId = object.data?.navigationId;
    if (!navigationId) return; // if it doesn't exist, it means it's using the default navigation.
    // default navigation is already fetched in fetchDefaultNavigationSaga

    const navigations = getNavigations(state);
    if (navigations.some(nav => nav._id === navigationId)) return; // if it exists, it means it's already fetched.

    const primaryToken = getPrimaryToken(state);
    const siteId = getSiteId(state);
    if (!primaryToken || !siteId) return;

    const navigation = yield call(fetchNavigationAPI, { primaryToken, siteId, navigationId });
    yield put(setNavigations([...navigations, navigation]));
  } catch (e) {
    console.error('handlePageSwitchSaga failed. error:', e);
  }
});

const navigationSaga = function* () {
  yield fork(fetchDefaultNavigationSaga);
  yield takeLatest(SET_OBJECT, handlePageSwitchSaga);
  yield takeEvery(UPDATE_NAVIGATION, updateNavigationSaga);
  yield takeEvery(INCLUDE_DUPLICATED_PAGE_TO_DEFAULT_NAVIGATION, includeDuplicatedPageToDefaultNavigationSaga);
  yield takeEvery(SET_NAVIGATION_APPLIED_IN_THE_PAGE, setNavigationAppliedInThePageSaga);
  yield takeEvery(ADD_LINK_TO_NAVIGATION, addLinkToNavigationSaga);
  yield takeEvery(REMOVE_LINK_FROM_NAVIGATION, removeLinkFromNavigationSaga);
  yield takeEvery(CREATE_CUSTOM_NAVIGATION, createCustomNavigationSaga);
  yield takeEvery(DELETE_NAVIGATION, deleteNavigationSaga);
  yield takeEvery(ADD_ITEM_TO_CUSTOM_NAVIGATION, addItemToCustomNavigationSaga);
  yield takeEvery(ADD_FOLDER_TO_CUSTOM_NAVIGATION, addFolderToCustomNavigationSaga);
  yield takeEvery(REMOVE_ITEM_FROM_CUSTOM_NAVIGATION, removeItemFromCustomNavigationSaga);
  yield takeEvery(FETCH_ALL_NAVIGATIONS, fetchAllNavigationsSaga);
  yield takeEvery(REMOVE_ITEM_FROM_NAVIGATIONS, removeItemFromNavigationsSaga);
  yield takeEvery(UPDATE_PAGE_IN_NAVIGATIONS, updatePageInNavigationsSaga);
};

export default navigationSaga;
