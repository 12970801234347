import { compose, createStore as createReduxStore, applyMiddleware } from 'redux';
import freeze from 'redux-freeze';
import createSagaMiddleware from 'redux-saga';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import sample from 'lodash/sample';
import * as firebase from 'firebase-db';
import invariant from 'invariant';

import { FIREBASE_SHARD_OVERRIDE, BASE_PATH, ENABLE_REDUX_DEBUG, isLiveSite, SITE_SLUG } from 'config';
import { hasOwn } from 'utils';
import { lazy } from 'utils/lazy';
import { logError } from './error';
import createRootReducer from './root-reducer';
import rootSaga from './root-saga';
import navigationConfirmationMiddleware from './middleware/navigationConfirmationMiddleware';

const REDUX_DEV_KEY = '__REDUX_DEVTOOLS_EXTENSION_COMPOSE__';
const shards = window.INIT.SITE_SHARDS;

const shardName = FIREBASE_SHARD_OVERRIDE || sample(shards);

invariant(
  shardName,
  'No shard found! WTF???',
);

firebase.init(shardName);

export const history = createBrowserHistory({
  basename: !isLiveSite ? `/${SITE_SLUG}` : BASE_PATH,
  forceRefresh: false,
});

const createStore = () => {
  let store = null;

  const sagaMiddleware = createSagaMiddleware({
    onError: error => store?.dispatch(logError(error, 'Uncaught saga error')),
  });

  const middleware = [
    routerMiddleware(history),
    sagaMiddleware,
    (process.env.NODE_ENV === 'production') ? null : freeze,
  ].filter(item => item);

  const enhancers = [
    applyMiddleware(
      navigationConfirmationMiddleware,
      ...middleware,
    ),
  ];

  const enableDev = (
    process.env.NODE_ENV !== 'production' ||
    ENABLE_REDUX_DEBUG
  );
  const composeEnhancers =
    (enableDev && hasOwn(window, REDUX_DEV_KEY)) ?
      window[REDUX_DEV_KEY]({ actionsDenylist: [], maxAge: 200 }) :
      compose;

  store = createReduxStore(
    createRootReducer(history),
    composeEnhancers(...enhancers),
  );

  sagaMiddleware.run(rootSaga);

  return store;
};

export const store = lazy(() => createStore());
