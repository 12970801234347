import axios, { AxiosError } from 'axios';
import axiosRetry from 'axios-retry';

interface IRetryLogic {
  retries?: number;
  retryCondition?: (error: AxiosError) => boolean;
  retryDelay?: (retryCount: number, error: AxiosError) => number;
}

interface IMaestroApiRequest {
  freshInstance?: boolean;
  primaryToken: string | null;
  retryLogic?: IRetryLogic;
  siteId: string;
}

export const maestroAxiosInstance = axios.create();

const defaultRetryCondition = (error: any) => {
  const status = error.response?.status || 200;
  return [400, 408, 502].includes(status);
};

const maestroApiRequest = ({
  freshInstance,
  primaryToken,
  retryLogic,
  siteId,
}: IMaestroApiRequest) => {
  const axiosInstance = freshInstance ?
    axios.create() :
    maestroAxiosInstance;

  axiosInstance.defaults.headers = {
    'x-maestro-client-id': siteId,
    'Authorization': `Bearer ${primaryToken}`,
  };

  axiosRetry(
    axiosInstance,
    {
      retries: retryLogic?.retries || 2,
      retryCondition: retryLogic?.retryCondition || defaultRetryCondition,
      retryDelay: retryLogic?.retryDelay || undefined,
    },
  );

  return axiosInstance;
};

export default maestroApiRequest;
