import { call, put, select, fork, takeLatest, takeEvery, delay } from 'redux-saga/effects';
import IState from 'services/state';
import { logInSuccess, logOut } from 'services/auth/actions';
import { getSiteId } from 'services/app/selectors';
import { showModal } from 'services/modals/actions';
import { isUserLoggedIn, getPrimaryToken } from 'services/auth/selectors';
import { deleteQuery } from 'services/app-router';
import { loginModal } from 'services/modals';
import { consumeIntent, registerInvite, updateInvite, getRegisterInvites, sendInvite, deleteInvite, getInvite, resendInvite } from './api';
import { INVITE_TOKEN, REGISTER_TOKEN } from 'config';
import {
  setInvalidInvite,
  GET_INVITE_LINK,
  getInviteLinkSuccess,
  getInviteLinkError,
  UPDATE_INVITE,
  GET_PENDING_INVITES,
  setPendingInvites,
  SEND_INVITE,
  sendInviteSuccess,
  sendInviteError,
  SEND_INVITE_SUCCESS,
  DELETE_INVITE,
  DELETE_INVITE_SUCCESS,
  deleteInviteSuccess,
  resendInviteSuccess,
  RESEND_INVITE,
  accountExists,
  deletedInvite,
  accountExistsSuccess,
  RESEND_INVITE_SUCCESS,
} from './actions';
import { getInviteSelector, getInviteURLSelector } from './selectors';
import { IInviteResponse } from './models';
import { ModalKinds } from 'services/modals/types';

export function* checkInviteQuery() {
  if (!INVITE_TOKEN) {
    return;
  }
  const state: IState = yield select();
  const siteId: string = getSiteId(state);
  const loggedIn: boolean = yield select(isUserLoggedIn);

  const isRevoked = INVITE_TOKEN === 'revoked';
  if (isRevoked || INVITE_TOKEN === 'error') {
    if (loggedIn && isRevoked) {
      yield put(logOut());
    }
    yield put(showModal({ kind: ModalKinds.invite, data: { text: '' } }));
    deleteQuery('invite');
    return;
  }

  if (loggedIn) {
    deleteQuery('invite');
    return;
  }

  try {
    const result: IInviteResponse = yield call(consumeIntent, { INVITE_TOKEN, siteId });
    if (!result.data) {
      yield put(showModal({ kind: ModalKinds.invite, data: { text: '' } }));
      return;
    }
    const { jwt, refreshToken, service } = result.data;
    if (jwt) {
      yield put(logInSuccess({
        accessToken: jwt,
        auto: true,
        refreshToken,
        service,
      }));
    }
  } catch (error) {
    yield put(showModal({ kind: ModalKinds.invite, data: { text: '' } }));
  }
  deleteQuery('invite');
}

export function* checkRegisterQuery() {
  if (!REGISTER_TOKEN) {
    return;
  }
  const state: IState = yield select();
  const siteId: string = getSiteId(state);
  const isRevoked = REGISTER_TOKEN === 'revoked';

  if (REGISTER_TOKEN === 'expired') {
    yield put(setInvalidInvite(true));
    deleteQuery('registerinvite');
    yield put(showModal({ kind: ModalKinds.invite, data: { text: 'INVITE_EXPIRED' } }));
    return;
  }

  if (REGISTER_TOKEN === 'notfound') {
    yield put(setInvalidInvite(true));
    deleteQuery('registerinvite');
    yield put(showModal({ kind: ModalKinds.invite, data: { text: 'INVITE_NOT_VALID' } }));
    return;
  }

  if (REGISTER_TOKEN === 'consumed') {
    yield put(setInvalidInvite(true));
    deleteQuery('registerinvite');
    yield put(showModal({ kind: ModalKinds.invite, data: { text: 'INVITE_CONSUMED' } }));
    return;
  }

  if (isRevoked || REGISTER_TOKEN === 'error') {
    yield put(setInvalidInvite(true));
    deleteQuery('registerinvite');
    yield put(showModal({ kind: ModalKinds.invite, data: { text: '' } }));
    return;
  }

  try {
    const doGetInvite = yield call(getInvite, {
      inviteId: REGISTER_TOKEN,
      siteId,
    });
    const invite = doGetInvite.data;
    deleteQuery('registerinvite');
    if (invite.revoked || invite.consumed) {
      yield put(showModal({ kind: ModalKinds.invite, data: { text: 'INVITE_CONSUMED' } }));
      return;
    }
    yield put(getInviteLinkSuccess({ inviteUrl: '', invite: doGetInvite.data }));
    const loggedIn: boolean = yield select(isUserLoggedIn);
    if (loggedIn) {
      yield put(logOut());
    }

    if (invite.email) {
      yield put(loginModal({ autoSubmit: true, page: 'ACCOUNTLOOKUP', provider: 'registerInvite', email: invite.email }));
    } else {
      yield put(loginModal({ provider: 'registerInvite' }));
    }
  } catch (error) {
    yield put(setInvalidInvite(true));
    deleteQuery('registerinvite');
    yield put(showModal({ kind: ModalKinds.invite, data: { text: '' } }));
  }
}

export function* registerInviteSaga({ payload }: any) {
  const state: IState = yield select();
  const siteId: string = getSiteId(state);
  const redirectUri: string = window.location.href.split('?')[0];
  const token: string | null = getPrimaryToken(state);
  try {
    const doRegisterInvite = yield call(registerInvite, {
      siteId,
      redirectUri,
      targetRoles: payload,
      token,
    });
    yield put(getInviteLinkSuccess(doRegisterInvite.data));
  } catch (error) {
    yield put(getInviteLinkError(error));
  }
}

export function* updateInviteSaga({ payload }: any) {
  const state: IState = yield select();
  const siteId: string = getSiteId(state);
  const invite: any = getInviteSelector(state);
  const token: string | null = getPrimaryToken(state);
  const inviteLink: string | null = getInviteURLSelector(state);
  try {
    const doUpdateInvite = yield call(updateInvite, {
      siteId,
      inviteId: invite._id,
      token,
      invite: {
        ...payload,
      },
    });
    yield put(getInviteLinkSuccess({ inviteUrl: inviteLink, invite: doUpdateInvite.data }));
  } catch (error) {
    yield put(getInviteLinkError(error));
  }
}

export function* getPendingInvitesSaga() {
  const state: IState = yield select();
  const siteId: string = getSiteId(state);
  const token: string | null = getPrimaryToken(state);
  try {
    const doGetPendingInvites = yield call(getRegisterInvites, {
      siteId,
      token,
    });
    const filteredInvites = doGetPendingInvites.data.filter((item: { email: any; }) => item.email);
    yield put(setPendingInvites(filteredInvites));
  } catch (error) {
    yield put(getInviteLinkError(error));
  }
}

export function* sendInviteSaga({ payload }: any) {
  const state: IState = yield select();
  const siteId: string = getSiteId(state);
  const token: string | null = getPrimaryToken(state);
  const invite: any = getInviteSelector(state);
  try {
    const doSendInvite = yield call(sendInvite, {
      inviteId: invite._id,
      siteId,
      token,
      email: payload,
    });
    if (doSendInvite.data._id) {
      yield put(sendInviteSuccess({ ...invite, email: payload }));
    } else {
      yield put(accountExists(invite._id));
    }
  } catch (error) {
    yield put(sendInviteError(error));
  }
}

export function* deleteInviteSaga({ payload }: any) {
  const state: IState = yield select();
  const siteId: string = getSiteId(state);
  const token: string | null = getPrimaryToken(state);

  try {
    yield call(deleteInvite, {
      inviteId: payload,
      siteId,
      token,
    });
    yield put(deletedInvite(payload));
    yield delay(2000);

    yield put(deleteInviteSuccess(payload));
  } catch (error) {
    yield put(getInviteLinkError(error));
  }
}

export function* resendInviteSaga({ payload }: any) {
  const state: IState = yield select();
  const siteId: string = getSiteId(state);
  const token: string | null = getPrimaryToken(state);
  try {
    const doResendInvite = yield call(resendInvite, {
      inviteId: payload,
      siteId,
      token,
    });

    if (doResendInvite.data.message === 'Invite resent') {
      yield put(resendInviteSuccess());
    } else {
      yield put(accountExists(payload));
      yield delay(5000);
      yield put(accountExistsSuccess(payload));
    }
  } catch (error) {
    yield put(getInviteLinkError(error));
  }
}

const inviteSaga = function* () {
  yield fork(checkInviteQuery);
  yield fork(checkRegisterQuery);
  yield takeLatest(GET_INVITE_LINK, registerInviteSaga);
  yield takeEvery(UPDATE_INVITE, updateInviteSaga);
  yield takeEvery([GET_PENDING_INVITES, SEND_INVITE_SUCCESS, DELETE_INVITE_SUCCESS, RESEND_INVITE_SUCCESS], getPendingInvitesSaga);
  yield takeLatest(SEND_INVITE, sendInviteSaga);
  yield takeEvery(DELETE_INVITE, deleteInviteSaga);
  yield takeEvery(RESEND_INVITE, resendInviteSaga);
};

export default inviteSaga;
