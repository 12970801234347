import { put, select, take } from 'redux-saga/effects';
import { makeSaga } from 'redux-utils';
import { subscribe } from 'services/realtime/actions';
import { getUserId } from 'services/auth';
import type IState from 'services/state';
import { isListening } from 'services/realtime/selectors';
import { pendingNotificationsCollection } from './constants';

export const setupNotificationListenerSaga = makeSaga(
  {},
  function* () {
    let state: IState = yield select();
    let currentUserId = getUserId(state);

    while (!currentUserId) {
      yield take();
      state = yield select();
      currentUserId = getUserId(state);
    }

    if (!isListening(state, pendingNotificationsCollection, currentUserId)) {
      yield put(
        subscribe(pendingNotificationsCollection + '/' + currentUserId),
      );
    }
  },
);
