import { NAVIGATION_SERVICE_BASE_URL } from 'config';
import INavigation from 'models/INavigation';
import maestroApiRequest from 'services/maestro-api-client';

interface IBaseAPIParams {
  primaryToken: string;
  siteId: string;
}

export interface IDeleteCustomNavigationResponse {
  pages: string[];
  result: boolean;
}

export const updateNavigationAPI = async ({ primaryToken, siteId, navigation }: IBaseAPIParams & { navigation: INavigation }): Promise<INavigation> => {
  const response = await maestroApiRequest({
    primaryToken,
    siteId,
  }).put<INavigation>(`${NAVIGATION_SERVICE_BASE_URL}/${navigation._id}`, navigation);

  return response.data;
};

export const createCustomNavigationAPI = async ({ primaryToken, siteId, navigation }: IBaseAPIParams & { navigation: INavigation }): Promise<INavigation> => {
  const response = await maestroApiRequest({
    primaryToken,
    siteId,
  }).post<INavigation>(`${NAVIGATION_SERVICE_BASE_URL}/`, navigation);

  return response.data;
};

export const deleteCustomNavigationAPI = async ({ primaryToken, siteId, navigationId }: IBaseAPIParams & { navigationId: string }): Promise<IDeleteCustomNavigationResponse> => {
  const response = await maestroApiRequest({
    primaryToken,
    siteId,
  }).delete(`${NAVIGATION_SERVICE_BASE_URL}/${navigationId}`);

  return response.data;
};

export const fetchAllNavigationsAPI = async ({ primaryToken, siteId }: IBaseAPIParams): Promise<INavigation[]> => {
  const response = await maestroApiRequest({
    primaryToken,
    siteId,
  }).get<INavigation[]>(`${NAVIGATION_SERVICE_BASE_URL}/`);

  return response.data;
};

export const fetchDefaultNavigationAPI = async ({ primaryToken, siteId }: IBaseAPIParams): Promise<INavigation> => {
  const response = await maestroApiRequest({
    primaryToken,
    siteId,
  }).get<INavigation>(`${NAVIGATION_SERVICE_BASE_URL}/default/navigation`);

  return response.data;
};

export const fetchNavigationAPI = async ({ primaryToken, siteId, navigationId }: IBaseAPIParams & { navigationId: string }): Promise<INavigation> => {
  const response = await maestroApiRequest({
    primaryToken,
    siteId,
  }).get<INavigation>(`${NAVIGATION_SERVICE_BASE_URL}/${navigationId}`);

  return response.data;
};

export const applyNavigationToPageAPI = async ({
  primaryToken, siteId, navigationId, pageId,
}: IBaseAPIParams & { navigationId: string, pageId: string }): Promise<void> => {
  await maestroApiRequest({
    primaryToken,
    siteId,
  }).post<INavigation>(`${NAVIGATION_SERVICE_BASE_URL}/apply/${navigationId}`, { pageId });
};
