import { isObject, isPlainObject, transform } from 'lodash';

/**
 * Transforms all keys in a given object deeply using the provided callback function,
 * while keeping the values intact.
 *
 * @template T - The type of the object.
 * @param {T} obj - The object to transform.
 * @param {(v: string) => string} callback - The callback function to transform keys.
 * @returns {T} A new object with transformed keys.
 */
export function transformKeysDeep<T extends Record<string, any>>(obj: T, callback: (v: string) => string) {
  if (!isObject(obj)) {
    return obj;
  }

  return transform(obj, (result, value, key) => {
    const transformedKey = callback(key);
    result[transformedKey] = isPlainObject(value) ? transformKeysDeep(value, callback) : value;
  }, {} as Record<string, any>);
}
