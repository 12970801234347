import { IVideo } from 'models';
import IStream from 'models/IStream';
import { IStreamProviderAccount } from 'models/IStreamProviderAccount';
import { createSelector } from 'reselect';
import IState from 'services/state';
import { getDocumentPath } from 'utils';

export const getRealtimeDocuments = (state: IState) => state.realtime.documents;

export const isDocumentLoaded = (state: IState, collection: string, id: string) =>
  state.realtime.documents[getDocumentPath(collection, id)] !== undefined;

export const getDocument = createSelector(
  (state: IState, collection: string, id: string) => state.realtime.documents[getDocumentPath(collection, id)],
  (document) => (document === undefined ? null : document),
);

const getDocumentsByKey = <T>(documents: { [key: string]: T }, key: string) => {
  return Object.entries(documents).reduce((acc, [path, document]) => {
    if (path.startsWith(key)) {
      acc[path] = document;
    }
    return acc;
  }, {} as { [key: string]: T });
};

export const getStreamProviderAccountDocuments = createSelector(
  getRealtimeDocuments,
  (documents) => getDocumentsByKey<IStreamProviderAccount | undefined>(documents, 'streamprovideraccount'),
);

export const getVideoDocuments = createSelector(
  getRealtimeDocuments,
  (documents) => getDocumentsByKey<IVideo | null | undefined>(documents, 'video'),
);

export const getStreamDocuments = createSelector(
  getRealtimeDocuments,
  (documents) => getDocumentsByKey<IStream | null | undefined>(documents, 'stream'),
);

export const isListening = (state: IState, collection: string, id: string) =>
  state.realtime.listeners[getDocumentPath(collection, id)] !== undefined;
