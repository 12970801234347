import invariant from 'invariant';

import EventBuilder from './EventBuilder';

class CardEventBuilder extends EventBuilder {
  constructor() {
    super('card');
  }

  validateTrack(action, renderer) {
    invariant(
      renderer,
      'CardEventBuilder: null renderer',
    );
    return true;
  }

  /* eslint-disable class-methods-use-this */
  buildOntology(action, renderer) {
    return {
      /* eslint-disable sort-keys */
      phylum: action,
      class: renderer.cardType,
      order: renderer.id,
      family: renderer.title,
    };
  }

  buildMetadata(action, renderer, extra) {
    const { cardType: kind } = renderer;
    invariant(
      kind,
      'Card type missing in buildOntology',
    );
    switch (kind) {
      case 'image':
        if (action === 'view' || action === 'dismiss') {
          return {
            image: renderer.imageUrl,
          };
        }
        if (action === 'engage') {
          return {
            image: renderer.imageUrl,
            ...extra,
          };
        }
        return null;
      case 'instagram':
        return {
          post: renderer.postUrl,
        };
      case 'message':
        if (action === 'engage') {
          return extra;
        }
        return null;
      case 'person':
        return {
          person: renderer.personId,
        };
      case 'poll':
        return {
          poll: renderer.pollId,
        };
      case 'tweet':
        return {
          url: renderer.tweetUrl,
        };
      default:
        invariant(
          false,
          `CardEventBuilder: Unknown card kind ${JSON.stringify(kind)}`,
        );
        return null; // Appease linter; invariant throws
    }
  }
}
/* eslint-enable class-methods-use-this */

export default new CardEventBuilder();
