import { PaymentMethod, Stripe, StripeCardNumberElement } from '@stripe/stripe-js';
import { IShippingAddress } from 'services/shipping-address';
import {
  IConnectingShopResponse,
  IShopInfoResponse,
  ICheckoutStorefront,
  ISaveBillingDataToSourcePayload,
  IProduct,
  ICollection,
  IPageInfo,
  IProductVariant,
  IStripeTokenResponse,
  IRecurringCharge,
} from './models';

export const LOADING_PRODUCTS = 'shopify/LOADING_PRODUCTS';
export const SET_PRODUCTS = 'shopify/SET_PRODUCTS';
export const SUCCESS_PRODUCTS = 'shopify/SUCCESS_PRODUCTS';
export const FAIL_PRODUCTS = 'shopify/FAIL_PRODUCTS';
export const LOADING_COLLECTIONS = 'shopify/LOADING_COLLECTIONS';
export const SET_COLLECTIONS = 'shopify/SET_COLLECTIONS';
export const SUCCESS_COLLECTIONS = 'shopify/SUCCESS_COLLECTIONS';
export const FAIL_COLLECTIONS = 'shopify/FAIL_COLLECTIONS';
export const REMOVE_SHOP = 'shopify/REMOVING_SHOP';
export const FAIL_SHOP_REMOVAL = 'shopify/FAIL_SHOP_REMOVAL';
export const SUCCESS_SHOP_REMOVAL = 'shopify/SUCCESS_SHOP_REMOVAL';
export const ADD_CART_ITEM = 'shopify/ADD_CART_ITEM';
export const CONNECTING_SHOP = 'shopify/CONNECTING_SHOP';
export const SUCCESS_CONNECTING_SHOP = 'shopify/SUCCESS_CONNECTING_SHOP';
export const FAIL_CONNECTING_SHOP = 'shopify/FAIL_CONNECTING_SHOP';
export const SHOP_RECURRING_CHARGE = 'shopify/SHOP_RECURRING_CHARGE';
export const SHOP_INFO = 'shopify/SHOP_INFO';
export const SUCCESS_SHOP_INFO = 'shopify/SUCCESS_SHOP_INFO';
export const FAIL_SHOP_INFO = 'shopify/FAIL_SHOP_INFO';
export const SET_PAYMENT_METHOD = 'shopify/SET_PAYMENT_METHOD';
export const SAVE_BILLING_DATA_TO_SOURCE = 'shopify/SAVE_BILLING_DATA_TO_SOURCE';
export const SET_SINGLE_USE_SOURCE_CARD = 'shopify/SET_SINGLE_USE_SOURCE_CARD';
export const REMOVE_SINGLE_USE_SOURCE_CARD = 'shopify/REMOVE_SINGLE_USE_SOURCE_CARD';
export const CHECKOUT_BUNDLE_SHOPIFY_ITEMS = 'shopify/CHECKOUT_BUNDLE_SHOPIFY_ITEMS';
export const SET_BUNDLE_LOADING = 'shopify/SET_BUNDLE_LOADING';
export const SET_BUNDLE_ERROR = 'shopify/SET_BUNDLE_ERROR';
export const SET_BUNDLE_CHECKOUT = 'shopify/SET_BUNDLE_CHECKOUT';
export const SET_PAYMENT_TOKEN = 'shopify/SET_PAYMENT_TOKEN';
export const SET_BUNDLE_SUCCESS = 'shopify/SET_BUNDLE_SUCCESS';
export const CREATE_PAYMENT_TOKEN = 'shopify/CREATE_PAYMENT_TOKEN';
export const PAY_BUNDLE_ITEMS = 'shopify/PAY_BUNDLE_ITEMS';
export const RESET_BUNDLE = 'shopify/RESET_BUNDLE';
export interface ILoadingProducts {
  type: typeof LOADING_PRODUCTS;
}

export interface ICheckoutBundleShopifyItemsPayload {
  productsVariant: Record<string, IProductVariant['node']>;
  shippingAddress: IShippingAddress;
}

export interface ICheckoutBundleShopifyItemsAction {
  payload: ICheckoutBundleShopifyItemsPayload;
  type: typeof CHECKOUT_BUNDLE_SHOPIFY_ITEMS;
}

export interface ISetProducts {
  payload: {
    pageInfo: IPageInfo,
    products: IProduct[],
  }
  type: typeof SET_PRODUCTS
}

export interface ISuccessProducts {
  type: typeof SUCCESS_PRODUCTS;
}

export interface IFailProducts {
  payload: any;
  type: typeof FAIL_PRODUCTS;
}

export interface ILoadingCollections {
  type: typeof LOADING_COLLECTIONS;
}

export interface ISetCollections {
  payload: {
    collections: ICollection[],
    pageInfo: IPageInfo,
  };
  type: typeof SET_COLLECTIONS;
}

export interface ISuccessCollections {
  type: typeof SUCCESS_COLLECTIONS;
}

export interface IFailCollections {
  payload: any;
  type: typeof FAIL_COLLECTIONS;
}

export interface IRemoveShop {
  payload: any;
  type: typeof REMOVE_SHOP;
}

export interface IFailShopRemoval {
  payload: any;
  type: typeof FAIL_SHOP_REMOVAL;
}

export interface ISuccessShopRemoval {
  type: typeof SUCCESS_SHOP_REMOVAL;
}

export interface IConnectingShop {
  type: typeof CONNECTING_SHOP;
}

export interface ISuccessConnectingShop {
  payload: IConnectingShopResponse;
  type: typeof SUCCESS_CONNECTING_SHOP;
}

export interface IFailConnectionShop {
  payload: any;
  type: typeof FAIL_CONNECTING_SHOP;
}

export interface ISuccessShopInfo {
  payload: IShopInfoResponse;
  type: typeof SUCCESS_SHOP_INFO;
}

export interface IFailShopInfo {
  payload: any;
  type: typeof FAIL_SHOP_INFO;
}

export interface IShopInfo {
  type: typeof SHOP_INFO;
}

export interface IShopRecurringCharge {
  payload: IRecurringCharge;
  type: typeof SHOP_RECURRING_CHARGE;
}

export interface ISetPaymentMethod {
  payload: any
  type: typeof SET_PAYMENT_METHOD
}

export type ISaveBillingDataToSource = {
  payload: ISaveBillingDataToSourcePayload;
  type: typeof SAVE_BILLING_DATA_TO_SOURCE;
};

export type ISetSingleUseSourceCard = {
  payload: string;
  type: typeof SET_SINGLE_USE_SOURCE_CARD;
};

export type IRemoveSingleUseSourceCard = {
  payload: string;
  type: typeof REMOVE_SINGLE_USE_SOURCE_CARD;
};

export interface ISetBundleLoadingAction {
  payload: boolean;
  type: typeof SET_BUNDLE_LOADING;
}

export interface ISetBundleCheckoutAction {
  payload: ICheckoutStorefront | null;
  type: typeof SET_BUNDLE_CHECKOUT;
}

export interface ISetPaymentTokenAction {
  payload: IStripeTokenResponse | string;
  type: typeof SET_PAYMENT_TOKEN;
}

export interface ISetBundleErrorAction {
  payload: string;
  type: typeof SET_BUNDLE_ERROR;
}

export interface ISetBundleSuccessAction {
  payload: boolean | null;
  type: typeof SET_BUNDLE_SUCCESS;
}

export interface ICreatePaymentTokenPayload {
  element?: StripeCardNumberElement;
  paymentMethod?: PaymentMethod;
  stripe: Stripe;
}

export interface ICreatePaymentTokenAction {
  payload: ICreatePaymentTokenPayload;
  type: typeof CREATE_PAYMENT_TOKEN;
}

export interface IPayBundleItemsPayload {
  // TODO: add types
  checkout: any;
}

export interface IPayBundleItemsAction {
  payload: IPayBundleItemsPayload;
  type: typeof PAY_BUNDLE_ITEMS;
}

export interface IResetBundleAction {
  type: typeof RESET_BUNDLE;
}


export type IShopifyActions = (
  ILoadingProducts |
  ISetProducts |
  ISuccessProducts |
  IFailProducts |
  ILoadingCollections |
  ISetCollections |
  ISuccessCollections |
  IFailCollections |
  IRemoveShop |
  IFailShopRemoval |
  ISuccessShopRemoval |
  IConnectingShop |
  ISuccessConnectingShop |
  IFailConnectionShop |
  ISaveBillingDataToSource |
  ICheckoutBundleShopifyItemsAction |
  ISetBundleLoadingAction |
  ISetBundleCheckoutAction |
  ISetPaymentTokenAction |
  ISetBundleErrorAction |
  ISetBundleSuccessAction |
  ICreatePaymentTokenAction |
  IPayBundleItemsAction |
  IResetBundleAction
);

export const setPaymentToken = (payload: IStripeTokenResponse | string): ISetPaymentTokenAction => ({
  payload,
  type: SET_PAYMENT_TOKEN,
});

export const setBundleCheckout = (payload: ICheckoutStorefront | null): ISetBundleCheckoutAction => ({
  payload,
  type: SET_BUNDLE_CHECKOUT,
});

export const setBundleLoading = (payload: boolean): ISetBundleLoadingAction => ({
  payload,
  type: SET_BUNDLE_LOADING,
});

export const setBundleError = (payload: string): ISetBundleErrorAction => ({
  payload,
  type: SET_BUNDLE_ERROR,
});

export const loadingProducts = (): ILoadingProducts => ({
  type: LOADING_PRODUCTS,
});

export const setProducts = (payload: { pageInfo: IPageInfo ,products: IProduct[]}): ISetProducts => ({
  payload,
  type: SET_PRODUCTS,
});

export const successProducts = (): ISuccessProducts => ({
  type: SUCCESS_PRODUCTS,
});

export const failProducts = (payload: any): IFailProducts => ({
  payload,
  type: FAIL_PRODUCTS,
});

export const loadingCollections = (): ILoadingCollections => ({
  type: LOADING_COLLECTIONS,
});

export const setCollections = (payload: { collections: ICollection[], pageInfo: IPageInfo }): ISetCollections => ({
  payload,
  type: SET_COLLECTIONS,
});

export const successCollections = (): ISuccessCollections => ({
  type: SUCCESS_COLLECTIONS,
});

export const failCollections = (payload: any): IFailCollections => ({
  payload,
  type: FAIL_COLLECTIONS,
});

export const removeShop = (payload: any): IRemoveShop => ({
  payload,
  type: REMOVE_SHOP,
});

export const failShopRemoval = (payload: any): IFailShopRemoval => ({
  payload,
  type: FAIL_SHOP_REMOVAL,
});

export const successShopRemoval = (): ISuccessShopRemoval => ({
  type: SUCCESS_SHOP_REMOVAL,
});

export const connectingShop = (): IShopifyActions => ({
  type: CONNECTING_SHOP,
});

export const successConnectingShop = (payload: IConnectingShopResponse): ISuccessConnectingShop => ({
  payload,
  type: SUCCESS_CONNECTING_SHOP,
});

export const failConnectingShop = (payload: any): IFailConnectionShop => ({
  payload,
  type: FAIL_CONNECTING_SHOP,
});

export const shopInfo = (): IShopInfo => ({
  type: SHOP_INFO,
});

export const recurringCharge = (payload: IRecurringCharge): IShopRecurringCharge => ({
  type: SHOP_RECURRING_CHARGE,
  payload,
});

export const successShopInfo = (payload: IShopInfoResponse): ISuccessShopInfo => ({
  payload,
  type: SUCCESS_SHOP_INFO,
});

export const failShopInfo = (payload: any): IFailShopInfo => ({
  payload,
  type: FAIL_SHOP_INFO,
});

export const saveBillingDataToSource = (payload: ISaveBillingDataToSourcePayload): ISaveBillingDataToSource => ({
  payload,
  type: SAVE_BILLING_DATA_TO_SOURCE,
});

export const setSingleUseSourceCard = (payload: string): ISetSingleUseSourceCard => ({
  payload,
  type: SET_SINGLE_USE_SOURCE_CARD,
});

export const removeSingleUseSourceCard = (payload: string): IRemoveSingleUseSourceCard => ({
  payload,
  type: REMOVE_SINGLE_USE_SOURCE_CARD,
});

export const checkoutBundleShopifyItems = (payload: ICheckoutBundleShopifyItemsPayload): ICheckoutBundleShopifyItemsAction => ({
  payload,
  type: CHECKOUT_BUNDLE_SHOPIFY_ITEMS,
});

export const setBundleSuccess = (payload: boolean): ISetBundleSuccessAction => ({
  payload,
  type: SET_BUNDLE_SUCCESS,
});

export const createPaymentToken = (payload: ICreatePaymentTokenPayload): ICreatePaymentTokenAction => ({
  payload,
  type: CREATE_PAYMENT_TOKEN,
});

export const resetBundle = (): IResetBundleAction => ({
  type: RESET_BUNDLE,
});
