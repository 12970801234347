
import { hasOwn } from 'utils';
import { ModeTypes } from 'services/admin/constants';
import { getSidebarDraft } from 'services/admin/selectors';
import { createSelector } from 'reselect';
import { shouldRenderChannelGate } from 'services/gate/selectors/common';
import {
  getLegacyRenderer,
} from './legacy';

/**
  * Before Channels 2.0, Draft Sidebar was stored on regionRendererDrafts but we moved it to be part
  * of object collection (data.renderers.sidebar). With that we can persist changes
  * on the localstorage because object collection is stored there.
*/
const getDraftSidebar = (state) => {
  const draftSidebar = getSidebarDraft(state);
  const parsedDraftSidebar = draftSidebar ? { sidebar: draftSidebar } : null;
  const legacyDraftSidebar = state.admin.regionRendererDrafts;
  return parsedDraftSidebar || legacyDraftSidebar;
};

export const getRenderer = createSelector(
  (state, rendererId) => {
    const editing = state.admin.adminMode === ModeTypes.EDIT;
    const isChannelGateOn = shouldRenderChannelGate(state);

    const drafts = getDraftSidebar(state);

    if (editing && hasOwn(drafts, rendererId)) {
      return drafts[rendererId];
    }

    const target = state.app.object;

    if (!target) {
      return null;
    }

    if ((target.activeRenderers?.[rendererId] || !target.created) && !isChannelGateOn) {
      return target.renderers?.[rendererId] || null;
    }

    return getLegacyRenderer(state, rendererId);
  },
  renderer => {
    return renderer;
  },
);
