import { getObject } from 'services/app';
import { isLivestreamPublished } from 'services/livestream';
import IState from 'services/state';
import type { MaestroReadyBasePayload } from './models';

export const getSDKInitialLocale = (state: IState) => state.iframe.sdk.initIFrameSDKLocale;
export const getParentOrigin = (state: IState) => state.iframe.sdk.parentOrigin;
export const getMaestroReadyBasePayload = (state: IState): MaestroReadyBasePayload => {
  const currentPageOrChannel = getObject(state);
  return {
    slug: currentPageOrChannel.slug,
    pageType: currentPageOrChannel.type,
    channelStatus: isLivestreamPublished(state)
      ? 'live'
      : 'offline',
    seo: currentPageOrChannel.seo,
  } as const;
};
