// tslint:disable:no-console
import { IState } from 'services/state';
import {
  call,
  select,
} from 'redux-saga/effects';
import { getMaestroStreamId } from '../../selectors';
import { getEventBase } from 'services/insights/selectors';
import { createFinalEvent } from 'services/insights/saga';

export function* makeStartEvent() {
  const state: IState = yield select();
  const eventBase = yield getEventBase(state);
  const maestroStreamId = getMaestroStreamId(state);
  return yield call(
    createFinalEvent as any,
    {
      event: {
        ontology: {
          class: 'livestream',
          family: 'Unscheduled Livestream',
          kingdom: 'admin',
          order: maestroStreamId,
          phylum: 'start',
          // metadata is filled by the back-end
          metadata: {},
        },
        url: window.location.href,
      },
      eventBase,
    },
  );
}
