import uuid from 'uuid/v4';
import {
  CHARGE_TIP,
  CHARGE_TIP_FAILURE,
  CHARGE_TIP_SUCCESS,
  SET_TIP_AMOUNT,
  SET_TIP_MESSAGE,
  ITippingAction,
  CLOSE_SUCCESS_VIEW,
  SET_TIP_SUGGESTED_AMOUNTS,
  REFRESH_IDEMPOTENCY_KEY,
} from './actions';
import { ITipSuggestedAmounts } from './models';
import { USD_SUGGESTED_TIP_AMOUNT } from './utils';

export interface ITippingInitialState {
  amount: number;
  idempotencyKey: string;
  loadingChargeTip: boolean;
  message: string;
  successChargeTip: boolean;
  tipSuggestedAmounts: ITipSuggestedAmounts;
  usingPaymentRequestButtonForm: boolean;
}

export type ITippingState = ITippingInitialState;

const INITIAL_STATE: ITippingInitialState = {
  amount: 0,
  idempotencyKey: uuid(),
  usingPaymentRequestButtonForm: false,
  loadingChargeTip: false,
  message: '',
  successChargeTip: false,
  tipSuggestedAmounts: USD_SUGGESTED_TIP_AMOUNT,
};

export const getInitialState = (): ITippingState => ({
  ...INITIAL_STATE,
});

export default function tippingReducer(
  state: ITippingState = INITIAL_STATE,
  action: ITippingAction,
): ITippingState {
  switch (action.type) {
    case REFRESH_IDEMPOTENCY_KEY: {
      return {
        ...state,
        idempotencyKey: uuid(),
      };
    }
    case SET_TIP_SUGGESTED_AMOUNTS: {
      return {
        ...state,
        tipSuggestedAmounts: action.payload,
      };
    }
    case SET_TIP_AMOUNT: {
      return {
        ...state,
        amount: action.payload.amount,
      };
    }
    case SET_TIP_MESSAGE: {
      return {
        ...state,
        message: action.payload.message,
      };
    }
    case CHARGE_TIP: {
      return {
        ...state,
        loadingChargeTip: true,
        usingPaymentRequestButtonForm: action.payload.usingPaymentRequestButtonForm,
      };
    }
    case CHARGE_TIP_SUCCESS: {
      return {
        ...state,
        loadingChargeTip: false,
        usingPaymentRequestButtonForm: false,
        successChargeTip: true,
      };
    }
    case CHARGE_TIP_FAILURE: {
      return {
        ...state,
        loadingChargeTip: false,
        usingPaymentRequestButtonForm: false,
      };
    }
    case CLOSE_SUCCESS_VIEW: {
      return {
        ...state,
        successChargeTip: false,
        message: '',
        amount: 0,
      };
    }
    default:
      return state;
  }
}
