import { ITheme } from 'models';
import maestroApiRequest from 'services/maestro-api-client';
import { THEMES_SERVICE_SERVICE_BASE_URL } from 'config';
import { createCustomThemeFromClassicOrAdvanced } from './utils';
import { CLASSIC_THEMES } from './constants';
import { THEME_TYPES } from 'models/ITheme';

interface IBaseAPIParams {
  primaryToken: string;
  siteId: string;
}

interface IGetThemeParams extends IBaseAPIParams {
  themeId: string;
}

export interface IUpsertThemeParams extends IBaseAPIParams {
  id: string;
  theme: ITheme;
}

type IDeleteThemeParams = IGetThemeParams;

export interface IDeleteThemeResponse {
  deleted: boolean;
  pages: string[];
}

export const upsertTheme = async ({ theme, id, primaryToken, siteId }: IUpsertThemeParams): Promise<ITheme> => {
  const isCustomTheme = theme.type === THEME_TYPES.CUSTOM;

  if (isCustomTheme) {
    await maestroApiRequest({
      primaryToken,
      siteId,
    }).put(`${THEMES_SERVICE_SERVICE_BASE_URL}/custom/${id}`, theme);
    return theme;
  }

  const customTheme = createCustomThemeFromClassicOrAdvanced(theme);
  const result = await maestroApiRequest({
    primaryToken,
    siteId,
  }).post<ITheme>(`${THEMES_SERVICE_SERVICE_BASE_URL}/custom`, customTheme);
  return result.data;
};

export const getThemeById = async ({ primaryToken, siteId, themeId }: IGetThemeParams): Promise<ITheme> => {
  const classicTheme = CLASSIC_THEMES.find(theme => theme._id === themeId);
  if (classicTheme) {
    return classicTheme;
  }

  const response = await maestroApiRequest({
    primaryToken,
    siteId,
  }).get<ITheme>(`${THEMES_SERVICE_SERVICE_BASE_URL}/${themeId}`);

  return response.data;
};

export const deleteTheme = async ({ primaryToken, siteId, themeId }: IDeleteThemeParams): Promise<IDeleteThemeResponse> => {
  const response = await maestroApiRequest({
    primaryToken,
    siteId,
  }).delete(`${THEMES_SERVICE_SERVICE_BASE_URL}/${themeId}`);

  return response.data as IDeleteThemeResponse;
};
