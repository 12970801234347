import { IUbisoftAdobe } from './models';

export const INITIALIZE_UBISOFT = 'third-party-analytics/INITIALIZE_UBISOFT';
export const SET_ACTIVE = 'third-party-analytics/SET_ACTIVE';
export const ON_CLAIM_SUCCESS = 'third-party-analytics/ON_CLAIM_SUCCESS';
export const ON_NAV_CLICK = 'third-party-analytics/ON_NAV_CLICK';
export const ON_SOCIAL_CLICK = 'third-party-analytics/ON_SOCIAL_CLICK';
export const ON_SOCIAL_SHARE_CLICK = 'third-party-analytics/ON_SOCIAL_SHARE_CLICK';
export const ON_QUEST_ACCESS_TOKEN = 'third-party-analytics/ON_QUEST_ACCESS_TOKEN';
export const ON_VIDEO_LINK_CLICK = 'third-party-analytics/ON_VIDEO_LINK_CLICK';
export const UPDATE_UBISOFT = 'third-party-analytics/UPDATE_UBISOFT';

export type IThirdPartyAnalyticsAction = (
  IInitializeUbisoftAction |
  ISetActiveAction | IUpdateUbisoftAction |
  IOnClaimSuccessAction | IOnQuestAccessTokenAction |
  IOnNavClickAction | IOnVideoLinkClickAction | IOnSocialClickAction |
  IOnSocialShareClickAction);

interface ISetActiveAction {
  payload: boolean;
  type: typeof SET_ACTIVE
}
export const setActive = (value: boolean): ISetActiveAction => ({
  payload: value,
  type: SET_ACTIVE,
});

interface IInitializeUbisoftAction {
  type: typeof INITIALIZE_UBISOFT;
}

export const initializeUbisoft = (): IInitializeUbisoftAction => ({
  type: INITIALIZE_UBISOFT,
});

interface IUpdateUbisoftAction {
  payload: IUbisoftAdobe;
  type: typeof UPDATE_UBISOFT;
}

export const updateUbisoft = (payload: IUbisoftAdobe): IUpdateUbisoftAction => ({
  payload,
  type: UPDATE_UBISOFT,
});

interface IOnClaimSuccessPayload {
  rewardDescription: string;
  rewardType: string;
  title: string;
}

export interface IOnClaimSuccessAction {
  payload: IOnClaimSuccessPayload;
  type: typeof ON_CLAIM_SUCCESS;
}

export const onClaimSuccess = (
  payload: IOnClaimSuccessPayload,
): IOnClaimSuccessAction => ({
  payload,
  type: ON_CLAIM_SUCCESS,
});

export interface IOnQuestAccessTokenAction {
  payload: { accessToken: string };
  type: typeof ON_QUEST_ACCESS_TOKEN;
}
export const onQuestAccessToken = (accessToken: string): IOnQuestAccessTokenAction => ({
  payload: { accessToken },
  type: ON_QUEST_ACCESS_TOKEN,
});

interface IOnClickPayload {
  action: string;
  category: string;
  location: string;
}

export interface IOnNavClickAction {
  payload: IOnClickPayload;
  type: typeof ON_NAV_CLICK;
}

export const onNavClick = (reference: string): IOnNavClickAction => ({
  payload: {
    action: reference,
    category: 'action',
    location: 'navigation',
  },
  type: ON_NAV_CLICK,
});

export interface IOnSocialClickAction {
  payload: IOnClickPayload;
  type: typeof ON_SOCIAL_CLICK;
}

export const onSocialClick = (socialNetwork: string): IOnSocialClickAction => ({
  payload: {
    action: socialNetwork,
    category: 'action',
    location: 'social',
  },
  type: ON_SOCIAL_CLICK,
});

export interface IOnVideoLinkClickAction {
  payload: IOnClickPayload;
  type: typeof ON_VIDEO_LINK_CLICK;
}

export const onVideoLinkClick = (videoId: string): IOnVideoLinkClickAction => ({
  payload: {
    action: videoId,
    category: 'video',
    location: 'page',
  },
  type: ON_VIDEO_LINK_CLICK,
});

export interface IOnSocialShareClickAction {
  payload: IOnClickPayload;
  type: typeof ON_SOCIAL_SHARE_CLICK;
}

export const onSocialShareClick = (platform: string): IOnSocialShareClickAction => ({
  payload: {
    action: `social share ${platform}`,
    category: 'social',
    location: 'page',
  },
  type: ON_SOCIAL_SHARE_CLICK,
});

export type IOnClickAction = (IOnNavClickAction |
  IOnSocialClickAction | IOnVideoLinkClickAction |
  IOnSocialShareClickAction);
