import i18n, { InitOptions } from 'i18next';
import { initReactI18next } from 'react-i18next';
import type { TTranslator } from 'hooks/use-translation';
import enDictionary from 'strings/en-MAESTRO';
import { setupHtmlParser } from './htmlParser';
import { makeFuture } from 'utils/future';

const defaultLangCode = 'en-MAESTRO';

const i18nFuture = makeFuture<TTranslator>();

export const setupI18n = async () => {
  await Promise.all([
    (async () => {
      const { langCode, admin, endUser } = (await window.TRANSLATIONS) || {};

      const resources: InitOptions['resources'] = {
        [defaultLangCode]: {
          translation: {
            admin: enDictionary,
          },
        },
      };

      if (langCode) {
        resources[langCode] = {
          translation: {
            admin: admin || {},
            endUser: endUser || {},
          },
        };
      }

      i18nFuture.resolve(
        await i18n
          .use(initReactI18next)
          .init({
            resources,
            lng: langCode,
            fallbackLng: defaultLangCode,
            interpolation: {
              escapeValue: false,
            },
          }),
      );
    })(),
    setupHtmlParser(),
  ]);
};

/**
 * Returns a Promise that will resolve to the `t` function
 * when setup is finished.
 */
export const getT = () => i18nFuture.promise;
