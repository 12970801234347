import { makeFuture } from 'utils/future';

const userInteractionFuture = makeFuture<boolean>();

/**
 * A `Promise` that resolves as soon as the user interacts with the page
 */
export const waitForUserInteraction = userInteractionFuture.promise;

/**
 * Attaches event listeners to detect when the user interacts with the page.
 * The main use case for this is to determine wether or not a video can autoplay.
 *
 * See https://developer.mozilla.org/en-US/docs/Web/Media/Autoplay_guide for more details.
 */
export const setupUserInteractionListener = () => {
  const handleClickEvent = () => {
    userInteractionFuture.resolve(true);
    window.removeEventListener('click', handleClickEvent);
  };

  window.addEventListener('click', handleClickEvent);
};
