export enum IUserRoles {
  ADMIN = 'admin',
  USER = 'viewer',
}

export interface IUserTraits {
  accountId: string;
  accountProvider: string;
  creatorType?: string;
  email?: string;
  mobile: boolean;
  role: IUserRoles;
  siteId: string;
  siteName: string;
  ssu: boolean;
}
