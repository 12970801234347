import axios from 'axios';
import { ACCOUNTS_SERVICE_BASE_URL, SITE_ID } from 'config';

export const sendForgotPasswordEmail = async ({
    email,
    siteId = SITE_ID,
    baseUrl,
  }: {
    baseUrl: string;
    email: string;
    siteId: string;
  }) => {
    const { data } = await axios.post<any>(`${ACCOUNTS_SERVICE_BASE_URL}/reset`, {
      email,
      baseUrl,
    }, {
      headers: {
        'x-maestro-client-id': siteId,
      },
    });
    return data;
  };