import shortid from 'shortid';
import { hasOwn } from 'utils';
import jennyWillsProfile from 'assets/chat-panel-preview-profiles/jenny-wills-profile.png';
import johnSmithProfile from 'assets/chat-panel-preview-profiles/john-smith-profile.png';
import kimYongProfile from 'assets/chat-panel-preview-profiles/kim-yong-profile.png';
import liaStoneProfile from 'assets/chat-panel-preview-profiles/lia-stone-profile.png';
import markClarkProfile from 'assets/chat-panel-preview-profiles/mark-clark-profile.png';
import marthaJulesProfile from 'assets/chat-panel-preview-profiles/martha-jules-profile.png';
import willBuckProfile from 'assets/chat-panel-preview-profiles/will-buck-profile.png';

const warnedKinds = {};

const SOCIAL_USERNAME = 'username';
const SOCIAL_HASHTAG = 'hashtag';
const SOCIAL_ADVANCED = 'advanced';

const computePanelRendererDataFromDoc = (doc) => {
  if (!doc) {
    return null;
  }
  const { data, renderer } = doc;
  if (renderer) {
    return renderer;
  }
  if (!data) {
    return null;
  }
  const { kind } = data;
  switch (kind) {
    case 'chat':
      return {
        filterText: data.filteredWords || '', // TODO: This should NOT be a string, omg
        id: doc._id,
        systemImage: data.system?.image || null,
        systemName: data.system?.name || null,
        welcomeMessage: data.system?.welcomeMessage || null,
      };
    case 'multiple_choice':
      return {
        questions: (data.questions || [])
          .map((item) => {
            const { kind: questionKind } = item;
            const id = item[questionKind]?._id;
            if (!id) {
              return null;
            }
            return {
              arrayId: item.arrayId || shortid.generate(),
              pollId: id,
            };
          }),
      };
    case 'playlist': {
      return {
        legacy: true,
      };
    }
    case 'social': {
      const sharedSocialsData = {
        id: doc._id,
        legacy: true,
        tumblrUrl: data.tumblr,
        twitterBlacklist: data.twitter?.blacklist,
        twitterShowRetweets: data.twitter?.showRetweets,
      };
      const twitterSource = data.twitter?.source;
      switch (twitterSource) {
        case SOCIAL_USERNAME:
          return {
            ...sharedSocialsData,
            twitterUsername: data.twitter?.username,
          };
        case SOCIAL_HASHTAG:
          return {
            ...sharedSocialsData,
            twitterHashtag: data.twitter?.hashtag,
          };
        case SOCIAL_ADVANCED: {
          const twitterSources = data.twitter.sources.map((twitterObj) => {
            const hashtagOrUsername = twitterObj.source === 'username' ? {
              twitterUsername: twitterObj.username,
            } : {
              twitterHashtag: twitterObj.hashtag,
            };
            return {
              ...hashtagOrUsername,
              arrayId: twitterObj.arrayId,
            };
          });
          return {
            ...sharedSocialsData,
            twitterSources,
          };
        }
        default:
          return sharedSocialsData;
      }
    }
    case 'schedule':
      return {
        artistName: data.artistName,
        channels: data.channels,
        id: doc._id,
        legacy: true,
        manualEvents: data.events,
        scheduleKind: data.source,
      };
    case 'iframe':
      return {
        id: doc._id,
        url: data.url,
      };
    case 'image':
      return {
        id: doc._id,
        imageUrl: data.image,
        linkUrl: data.link,
      };
    case 'groups':
      return {
        displayKind: data.display,
        id: doc._id,
        selectedGroups: data.groupsSelection,
      };
    case 'group':
      return {
        groupId: data.group?._id,
        id: doc._id,
      };
    case 'person':
      return {
        id: doc._id,
        personId: data.person?._id,
      };
    case 'twitch_chat':
      return {
        channel: doc.data?.channel,
        enableDarkTheme: doc.data?.enableDarkTheme || false,
        id: doc._id,
      };
    case 'quests':
      return {
        helpLinkUrl: (data.help === 'help_link' && data.helpLink) || null,
        id: doc._id,
        loginImageUrl: data.loginImage || null,
        quests: (data.quests || [])
          .map((item) => {
            const valid = item && item._id;
            if (!valid) {
              return null;
            }
            return {
              // NOTE: While we COULD add a fallback to shortid.generate() here, we can't track
              //       quest action progress without a stable ID. Unlikely that anybody will
              //       intentionally add the same quest twice to a single panel, so this should be
              //       fine.
              arrayId: item.arrayId || item._id,
              questId: item._id,
            };
          })
          .filter(quest => quest),
        rulesLinkUrl: (data.rules === 'rules_link' && data.rulesLink) || null,
      };
    case 'shopify':
      return {
        product: doc.data?.product,
        variant: doc.data?.variant,
      };
    case 'shopifyBlockPanel':
      return {
        product: doc.data?.product,
        variant: doc.data?.variant,
      };
    default:
      // TODO: Implement the rest
      if (!hasOwn(warnedKinds, kind)) {
        warnedKinds[kind] = true;
        // eslint-disable-next-line no-console
        console.warn(`computePanelRendererFromDoc: unknown panel kind: ${JSON.stringify(kind)}`);
      }
      return null;
  }
};

export const computeDefaultPanelRenderData = (renderer) => {
  switch (renderer.panelType) {
    case 'chat': return {
      chat: [
        {
          image: jennyWillsProfile,
          name: 'Jenny Wills',
          text: 'Yes, yes, yes !!!',
          time: 1612203720000,
        },
        {
          image: jennyWillsProfile,
          name: 'Jenny Wills',
          text: 'Yes, yes, yes !!!',
          time: 1612203720000,
        },
        {
          image: liaStoneProfile,
          name: 'Lia Stone',
          text: 'Hey guys this is wonderfull!',
          time: 1612203660000,
        },
        {
          image: johnSmithProfile,
          name: 'John Smith',
          text: 'Wow this livestream is incredible *.*',
          time: 1612203600000,
        },
        {
          image: markClarkProfile,
          name: 'Mark Clark',
          text: 'love that song! one of my favorites of all time. Even better to see it live <3',
          time: 1612203600000,
        },
        {
          image: marthaJulesProfile,
          name: 'Martha Jules',
          text: 'I’m so stoked for this guys, i can wait to see all the comunity reunited again!',
          time: 1612203480000,
        },
        {
          image: kimYongProfile,
          name: 'Kim Yong',
          text: 'I never thought livestreams were this good! My lord!',
          time: 1612203120000,
        },
        {
          image: willBuckProfile,
          name: 'Will Buck',
          text: 'Hey guys this is amazing!',
          time: 'Just now',
        },
        {
          image: jennyWillsProfile,
          name: 'Jenny Wills',
          text: 'Yes, yes, yes !!!',
          time: 'Just now',
        },
      ],
      iconName: 'chat',
      panelName: 'Chat',
      panelType: 'chat',
    };
    default: {
      return renderer;
    }
  }
};

export const computePanelRendererFromDoc = (doc) => {
  let renderer = computePanelRendererDataFromDoc(doc);

  if (renderer) {
    renderer = {
      iconName: renderer.iconName || doc?.data?.icon || '',
      panelName: renderer.panelName || doc?.data?.title || doc?.data?.name || '',
      panelType: renderer.panelType || doc?.data?.kind || null,
      ...renderer,
    };
  } else {
    renderer = {
      iconName: doc?.data?.icon || '',
      legacyGenerated: true,
      panelName: doc?.data?.title || doc?.data?.name || '',
      panelType: doc?.data?.kind || null,
    };
  }

  return renderer;
};
