/**
 * All of those queries can be compiled into typescript using the following command:
 * yarn gqlschema
 * That will generate a file called types.ts in the same folder as this file.
 */

export const graphql = String.raw;

export const GET_SHOP_INFO_QUERY = graphql`
  query getShopInfo {
    shop {
      moneyFormat
      paymentSettings {
        currencyCode
        shopifyPaymentsAccountId
      }
    }
  }
`;

export const GET_PRODUCTS_QUERY = graphql`
  query getProducts($cursor: String) {
    products(first: 100, after: $cursor) {
      pageInfo {
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          handle
          title
          description
          availableForSale
          options {
            id
            name
            values
          }
          variants(first: 100) {
            edges {
              node {
                title
                price {
                  amount
                }
                id
                availableForSale
                requiresShipping
                image {
                  id
                  url
                  altText
                }
                selectedOptions {
                  name
                  value
                }
              }
            }
          }
          images(first: 1) {
            edges {
              node {
                id
                url
                altText
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_PRODUCT_BY_HANDLE_QUERY = graphql`
  query getProductByHandle($productHandle: String!) {
    productByHandle(handle: $productHandle) {
      id
      handle
      title
      description
      availableForSale
      options {
        id
        name
        values
      }
      variants(first: 100) {
        edges {
          node {
            title
            price {
              amount
            }
            id
            availableForSale
            requiresShipping
            image {
              id
              url
              altText
            }
            selectedOptions {
              name
              value
            }
          }
        }
      }
      images(first: 1) {
        edges {
          node {
            id
            url
            altText
          }
        }
      }
    }
  }
`;

export const GET_COLLECTIONS_QUERY = graphql`
  query getCollections($cursor: String) {
    collections(first: 100, after: $cursor) {
      pageInfo {
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          title
          handle
          description
        }
      }
    }
  }
`;

export const GET_COLLECTION_BY_HANDLE_QUERY = graphql`
  query getCollectionByHandle($collectionHandle: String!) {
    collectionByHandle(handle: $collectionHandle) {
      id
      title
      description
      products(first: 100) {
        edges {
          node {
            id
            title
            description
            availableForSale
            options {
              id
              name
              values
            }
            variants(first: 100) {
              edges {
                node {
                  title
                  price {
                    amount
                  }
                  id
                  availableForSale
                  requiresShipping
                  image {
                    id
                    url
                    altText
                  }
                  selectedOptions {
                    name
                    value
                  }
                }
              }
            }
            images(first: 1) {
              edges {
                node {
                  id
                  url
                  altText
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const CREATE_CHECKOUT_WITH_SHIPPING_MUTATION = graphql`
  mutation checkoutCreateWithShipping($input: CheckoutCreateInput!) {
    checkoutCreate(input: $input) {
      checkout {
        shippingAddress {
          firstName
          lastName
          address1
          city
          countryCodeV2
          provinceCode
          zip
          phone
        }
        requiresShipping
        availableShippingRates {
          ready
          shippingRates {
            handle
            priceV2 {
              amount
              currencyCode
            }
            title
          }
        }
        shippingLine {
          handle
          priceV2 {
            amount
            currencyCode
          }
          title
        }
        completedAt
        createdAt
        currencyCode
        customAttributes {
          key
          value
        }
        email
        id
        lineItems(first: 100) {
          edges {
            node {
              id
              title
              quantity
            }
          }
        }
        lineItemsSubtotalPrice {
          amount
          currencyCode
        }
        note
        order {
          id
          name
        }
        orderStatusUrl
        paymentDueV2 {
          amount
          currencyCode
        }
        ready
        subtotalPriceV2 {
          amount
          currencyCode
        }
        taxesIncluded
        taxExempt
        totalPriceV2 {
          amount
          currencyCode
        }
        totalTaxV2 {
          amount
          currencyCode
        }
        updatedAt
        webUrl
      }
      checkoutUserErrors {
        code
        field
        message
      }
    }
  }
`;

export const CREATE_CHECKOUT_WITHOUT_SHIPPING_MUTATION = graphql`
  mutation checkoutCreateWithoutShipping($input: CheckoutCreateInput!) {
    checkoutCreate(input: $input) {
      checkout {
        shippingAddress {
          firstName
          lastName
          address1
          city
          countryCodeV2
          provinceCode
          zip
          phone
        }
        completedAt
        createdAt
        currencyCode
        customAttributes {
          key
          value
        }
        email
        id
        lineItems(first: 100) {
          edges {
            node {
              id
              title
              quantity
            }
          }
        }
        lineItemsSubtotalPrice {
          amount
          currencyCode
        }
        note
        order {
          id
          name
        }
        orderStatusUrl
        paymentDueV2 {
          amount
          currencyCode
        }
        ready
        subtotalPriceV2 {
          amount
          currencyCode
        }
        taxesIncluded
        taxExempt
        totalPriceV2 {
          amount
          currencyCode
        }
        totalTaxV2 {
          amount
          currencyCode
        }
        updatedAt
        webUrl
      }
      checkoutUserErrors {
        code
        field
        message
      }
    }
  }
`;

export const UPDATE_CHECKOUT_SHIPPING_LINE_ADDRESS_MUTATION = graphql`
  mutation checkoutShippingLineUpdate(
    $checkoutId: ID!
    $shippingRateHandle: String!
  ) {
    checkoutShippingLineUpdate(
      checkoutId: $checkoutId
      shippingRateHandle: $shippingRateHandle
    ) {
      checkout {
        id
        ready
        shippingLine {
          handle
          priceV2 {
            amount
            currencyCode
          }
          title
        }
      }
      checkoutUserErrors {
        code
        field
        message
      }
    }
  }
`;

export const GET_CHECKOUT_WITH_SHIPPING_QUERY = graphql`
  query getCheckoutWithShipping($checkoutId: ID!) {
    node(id: $checkoutId) {
      id
      ... on Checkout {
        shippingAddress {
          firstName
          lastName
          address1
          city
          countryCodeV2
          provinceCode
          zip
          phone
        }
        availableShippingRates {
          ready
          shippingRates {
            handle
            priceV2 {
              amount
              currencyCode
            }
            title
          }
        }
        shippingLine {
          handle
          priceV2 {
            amount
            currencyCode
          }
          title
        }

        completedAt
        createdAt
        currencyCode
        customAttributes {
          key
          value
        }
        email
        id
        lineItems(first: 100) {
          edges {
            node {
              id
              title
              quantity
            }
          }
        }
        lineItemsSubtotalPrice {
          amount
          currencyCode
        }
        note
        order {
          id
          name
        }
        orderStatusUrl
        paymentDueV2 {
          amount
          currencyCode
        }
        ready
        requiresShipping
        subtotalPriceV2 {
          amount
          currencyCode
        }
        taxesIncluded
        taxExempt
        totalPriceV2 {
          amount
          currencyCode
        }
        totalTaxV2 {
          amount
          currencyCode
        }
        updatedAt
        webUrl
      }
    }
  }
`;

export const GET_CHECKOUT_WITHOUT_SHIPPING_QUERY = graphql`
  query getCheckoutWithoutShipping($checkoutId: ID!) {
    node(id: $checkoutId) {
      id
      ... on Checkout {
        shippingAddress {
          firstName
          lastName
          address1
          city
          countryCodeV2
          provinceCode
          zip
          phone
        }
        completedAt
        createdAt
        currencyCode
        customAttributes {
          key
          value
        }
        email
        id
        lineItems(first: 100) {
          edges {
            node {
              id
              title
              quantity
            }
          }
        }
        lineItemsSubtotalPrice {
          amount
          currencyCode
        }
        note
        order {
          id
          name
        }
        orderStatusUrl
        paymentDueV2 {
          amount
          currencyCode
        }
        ready
        requiresShipping
        subtotalPriceV2 {
          amount
          currencyCode
        }
        taxesIncluded
        taxExempt
        totalPriceV2 {
          amount
          currencyCode
        }
        totalTaxV2 {
          amount
          currencyCode
        }
        updatedAt
        webUrl
      }
    }
  }
`;

export const GET_PAYMENT_QUERY = graphql`
  query getPayment($paymentId: ID!) {
    node(id: $paymentId) {
      id
      ... on Payment {
        id
        checkout {
          createdAt
          lineItems(first: 100) {
            edges {
              node {
                id
                title
                quantity
                variant {
                  id
                  image {
                    altText
                    url
                  }
                  priceV2 {
                    amount
                    currencyCode
                  }
                  product {
                    handle
                    options(first: 100) {
                      id
                      name
                    }
                  }
                  sku
                  title
                }
              }
            }
          }
          order {
            name
          }
          discountApplications(first: 100) {
            edges {
              node {
                allocationMethod
                targetSelection
                targetType
                value {
                  __typename
                  ... on PricingPercentageValue {
                    percentage
                  }
                  ... on MoneyV2 {
                    amount
                    currencyCode
                  }
                }
              }
            }
          }
          shippingAddress {
            firstName
            lastName
            address1
            city
            countryCodeV2
            provinceCode
            zip
            phone
          }
          shippingLine {
            handle
            priceV2 {
              amount
              currencyCode
            }
            title
          }
          subtotalPriceV2 {
            amount
            currencyCode
          }
          totalPriceV2 {
            amount
            currencyCode
          }
          totalTaxV2 {
            amount
            currencyCode
          }
        }
        creditCard {
          maskedNumber
        }
        errorMessage
        nextActionUrl
        ready
        test
        transaction {
          amount {
            amount
            currencyCode
          }
          kind
          statusV2
          test
        }
      }
    }
  }
`;

export const COMPLETE_CHECKOUT_WITH_TOKENIZED_PAYMENT_MUTATION = graphql`
  mutation checkoutCompleteWithTokenizedPaymentV3(
    $checkoutId: ID!
    $payment: TokenizedPaymentInputV3!
  ) {
    checkoutCompleteWithTokenizedPaymentV3(
      checkoutId: $checkoutId
      payment: $payment
    ) {
      checkout {
        id
        lineItems(first: 100) {
          edges {
            node {
              id
              title
              quantity
            }
          }
        }
      }
      checkoutUserErrors {
        code
        field
        message
      }
      payment {
        id
        checkout {
          order {
            name
          }
        }
        creditCard {
          maskedNumber
        }
        errorMessage
        nextActionUrl
        ready
        test
        transaction {
          amount {
            amount
            currencyCode
          }
          kind
          statusV2
          test
        }
      }
    }
  }
`;

export const UPDATE_CHECKOUT_LINE_ITEMS_MUTATION = graphql`
  mutation checkoutLineItemsReplace(
    $lineItems: [CheckoutLineItemInput!]!
    $checkoutId: ID!
  ) {
    checkoutLineItemsReplace(lineItems: $lineItems, checkoutId: $checkoutId) {
      checkout {
        id
      }
      userErrors {
        code
        field
        message
      }
    }
  }
`;

export const UPDATE_CHECKOUT_SHIPPING_ADDRESS_MUTATION = graphql`
  mutation checkoutShippingAddressUpdateV2(
    $shippingAddress: MailingAddressInput!
    $checkoutId: ID!
  ) {
    checkoutShippingAddressUpdateV2(
      shippingAddress: $shippingAddress
      checkoutId: $checkoutId
    ) {
      checkout {
        id
      }
      checkoutUserErrors {
        code
        field
        message
      }
    }
  }
`;

export const UPDATE_CHECKOUT_EMAIL_MUTATION = graphql`
  mutation checkoutEmailUpdateV2($checkoutId: ID!, $email: String!) {
    checkoutEmailUpdateV2(checkoutId: $checkoutId, email: $email) {
      checkout {
        id
      }
      checkoutUserErrors {
        code
        field
        message
      }
    }
  }
`;

export const CHECKOUT_DISCOUNT_CODE_MUTATION = graphql`
  mutation checkoutDiscountCodeApplyV2(
    $discountCode: String!
    $checkoutId: ID!
  ) {
    checkoutDiscountCodeApplyV2(
      discountCode: $discountCode
      checkoutId: $checkoutId
    ) {
      checkout {
        availableShippingRates {
          ready
          shippingRates {
            handle
            priceV2 {
              amount
              currencyCode
            }
            title
          }
        }
        completedAt
        createdAt
        currencyCode
        customAttributes {
          key
          value
        }
        discountApplications(first: 100) {
          edges {
            node {
              allocationMethod
              targetSelection
              targetType
              value {
                __typename
                ... on PricingPercentageValue {
                  percentage
                }
                ... on MoneyV2 {
                  amount
                  currencyCode
                }
              }
            }
          }
        }
        email
        id
        lineItems(first: 100) {
          edges {
            node {
              id
              title
              quantity
            }
          }
        }
        lineItemsSubtotalPrice {
          amount
          currencyCode
        }
        note
        order {
          id
          name
        }
        orderStatusUrl
        paymentDueV2 {
          amount
          currencyCode
        }
        ready
        requiresShipping
        shippingAddress {
          firstName
          lastName
          address1
          city
          countryCodeV2
          provinceCode
          zip
          phone
        }
        shippingLine {
          handle
          priceV2 {
            amount
            currencyCode
          }
          title
        }
        subtotalPriceV2 {
          amount
          currencyCode
        }
        taxesIncluded
        taxExempt
        totalPriceV2 {
          amount
          currencyCode
        }
        totalTaxV2 {
          amount
          currencyCode
        }
        updatedAt
        webUrl
      }
      checkoutUserErrors {
        code
        field
        message
      }
    }
  }
`;
