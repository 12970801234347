import { Channel, eventChannel } from 'redux-saga';
import { call, put, select, take, fork } from 'redux-saga/effects';
import { takeEvery } from 'redux-utils';

import { getSiteId } from 'services/app/selectors';
import { ON_PAGE_CHANGE, onPageChange } from 'services/app/actions';
import {
  isUserLoggedIn,
  getUserAccount,
  getPrimaryToken,
} from 'services/auth/selectors';
import {
  ILoginResponse,
  logInSuccess,
  logOut,
  refreshUser,
} from 'services/auth/actions';
import {
  ILoginIntentResponse,
  requestExternaLoginIntentToken,
  logInWithToken,
} from 'services/auth/api';
import {
  setGDPR,
  setTheaterMode,
  setLoginGate,
  setSubscriptionGate,
  setPasswordGate,
  setAmazonBenefitGate,
} from 'services/user-layout/actions';
import { loginModal, showModal } from 'services/modals/actions';
import { ModalKinds } from 'services/modals/types';
import { getDevicePermanentId } from 'services/device/selectors';

import { postMessageToIframeSDK } from '../api';
import { setInitIframeSDKLocale, setParentOrigin } from '../actions';
import {
  EventSource,
  MaestroIFrameEvents,
  MaestroIFrameMessages,
  IFrameMessageSource,
  IFrameSDKActions,
  IIFrameSDKMessage,
  WatchTogetherActions,
  WatchTogetherMessage,
} from '../models';
import { setupNavigationEventListeners } from './navigationEvent';
import { fireMaestroReadyEvent } from './maestroReadyEvent';
import { setSelectedPlanId } from 'services/self-service-signup/actions';
import { constructPathForSlug } from 'services/navigationv2/utils';
import IState from 'services/state';
import { getDefaultNavigation } from 'services/navigationv2';

const VIEW_OPTIONS_MAP: any = {
  theater: setTheaterMode,
  gdpr: setGDPR,
  subscriptionGate: setSubscriptionGate,
  loginGate: setLoginGate,
  passwordGate: setPasswordGate,
  amazonBenefitGate: setAmazonBenefitGate,
};

export const createPostMessageEmbedChannel = (source: IFrameMessageSource) => eventChannel((emit) => {
  const listener = (event: MessageEvent) => {
    const isValidEvent = event.data?.source === source;
    if (isValidEvent) {
      emit(event);
    }
  };
  window.addEventListener('message', listener);
  return () => window.removeEventListener('message', listener);
});

export function* watchTogetherMessageSaga() {
  const embedChannel: Channel<WatchTogetherMessage> = yield call(createPostMessageEmbedChannel, IFrameMessageSource.WATCH_TOGETHER);
  while (true) {
    const { data: response } = yield take(embedChannel);
    const { action } = response;
    switch (action) {
      case WatchTogetherActions.RENDER_LOGIN_MODAL: {
        yield put(showModal({ kind: ModalKinds.login, data: { page: 'LOGIN', autoSubmit: false } }));
        break;
      }
      default: {
        break;
      }
    }
  }
}

export function* iFrameSDKMessageSaga() {
  const embedChannel: Channel<IIFrameSDKMessage> = yield call(createPostMessageEmbedChannel, IFrameMessageSource.IFRAME_SDK);
  while (true) {
    const { data: response, origin } = yield take(embedChannel);
    const state = yield select();
    const loggedIn = isUserLoggedIn(state);
    const { action, account, data } = response;
    switch (action) {
      case IFrameSDKActions.REQUEST_LOGIN: {
        try {
          if (loggedIn) {
            break;
          }

          const siteId = getSiteId(state);
          const responseDto = { provider: account.bridgeAuth ? 'webSdk' : 'external', ...account };
          const intentResp: ILoginIntentResponse = yield call(requestExternaLoginIntentToken, {
            ...responseDto,
          });
          const deviceId = getDevicePermanentId(state);

          const authCreds: ILoginResponse = yield call(logInWithToken, {
            siteId,
            token: intentResp.token,
            deviceId,
          });

          yield put(logInSuccess({
            accessToken: authCreds.jwt,
            auto: false,
            refreshToken: authCreds.refreshToken,
            service: authCreds.service,
          }));
        } catch (error) {
          // TODO: Log properly
          console.error('iframe saga error: iframeSDKLoginSaga'); // tslint:disable-line no-console
          console.error(error); // tslint:disable-line no-console
        }
        break;
      }
      case IFrameSDKActions.REQUEST_LOGOUT: {
        if (loggedIn) {
          yield put(logOut());
          break;
        }
        break;
      }
      case IFrameSDKActions.REQUEST_LAYOUT: {
        yield put(setParentOrigin(origin));
        const { viewOptions }: any = response;
        if (!viewOptions) {
          yield put(setGDPR(true));
          break;
        }
        const viewOptionKeys = Object.keys(viewOptions);
        // tslint:disable-next-line: prefer-for-of
        for (let key = 0; key < viewOptionKeys.length; key++) {
          const optionKey = viewOptionKeys[key];
          const viewAction = VIEW_OPTIONS_MAP[optionKey];
          const payload = viewOptions[optionKey];
          yield put(viewAction(!!(payload)));
        }
        break;
      }
      case IFrameSDKActions.INIT_LOCALE: {
        const { locale } = response;
        yield put(setInitIframeSDKLocale(locale));
      }
      case IFrameSDKActions.GET_MAESTRO_ACCOUNT: {
        yield put(refreshUser());
        const accountToReturn = getUserAccount(state);
        const accessToken = getPrimaryToken(state);
        // tslint:disable-next-line: no-shadowed-variable
        const account = (loggedIn && accountToReturn) ? { ...accountToReturn, accessToken } : null;
        yield call(postMessageToIframeSDK, { account, loggedIn, action: MaestroIFrameMessages.RETURN_ACCOUNT, eventSource: EventSource.MESSAGE_SAGA });
        break;
      }
      case IFrameSDKActions.SSU_SIGNUP: {
        yield put(setSelectedPlanId(data));
        yield put(loginModal());
      }
      default: {
        break;
      }
    }
  }
}

export function* iframeRouterSaga({ newObject }: ReturnType<typeof onPageChange> ) {
  const state: IState = yield select();
  const defaultNavigation = getDefaultNavigation(state);

  const channelPath = yield call(constructPathForSlug, defaultNavigation, newObject.slug);

  yield call(postMessageToIframeSDK, {
    action: MaestroIFrameEvents.CHANNEL_CHANGE,
    channelSlug: newObject.slug,
    eventSource: EventSource.ROUTER_SAGA,
    channelPath,
  });
}

export default function* iframeSaga() {
  setupNavigationEventListeners(window);
  yield call(fireMaestroReadyEvent);

  yield takeEvery(ON_PAGE_CHANGE, iframeRouterSaga);
  yield fork(iFrameSDKMessageSaga);
  yield fork(watchTogetherMessageSaga);
}
