import { call, takeEvery, select } from 'redux-saga/effects';
import { loadSegmentScript } from './api';
import {
  IDENTIFY,
  TRACK_EVENT,
  ITrackEventAction,
  IIdentifyAction,
  RESET_USER,
} from './actions';
import { identifyUser, resetSegmentUser, trackEvent } from './lib';
import { getSiteId, getSiteSlug } from 'services/app';
import { isMobileLayout } from 'services/device';
import { getUserEmail, getUserId, isUserAdmin } from 'services/auth';
import { IUserRoles, IUserTraits } from './models';
import IState from 'services/state';
import { getSessionId } from 'services/insights/selectors';
import { getPageSlug } from 'services/app/selectors';
import { getPageId } from 'services/app/selectors';
import { IS_MARKETING_SITE, SITES_WITH_ANALYTICS_TURNED_OFF } from 'config';
import { getIsPlanTypeSelfServiceOrProPlan } from 'services/billing';

export function* loadSegmentSaga() {
  yield call(loadSegmentScript);
}

export function* identifySaga({ payload }: IIdentifyAction) {
  const siteId: string = yield select(getSiteId);
  const siteSlug: string = yield select(getSiteSlug);
  const mobile: boolean = yield select(isMobileLayout);
  const identifyEventId: string = yield select(getUserId);
  const email: string = yield select(getUserEmail);
  const role: IUserRoles = yield select(isUserAdmin)
    ? IUserRoles.ADMIN
    : IUserRoles.USER;
  const ssu: boolean = yield select(getIsPlanTypeSelfServiceOrProPlan);

  const userTraits: IUserTraits = {
    accountId: identifyEventId,
    accountProvider: payload.provider,
    creatorType: payload.creatorType,
    email,
    mobile,
    role,
    siteId,
    siteName: siteSlug,
    ssu,
  };

  yield call(identifyUser, identifyEventId, userTraits);
}

export function* segmentTrackSaga({ payload }: ITrackEventAction) {
  const { event, properties } = payload;
  const state: IState = yield select();

  const eventProperties = IS_MARKETING_SITE ? properties : {
    siteId: getSiteId(state),
    siteName: getSiteSlug(state),
    sessionId: getSessionId(state),
    channelId: getPageId(state),
    channelName: getPageSlug(state),
    ssu: getIsPlanTypeSelfServiceOrProPlan(state),
    ...properties,
  };

  yield call(trackEvent, event, eventProperties, {});
}

export function* resetSegmentUserSaga() {
  yield call(resetSegmentUser);
}

export default function* segmentAnalyticsSaga() {
  const siteId: string = yield select(getSiteId);
  if (SITES_WITH_ANALYTICS_TURNED_OFF.includes(siteId)) {
    return;
  }

  if (IS_MARKETING_SITE) {
    yield call(loadSegmentSaga);
  }

  yield takeEvery(IDENTIFY, identifySaga);
  yield takeEvery(TRACK_EVENT, segmentTrackSaga);
  yield takeEvery(RESET_USER, resetSegmentUserSaga);
}
