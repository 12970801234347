import { makeReducer } from 'redux-utils';
import * as actions from './actions';

export const getInitialState = () => ({
  isBlocked: false,
  isUserActionRequested: false,
  pendingNavigationActions: [] as string[],
});

const navigationReducer = makeReducer(actions, getInitialState, (state, action) => {
  switch (action.type) {
    case actions.SET_NAVIGATION_BLOCKED: {
      return { ...state, isBlocked: action.payload };
    }
    case actions.SET_IS_USER_ACTION_REQUESTED_TO_UNBLOCK_NAVIGATION: {
      return { ...state, isUserActionRequested: action.payload };
    }
    case actions.CLEAR_PENDING_NAVIGATION_ACTIONS: {
      return { ...state, pendingNavigationActions: [] };
    }
    case actions.ADD_PENDING_NAVIGATION_ACTION: {
      return {
        ...state,
        pendingNavigationActions: [...state.pendingNavigationActions, action.payload],
      };
    }
    default:
      return state;
  }
});

export type INavigationState = ReturnType<typeof getInitialState>;

export default navigationReducer;

