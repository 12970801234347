import { createSelector } from 'reselect';
import { isMobileLayout } from 'services/device/selectors';
import { getContentWidth } from 'services/app/selectors';

import {
  THUMBNAIL_WIDTH_SMALL_DESKTOP,
  THUMBNAIL_MARGIN_DESKTOP,
  getSizes,
} from 'components/Grid/styles';

export const getSlideContainerMaxWidth = createSelector(
  [getContentWidth, isMobileLayout],
  (contentWidth, mobileLayout) => (
    // when mobile, grid row takes up entire content width
    // otherwise, must account for 2x slider buttons also fitting in content width
    mobileLayout ? contentWidth : contentWidth - 65
  ),
);

export const isGridMobile = createSelector(
  [getSlideContainerMaxWidth, isMobileLayout],
  (width, mobileLayout) => (
    mobileLayout || (width < (2 * (THUMBNAIL_WIDTH_SMALL_DESKTOP + THUMBNAIL_MARGIN_DESKTOP)))
  ),
);

const getGridSizes = createSelector(
  [isGridMobile],
  isMobile => getSizes(isMobile),
);

export const getTotalSlidesToShow = createSelector(
  [getSlideContainerMaxWidth, getGridSizes],
  (width, sizes) => Math.trunc(width / (sizes.THUMBNAIL_WIDTH_SMALL + sizes.THUMBNAIL_MARGIN)),
);

export const getSlidesWidth = createSelector(
  [getTotalSlidesToShow, getGridSizes],
  (slidesNum, sizes) => (
    slidesNum * sizes.THUMBNAIL_WIDTH_SMALL + (slidesNum - 1) * sizes.THUMBNAIL_MARGIN
  ),
);
