// tslint:disable:no-console
import { takeEvery, takeLatest } from 'redux-utils';
import { call, fork } from 'redux-saga/effects';
import {
  MAESTRO_GO_LIVE,
  MAESTRO_GO_OFFLINE,
  SET_ADMIN_MODE,
  NAVIGATE_TO_ADMIN_BAR_ACTION,
} from 'services/admin/actions';
import { UPDATE_DOCUMENT } from 'services/realtime/actions';
import { SET_OBJECT, SET_SITE } from 'services/app';
import {
  REGISTER_STUDIO_IFRAME,
  MANUALLY_REQUEST_NEW_STREAM,
  SET_LIVESTREAM_METADATA,
  INVITE_GUEST_TO_STUDIO,
} from '../actions';
import { LOG_IN_SUCCESS } from 'services/auth';
import { studioGuestModeInviteCodeSaga } from './studioGuestModeInviteCode';
import { goLiveSaga } from './goLive';
import { goOfflineSaga } from './goOffline';
import { addMetadataSaga } from './addMetadata';
import { prepareChannelStreamSaga } from './prepareChannelStream';
import { livestreamUpdateSaga } from './livestreamUpdate';
import { maestroStudioMessageSaga } from './maestroStudioMessage';
import { registerStudioIframeSaga } from './registerStudioIframe';
import { activateStreamSaga } from './activateStream';
import { resetStudioSessionSaga } from './resetStudioSession';
import { inviteGuestToStudioSaga } from './inviteGuestToStudio';
import { pollLivestreamSaga } from './pollLivestream';

function* livestreamSaga() {
  yield call(studioGuestModeInviteCodeSaga);
  yield takeLatest(MAESTRO_GO_LIVE, goLiveSaga);
  yield takeLatest(MAESTRO_GO_OFFLINE, goOfflineSaga);
  yield takeLatest(SET_LIVESTREAM_METADATA, addMetadataSaga);
  yield takeEvery([SET_OBJECT, SET_SITE, UPDATE_DOCUMENT, MANUALLY_REQUEST_NEW_STREAM], prepareChannelStreamSaga);
  yield takeEvery([UPDATE_DOCUMENT, LOG_IN_SUCCESS, SET_OBJECT], livestreamUpdateSaga);
  yield fork(pollLivestreamSaga);
  yield fork(maestroStudioMessageSaga);
  yield takeLatest(REGISTER_STUDIO_IFRAME, registerStudioIframeSaga);
  yield takeLatest(SET_ADMIN_MODE, activateStreamSaga);
  yield takeEvery(NAVIGATE_TO_ADMIN_BAR_ACTION, resetStudioSessionSaga);
  yield takeEvery([INVITE_GUEST_TO_STUDIO], inviteGuestToStudioSaga);
}

export default livestreamSaga;
