// tslint:disable:no-console
import { makeSaga } from 'redux-utils';
import { IState } from 'services/state';
import { call, select, delay } from 'redux-saga/effects';
import { getPrimaryToken } from 'services/auth/selectors';
import { isLivestreamPreviewActive } from 'services/admin';
import { getRealtimeStream } from '../selectors';
import { pollStream } from '../api';

export const pollLivestreamSaga = makeSaga({}, function* () {
  while (true) {
    yield delay(5000);

    try {
      const state: IState = yield select();
      const isPreviewActive = isLivestreamPreviewActive(state);
      // start polling when admin is on stream preview screen (stream can be inactive/offline or active/live)
      if (!isPreviewActive) continue;

      const stream = getRealtimeStream(state);

      if (!stream) continue;

      const token = getPrimaryToken(state);
      const { toggleTime, idleActiveToggleTime, provider, providerStreamId } = stream;

      if (!token || !provider || !providerStreamId) continue;

      let streamRecentlyUpdated = false;

      if (!toggleTime && !idleActiveToggleTime) {
        streamRecentlyUpdated = false;
      } else {
        streamRecentlyUpdated = Date.now() - Math.max(toggleTime ?? 0, idleActiveToggleTime ?? 0) < 5000;
      }

      if (streamRecentlyUpdated) continue;

      // if stream object has not been recently updated in five seconds, poll provider and update stream if stale
      yield call(
        pollStream,
        {
          providerStreamId,
          token,
          provider,
        },
      );
    } catch (error) {
      console.error('pollLivestreamSaga:', error);
    }
  }
});
