import axios from 'axios';
import { AUTH_SERVICE_BASE_URL, INVITE_SERVICE_BASE_URL } from 'config';

const CONSUME_INTENT_URL: string = `${AUTH_SERVICE_BASE_URL}/intent/consume`;

export const consumeIntent = ({ INVITE_TOKEN, siteId }: {INVITE_TOKEN:string, siteId: string}): Promise<any> => (
  axios({
    headers: {
      'x-maestro-client-id': siteId,
    },
    method: 'GET',
    url: `${CONSUME_INTENT_URL}/${INVITE_TOKEN}`,
  })
);

export const registerInvite = ({ redirectUri, siteId, targetRoles, token }: { redirectUri: string, siteId: string, targetRoles: [{}], token: string | null }): Promise<any> => (
  axios({
    headers: {
      'x-maestro-client-id': siteId,
      authorization: `Bearer ${token}`,
    },
    method: 'POST',
    url: `${INVITE_SERVICE_BASE_URL}/register`,
    data: {
      siteId,
      redirectUri,
      targetRoles,
    },
  })
);

export const updateInvite = ({ invite, inviteId, siteId, token }: { invite: any, inviteId: string, siteId: string, token: string | null}): Promise<any> => (
  axios({
    headers: {
      'x-maestro-client-id': siteId,
      authorization: `Bearer ${token}`,
    },
    method: 'PUT',
    url: `${INVITE_SERVICE_BASE_URL}/${inviteId}`,
    data: {
      ...invite,
    },
  })
);

export const getInvite = ({ inviteId, siteId }: { inviteId: string, siteId: string }):Promise<any> => (
  axios({
    headers: {
      'x-maestro-client-id': siteId,
    },
    method: 'GET',
    url: `${INVITE_SERVICE_BASE_URL}/${inviteId}`,
  })
);

export const getRegisterInvites = ({ siteId, token }: { siteId: string, token: string | null }): Promise<any> => (
  axios({
    headers: {
      'x-maestro-client-id': siteId,
      authorization: `Bearer ${token}`,
    },
    method: 'GET',
    url: `${INVITE_SERVICE_BASE_URL}?type=register&consumed=false&revoked=false`,
  })
);

export const deleteInvite = ({ inviteId, siteId, token }: {  inviteId: string, siteId: string, token: string | null }): Promise<any> => (
  axios({
    headers: {
      'x-maestro-client-id': siteId,
      authorization: `Bearer ${token}`,
    },
    method: 'DELETE',
    url: `${INVITE_SERVICE_BASE_URL}/register/revoke/${inviteId}`,
  })
);

export const sendInvite = ({ email, inviteId, siteId, token }: { email: string, inviteId: string, siteId: string, token: string | null }): Promise<any> => (
  axios({
    headers: {
      'x-maestro-client-id': siteId,
      authorization: `Bearer ${token}`,
    },
    method: 'POST',
    url: `${INVITE_SERVICE_BASE_URL}/register/send-email/${inviteId}`,
    data: {
      email,
    },
  })
);

export const resendInvite = ({ inviteId, siteId, token }: { inviteId: string, siteId: string, token: string | null }): Promise<any> => (
  axios({
    headers: {
      'x-maestro-client-id': siteId,
      authorization: `Bearer ${token}`,
    },
    method: 'POST',
    url: `${INVITE_SERVICE_BASE_URL}/register/re-send-email/${inviteId}`,
  })
);
