export enum FlowSteps {
  HOME = 'home',
  PICK_A_THEME = 'pick a theme',
  SELECT_CHANNEL = 'select channel',
  SET_UP_SUBSCRIPTION_ONLY_LIVESTREAM = 'setup subscription-only livestream',
  SET_UP_SUBSCRIPTION_VOD = 'setup subscription video on demand',
  SET_UP_TICKETED_EVENT = 'setup ticketed event',
}

export enum WizardFlowTypes {
  EXPLORE_ON_MY_OWN = 'Explore on My Own',
  I_DONT_WANNNA_MAKE_MONEY = 'I Don\'t Wanna Make Money',
  LIVE_SHOPPING = 'Live Shopping',
  SUBSCRIBER_ONLY_LIVESTREAMS = 'Subscriber-Only Livestreams',
  SUBSCRIPTION_VOD = 'Subscription Video on Demand',
  TICKETED_EVENT = 'Ticketed Event',
}
