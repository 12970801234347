// tslint:disable:no-console
import { makeSaga } from 'redux-utils';
import { IState } from 'services/state';
import {
  call,
  put,
  select,
} from 'redux-saga/effects';
import {
  setAdminMode,
  setMaestroLivestreamPreview,
  goOfflineWithMaestroLivestream,
} from 'services/admin/actions';
import { ModeTypes } from 'services/admin/constants';
import { trackAdmin } from 'services/insights/actions';
import { getPrimaryToken } from 'services/auth/selectors';
import { MetadataStatus, StreamSource } from '../constants';
import {
  getMaestroStreamId,
  getStreamSource,
} from '../selectors';
import {
  setLivestreamError,
  setMetadataStatus,
} from '../actions';
import { stopStream } from '../api';
import { AdminActionEvents } from 'services/admin/models';
import { studioIframe } from './registerStudioIframe';

export const goOfflineSaga = makeSaga({ goOfflineWithMaestroLivestream }, function* () {
  try {
    const state: IState = yield select();
    const maestroStreamId = getMaestroStreamId(state);
    const token = getPrimaryToken(state)!;
    const source = getStreamSource(state)!;

    if (!token)
      return;

    yield call(
      stopStream,
      {
        maestroStreamId,
        source,
        token,
      },
    );

    if (source === StreamSource.STUDIO)
      studioIframe?.contentWindow?.postMessage(
        {
          source: 'maestro:web:v1',
          command: 'stop',
        },
        '*',
      );

    if (source === StreamSource.THIRD_PARTY) {
      yield put(setMaestroLivestreamPreview(true));
      yield put(setAdminMode(ModeTypes.MAESTRO_STREAM));
    }

    yield put(trackAdmin(
      AdminActionEvents.STREAM,
      {
        id: maestroStreamId,
        kind: 'stop',
      },
      {},
    ));
    yield put(setMetadataStatus(MetadataStatus.IDLE));
  } catch (error) {
    console.error(`goOffline error: ${error.message}`);
    yield put(setMetadataStatus(MetadataStatus.REJECTED));
    yield put(setLivestreamError(Date.now()));
  }
});
