import parse from 'url-parse';
import { prefix } from 'redux-utils';

const reduxAction = prefix('insights');

// session things
export const SET_ACTIVE = reduxAction('SET_ACTIVE');
export const SET_COUNTRY_CODE_SAGA_ACTION = reduxAction('SET_COUNTRY_CODE_SAGA_ACTION');
export const SET_COUNTRY_CODE = reduxAction('SET_COUNTRY_CODE');
export const SET_INITIALIZED = reduxAction('SET_INITIALIZED');
export const SET_REFERRER = reduxAction('SET_REFERRER');
export const SET_SESSION_ID = reduxAction('SET_SESSION_ID');
export const SET_SESSION_START = reduxAction('SET_SESSION_START');
export const SET_PAGE_ID = reduxAction('SET_PAGE_ID');
export const TRACK = reduxAction('ACTION'); // using the name action here, don't want track in codebase
export const BUILD = reduxAction('BUILD');
export const POST = reduxAction('POST');
export const ADD_DEBUG = reduxAction('ADD_DEBUG');
export const LOAD_GA = reduxAction('LOAD_GA');
export const SET_LAST_EVENT_SENT_TIME_MS = reduxAction('SET_LAST_EVENT_SENT_TIME_MS');
export const SET_NEXT_SHORT_BEACON_TIME = reduxAction('SET_NEXT_SHORT_BEACON_TIME');
export const SET_NEXT_LONG_BEACON_TIME = reduxAction('SET_NEXT_LONG_BEACON_TIME');

export const setLastEventSentTime = (payload) => ({
  payload, type: SET_LAST_EVENT_SENT_TIME_MS,
});
export const setNextLongBeaconTime = (payload) => ({
  payload,
  type: SET_NEXT_LONG_BEACON_TIME,
});
export const setNextShortBeaconTime = (payload) => ({
  payload,
  type: SET_NEXT_SHORT_BEACON_TIME,
});

export const setPageId = pageId => ({
  payload: pageId,
  type: SET_PAGE_ID,
});

export const setSessionId = sessionId => ({
  payload: sessionId,
  type: SET_SESSION_ID,
});

export const setSessionStart = time => ({
  payload: time,
  type: SET_SESSION_START,
});

export const addDebug = event => ({
  payload: event,
  type: ADD_DEBUG,
});

export const setActive = ({ authAction, state }) => ({
  payload: { authAction, state },
  type: SET_ACTIVE,
});

export const setCountryCodeSagaAction = countryCode => ({
  payload: countryCode,
  type: SET_COUNTRY_CODE_SAGA_ACTION,
});

export const setCountryCode = countryCode => ({
  payload: countryCode,
  type: SET_COUNTRY_CODE,
});

export const setInitialized = initialized => ({
  payload: initialized,
  type: SET_INITIALIZED,
});

export const setReferrer = () => {
  const source = parse(window.location.href, true).query.r;
  return {
    payload: {
      domain: document.referrer ? parse(document.referrer).host : '$direct',
      source: source || null,
      url: document.referrer || '$direct',
    },
    type: SET_REFERRER,
  };
};

export const postEvent = event => ({
  payload: event,
  type: POST,
});

export const trackAction = (kingdom, action, doc, extra) => ({
  payload: {
    action,
    doc,
    extra,
    kingdom,
  },
  type: BUILD,
});

export const loadGA = () => ({
  type: LOAD_GA,
});

export const trackAdmin = (action, doc, extra) => trackAction('admin', action, doc, extra);
export const trackAuth = (action, doc, extra) => trackAction('auth', action, doc, extra);
export const trackError = (action, doc, extra) => trackAction('error', action, doc, extra);
export const trackFooter = (action, doc, extra) => trackAction('footer', action, doc, extra);
export const trackPanel = (action, doc, extra) => trackAction('panel', action, doc, extra);
export const trackChannelSelect = (action, doc, extra) => trackAction('channel_select', action, doc, extra);
export const trackGate = (action, doc, extra) => trackAction('gate', action, doc, extra);
export const trackGrid = (action, doc, extra) => trackAction('grid', action, doc, extra);
export const trackAd = (action, doc, extra) => trackAction('ad', action, doc, extra);
export const trackCard = (action, doc, extra) => trackAction('card', action, doc, extra);
export const trackOverlay = (action, doc, extra) => trackAction('overlay', action, doc, extra);
export const trackPageview = (action, doc, extra) => trackAction('pageview', action, doc, extra);
export const trackPortal = (action, doc, extra) => trackAction('portal', action, doc, extra);
export const trackQuest = (action, doc, extra) => trackAction('quest', action, doc, extra);
export const trackSession = (action, doc, extra) => trackAction('session', action, doc, extra);
export const trackVideo = (action, doc, extra) => trackAction('video', action, doc, extra);
export const trackHeader = (action, doc, extra) => trackAction('header', action, doc, extra);
export const trackSubscription = (action, doc, extra) => trackAction('subscription', action, doc, extra);
