import { IObject, IVideo, IEPGResponse, VideoTypes, PlayerTypes } from 'models';
import { ModeTypes } from 'services/admin/constants';
import { ViewerState } from '../auth/selectors';
import type { Size } from 'hooks/use-element-size';

export enum VideoMIMETypes {
  'flv' = 'video/x-flv',
  'mp4' = 'video/mp4',
  'm3u8' = 'vnd.apple.mpegURL',
  'ts' = 'video/MP2T',
  '3gp' = 'video/3gpp',
  'mov' = 'video/quicktime',
  'avi' = 'video/x-msvideo',
  'wmv' = 'video/x-ms-wmv',
}

export enum ClosedCaptionSetting {
  ENABLED = 1,
  DISABLED = 0, // https://developers.google.com/youtube/player_parameters#cc_load_policy
}

export enum ControlTypes {
  fullscreen = 'FullscreenToggle',
  volume = 'VolumeSlider',
  closeVod = 'VodClose',
  concurrents = 'Concurrents',
}

export type HighFrequencyVideoPlaybackData = {
  loaded: number;
  loadedSeconds: number;
  played: number;
  playedSeconds: number;
};

export type IVideoPlayback = {
  cc: boolean;
  lastPausedTimestamp: null | number;
  muted: boolean;
  playing: boolean;
  showControls?: boolean;
  volume: number;
};

export enum PlayerSpot {
  video = 'video',
  tv = 'tv',
  videoSpotlight = 'videoSpotlight',
}

export interface ISeekVideo {
  playedSeconds: number;
  videoKey: string;
}

export interface ISetPlaylistData {
  id: string | null;
  index: number | null;
  videoKey: string;
}

export interface ISetTagPlaylistData {
  index: number | null;
  members: string[];
  tag: string | null;
  videoKey: string;
}

export enum CanIPlayKind {
  video = 'video',
  channel = 'channel',
}

export interface ICanIPlayParams {
  deviceId: string,
  id: string,
  kind: CanIPlayKind,
  siteId: string,
  token: string
}

export type EPGMap = Record<string, IEPGResponse>;

export interface IVideoPlayerState {
  activeVideoKey: string | null;
  deviceKickedAt: number | null;
  epg: EPGMap;
  size: Size;
  videoModal: IVideoModal;
  viewer: IVideoViewer;
}

export interface IVideoViewer {
  deviceLimitEnabled: boolean;
  locationKey: string;
  playerContentError?: boolean;
  seenScheduledVod: boolean;
  sessionId: string | null;
  userSelectedVod: boolean;
}

export interface ILoadEpg {
  channelId: string;
}

export interface ISetEPG {
  channelId: string;
  epg: IEPGResponse;
}

export interface ISetAdminMode {
  modeName: ModeTypes;
}

export interface IChannelSelectPage {
  array_id?: string;
  page: {
    _id: string,
    seo: {
      title: string,
    };
    slug?: string;
  };
}

export interface IDeviceLimit {
  accountDevices: string[];
  accountOverDeviceLimit: boolean;
  siteDeviceLimit: number;
}

export interface IAccountDevices {
  [deviceId: string]: string
}

export enum PlaybackAccess {
  FULL = 'full',
  PREVIEW = 'preview',
  PREVIEW_OVER = 'preview_over',
}

export type ICanIPlayResponse = {
  access?: PlaybackAccess;
  accountDevices?: IAccountDevices;
  accountOverDeviceLimit?: boolean;
  live?: boolean;
  offset?: number;
  previewStartTime?: number | null;
  previewTimeRemainingSeconds?: number | null;
  siteDeviceLimit?: number | null;
  video?: IVideo,
};

export interface ILoadChannelContent {
  canIPlayResponse: ICanIPlayResponse;
  channel: IObject | null;
}

export interface IRemoveDevicePayload {
  deviceId: string;
}

export interface IVideoModal {
  modalId: string | null;
}

export interface ISyncChannel {
  channelId: string | undefined;
}

export enum WatchSessionType {
  livestream = 'livestream',
  vod = 'vod',
}

export type IBeaconMetadata = {
  assetStartTime: null | number;
  // update this everytime this is changed
  contentMetadataVersion: '2021-09-01' | '2021-10-05' | '2024-08-13',
  isBillable: boolean;
  paywall_gated: boolean;
  playerSpot: PlayerSpot | null;
  playlistId: null | string;
  playlistIndex: null | number;
  videoHasSubscriptions: boolean;
  videoId: string;
  videoPlayer: PlayerTypes;
  videoType: VideoTypes;
  videoUrl: string;
  viewerId: string;
  viewerState: ViewerState;
  watchSessionId: string;
  watchSessionType: WatchSessionType;
};
