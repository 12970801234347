import { createSelector } from 'reselect';
import { getStreamDocuments, getStreamProviderAccountDocuments, getVideoDocuments } from 'services/realtime/selectors';
import IState from '../state';
import { StreamSource } from './constants';
import { getDefaultStreamingProvider, getObject, getSiteSettings, getStreamingProviderAccountId } from 'services/app/selectors';
import type { IStreamProviderAccount } from 'models/IStreamProviderAccount';
import { isMaestroStreamMode, isStudioStreamMode } from 'services/admin';
import { getDocumentPath } from 'utils';

export const livestreamRenderer = createSelector(
  (state: IState) => getObject(state),
  (object) => object?.renderers?.livestream,
);

export const getMaestroStreamId = createSelector(
  livestreamRenderer,
  (renderer) => renderer?.streamId,
);

export const getRealtimeStream = createSelector(
  getStreamDocuments,
  getMaestroStreamId,
  (streamDocuments, streamId) => {
    if (!streamId) {
      return null;
    }

    return streamDocuments[getDocumentPath('stream', streamId)];
  },
);

export const isLivestreamPublished = createSelector(
  livestreamRenderer,
  (renderer) => Boolean(renderer?.livestreamPublished),
);

export const isLivestreamPreviewActive = (state: IState) => state.admin.livestreamPreviewActive;

export const getLivestreamProvider = createSelector(
  (state: IState) => getSiteSettings(state),
  (settings) => settings?.defaultStreamingProvider || 'mux',
);

export const getLivestreamVideoId = createSelector(
  livestreamRenderer,
  (renderer) => renderer?.videoId,
);

export const getLivestreamVideo = createSelector(
  getLivestreamVideoId,
  getVideoDocuments,
  (videoId, videos) => {
    if (!videoId) {
      return null;
    }

    return videos[getDocumentPath('video', videoId)];
  },
);

export const isLivestreamAvailable = createSelector(
  getMaestroStreamId,
  (streamId) => Boolean(streamId),
);

export const getLivestreamError = (state: IState) => state.livestream.error;

export const getMetadataStatus = (state: IState) => state.livestream.metadataStatus;

export const getLivestreamLiveBroadcastId = createSelector(
  getLivestreamVideo,
  video => video?.liveBroadcastId,
);

const getLivestreamSource = createSelector(
  livestreamRenderer,
  (renderer) => renderer?.source,
);

export const getStreamSource = createSelector(
  getLivestreamSource,
  (state: IState) => isMaestroStreamMode(state),
  (state: IState) => isStudioStreamMode(state),
  (publishedSource, isMaestroStream, isStudioStream): StreamSource | undefined => {
     if (publishedSource) return publishedSource;

    if (isMaestroStream)
      return StreamSource.THIRD_PARTY;

    if (isStudioStream)
      return StreamSource.STUDIO;
  },
);

export const isPublishedWithMaestroStream = createSelector(
  isLivestreamPublished,
  getStreamSource,
  (isPublished, source) => isPublished && source === StreamSource.THIRD_PARTY,
);

export const isPublishedWithStudioStream = createSelector(
  isLivestreamPublished,
  getStreamSource,
  (isPublished, source) => isPublished && source === StreamSource.STUDIO,
);

export const getStudioSessionReady = (state: IState) => state.livestream.studioSessionReady;

export const getStudioGuestModeInviteCode = (state: IState) => (state.livestream?.studioGuestModeInviteCode || null);

export const getPendingStudioGuestInviteAccountId = (state: IState) => state.livestream?.pendingGuestAccountId;

export const getStudioSessionId = createSelector(
  livestreamRenderer,
  (renderer) => renderer?.studioSessionId,
);

export const isStreamBeingCreatedForCurrentChannel = createSelector(
  livestreamRenderer,
  (renderer) => renderer?.status === 'creating',
);

export const getStreamProviderAccount = createSelector(
  [
    (state: IState) => getStreamingProviderAccountId(state),
    (state: IState) => getDefaultStreamingProvider(state),
    (state: IState) => getStreamProviderAccountDocuments(state),
  ],
  (accountId, provider, streamProviderAccounts): IStreamProviderAccount | undefined => {
    if (!accountId) {
      return undefined;
    }

    const account = streamProviderAccounts[getDocumentPath('streamprovideraccount', accountId)];
    if (account?.providerName === provider)
      return account;
  },
);
