import maestroApiRequest from 'services/maestro-api-client';
import { NOTIFICATION_SERVICE_BASE_URL, SITE_ID as siteId } from 'config';
import { INotification } from 'models/INotification';

export const sendNotification = async <T extends INotification['payload']>(
  primaryToken: string,
  targetAccountId: string,
  payload: T,
  expires?: number,
) => {
  await maestroApiRequest({
    freshInstance: true,
    primaryToken,
    siteId,
  })
    .post(
      `${NOTIFICATION_SERVICE_BASE_URL}/account/${targetAccountId}`,
      {
        expires,
        payload,
      },
    );
};

export const getNotification = async (primaryToken: string, notificationId: string) => {
  const { data } = await maestroApiRequest({
    freshInstance: true,
    primaryToken,
    siteId,
  })
    .get<INotification>(
      `${NOTIFICATION_SERVICE_BASE_URL}/${notificationId}`,
    );

  return data;
};

export const consumeNotification = async (primaryToken: string, notificationId: string) => {
  const { data } = await maestroApiRequest({
    freshInstance: true,
    primaryToken,
    siteId,
  })
    .put(
      `${NOTIFICATION_SERVICE_BASE_URL}/consume/${notificationId}`,
    );

  return data;
};
