import { useState, useEffect } from 'react';

const uploadProgressMap = new Map<string, number>();

export const updateOrDeleteVideoUploadProgress = (
  fileId: string,
  progress?: number,
) => {
  if (progress === undefined) {
    uploadProgressMap.delete(fileId);
    return;
  }

  uploadProgressMap.set(fileId, progress);
};

// optimization to reduce update frequency of the upload progress bar
// and improve performance on large lists
export const useVideoUploadProgress = (
  fileId: string | undefined | null,
  running: boolean,
  checkIntervalMs = 100,
) => {
  const [progress, setProgress] = useState(
    !!fileId
      ? uploadProgressMap.get(fileId)
      : undefined,
  );

  useEffect(
    () => {
      if (!running || !fileId)
        return;

      const interval = setInterval(
        () => setProgress(
          uploadProgressMap.get(fileId),
        ),
        checkIntervalMs,
      );

      return () => clearInterval(interval);
    },
    [fileId, checkIntervalMs, running],
  );

  return progress;
};
