import { persistenceService } from 'services/persistence';
import type {
  IVideoPlayback,
  IVideoViewer,
  IVideoPlayerState,
  IVideoModal,
} from './interfaces';
import type { Size } from 'hooks/use-element-size';
import { lazyFn } from 'utils/lazy';

export const initialStateVideoPlayback = lazyFn(
  () =>
    (muted: boolean = true): IVideoPlayback => {
      return {
        showControls: undefined,
        cc: false,
        lastPausedTimestamp: null,
        muted,
        playing: false,
        volume: persistenceService().readStatic('site:volume') ?? 1.0,
      };
    },
);

export const INITIAL_STATE_VIDEO_VIEWER: IVideoViewer = {
  deviceLimitEnabled: true,
  locationKey: '',
  seenScheduledVod: false,
  sessionId: null,
  userSelectedVod: false,
};

export const INITIAL_STATE_VIDEO_MODAL: IVideoModal = {
  modalId: null,
};

export const INITIAL_STATE_VIDEO_SIZE: Size = {
  height: 0,
  width: 0,
};

export const INITIAL_STATE_VIDEO_PLAYER: IVideoPlayerState = {
  epg: {},
  size: { ...INITIAL_STATE_VIDEO_SIZE },
  videoModal: { ...INITIAL_STATE_VIDEO_MODAL },
  viewer: { ...INITIAL_STATE_VIDEO_VIEWER },
  activeVideoKey: null,
  deviceKickedAt: null,
};
