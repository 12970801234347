import {
  IRoleAction,
  ISiteRoles,
  SET_SITE_ROLES_STATE,
} from './actions';

export interface IRolesState {
  siteRoles: ISiteRoles[];
}

const INITIAL_STATE: IRolesState = {
  siteRoles: [],
};

export const getInitialState = () => ({
  ...INITIAL_STATE,
});

export default function userRoleReducer(state: IRolesState = INITIAL_STATE, action: IRoleAction): IRolesState {
  switch (action.type) {
    case SET_SITE_ROLES_STATE:
      return {
        ...state,
        siteRoles: action.payload,
      };
    default:
      return state;
  }
}
