declare global {
  interface Window {
    analytics?: any;
  }
}

import { SEGMENT_WRITE_KEY } from 'config';

// load segment analytics
export const loadSegmentScript = (): void => {
  const analytics = window.analytics || [];
  if (analytics.initialize || analytics.invoked) {
    // tslint:disable:no-console
    console.error('Segment snippet included twice.');
    return;
  }
  analytics.invoked = !0;
  analytics.SNIPPET_VERSION = '3.1.0';

  // tslint:disable:only-arrow-functions
  (function (d, s) {
    if (!d.getElementById(s)) {
      const script = d.createElement('script');
      script.type = 'text/javascript';
      script.id = s;
      script.async = true;
      script.src =
        'https://cdn.segment.com/analytics.js/v1/' +
        SEGMENT_WRITE_KEY +
        '/analytics.min.js';
      const f = d.getElementsByTagName('script')[0];
      f.parentNode?.insertBefore(script, f);
    }
  })(document, 'analytics-js');
};
