import invariant from 'invariant';
import { AdminActionEvents } from 'services/admin/models';
import EventBuilder from './EventBuilder';

class AdminEventBuilder extends EventBuilder {
  constructor() {
    super('admin');
  }

  buildOntology(action, doc, extra) {
    const ontology = {
      /* eslint-disable sort-keys */
      phylum: extra.p || null,
      class: action,
      order: extra.o || null,
      family: extra.f || null,
      genus: extra.g || null,
      species: extra.s || null,
      /* eslint-enable */
    };
    return ontology;
  }

  buildMetadata(action, doc, extra) {
    switch (action) {
      case AdminActionEvents.CHANNEL_VIEWED:
      case AdminActionEvents.PAGE_VIEWED:
        return {
          sessionId: extra.sessionId,
        };
      case AdminActionEvents.STREAM:
        return null;
      case AdminActionEvents.NAVBAR:
        return {
          element: extra.element,
          menu: extra.menu,
        };
      default:
        invariant(
          false,
          `AdminEventBuilder: Unknown action ${JSON.stringify(action)}`,
        );
        return null; // Appease linter; invariant throws
    }
  }
}

export default new AdminEventBuilder();
