/**
 * Exposes realtime functionality for logic outside of Redux Saga
 */

import { Store } from 'redux';
import { subscribe as reduxSubscribe, unsubscribe } from './actions';
import { getRealtimeDocuments } from './selectors';

type UnsubscribeFn = () => void;
type Listener<DocumentType> = (doc: DocumentType) => unknown;
type Subscription = [path: string, cb: Listener<unknown>];

const listeners: Set<Subscription> = new Set();
let getStoreRef: (() => Store) | null = null;

export const subscribe = <DocumentType>(
  path: string,
  listener: Listener<DocumentType>,
): UnsubscribeFn => {
  const store = getStoreRef?.();
  store?.dispatch(reduxSubscribe(path));

  const subscription: [path: string, cb: Listener<unknown>] = [
    path,
    (doc) => {
      try {
        listener(doc as any);
      } catch {
        // errors need to be handled locally
      }
    },
  ];

  listeners.add(subscription);

  const dataInState = store
    ? (getRealtimeDocuments(store.getState())[path] as DocumentType)
    : undefined;

  // fire notification when the subscribed document is already in state
  if (dataInState) {
    subscription[1](dataInState);
  }

  return () => {
    getStoreRef?.().dispatch(unsubscribe(path));
    listeners.delete(subscription);
  };
};

export const notify = (path: string, doc: unknown) => {
  listeners.forEach(([subsPath, cb]) => {
    if (subsPath === path) {
      cb(doc);
    }
  });
};

export const configureRealtimeBridge = (store: () => Store) => {
  getStoreRef = store;
};
