import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { IPlaylist, IVideo } from 'models';
import { getPlaylist, getTagPlaylist } from './api';
import { getSiteId } from 'services/app';
import { getPrimaryToken } from 'services/auth';
import { useCanIPlay } from 'services/caniplay/useCanIPlay';

export const usePlaylist = (id?: string | null) => {
  const [playlist, setPlaylist] = useState<IPlaylist>();

  const token = useSelector(getPrimaryToken) ?? undefined;
  const siteId = useSelector(getSiteId);

  useEffect(() => {
    let cleanup = () => {
      // placeholder
    };

    setPlaylist(undefined);

    if (!id) {
      return;
    }

    getPlaylist({
      id,
      siteId,
      token,
      captureCanceler: (abortFn) => {
        cleanup = abortFn;
      },
    }).then((result) => {
      if (result) {
        setPlaylist(result);
      }
    });

    return () => {
      cleanup();
    };
  }, [id, siteId, token]);

  return playlist;
};

export const useTagPlaylist = (tag?: string | null) => {
  const [playlist, setPlaylist] = useState<IPlaylist>();

  const token = useSelector(getPrimaryToken) ?? undefined;
  const siteId = useSelector(getSiteId);

  useEffect(() => {
    let cleanup = () => {
      // placeholder
    };

    setPlaylist(undefined);

    if (!tag) {
      return;
    }

    getTagPlaylist({
      tag,
      siteId,
      token,
      captureCanceler: (abortFn) => {
        cleanup = abortFn;
      },
    }).then((result) => {
      if (result) {
        setPlaylist(result);
      }
    });

    return () => {
      cleanup();
    };
  }, [tag, siteId, token]);

  return playlist;
};

export const usePlaylistOrchestration = (
  playlist?: IPlaylist | null,
  initialVideoId?: string | null,
) => {
  const [currentIndex, setCurrentIndex] = useState<number>();

  useEffect(() => {
    if (!playlist?.members.length) {
      setCurrentIndex(undefined);
      return;
    }

    if (initialVideoId) {
      const idx = playlist.members.findIndex((m) => m.id === initialVideoId);
      setCurrentIndex(idx >= 0 ? idx : 0);
      return;
    }

    setCurrentIndex(0);
  }, [playlist, initialVideoId]);

  const next = useCallback(() => {
    if (!playlist || playlist.members.length === 0) {
      return;
    }

    setCurrentIndex((idx = -1) => (idx + 1) % playlist.members.length);
  }, [playlist]);

  const currentVideoId =
    currentIndex === undefined
      ? undefined
      : playlist?.members[currentIndex]?.id;

  const canIPlay = useCanIPlay(currentVideoId);

  return [canIPlay, next] as const;
};
