import axios from 'axios';

import { SITE_ID, SHOPIFY_SERVICE_BASE_URL } from 'config';

import {
  IShop,
  ICheckoutRequestData,
  ICheckoutResponse,
  IShippingRatesResponse,
  IUpdateCheckout,
  IPaymentRequestData,
  ICheckoutsPayment,
  IConnectingShopResponse,
  ICheckoutInput,
  IPaymentStorefrontInput,
  ICheckoutLineItemInput,
  ICheckoutMailingAddressInput,
  ISaveBillingDataToSourcePayload,
  IFetchTokenFromReusableSourceParams,
  IFetchTokenFromSingleUseSourceParams,
  ITokenResponse,
  IGetCheckoutGraphQLResponse,
  IUpdateCheckoutShippingLineResponse,
} from './models';
import {
  CHECKOUT_DISCOUNT_CODE_MUTATION,
  COMPLETE_CHECKOUT_WITH_TOKENIZED_PAYMENT_MUTATION,
  CREATE_CHECKOUT_WITHOUT_SHIPPING_MUTATION,
  CREATE_CHECKOUT_WITH_SHIPPING_MUTATION,
  GET_CHECKOUT_WITHOUT_SHIPPING_QUERY,
  GET_CHECKOUT_WITH_SHIPPING_QUERY,
  GET_COLLECTIONS_QUERY,
  GET_COLLECTION_BY_HANDLE_QUERY,
  GET_PAYMENT_QUERY,
  GET_PRODUCTS_QUERY,
  GET_PRODUCT_BY_HANDLE_QUERY,
  GET_SHOP_INFO_QUERY,
  UPDATE_CHECKOUT_EMAIL_MUTATION,
  UPDATE_CHECKOUT_LINE_ITEMS_MUTATION,
  UPDATE_CHECKOUT_SHIPPING_ADDRESS_MUTATION,
  UPDATE_CHECKOUT_SHIPPING_LINE_ADDRESS_MUTATION,
} from './graphql/queries';
import {
  CheckoutCompleteWithTokenizedPaymentV3Mutation,
  CheckoutCreateWithShippingMutation,
  CheckoutCreateWithoutShippingMutation,
  CheckoutDiscountCodeApplyV2Mutation,
  CheckoutEmailUpdateV2Mutation,
  CheckoutLineItemsReplaceMutation,
  CheckoutShippingAddressUpdateV2Mutation,
  GetCollectionByHandleQuery,
  GetCollectionsQuery,
  GetPaymentQuery,
  GetProductByHandleQuery,
  GetProductsQuery,
  GetShopInfoQuery,
} from './graphql/types';

export const SHOPIFY_API_VERSION = '2022-10';
const graphql = String.raw;

export const addCheckout = async ({
  checkoutData,
  shop,
  siteId,
}: {
  checkoutData: ICheckoutRequestData;
  shop: string;
  siteId: string;
}): Promise<ICheckoutResponse> => {
  const { data } = await axios.post<ICheckoutResponse>(
    `${SHOPIFY_SERVICE_BASE_URL}/checkouts`,
    checkoutData,
    {
      headers: {
        shop,
        'x-maestro-client-id': siteId,
      },
    },
  );

  return data;
};

export const connectShop = async ({
  shop,
  siteId,
  token,
}: {
  shop: string;
  siteId: string;
  token: string | null;
}): Promise<IConnectingShopResponse> => {
  const { data } = await axios.post<IConnectingShopResponse>(
    `${SHOPIFY_SERVICE_BASE_URL}/shop/${shop}/connect`,
    {},
    {
      headers: {
        'x-maestro-client-id': siteId,
        authorization: `Bearer ${token}`,
      },
    },
  );

  return data;
};

export const getShippingRates = async ({
  shop,
  siteId,
  tokenCheckout,
}: {
  shop: string;
  siteId: string;
  tokenCheckout: string;
}): Promise<IShippingRatesResponse> => {
  const { data } = await axios.get<IShippingRatesResponse>(
    `${SHOPIFY_SERVICE_BASE_URL}/checkouts/${tokenCheckout}/shipping_rates`,
    {
      headers: {
        shop,
        'x-maestro-client-id': siteId,
      },
    },
  );
  return data;
};

export const retrieveValidShop = async (siteId: string): Promise<string> => {
  const { data } = await axios.get<IShop>(`${SHOPIFY_SERVICE_BASE_URL}/shops`, {
    headers: {
      'x-maestro-client-id': siteId,
    },
  });

  const { shop } = data;
  return shop;
};

export const getShopInfo = async (
  siteId: string,
): Promise<IShop | undefined> => {
  const { data } = await axios.get<IShop>(`${SHOPIFY_SERVICE_BASE_URL}/shops`, {
    headers: {
      'x-maestro-client-id': siteId,
    },
  });

  if (!data.shop) {
    return;
  }

  const { shop, storefrontAccessToken } = data;
  const response = await axios.post(
    `https://${shop}/api/${SHOPIFY_API_VERSION}/graphql.json`,
    {
      query: GET_SHOP_INFO_QUERY,
    },
    {
      headers: {
        'Content-Type': 'application/json',
        'X-Shopify-Storefront-Access-Token': storefrontAccessToken,
      },
    },
  );
  const {
    shop: {
      moneyFormat,
      paymentSettings: { currencyCode, shopifyPaymentsAccountId },
    },
  } = response.data.data as GetShopInfoQuery;
  // @ts-ignore
  return { ...data, moneyFormat, currencyCode, shopifyPaymentsAccountId };
};

export const getProducts = async (
  shop: string,
  accessToken: string,
  cursor: string | null,
): Promise<GetProductsQuery> => {
  const { data } = await axios.post(
    `https://${shop}/api/${SHOPIFY_API_VERSION}/graphql.json`,
    {
      query: GET_PRODUCTS_QUERY,
      variables: {
        cursor,
      },
    },
    {
      headers: {
        'Content-Type': 'application/json',
        'X-Shopify-Storefront-Access-Token': accessToken,
      },
    },
  );
  return data.data;
};

export const getCollections = async (
  shop: string,
  accessToken: string,
  cursor: string | null,
): Promise<GetCollectionsQuery> => {
  const { data } = await axios.post(
    `https://${shop}/api/${SHOPIFY_API_VERSION}/graphql.json`,
    {
      query: GET_COLLECTIONS_QUERY,
      variables: {
        cursor,
      },
    },
    {
      headers: {
        'Content-Type': 'application/json',
        'X-Shopify-Storefront-Access-Token': accessToken,
      },
    },
  );
  return data.data;
};

export const getProduct = async (
  shop: string,
  accessToken: string,
  productHandle: string,
): Promise<GetProductByHandleQuery>=> {
  const { data } = await axios.post(
    `https://${shop}/api/${SHOPIFY_API_VERSION}/graphql.json`,
    {
      query: GET_PRODUCT_BY_HANDLE_QUERY,
      variables: {
        productHandle,
      },
    },
    {
      headers: {
        'Content-Type': 'application/json',
        'X-Shopify-Storefront-Access-Token': accessToken,
      },
    },
  );
  return data.data;
};

export const getCollection = async (
  shop: string,
  accessToken: string,
  collectionHandle: string,
): Promise<GetCollectionByHandleQuery>=> {
  const { data } = await axios.post(
    `https://${shop}/api/${SHOPIFY_API_VERSION}/graphql.json`,
    {
      query: GET_COLLECTION_BY_HANDLE_QUERY,
      variables: {
        collectionHandle,
      },
    },
    {
      headers: {
        'Content-Type': 'application/json',
        'X-Shopify-Storefront-Access-Token': accessToken,
      },
    },
  );
  return data.data;
};

export const fetchTokenFromReusableSource = async ({
  accessToken,
  account,
  cardId,
  customer,
}: IFetchTokenFromReusableSourceParams): Promise<ITokenResponse> => {
  const { data } = await axios.post(
    `${SHOPIFY_SERVICE_BASE_URL}/reusable-source/token`,
    {
      account,
      cardId,
      customer,
    },
    {
      headers: {
        authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
        'x-maestro-client-id': SITE_ID,
      },
      validateStatus: (status) => {
        return status < 400; // Resolve only if the status code is less than 500
      },
    },
  );

  return data;
};

export const fetchTokenFromSingleUseSource = async ({
  account,
  customer,
  tokenId,
}: IFetchTokenFromSingleUseSourceParams): Promise<ITokenResponse> => {
  const { data } = await axios.post(
    `${SHOPIFY_SERVICE_BASE_URL}/single-use-source/token`,
    {
      account,
      customer,
      tokenId,
    },
    {
      headers: {
        'Content-Type': 'application/json',
        'x-maestro-client-id': SITE_ID,
      },
      validateStatus: (status) => {
        return status < 500; // Resolve only if the status code is less than 500
      },
    },
  );

  return data;
};

export const updateCheckouts = async ({
  checkoutData,
  shop,
  siteId,
  tokenCheckout,
}: {
  checkoutData: IUpdateCheckout;
  shop: string;
  siteId: string;
  tokenCheckout: string;
}): Promise<ICheckoutResponse> => {
  const { data } = await axios.put<ICheckoutResponse>(
    `${SHOPIFY_SERVICE_BASE_URL}/checkouts/${tokenCheckout}`,
    checkoutData,
    {
      headers: {
        shop,
        'x-maestro-client-id': siteId,
      },
    },
  );

  return data;
};

export const updateSource = async ({
  accessToken,
  billingData,
  cardId,
  customer,
}: ISaveBillingDataToSourcePayload) => {
  const { data } = await axios.put(
    `${SHOPIFY_SERVICE_BASE_URL}/reusable-source/customer/${customer}/card/${cardId}`,
    {
      shopifyBillingData: billingData,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
        'x-maestro-client-id': SITE_ID,
      },
      validateStatus: (status) => {
        return status < 500; // Resolve only if the status code is less than 500
      },
    },
  );

  return data;
};

export const deleteSingleUseSource = async ({
  accessToken,
  cardId,
  customer,
}: any): Promise<{ success: boolean }> => {
  const { data } = await axios.delete(
    `${SHOPIFY_SERVICE_BASE_URL}/single-use-source/customer/${customer}/card/${cardId}`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
        'x-maestro-client-id': SITE_ID,
      },
    },
  );

  return data;
};

export const removeShopApi = async ({
  shop,
  siteId,
  token,
}: {
  shop: string;
  siteId: string;
  token: string | null;
}) => {
  return await axios.delete(
    `${SHOPIFY_SERVICE_BASE_URL}/shop/uninstall/${shop}`,
    {
      headers: {
        'x-maestro-client-id': siteId,
        authorization: `Bearer ${token}`,
      },
    },
  );
};

export const setPaymentApi = async ({
  payment,
  shop,
  siteId,
  tokenCheckout,
}: {
  payment: IPaymentRequestData;
  shop: string;
  siteId: string;
  tokenCheckout: string;
}): Promise<ICheckoutsPayment> => {
  const { data } = await axios.post<ICheckoutsPayment>(
    `${SHOPIFY_SERVICE_BASE_URL}/checkouts/${tokenCheckout}/payments`,
    payment,
    {
      headers: {
        shop,
        'x-maestro-client-id': siteId,
      },
    },
  );

  return data;
};

export const createCommission = async ({
  orderId,
  shop,
  siteId,
  total,
}: {
  orderId: string;
  shop: string;
  siteId: string;
  total: number;
}): Promise<any> => {
  const result = await axios.post(
    `${SHOPIFY_SERVICE_BASE_URL}/commission`,
    {
      orderId,
      total,
      shop,
    },
    {
      headers: {
        'x-maestro-client-id': siteId,
        'Content-Type': 'application/json',
      },
    },
  );
  return result.data;
};

export const createCheckoutGraphQL = async ({
  accessToken,
  checkoutData,
  shop,
  requiresShipping,
}: {
  accessToken: string;
  checkoutData: ICheckoutInput;
  requiresShipping: boolean;
  shop: string;
}): Promise<CheckoutCreateWithShippingMutation | CheckoutCreateWithoutShippingMutation> => {
  const response = await axios.post(
    `https://${shop}/api/${SHOPIFY_API_VERSION}/graphql.json`,
    {
      query: requiresShipping
        ? CREATE_CHECKOUT_WITH_SHIPPING_MUTATION
        : CREATE_CHECKOUT_WITHOUT_SHIPPING_MUTATION,
      variables: { input: checkoutData },
    },
    {
      headers: {
        'Content-Type': 'application/json',
        'X-Shopify-Storefront-Access-Token': accessToken,
      },
    },
  );
  return response.data.data;
};

export const updateCheckoutShippingLine = async ({
  accessToken,
  checkoutId,
  handle,
  shop,
}: {
  accessToken: string;
  checkoutId: string;
  handle: string;
  shop: string;
}): Promise<IUpdateCheckoutShippingLineResponse> => {
  const { data } = await axios.post(
    `https://${shop}/api/${SHOPIFY_API_VERSION}/graphql.json`,
    {
      query: UPDATE_CHECKOUT_SHIPPING_LINE_ADDRESS_MUTATION,
      variables: { checkoutId, shippingRateHandle: handle },
    },
    {
      headers: {
        'Content-Type': 'application/json',
        'X-Shopify-Storefront-Access-Token': accessToken,
      },
    },
  );
  return data.data;
};

export const getCheckoutGraphQL = async ({
  accessToken,
  checkoutId,
  shop,
  requiresShipping,
}: {
  accessToken: string;
  checkoutId: string;
  requiresShipping: boolean;
  shop: string;
}): Promise<IGetCheckoutGraphQLResponse> => {
  const response = await axios.post(
    `https://${shop}/api/${SHOPIFY_API_VERSION}/graphql.json`,
    {
      query: requiresShipping
        ? GET_CHECKOUT_WITH_SHIPPING_QUERY
        : GET_CHECKOUT_WITHOUT_SHIPPING_QUERY,
      variables: { checkoutId },
    },
    {
      headers: {
        'Content-Type': 'application/json',
        'X-Shopify-Storefront-Access-Token': accessToken,
      },
    },
  );
  return response.data;
};

export const getPaymentGraphQL = async ({
  accessToken,
  paymentId,
  shop,
}: {
  accessToken: string;
  paymentId: string;
  shop: string;
}): Promise<GetPaymentQuery> => {
  const response = await axios.post(
    `https://${shop}/api/${SHOPIFY_API_VERSION}/graphql.json`,
    { query: GET_PAYMENT_QUERY, variables: { paymentId } },
    {
      headers: {
        'Content-Type': 'application/json',
        'X-Shopify-Storefront-Access-Token': accessToken,
      },
    },
  );
  return response.data.data;
};

export const completeCheckoutWithTokenGraphQL = async ({
  accessToken,
  checkoutId,
  payment,
  shop,
}: {
  accessToken: string;
  checkoutId: string;
  payment: IPaymentStorefrontInput;
  shop: string;
}): Promise<CheckoutCompleteWithTokenizedPaymentV3Mutation> => {
  const response = await axios.post(
    `https://${shop}/api/${SHOPIFY_API_VERSION}/graphql.json`,
    {
      query: COMPLETE_CHECKOUT_WITH_TOKENIZED_PAYMENT_MUTATION,
      variables: { checkoutId, payment },
    },
    {
      headers: {
        'Content-Type': 'application/json',
        'X-Shopify-Storefront-Access-Token': accessToken,
      },
    },
  );
  return response.data.data;
};

export const updateCheckoutLineItemsGraphQL = async ({
  accessToken,
  checkoutId,
  lineItems,
  shop,
}: {
  accessToken: string;
  checkoutId: string;
  lineItems: Partial<ICheckoutLineItemInput[]>;
  shop: string;
}): Promise<CheckoutLineItemsReplaceMutation> => {
  const response = await axios.post(
    `https://${shop}/api/${SHOPIFY_API_VERSION}/graphql.json`,
    {
      query: UPDATE_CHECKOUT_LINE_ITEMS_MUTATION,
      variables: { checkoutId, lineItems },
    },
    {
      headers: {
        'Content-Type': 'application/json',
        'X-Shopify-Storefront-Access-Token': accessToken,
      },
    },
  );
  return response.data.data;
};

export const updateCheckoutShippingAddressGraphQL = async ({
  accessToken,
  checkoutId,
  shippingAddress,
  shop,
}: {
  accessToken: string;
  checkoutId: string;
  shippingAddress: Partial<ICheckoutMailingAddressInput>;
  shop: string;
}): Promise<CheckoutShippingAddressUpdateV2Mutation> => {
  const response = await axios.post(
    `https://${shop}/api/${SHOPIFY_API_VERSION}/graphql.json`,
    {
      query: UPDATE_CHECKOUT_SHIPPING_ADDRESS_MUTATION,
      variables: { checkoutId, shippingAddress },
    },
    {
      headers: {
        'Content-Type': 'application/json',
        'X-Shopify-Storefront-Access-Token': accessToken,
      },
    },
  );
  return response.data.data;
};

export const updateCheckoutEmailGraphQL = async ({
  accessToken,
  checkoutId,
  email,
  shop,
}: {
  accessToken: string;
  checkoutId: string;
  email: string;
  shop: string;
}): Promise<CheckoutEmailUpdateV2Mutation> => {
  const response = await axios.post(
    `https://${shop}/api/${SHOPIFY_API_VERSION}/graphql.json`,
    { query: UPDATE_CHECKOUT_EMAIL_MUTATION, variables: { checkoutId, email } },
    {
      headers: {
        'Content-Type': 'application/json',
        'X-Shopify-Storefront-Access-Token': accessToken,
      },
    },
  );

  return response.data.data;
};

export const checkoutDiscountCode = async ({
  accessToken,
  checkoutId,
  discountCode,
  shop,
}: {
  accessToken: string;
  checkoutId: string;
  discountCode: string;
  shop: string;
}): Promise<CheckoutDiscountCodeApplyV2Mutation> => {
  const response = await axios.post(
    `https://${shop}/api/${SHOPIFY_API_VERSION}/graphql.json`,
    {
      query: CHECKOUT_DISCOUNT_CODE_MUTATION,
      variables: { discountCode, checkoutId },
    },
    {
      headers: {
        'Content-Type': 'application/json',
        'X-Shopify-Storefront-Access-Token': accessToken,
      },
    },
  );
  return response.data.data;
};
