import axios from 'axios';
import { ACCOUNTS_SERVICE_BASE_URL } from 'config';
import { IGetSiteRolesResponse } from './models';

export const getSiteRoles = async (
  token: string,
  siteId: string,
  cacheBust?: boolean,
): Promise<IGetSiteRolesResponse> => {
  const url = `${ACCOUNTS_SERVICE_BASE_URL}/roles/query?admin=true${
    cacheBust ? '&cachebust=true' : ''
  }`;
  const { data } = await axios.get<IGetSiteRolesResponse>(url, {
    headers: {
      'Authorization': `Bearer ${token}`,
      'x-maestro-client-id': siteId,
    },
  });
  return data;
};
