import invariant from 'invariant';
import EventBuilder from './EventBuilder';

class AuthEventBuilder extends EventBuilder {
  constructor() {
    super('auth');
  }

  buildOntology(action, doc, extra) {
    return {
      class: extra.service,
      order: extra.event,
      phylum: action,
    };
  }

  buildMetadata(action, doc, extra) {
    invariant(
      extra,
      'Missing auth extra data for buildMetadata',
    );
    const { autoLogin, error, event } = extra;
    switch (event) {
      case 'attempt':
        return action === 'login' ? { autoLogin } : { };
      case 'fail':
        return action === 'login' ? { autoLogin, error } : { error };
      default:
        invariant(
          false,
          `Invalid auth event for buildMetadata: ${event}`,
        );
        return null; // Appease linter; invariant will throw
    }
  }

  // TODO: implement buildMetadata
}

export default new AuthEventBuilder();
