import { prefix } from 'redux-utils';

const action = prefix('invite');

export const SEND_INVITE = action('SEND_INVITE');
export const SEND_INVITE_SUCCESS = action('SEND_INVITE_SUCCESS');
export const SEND_INVITE_ERROR = action('SEND_INVITE_ERROR');
export const RESEND_INVITE = action('RESEND_INVITE');
export const RESEND_INVITE_SUCCESS = action('RESEND_INVITE_SUCCESS');
export const GET_INVITE_LINK = action('GET_INVITE_LINK');
export const GET_INVITE_LINK_SUCCESS = action('GET_INVITE_LINK_SUCCESS');
export const GET_INVITE_LINK_ERROR = action('GET_INVITE_LINK_ERROR');
export const SET_INVALID_INVITE = action('SET_INVALID_INVITE');
export const UPDATE_INVITE = action('UPDATE_INVITE');
export const GET_PENDING_INVITES = action('GET_PENDING_INVITES');
export const SET_PENDING_INVITES = action('SET_PENDING_INVITES');
export const DELETE_INVITE = action('DELETE_INVITE');
export const DELETED_INVITE = action('DELETED_INVITE');
export const DELETE_INVITE_SUCCESS = action('DELETE_INVITE_SUCCESS');
export const EDIT_INVITE = action ('EDIT_INVITE');
export const CLEAR_INVITE = action ('CLEAR_INVITE');
export const ACCOUNT_EXISTS = action ('ACCOUNT_EXISTS');
export const ACCOUNT_EXISTS_SUCCESS = action('ACCOUNT_EXISTS_SUCCESS');

export interface ISetPendingInvites {
  type: typeof SET_PENDING_INVITES;
}
export interface ISendInvite {
  payload: string;
  type: typeof SEND_INVITE;
}
export interface ISendInviteSuccess {
  payload: any;
  type: typeof SEND_INVITE_SUCCESS;
}
export interface ISendInviteError {
  payload: string;
  type: typeof SEND_INVITE_ERROR;
}

export interface IResendInvite {
  payload: string;
  type: typeof RESEND_INVITE;
}

export interface IResendInviteSuccess {
  type: typeof RESEND_INVITE_SUCCESS;
}
export interface IGetInviteLink {
  payload: any;
  type: typeof GET_INVITE_LINK;
}
export interface IGetInviteLinkSuccess {
  payload: string;
  type: typeof GET_INVITE_LINK_SUCCESS;
}
export interface IGetInviteLinkError {
  payload: string;
  type: typeof GET_INVITE_LINK_ERROR;
}
export interface ISetInvalidInvite {
  payload: boolean;
  type: typeof SET_INVALID_INVITE;
}
export interface IUpdateInvite {
  payload: any;
  type: typeof UPDATE_INVITE;
}
export interface IGetPendingInvites {
  type: typeof GET_PENDING_INVITES;
}
export interface ISetPendingInvites {
  payload: any;
  type: typeof SET_PENDING_INVITES;
}

export interface IDeleteInvite {
  payload: string;
  type: typeof DELETE_INVITE;
}

export interface IDeletedInvite {
  payload: string;
  type: typeof DELETED_INVITE;
}

export interface IDeleteInviteSuccess {
  payload: string;
  type: typeof DELETE_INVITE_SUCCESS;
}

export interface IEditInvite {
  payload: string;
  type: typeof EDIT_INVITE;
}

export interface IClearInvite {
  type: typeof CLEAR_INVITE;
}

export interface IAccountExists {
  payload: string,
  type: typeof ACCOUNT_EXISTS;
}

export interface IAccountExistsSuccess {
  payload: string,
  type: typeof ACCOUNT_EXISTS_SUCCESS,
}

export const sendInvite = (payload: string): ISendInvite => ({
  payload,
  type: SEND_INVITE,
});

export const sendInviteSuccess = (payload:any):ISendInviteSuccess => ({
  payload,
  type: SEND_INVITE_SUCCESS,
});

export const sendInviteError = (payload: string): ISendInviteError  => ({
  payload,
  type: SEND_INVITE_ERROR,
});

export const resendInvite = (payload: string): IResendInvite => ({
  payload,
  type: RESEND_INVITE,
});

export const resendInviteSuccess = (): IResendInviteSuccess => ({
  type: RESEND_INVITE_SUCCESS,
});

export const getInviteLink = (payload: any): IGetInviteLink => ({
  payload,
  type: GET_INVITE_LINK,
});

export const getInviteLinkSuccess = (payload: any): IGetInviteLinkSuccess => ({
  payload,
  type: GET_INVITE_LINK_SUCCESS,
});

export const getInviteLinkError = (payload: string): IGetInviteLinkError => ({
  payload,
  type: GET_INVITE_LINK_ERROR,
});

export const setInvalidInvite = (payload: boolean): ISetInvalidInvite => ({
  payload,
  type: SET_INVALID_INVITE,
});

export const updateInvite = (payload: any): IUpdateInvite => ({
  payload,
  type: UPDATE_INVITE,
});

export const getPendingInvites = (): IGetPendingInvites => ({
  type: GET_PENDING_INVITES,
});

export const setPendingInvites = (payload: any): ISetPendingInvites => ({
  payload,
  type: SET_PENDING_INVITES,
});

export const deleteInvite = (payload: string): IDeleteInvite => ({
  payload,
  type: DELETE_INVITE,
});

export const deletedInvite = (payload: string): IDeletedInvite => ({
  payload,
  type: DELETED_INVITE,
});

export const deleteInviteSuccess = (payload: string): IDeleteInviteSuccess => ({
  payload,
  type: DELETE_INVITE_SUCCESS,
});

export const editInvite = (payload: any): IEditInvite => ({
  payload,
  type: EDIT_INVITE,
});

export const clearInvite = (): IClearInvite => ({
  type: CLEAR_INVITE,
});

export const accountExists = (payload: string): IAccountExists => ({
  payload,
  type: ACCOUNT_EXISTS,
});

export const accountExistsSuccess = (payload: string): IAccountExistsSuccess => ({
  payload,
  type: ACCOUNT_EXISTS_SUCCESS,
});
