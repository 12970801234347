import { postMessageToIframeSDK } from 'services/iframe/api';
import { MaestroIFrameMessages } from 'services/iframe/models';
import uuid from 'uuid';
import { IPersistenceService } from './interfaces';
import { formatKey } from './utils';
import { StorageType, LOCAL_STORAGE, SESSION_STORAGE } from './utils';

interface IDispatchToSdk<T> {
  action: string;
  key: string;
  storageType: StorageType;
  value?: T;
}

/**
 * Sends a message to the parent window (iframe/sdk) and listens for a response.
 * @returns A promise that resolves with the result or null if there's an error.
 */
const dispatchToSdk = <T>({
  action,
  key,
  storageType,
  value,
}: IDispatchToSdk<T>) => {
  return new Promise<T | null>((resolve) => {
    const messageId = uuid.v4();

    // send the message to the parent window (iframe/sdk)
    switch (action) {
      case MaestroIFrameMessages.WRITE_STORAGE:
        postMessageToIframeSDK({
          action: MaestroIFrameMessages.WRITE_STORAGE,
          id: messageId,
          key,
          value,
          storageType,
        });
        break;
      case MaestroIFrameMessages.READ_STORAGE:
        postMessageToIframeSDK({
          action: MaestroIFrameMessages.READ_STORAGE,
          id: messageId,
          key,
          storageType,
        });
        break;
    case MaestroIFrameMessages.DELETE_STORAGE:
      postMessageToIframeSDK({
        action: MaestroIFrameMessages.DELETE_STORAGE,
        id: messageId,
        key,
        storageType,
      });
    }

    let timeoutId: NodeJS.Timeout;

    // set up a message event listener to capture the response from the iframe/sdk
    const messageListener = (event: MessageEvent) => {
        // use id to match response to request
        if (event.data.id === messageId) {
            clearTimeout(timeoutId);
            window.removeEventListener('message', messageListener);
            if (event.data.error) {
                // tslint:disable-next-line:no-console
                console.error(`Encountered error for ${action} action`);
                resolve(null);
            }
            resolve(event.data.result);
        }
    };

    // clean up if no response is received
    timeoutId = setTimeout(() => {
        // tslint:disable-next-line:no-console
        console.error(`Timeout for action ${action}`);
        window.removeEventListener('message', messageListener);
        resolve(null);
    }, 1000);

    window.addEventListener('message', messageListener);
  });
};

const cache: Record<StorageType, object> = {
  [LOCAL_STORAGE]: {},
  [SESSION_STORAGE]: {},
};

export const makeSdkPersistenceService = (storageType: StorageType): IPersistenceService => {

  const read: IPersistenceService['read'] = async <T>(key, isRawKey) => {
    return await dispatchToSdk<T>({
      action: MaestroIFrameMessages.READ_STORAGE,
      key: formatKey(key, isRawKey),
      storageType,
    });
  };

  const refreshCache: IPersistenceService['refreshCache'] = async () => {
    const localData = await dispatchToSdk({
      action: MaestroIFrameMessages.READ_STORAGE,
      key: '*',
      storageType: LOCAL_STORAGE,
    });

    if (localData) cache[LOCAL_STORAGE] = localData;

    const sessionData = await dispatchToSdk({
      action: MaestroIFrameMessages.READ_STORAGE,
      key: '*',
      storageType: SESSION_STORAGE,
    });

    if (sessionData) cache[SESSION_STORAGE] = sessionData;
  };

  const write: IPersistenceService['write'] = async (key, value, isRawKey) => {
    const formattedKey = formatKey(key, isRawKey);
    const res = await dispatchToSdk({
      action: MaestroIFrameMessages.WRITE_STORAGE,
      key: formattedKey,
      storageType,
      value,
    });
    if (res) {
      // for consistency, update local cache if request was a success
      cache[storageType][formattedKey] = value;
    }
  };

  const remove: IPersistenceService['remove'] = async (key, isRawKey) => {
    const formattedKey = formatKey(key, isRawKey);
    const res = await dispatchToSdk({
      action: MaestroIFrameMessages.DELETE_STORAGE,
      key: formattedKey,
      storageType,
    });
    if (res) {
      // for consistency, delete from local cache if request was a success
      if (cache?.[storageType]?.[formattedKey]) {
        delete cache[storageType][formattedKey];
      }
    }
  };

  const readStatic: IPersistenceService['readStatic'] = (key, isRawKey) => {
    return cache?.[storageType]?.[formatKey(key, isRawKey)] ?? null;
  };

  return {
    read,
    readStatic,
    write,
    remove,
    refreshCache,
  };
};
