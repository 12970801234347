import { FACEBOOK_READY, ISocialsActions } from './actions';

export const getInitialState = () => ({
  facebookReady: false,
});

export type ISocialsState = ReturnType<typeof getInitialState>;

const appReducer = (state = getInitialState(), { type }: ISocialsActions) => {
  switch (type) {
    case FACEBOOK_READY:
      return {
        ...state,
        facebookReady: true,
      };
    default:
      return state;
  }
};

export default appReducer;
