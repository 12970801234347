/* eslint-disable no-multi-assign */
/* eslint-disable prefer-spread */
/* eslint-disable prefer-destructuring */
import { DEV_MODE } from 'config';
import { FACEBOOK_APP_ID, GTM_ID } from './constants';

export const loadFacebook = (appId, pixelId, gdprAccepted) => new Promise((resolve) => {
  const { prod, test } = FACEBOOK_APP_ID;

  window.fbAsyncInit = function () {
    if (appId) {
      window.FB.init({
        appId,
        autoLogAppEvents: true,
        cookie: gdprAccepted,
        version: 'v17.0',
        xfbml: true,
      });
    }
    // code extracted from https://developers.facebook.com/docs/facebook-pixel/implementation
    // eslint-disable-next-line no-unused-expressions
    !(function (f, b, e, v, n, t, s) {
      if (f.fbq) { return; }
      // eslint-disable-next-line no-param-reassign
      n = f.fbq = function () {
        // eslint-disable-next-line no-unused-expressions
        n.callMethod ?
        // eslint-disable-next-line prefer-rest-params
          n.callMethod.apply(n, arguments) :
        // eslint-disable-next-line prefer-rest-params
          n.queue.push(arguments);
      };
      // eslint-disable-next-line no-underscore-dangle
      if (!f._fbq) { f._fbq = n; }
      n.push = n;
      n.loaded = !0;
      n.version = '2.0';
      n.queue = [];
      // eslint-disable-next-line no-param-reassign
      t = b.createElement(e);
      t.async = !0;
      t.src = v;
      // eslint-disable-next-line no-param-reassign
      s = b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t, s);
    }(
      window,
      document,
      'script',
      'https://connect.facebook.net/en_US/fbevents.js',
    ));
    if (pixelId) {
    // eslint-disable-next-line no-undef
      fbq('init', pixelId);
    }
    // Initialize Maestro's Pixel
    // eslint-disable-next-line no-undef
    fbq('init', DEV_MODE ? test : prod);
    // eslint-disable-next-line no-undef
    fbq('track', 'PageView');

    resolve();
  };

  (function (d, s, id) {
    const fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) { return; }
    const js = d.createElement(s); js.id = id;
    js.src = 'https://connect.facebook.net/en_US/sdk.js';
    fjs.parentNode.insertBefore(js, fjs);
  }(document, 'script', 'facebook-jssdk'));
});

export const fbLogin = () => new Promise((resolve) => {
  window.FB.login((response) => {
    resolve(response?.authResponse);
  }, { scope: 'public_profile,email' });
});

export const loadGTM = () => {
  const GTM = DEV_MODE ? GTM_ID.dev : GTM_ID.prod;

  (function (w, d, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({ event: 'gtm.js', 'gtm.start': new Date().getTime() });
    const f = d.getElementsByTagName(s)[0];
    const j = d.createElement(s);
    const dl = l !== 'dataLayer' ? `&l=${l}` : '';
    j.async = true;
    j.src = `https://www.googletagmanager.com/gtm.js?id=${i}${dl}`;
    f.parentNode.insertBefore(j, f);

    d.head.appendChild(j);
  }(window, document, 'script', 'dataLayer', GTM));

  const noscript = document.createElement('noscript');
  const iframe = document.createElement('iframe');
  iframe.src = `https://www.googletagmanager.com/ns.html?id=${GTM}`;
  iframe.height = '0';
  iframe.width = '0';
  iframe.style.display = 'none';
  iframe.style.visibility = 'hidden';

  noscript.appendChild(iframe);
  document.body.appendChild(noscript);
};
