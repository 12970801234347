import invariant from 'invariant';
import EventBuilder from './EventBuilder';

class OverlayEventBuilder extends EventBuilder {
  constructor() {
    super('overlay');
  }

  validateTrack(action, doc) {
    // doc should have a kind in the overlays
    if (!doc?.payload?.data?.kind) {
      const payloadString = JSON.stringify(doc?.payload);
      invariant(
        doc?.payload,
        `Failed validation for overlay kingdom, payload must have a kind. ${payloadString}`,
      );
    }

    // don't track previews
    return doc?.payload?.channel !== 'preview';
  }

  buildOntology(action, doc) {
    const { kind, storedId, broadcastId, name } = doc.payload.data || {};

    const response = {
      /* eslint-disable sort-keys */
      phylum: action,
      class: kind,
      order: storedId,
      family: name,
      genus: null,
      species: broadcastId,
      /* eslint-enable */
    };

    // regex to override kind for trivia and polls
    const baseData = doc.payload.data || {};
    switch (kind) {
      case 'commerce': {
        response.class = 'product_sale';
        break;
      }
      case 'raffle':
      case 'raffle_active':
      case 'raffle_picking':
      case 'raffle_winner': {
        response.class = 'raffle';
        break;
      }

      case 'poll_results':
      case 'poll': {
        const pollKind = baseData.poll?.type;
        if (pollKind === 'trivia') {
          response.class = 'trivia';
        }
        if (pollKind === 'prediction') {
          response.class = 'prediction';
        }
        if (pollKind === 'poll') {
          response.class = 'poll';
        }
        break;
      }
      case 'highlight': {
        response.family = baseData.renderer?.name;
        break;
      }
      default:
        // do nothing
    }
    return response;
  }

  buildMetadata(action, doc, extra) {
    const baseData = doc.payload.data;

    const {
      kind,
      scheduled,
      post,
    } = baseData;

    const metadata = {
      scheduled: Boolean(scheduled),
    };

    switch (kind) {
      case 'commerce': {
        metadata.product = baseData.product?._id;
        break;
      }
      case 'instagram': {
        if (action === 'engage') {
          metadata.action = 'click';
        }
        metadata.url = post?.url;
        break;
      }
      case 'raffle':
      case 'raffle_active':
      case 'raffle_picking':
      case 'raffle_winner': {
        metadata.stage = kind;
        if (extra?.action) {
          metadata.action = extra.action;
        }
        break;
      }
      case 'poll': {
        metadata.options = extra?.options;
        if (action === 'engage') {
          metadata.action = extra.action;
          metadata.response = extra.response;
          metadata.poll = extra.poll;
          if (extra.action === 'answer_trivia') {
            metadata.correct = extra.correct;
          }
        }
        break;
      }
      case 'poll_results': {
        if (action === 'engage') {
          metadata.action = extra.action;
        }

        // engage only answer + correct check
        if (action === 'engage') {
          metadata.poll = extra.poll;
          metadata.response = extra.response;
          if (extra.action === 'answer_trivia') {
            metadata.correct = extra.correct;
          }
        }
        break;
      }
      case 'tweet': {
        metadata.url = baseData.renderer?.tweetUrl;
        if (action === 'engage') {
          if (extra.action) {
            metadata.action = extra.action;
          }
        }
        break;
      }
      case 'lower_third':
      case 'shopify':
      case 'message': {
        const { callToAction } = baseData;

        metadata.cta = callToAction?.kind || 'none';

        // only CTAS that add metadata this is not all
        switch (callToAction?.kind) {
          case 'go_to_channel':
            metadata.channel = callToAction.go_to_channel?._id;
            break;

          case 'open_link':
            metadata.url = callToAction.link;
            break;

          case 'play_video':
            metadata.video = callToAction.video?._id;
            break;

          case 'show_group':
            metadata.group = callToAction.group?._id;
            break;

          case 'show_panel':
            metadata.panel = callToAction.panel?._id;
            break;

          case 'show_person':
            metadata.person = callToAction.person?._id;
            break;

          default:

            // no metadata modifications
        }
        break;
      }
      case 'highlight': {
        metadata.cta = 'view stats';
        metadata.player = baseData.renderer?.ctaRenderer?.playerId;
        metadata.sport = 'nba';
        metadata.provider = 'sportradar';
        break;
      }
      default:
        // never should happen
    }

    return metadata;
  }
}

export default new OverlayEventBuilder();
