import { ITipSuggestedAmounts } from './models';

export const USD_SUGGESTED_TIP_AMOUNT: ITipSuggestedAmounts = {
  currency: 'usd',
  min: 5,
  max: 5000,
  suggested1: 5,
  suggested2: 10,
  suggested3: 20,
  conversionRate: 1,
};

// This also lives in backend tipping service, so if you're thinking of changing it,
// make sure you change it in both places.
export const PREDEFINED_SUGGESTED_TIP_AMOUNTS: ITipSuggestedAmounts[] = [
  USD_SUGGESTED_TIP_AMOUNT,
  {
    currency: 'aed',
    min: 20,
    max: 18370,
    suggested1: 20,
    suggested2: 40,
    suggested3: 75,
    conversionRate: 3.6731,
  },
  {
    currency: 'afn',
    min: 450,
    max: 447500,
    suggested1: 450,
    suggested2: 895,
    suggested3: 1790,
    conversionRate: 89.5,
  },
  {
    currency: 'all',
    min: 575,
    max: 573750,
    suggested1: 575,
    suggested2: 1150,
    suggested3: 2295,
    conversionRate: 114.75,
  },
  {
    currency: 'amd',
    min: 2035,
    max: 2033450,
    suggested1: 2035,
    suggested2: 4070,
    suggested3: 8135,
    conversionRate: 406.69,
  },
  {
    currency: 'ang',
    min: 10,
    max: 9015,
    suggested1: 10,
    suggested2: 20,
    suggested3: 40,
    conversionRate: 1.802676,
  },
  {
    currency: 'aoa',
    min: 2165,
    max: 2163210,
    suggested1: 2165,
    suggested2: 4330,
    suggested3: 8655,
    conversionRate: 432.6418,
  },
  {
    currency: 'ars',
    min: 660,
    max: 655630,
    suggested1: 660,
    suggested2: 1315,
    suggested3: 2625,
    conversionRate: 131.1256,
  },
  {
    currency: 'aud',
    min: 10,
    max: 7180,
    suggested1: 10,
    suggested2: 15,
    suggested3: 30,
    conversionRate: 1.435544,
  },
  {
    currency: 'awg',
    min: 10,
    max: 9000,
    suggested1: 10,
    suggested2: 20,
    suggested3: 40,
    conversionRate: 1.8,
  },
  {
    currency: 'azn',
    min: 10,
    max: 8500,
    suggested1: 10,
    suggested2: 20,
    suggested3: 35,
    conversionRate: 1.7,
  },
  {
    currency: 'bam',
    min: 10,
    max: 9655,
    suggested1: 10,
    suggested2: 20,
    suggested3: 40,
    conversionRate: 1.93053,
  },
  {
    currency: 'bbd',
    min: 15,
    max: 10100,
    suggested1: 15,
    suggested2: 25,
    suggested3: 45,
    conversionRate: 2.019633,
  },
  {
    currency: 'bdt',
    min: 475,
    max: 473740,
    suggested1: 475,
    suggested2: 950,
    suggested3: 1895,
    conversionRate: 94.74747,
  },
  {
    currency: 'bgn',
    min: 10,
    max: 9635,
    suggested1: 10,
    suggested2: 20,
    suggested3: 40,
    conversionRate: 1.926103,
  },
  {
    currency: 'bif',
    min: 10195,
    max: 10192500,
    suggested1: 10195,
    suggested2: 20385,
    suggested3: 40770,
    conversionRate: 2038.5,
  },
  {
    currency: 'bmd',
    min: 5,
    max: 5000,
    suggested1: 5,
    suggested2: 10,
    suggested3: 20,
    conversionRate: 1,
  },
  {
    currency: 'bnd',
    min: 10,
    max: 6920,
    suggested1: 10,
    suggested2: 15,
    suggested3: 30,
    conversionRate: 1.383599,
  },
  {
    currency: 'bob',
    min: 35,
    max: 34385,
    suggested1: 35,
    suggested2: 70,
    suggested3: 140,
    conversionRate: 6.876712,
  },
  {
    currency: 'brl',
    min: 30,
    max: 25940,
    suggested1: 30,
    suggested2: 55,
    suggested3: 105,
    conversionRate: 5.1878,
  },
  {
    currency: 'bsd',
    min: 10,
    max: 5005,
    suggested1: 10,
    suggested2: 15,
    suggested3: 25,
    conversionRate: 1.000291,
  },
  {
    currency: 'bwp',
    min: 65,
    max: 63150,
    suggested1: 65,
    suggested2: 130,
    suggested3: 255,
    conversionRate: 12.62979,
  },
  {
    currency: 'byn',
    min: 15,
    max: 12625,
    suggested1: 15,
    suggested2: 30,
    suggested3: 55,
    conversionRate: 2.524676,
  },
  {
    currency: 'bzd',
    min: 15,
    max: 10085,
    suggested1: 15,
    suggested2: 25,
    suggested3: 45,
    conversionRate: 2.016277,
  },
  {
    currency: 'cad',
    min: 10,
    max: 6420,
    suggested1: 10,
    suggested2: 15,
    suggested3: 30,
    conversionRate: 1.283625,
  },
  {
    currency: 'cdf',
    min: 10015,
    max: 10012500,
    suggested1: 10015,
    suggested2: 20025,
    suggested3: 40050,
    conversionRate: 2002.5,
  },
  {
    currency: 'chf',
    min: 5,
    max: 4780,
    suggested1: 5,
    suggested2: 10,
    suggested3: 20,
    conversionRate: 0.955915,
  },
  {
    currency: 'clp',
    min: 4555,
    max: 4551500,
    suggested1: 4555,
    suggested2: 9105,
    suggested3: 18210,
    conversionRate: 910.3,
  },
  {
    currency: 'cny',
    min: 35,
    max: 33740,
    suggested1: 35,
    suggested2: 70,
    suggested3: 135,
    conversionRate: 6.7473,
  },
  {
    currency: 'cop',
    min: 21880,
    max: 21876650,
    suggested1: 21880,
    suggested2: 43755,
    suggested3: 87510,
    conversionRate: 4375.33,
  },
  {
    currency: 'crc',
    min: 3345,
    max: 3344820,
    suggested1: 3345,
    suggested2: 6690,
    suggested3: 13380,
    conversionRate: 668.9632,
  },
  {
    currency: 'cve',
    min: 550,
    max: 545250,
    suggested1: 550,
    suggested2: 1095,
    suggested3: 2185,
    conversionRate: 109.05,
  },
  {
    currency: 'czk',
    min: 125,
    max: 120965,
    suggested1: 125,
    suggested2: 245,
    suggested3: 485,
    conversionRate: 24.1928,
  },
  {
    currency: 'djf',
    min: 890,
    max: 888600,
    suggested1: 890,
    suggested2: 1780,
    suggested3: 3555,
    conversionRate: 177.72,
  },
  {
    currency: 'dkk',
    min: 40,
    max: 36615,
    suggested1: 40,
    suggested2: 75,
    suggested3: 150,
    conversionRate: 7.3226,
  },
  {
    currency: 'dop',
    min: 275,
    max: 272350,
    suggested1: 275,
    suggested2: 545,
    suggested3: 1090,
    conversionRate: 54.47,
  },
  {
    currency: 'dzd',
    min: 735,
    max: 730910,
    suggested1: 735,
    suggested2: 1465,
    suggested3: 2925,
    conversionRate: 146.182,
  },
  {
    currency: 'egp',
    min: 95,
    max: 94615,
    suggested1: 95,
    suggested2: 190,
    suggested3: 380,
    conversionRate: 18.9221,
  },
  {
    currency: 'etb',
    min: 265,
    max: 260750,
    suggested1: 265,
    suggested2: 525,
    suggested3: 1045,
    conversionRate: 52.15,
  },
  {
    currency: 'eur',
    min: 5,
    max: 4920,
    suggested1: 5,
    suggested2: 10,
    suggested3: 20,
    conversionRate: 0.983665,
  },
  {
    currency: 'fjd',
    min: 15,
    max: 10970,
    suggested1: 15,
    suggested2: 25,
    suggested3: 45,
    conversionRate: 2.19355,
  },
  {
    currency: 'gbp',
    min: 5,
    max: 4125,
    suggested1: 5,
    suggested2: 10,
    suggested3: 20,
    conversionRate: 0.82411,
  },
  {
    currency: 'gel',
    min: 15,
    max: 13825,
    suggested1: 15,
    suggested2: 30,
    suggested3: 60,
    conversionRate: 2.765,
  },
  {
    currency: 'gmd',
    min: 270,
    max: 269600,
    suggested1: 270,
    suggested2: 540,
    suggested3: 1080,
    conversionRate: 53.92,
  },
  {
    currency: 'gnf',
    min: 44075,
    max: 44075000,
    suggested1: 44075,
    suggested2: 88150,
    suggested3: 176300,
    conversionRate: 8815,
  },
  {
    currency: 'gtq',
    min: 40,
    max: 38690,
    suggested1: 40,
    suggested2: 80,
    suggested3: 155,
    conversionRate: 7.737007,
  },
  {
    currency: 'gyd',
    min: 1050,
    max: 1046340,
    suggested1: 1050,
    suggested2: 2095,
    suggested3: 4190,
    conversionRate: 209.2675,
  },
  {
    currency: 'hkd',
    min: 40,
    max: 39250,
    suggested1: 40,
    suggested2: 80,
    suggested3: 160,
    conversionRate: 7.84972,
  },
  {
    currency: 'hnl',
    min: 125,
    max: 122800,
    suggested1: 125,
    suggested2: 250,
    suggested3: 495,
    conversionRate: 24.56,
  },
  {
    currency: 'hrk',
    min: 40,
    max: 36980,
    suggested1: 40,
    suggested2: 75,
    suggested3: 150,
    conversionRate: 7.3951,
  },
  {
    currency: 'htg',
    min: 610,
    max: 605160,
    suggested1: 610,
    suggested2: 1215,
    suggested3: 2425,
    conversionRate: 121.0319,
  },
  {
    currency: 'huf',
    min: 1990,
    max: 1986450,
    suggested1: 1990,
    suggested2: 3975,
    suggested3: 7950,
    conversionRate: 397.29,
  },
  {
    currency: 'idr',
    min: 74445,
    max: 74440250,
    suggested1: 74445,
    suggested2: 148885,
    suggested3: 297765,
    conversionRate: 14888.05,
  },
  {
    currency: 'ils',
    min: 20,
    max: 17090,
    suggested1: 20,
    suggested2: 35,
    suggested3: 70,
    conversionRate: 3.417705,
  },
  {
    currency: 'inr',
    min: 400,
    max: 398170,
    suggested1: 400,
    suggested2: 800,
    suggested3: 1595,
    conversionRate: 79.63305,
  },
  {
    currency: 'isk',
    min: 685,
    max: 681300,
    suggested1: 685,
    suggested2: 1365,
    suggested3: 2730,
    conversionRate: 136.26,
  },
  {
    currency: 'jmd',
    min: 770,
    max: 767455,
    suggested1: 770,
    suggested2: 1535,
    suggested3: 3070,
    conversionRate: 153.4905,
  },
  {
    currency: 'jpy',
    min: 675,
    max: 672340,
    suggested1: 675,
    suggested2: 1345,
    suggested3: 2690,
    conversionRate: 134.4675,
  },
  {
    currency: 'kes',
    min: 595,
    max: 594400,
    suggested1: 595,
    suggested2: 1190,
    suggested3: 2380,
    conversionRate: 118.88,
  },
  {
    currency: 'kgs',
    min: 420,
    max: 415355,
    suggested1: 420,
    suggested2: 835,
    suggested3: 1665,
    conversionRate: 83.0705,
  },
  {
    currency: 'khr',
    min: 20540,
    max: 20540000,
    suggested1: 20540,
    suggested2: 41080,
    suggested3: 82160,
    conversionRate: 4108,
  },
  {
    currency: 'kmf',
    min: 2430,
    max: 2426250,
    suggested1: 2430,
    suggested2: 4855,
    suggested3: 9705,
    conversionRate: 485.25,
  },
  {
    currency: 'krw',
    min: 6500,
    max: 6496675,
    suggested1: 6500,
    suggested2: 12995,
    suggested3: 25990,
    conversionRate: 1299.335,
  },
  {
    currency: 'kyd',
    min: 5,
    max: 4170,
    suggested1: 5,
    suggested2: 10,
    suggested3: 20,
    conversionRate: 0.8334773,
  },
  {
    currency: 'kzt',
    min: 2400,
    max: 2399300,
    suggested1: 2400,
    suggested2: 4800,
    suggested3: 9600,
    conversionRate: 479.8594,
  },
  {
    currency: 'lak',
    min: 77775,
    max: 77775000,
    suggested1: 77775,
    suggested2: 155550,
    suggested3: 311100,
    conversionRate: 15555,
  },
  {
    currency: 'lbp',
    min: 7580,
    max: 7580000,
    suggested1: 7580,
    suggested2: 15160,
    suggested3: 30320,
    conversionRate: 1516,
  },
  {
    currency: 'lkr',
    min: 1830,
    max: 1825455,
    suggested1: 1830,
    suggested2: 3655,
    suggested3: 7305,
    conversionRate: 365.0902,
  },
  {
    currency: 'lrd',
    min: 770,
    max: 766750,
    suggested1: 770,
    suggested2: 1535,
    suggested3: 3070,
    conversionRate: 153.35,
  },
  {
    currency: 'lsl',
    min: 80,
    max: 78550,
    suggested1: 80,
    suggested2: 160,
    suggested3: 315,
    conversionRate: 15.71,
  },
  {
    currency: 'mad',
    min: 55,
    max: 51620,
    suggested1: 55,
    suggested2: 105,
    suggested3: 210,
    conversionRate: 10.324,
  },
  {
    currency: 'mdl',
    min: 100,
    max: 96525,
    suggested1: 100,
    suggested2: 195,
    suggested3: 390,
    conversionRate: 19.30472,
  },
  {
    currency: 'mga',
    min: 21065,
    max: 21062500,
    suggested1: 21065,
    suggested2: 42125,
    suggested3: 84250,
    conversionRate: 4212.5,
  },
  {
    currency: 'mkd',
    min: 305,
    max: 304210,
    suggested1: 305,
    suggested2: 610,
    suggested3: 1220,
    conversionRate: 60.8411,
  },
  {
    currency: 'mmk',
    min: 9260,
    max: 9259785,
    suggested1: 9260,
    suggested2: 18520,
    suggested3: 37040,
    conversionRate: 1851.957,
  },
  {
    currency: 'mop',
    min: 45,
    max: 40440,
    suggested1: 45,
    suggested2: 85,
    suggested3: 165,
    conversionRate: 8.087116,
  },
  {
    currency: 'mur',
    min: 230,
    max: 227765,
    suggested1: 230,
    suggested2: 460,
    suggested3: 915,
    conversionRate: 45.55269,
  },
  {
    currency: 'mvr',
    min: 80,
    max: 76750,
    suggested1: 80,
    suggested2: 155,
    suggested3: 310,
    conversionRate: 15.35,
  },
  {
    currency: 'mwk',
    min: 5100,
    max: 5100000,
    suggested1: 5100,
    suggested2: 10200,
    suggested3: 20400,
    conversionRate: 1020,
  },
  {
    currency: 'mxn',
    min: 105,
    max: 101660,
    suggested1: 105,
    suggested2: 205,
    suggested3: 410,
    conversionRate: 20.3318,
  },
  {
    currency: 'myr',
    min: 25,
    max: 22265,
    suggested1: 25,
    suggested2: 45,
    suggested3: 90,
    conversionRate: 4.4525,
  },
  {
    currency: 'mzn',
    min: 320,
    max: 319150,
    suggested1: 320,
    suggested2: 640,
    suggested3: 1280,
    conversionRate: 63.83,
  },
  {
    currency: 'nad',
    min: 85,
    max: 82860,
    suggested1: 85,
    suggested2: 170,
    suggested3: 335,
    conversionRate: 16.5719,
  },
  {
    currency: 'ngn',
    min: 2080,
    max: 2077300,
    suggested1: 2080,
    suggested2: 4155,
    suggested3: 8310,
    conversionRate: 415.46,
  },
  {
    currency: 'nio',
    min: 180,
    max: 179375,
    suggested1: 180,
    suggested2: 360,
    suggested3: 720,
    conversionRate: 35.875,
  },
  {
    currency: 'nok',
    min: 50,
    max: 48805,
    suggested1: 50,
    suggested2: 100,
    suggested3: 200,
    conversionRate: 9.7601,
  },
  {
    currency: 'npr',
    min: 640,
    max: 638435,
    suggested1: 640,
    suggested2: 1280,
    suggested3: 2555,
    conversionRate: 127.6867,
  },
  {
    currency: 'nzd',
    min: 10,
    max: 7970,
    suggested1: 10,
    suggested2: 20,
    suggested3: 35,
    conversionRate: 1.59371,
  },
  {
    currency: 'pab',
    min: 10,
    max: 5005,
    suggested1: 10,
    suggested2: 15,
    suggested3: 25,
    conversionRate: 1.000192,
  },
  {
    currency: 'pen',
    min: 20,
    max: 19615,
    suggested1: 20,
    suggested2: 40,
    suggested3: 80,
    conversionRate: 3.9225,
  },
  {
    currency: 'pgk',
    min: 20,
    max: 17610,
    suggested1: 20,
    suggested2: 40,
    suggested3: 75,
    conversionRate: 3.52125,
  },
  {
    currency: 'php',
    min: 280,
    max: 279650,
    suggested1: 280,
    suggested2: 560,
    suggested3: 1120,
    conversionRate: 55.93,
  },
  {
    currency: 'pkr',
    min: 1205,
    max: 1201500,
    suggested1: 1205,
    suggested2: 2405,
    suggested3: 4810,
    conversionRate: 240.3,
  },
  {
    currency: 'pln',
    min: 25,
    max: 23405,
    suggested1: 25,
    suggested2: 50,
    suggested3: 95,
    conversionRate: 4.680471,
  },
  {
    currency: 'pyg',
    min: 34550,
    max: 34548380,
    suggested1: 34550,
    suggested2: 69100,
    suggested3: 138195,
    conversionRate: 6909.676,
  },
  {
    currency: 'qar',
    min: 20,
    max: 18205,
    suggested1: 20,
    suggested2: 40,
    suggested3: 75,
    conversionRate: 3.641,
  },
  {
    currency: 'ron',
    min: 25,
    max: 24270,
    suggested1: 25,
    suggested2: 50,
    suggested3: 100,
    conversionRate: 4.8531,
  },
  {
    currency: 'rsd',
    min: 580,
    max: 577300,
    suggested1: 580,
    suggested2: 1155,
    suggested3: 2310,
    conversionRate: 115.46,
  },
  {
    currency: 'rub',
    min: 310,
    max: 310000,
    suggested1: 310,
    suggested2: 620,
    suggested3: 1240,
    conversionRate: 62,
  },
  {
    currency: 'rwf',
    min: 5145,
    max: 5142500,
    suggested1: 5145,
    suggested2: 10285,
    suggested3: 20570,
    conversionRate: 1028.5,
  },
  {
    currency: 'sar',
    min: 20,
    max: 18785,
    suggested1: 20,
    suggested2: 40,
    suggested3: 80,
    conversionRate: 3.756231,
  },
  {
    currency: 'sbd',
    min: 45,
    max: 40990,
    suggested1: 45,
    suggested2: 85,
    suggested3: 165,
    conversionRate: 8.197272,
  },
  {
    currency: 'scr',
    min: 70,
    max: 65350,
    suggested1: 70,
    suggested2: 135,
    suggested3: 265,
    conversionRate: 13.06972,
  },
  {
    currency: 'sek',
    min: 55,
    max: 51210,
    suggested1: 55,
    suggested2: 105,
    suggested3: 205,
    conversionRate: 10.24182,
  },
  {
    currency: 'sgd',
    min: 10,
    max: 6905,
    suggested1: 10,
    suggested2: 15,
    suggested3: 30,
    conversionRate: 1.3805,
  },
  {
    currency: 'sll',
    min: 69600,
    max: 69600000,
    suggested1: 69600,
    suggested2: 139200,
    suggested3: 278400,
    conversionRate: 13920,
  },
  {
    currency: 'sos',
    min: 2925,
    max: 2925000,
    suggested1: 2925,
    suggested2: 5850,
    suggested3: 11700,
    conversionRate: 585,
  },
  {
    currency: 'srd',
    min: 125,
    max: 120990,
    suggested1: 125,
    suggested2: 245,
    suggested3: 485,
    conversionRate: 24.1975,
  },
  {
    currency: 'szl',
    min: 85,
    max: 82870,
    suggested1: 85,
    suggested2: 170,
    suggested3: 335,
    conversionRate: 16.57325,
  },
  {
    currency: 'thb',
    min: 185,
    max: 182350,
    suggested1: 185,
    suggested2: 365,
    suggested3: 730,
    conversionRate: 36.47,
  },
  {
    currency: 'tjs',
    min: 55,
    max: 50915,
    suggested1: 55,
    suggested2: 105,
    suggested3: 205,
    conversionRate: 10.18241,
  },
  {
    currency: 'top',
    min: 15,
    max: 11685,
    suggested1: 15,
    suggested2: 25,
    suggested3: 50,
    conversionRate: 2.33685,
  },
  {
    currency: 'try',
    min: 90,
    max: 89585,
    suggested1: 90,
    suggested2: 180,
    suggested3: 360,
    conversionRate: 17.9166,
  },
  {
    currency: 'ttd',
    min: 35,
    max: 33970,
    suggested1: 35,
    suggested2: 70,
    suggested3: 140,
    conversionRate: 6.793768,
  },
  {
    currency: 'twd',
    min: 150,
    max: 149750,
    suggested1: 150,
    suggested2: 300,
    suggested3: 600,
    conversionRate: 29.95,
  },
  {
    currency: 'tzs',
    min: 11665,
    max: 11663055,
    suggested1: 11665,
    suggested2: 23330,
    suggested3: 46655,
    conversionRate: 2332.611,
  },
  {
    currency: 'uah',
    min: 185,
    max: 184450,
    suggested1: 185,
    suggested2: 370,
    suggested3: 740,
    conversionRate: 36.88921,
  },
  {
    currency: 'ugx',
    min: 19395,
    max: 19392365,
    suggested1: 19395,
    suggested2: 38785,
    suggested3: 77570,
    conversionRate: 3878.473,
  },
  {
    currency: 'uyu',
    min: 210,
    max: 208475,
    suggested1: 210,
    suggested2: 420,
    suggested3: 835,
    conversionRate: 41.69455,
  },
  {
    currency: 'uzs',
    min: 54690,
    max: 54687500,
    suggested1: 54690,
    suggested2: 109375,
    suggested3: 218750,
    conversionRate: 10937.5,
  },
  {
    currency: 'vnd',
    min: 116815,
    max: 116815000,
    suggested1: 116815,
    suggested2: 233630,
    suggested3: 467260,
    conversionRate: 23363,
  },
  {
    currency: 'xaf',
    min: 3240,
    max: 3237120,
    suggested1: 3240,
    suggested2: 6475,
    suggested3: 12950,
    conversionRate: 647.424,
  },
  {
    currency: 'xcd',
    min: 15,
    max: 13515,
    suggested1: 15,
    suggested2: 30,
    suggested3: 55,
    conversionRate: 2.70255,
  },
  {
    currency: 'xof',
    min: 3220,
    max: 3217500,
    suggested1: 3220,
    suggested2: 6435,
    suggested3: 12870,
    conversionRate: 643.5,
  },
  {
    currency: 'xpf',
    min: 595,
    max: 590250,
    suggested1: 595,
    suggested2: 1185,
    suggested3: 2365,
    conversionRate: 118.05,
  },
  {
    currency: 'yer',
    min: 1255,
    max: 1251250,
    suggested1: 1255,
    suggested2: 2505,
    suggested3: 5005,
    conversionRate: 250.25,
  },
  {
    currency: 'zar',
    min: 85,
    max: 82480,
    suggested1: 85,
    suggested2: 165,
    suggested3: 330,
    conversionRate: 16.49592,
  },
  {
    currency: 'zmw',
    min: 85,
    max: 81650,
    suggested1: 85,
    suggested2: 165,
    suggested3: 330,
    conversionRate: 16.3293,
  },
];

export const getSuggestedTipAmountsByCurrency = (nativeCurrency: string | null): ITipSuggestedAmounts => {
  if (!nativeCurrency) {
    return USD_SUGGESTED_TIP_AMOUNT;
  }

  const result = PREDEFINED_SUGGESTED_TIP_AMOUNTS.find(suggested => suggested.currency.toLowerCase() === nativeCurrency?.toLowerCase());
  if (!result) {
    return USD_SUGGESTED_TIP_AMOUNT;
  }

  return result;
};

