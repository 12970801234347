import { mapStackTrace } from 'sourcemapped-stacktrace';

type Listener = (event: Event) => any;

export const addErrorListener = (listener: Listener) => {
  window.addEventListener('error', listener);
};

export const removeErrorListener = (listener: Listener) => {
  window.removeEventListener('error', listener);
};

export const mapErrorStack = (stack: string) => new Promise((resolve) => {
  mapStackTrace(stack, (mappedLines) => resolve(mappedLines.join('\n')));
});
