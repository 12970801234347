// tslint:disable:no-console
import { put } from 'redux-saga/effects';
import { replace } from 'services/app-router';
import { setStudioGuestModeInviteCode } from 'services/livestream/actions';
import { getTopLocationUrl } from 'url-utils';
import qs from 'qs';

export function* studioGuestModeInviteCodeSaga() {
  try {
    const topParams = getTopLocationUrl().split('?')[1];
    const {
      m_embed_url: embedUrl,
      studio_invite: studioInviteCode,
    } = qs.parse(topParams) as Record<string, string>;

    if (studioInviteCode) {
      yield put(setStudioGuestModeInviteCode(studioInviteCode));
    }

    if (embedUrl) {
      const embedUrlParts = embedUrl.split('?');
      const query = embedUrlParts[1] ? qs.parse(embedUrlParts[1]) : {};
      yield put(replace({ path: embedUrlParts[0], query }));
    }
  } catch (error) {
    console.error('studioGuestModeInviteCodeSaga:', error);
  }
}
