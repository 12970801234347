import axios from 'axios';
import { POLL_RESPONSE_SERVICE_BASE_URL, POLL_SERVICE_BASE_URL } from 'config';

import { IPoll, IPollResponse } from './models';

export const createPoll = async (accessToken: string, poll: IPoll, siteId: string): Promise<IPoll> => {
  const { data } = await axios.post<IPoll>(`${POLL_SERVICE_BASE_URL}`, poll, {
    headers: {
      'Authorization': `Bearer ${accessToken}`,
      'x-maestro-client-id': siteId,
    },
  });
  return data;
};

export const updatePoll = async (accessToken: string, poll: IPoll, siteId: string): Promise<IPoll> => {
  const { data } = await axios.put<IPoll>(`${POLL_SERVICE_BASE_URL}/${poll._id}`, poll, {
    headers: {
      'Authorization': `Bearer ${accessToken}`,
      'x-maestro-client-id': siteId,
    },
  });
  return data;
};

export const deletePoll = async (accessToken: string, pollId: string, siteId: string): Promise<void> => {
  await axios.delete(`${POLL_SERVICE_BASE_URL}/${pollId}`, {
    headers: {
      'Authorization': `Bearer ${accessToken}`,
      'x-maestro-client-id': siteId,
    },
  });
};

export const createPollResponse = async (
  accessToken: string, pollResponse: IPollResponse, siteId: string,
): Promise<IPollResponse> => {
  const { data } = await axios.post<IPollResponse>(`${POLL_RESPONSE_SERVICE_BASE_URL}`, pollResponse, {
    headers: {
      'Authorization': `Bearer ${accessToken}`,
      'x-maestro-client-id': siteId,
    },
  });
  return data;
};

export const getPoll = async (accessToken: string, pollId: string, siteId: string): Promise<IPoll> => {
  const { data } = await axios.get<IPoll>(`${POLL_SERVICE_BASE_URL}/${pollId}`, {
    headers: {
      'Authorization': `Bearer ${accessToken}`,
      'x-maestro-client-id': siteId,
    },
  });
  return data;
};
