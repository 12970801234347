import { PLAYLIST_SERVICE_BASE_URL, VIDEO_SERVICE_BASE_URL } from 'config';
import { IPlaylist } from 'models';
import maestroApiRequest from 'services/maestro-api-client';
import axios from 'axios';

type FetchPlaylistParams = {
  captureCanceler?: (abortFn: () => unknown) => unknown;
  siteId: string;
  token?: string;
};

export const getPlaylist = async ({
  id,
  siteId,
  token,
  captureCanceler,
}: FetchPlaylistParams & { id: string }): Promise<IPlaylist | null> => {
  const { data: playlist } = await maestroApiRequest({
    primaryToken: token ?? '',
    siteId,
  }).get(`${PLAYLIST_SERVICE_BASE_URL}/${id}`, {
    cancelToken: captureCanceler
      ? new axios.CancelToken(captureCanceler)
      : undefined,
  });

  return playlist;
};

export const getTagPlaylist = async ({
  tag,
  siteId,
  token,
  captureCanceler,
}: FetchPlaylistParams & { tag: string }): Promise<IPlaylist | null> => {
  const { data: videos } = await maestroApiRequest({
    primaryToken: token ?? '',
    siteId,
  }).get(`${VIDEO_SERVICE_BASE_URL}/tags/videos`, {
    params: {
      tagName: tag,
    },
    cancelToken: captureCanceler
      ? new axios.CancelToken(captureCanceler)
      : undefined,
  });

  if (Array.isArray(videos)) {
    return {
      members: videos.map((video) => ({ id: video._id, schema: 'video' })),
      siteId,
      title: tag,
    };
  }

  return null;
};
