import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import uuid from 'uuid/v4';
import IState from 'services/state';
import { AdminActionEvents } from 'services/admin/models';
import { LOG_IN_SUCCESS } from 'services/auth/actions';
import { ModalKinds } from 'services/modals/types';
import { getPrimaryToken, getUserEmail } from 'services/auth/selectors';
import { getSiteId } from 'services/app/selectors';
import { isFeatureEnabled } from 'services/feature-gate/selectors';
import { removeKeys } from 'services/app-router/actions';
import { showModal } from 'services/modals/actions';
import { trackEvent } from 'services/segment-analytics';
import { updatePaymentsSaga } from 'services/billing/saga';

import {
  failShopRemoval,
  successShopRemoval,
  REMOVE_SHOP,
  IRemoveShop,
  CONNECTING_SHOP,
  successConnectingShop,
  failConnectingShop,
  successShopInfo,
  failShopInfo,
  shopInfo,
  ISaveBillingDataToSource,
  SAVE_BILLING_DATA_TO_SOURCE,
  loadingProducts,
  loadingCollections,
  setProducts,
  setCollections,
  failProducts,
  failCollections,
  successCollections,
  successProducts,
  ICheckoutBundleShopifyItemsAction,
  CHECKOUT_BUNDLE_SHOPIFY_ITEMS,
  setPaymentToken,
  setBundleCheckout,
  setBundleLoading,
  setBundleSuccess,
  setBundleError,
  CREATE_PAYMENT_TOKEN,
  ICreatePaymentTokenAction,
  recurringCharge,
} from './actions';

import {
  getShopConnectionString,
  shopConnectionFlowTriggered,
  getShopAccessToken,
  getShop,
  getShopState,
  getProductsPageInfo,
  getCollectionsPageInfo,
  getShopifyPaymentsAccountId,
  getBundleCheckout,
  getIsShopifyConnected,
} from './selectors';

import {
  createCheckoutGraphQL,
  createCommission,
  getShopInfo,
  removeShopApi,
  connectShop,
  getCheckoutGraphQL,
  updateCheckoutShippingLine,
  completeCheckoutWithTokenGraphQL,
  getPaymentGraphQL,
  updateCheckoutLineItemsGraphQL,
  updateCheckoutShippingAddressGraphQL,
  updateCheckoutEmailGraphQL,
  updateSource,
  getProducts,
  getCollections,
} from './api';

import {
  IShop,
  IConnectingShopResponse,
  ICheckoutInput,
  ICheckoutLineItem,
  IPaymentStorefrontInput,
  IShopifyState,
  ICheckoutStorefront,
  IPaymentStorefront,
  IGetCheckoutGraphQLResponse,
  IUpdateCheckoutShippingLineResponse,
  ITokenResponse,
  IStripeTokenResponse,
} from './models';
import { getStripeCardToken } from 'services/billing/api';
import { fetchTokenFromReusableSource, fetchTokenFromSingleUseSource } from 'services/shopify/api';
import { getBillingInfo, getShopifyPaymentMethods, getStripeCustomerId } from 'services/billing';
import { PaymentMethod } from '@stripe/stripe-js';
import { getShippingAddress } from 'services/shipping-address';
import { loadEcommerceCartSaga } from 'services/ecommerce/saga/cart';
import { CheckoutCompleteWithTokenizedPaymentV3Mutation, CheckoutCreateWithShippingMutation, CheckoutCreateWithoutShippingMutation, CheckoutEmailUpdateV2Mutation, CheckoutLineItemsReplaceMutation, CheckoutShippingAddressUpdateV2Mutation, GetCheckoutWithShippingQuery, GetCheckoutWithoutShippingQuery, GetCollectionsQuery, GetPaymentQuery, GetProductsQuery } from './graphql/types';

export function* connectShopSaga() {
  const state: IState = yield select();
  const siteId: string = getSiteId(state);
  const shop: string = getShopConnectionString(state);
  const token: string | null = getPrimaryToken(state);

  try {
    const response: IConnectingShopResponse = yield call(
      connectShop,
      {
        shop,
        siteId,
        token,
      },
    );
    yield put(successConnectingShop(response));
    yield put(recurringCharge({
      active: false,
      confirmationUrl: response.confirmationUrl,
    }));
    yield put(trackEvent({ event: AdminActionEvents.SHOPIFY_STORE_CONNECTED, properties: { shopName: shop } }));
    yield put(removeKeys(['connectshop']));
  } catch (err) {
    yield put(failConnectingShop(err));
  }
}

export const createCheckoutIdSaga = function* ({ checkoutInputData, hasShippableItems, shop, storefrontAccessToken }: any) {
  try {
    // note: if we don't have the shipping data we are not going to call graphql-createCheckoutGraphQL
    // tslint:disable-next-line: no-shadowed-variable
    const { address1, city, country, firstName, lastName, zip } = checkoutInputData.shippingAddress;
    if (!(address1 && city && country && firstName && lastName && zip)) {
      throw (new Error('No Shipping Address.'));
    }

    const checkoutResponseData: CheckoutCreateWithShippingMutation | CheckoutCreateWithoutShippingMutation = yield call(createCheckoutGraphQL, {
      accessToken: storefrontAccessToken,
      checkoutData: checkoutInputData,
      shop,
      requiresShipping: hasShippableItems,
    });
    if (!checkoutResponseData?.checkoutCreate?.checkout) {
      throw new Error(checkoutResponseData.checkoutCreate?.checkoutUserErrors[0].message || 'Error creating checkout');
    }

    return { checkout: checkoutResponseData?.checkoutCreate?.checkout, success: true };

  } catch (error) {
    return { error, success: false };
  }
};

export const updateCheckoutSaga = function* ({ checkoutId, checkoutInputData, email, shop, storefrontAccessToken }: any) {
  try {
    const updateLineItemsRespData: CheckoutLineItemsReplaceMutation = yield call(updateCheckoutLineItemsGraphQL, {
      accessToken: storefrontAccessToken,
      checkoutId, // : checkout.id,
      lineItems: checkoutInputData.lineItems,
      shop,
    });

    if (!updateLineItemsRespData?.checkoutLineItemsReplace?.checkout) {
      throw new Error('Checkout does not exist');
    }

    if (updateLineItemsRespData.checkoutLineItemsReplace.userErrors && updateLineItemsRespData.checkoutLineItemsReplace.userErrors.length > 0) {
      throw new Error(updateLineItemsRespData.checkoutLineItemsReplace.userErrors[0].message);
    }

    const updateShippingAddressRespData: CheckoutShippingAddressUpdateV2Mutation = yield call(updateCheckoutShippingAddressGraphQL, {
      accessToken: storefrontAccessToken,
      checkoutId, // : checkout.id,
      shippingAddress: checkoutInputData.shippingAddress,
      shop,
    });
    const updateShippingAddressErrors = updateShippingAddressRespData?.checkoutShippingAddressUpdateV2?.checkoutUserErrors || [];
    if (updateShippingAddressErrors.length > 0) {
      throw new Error(updateShippingAddressErrors[0].message);
    }

    const updateEmailRespData: CheckoutEmailUpdateV2Mutation = yield call(updateCheckoutEmailGraphQL, {
      accessToken: storefrontAccessToken,
      checkoutId,
      email: email || '',
      shop,
    });

    if (updateEmailRespData?.checkoutEmailUpdateV2?.checkoutUserErrors && updateEmailRespData?.checkoutEmailUpdateV2.checkoutUserErrors.length > 0) {
      throw new Error(updateEmailRespData.checkoutEmailUpdateV2.checkoutUserErrors[0].message);
    }

    return { success: true };
  } catch (error) {
    return { error, success: false };
  }
};

export function* setupShopifyItemsSaga() {
  const state: IState = yield select();
  const isShopifyEnabled = isFeatureEnabled(state, 'shopify');
  const shop = getShop(state);
  const isValidShop = !!shop;
  if (isShopifyEnabled && isValidShop) {
    yield put(loadingCollections());
    yield (setupCollectionsSaga());
    yield put(loadingProducts());
    yield (setupProductsSaga());
  }
}

export function* setupProductsSaga(): any {
  const state: IState = yield select();
  const shop = getShop(state);
  const storefrontAccessToken = getShopAccessToken(state);
  const { hasNextPage, cursor } = getProductsPageInfo(state);
  if (!hasNextPage) {
    yield put(successProducts());
    return;
  }
  try {
    const data: GetProductsQuery = yield call(
      getProducts,
      shop,
      storefrontAccessToken,
      cursor,
    );
    const { products } = data;
    if (data) {
      yield put(
        setProducts({
          products: products.edges?.map((item: any) => item.node),
          pageInfo: {
            hasNextPage: products?.pageInfo.hasNextPage,
            cursor: products?.edges[products?.edges?.length - 1]?.cursor,
          },
        }));
      yield (setupProductsSaga());
    }
  } catch (err) {
    yield put(failProducts(err));
  }
}

export function* setupCollectionsSaga(): any {
  const state: IState = yield select();
  const shop = getShop(state);
  const storefrontAccessToken = getShopAccessToken(state);
  const { hasNextPage, cursor } = getCollectionsPageInfo(state);
  if (!hasNextPage) {
    yield put(successCollections());
    return;
  }
  try {
    const data: GetCollectionsQuery = yield call(
      getCollections,
      shop,
      storefrontAccessToken,
      cursor,
    );
    const { collections } = data;
    if (data) {
      yield put(
        setCollections({
          collections: collections.edges?.map((item: any) => item?.node),
          pageInfo: {
            hasNextPage: collections?.pageInfo?.hasNextPage,
            cursor: collections?.edges[collections?.edges?.length - 1]?.cursor,
          },
        }),
      );
      yield (setupCollectionsSaga());
    }
  } catch (err) {
    yield put(failCollections(err));
  }
}

export function* removeShopSaga({ payload }: IRemoveShop) {
  const { shop } = payload;
  const state: IState = yield select();
  const siteId: string = getSiteId(state);
  const token: string | null = getPrimaryToken(state);
  try {
    yield call(removeShopApi, { shop, siteId, token });
    yield put(successShopRemoval());
  } catch (err) {
    yield put(failShopRemoval(err));
  }
}

export function* openShopConnect() {
  if (!(yield select(shopConnectionFlowTriggered))) {
    return;
  }
  yield put(showModal({ kind: ModalKinds.shopify }));
}

export function* setupShopInfo() {
  const state: IState = yield select();
  const siteId: string = getSiteId(state);
  const isShopifyEnabled = isFeatureEnabled(state, 'shopify');
  if (!isShopifyEnabled) {
    return;
  }

  const isShopifyConnected = getIsShopifyConnected(state);
  if (isShopifyConnected) {
    yield call(loadEcommerceCartSaga);
    return;
  }
  try {
    yield put(shopInfo());
    const shopInfoData: IShop = yield call(getShopInfo, siteId);
    yield put(recurringCharge({
      active: shopInfoData.recurringChargeActivated,
      confirmationUrl: shopInfoData.recurringChargeConfirmationUrl,
    }));
    const isValidSite = !!shopInfoData.shop;
    if (isValidSite) {
      yield put(successShopInfo({
        moneyFormat: shopInfoData.moneyFormat,
        shop: shopInfoData.shop,
        shopAccessToken: shopInfoData.storefrontAccessToken,
        shopifyPaymentsAccountId: shopInfoData.shopifyPaymentsAccountId,
        currencyCode: shopInfoData.currencyCode,
      }));
      yield call(loadEcommerceCartSaga);
    }
  } catch (err) {
    yield put(failShopInfo({ err }));
  }
}

export function* saveBillingDataToSourceSaga({ payload }: ISaveBillingDataToSource) {
  const { accessToken, billingData, cardId, customer } = payload;
  yield call(updateSource, { accessToken, billingData, cardId, customer });
  yield call(updatePaymentsSaga);
}

export function* createCheckoutBundleShopifyItemsSaga({
  payload,
}: ICheckoutBundleShopifyItemsAction) {
  const { shippingAddress, productsVariant } = payload;
  yield put(setBundleLoading(true));
  yield put(setBundleError(''));
  const state: IState = yield select();
  const currentCheckout: ICheckoutStorefront | null = yield select(getBundleCheckout);

  const shipping = {
    address1: shippingAddress.address1,
    address2: shippingAddress.address2 || '',
    city: shippingAddress.city,
    country: shippingAddress.countryCode,
    firstName: shippingAddress.firstName,
    lastName: shippingAddress.lastName,
    phone: shippingAddress.phoneNumber,
    province: shippingAddress.state,
    zip: shippingAddress.postalCode,
  };

  const shopInfoData = getShopState(state);
  const email = getUserEmail(state) || '';
  let hasShippableItems = false;
  for (const itemId of Object.keys(productsVariant)) {
    const item = productsVariant[itemId];
    if (item?.requiresShipping) {
      hasShippableItems = true;
      break;
    }
  }
  const { shop, shopAccessToken: storefrontAccessToken } = shopInfoData;
  const checkoutInputData: ICheckoutInput = {
    email,
    lineItems: Object.keys(productsVariant).map((variantId) => ({ quantity: 1, variantId: productsVariant[variantId].id })),
    shippingAddress: shipping,
  };

  let checkout: ICheckoutStorefront | null = null;

  try {
    // If there was a previous checkout, we need to update its address in shopify
    if (currentCheckout && currentCheckout?.id && hasShippableItems) {
      // @ts-ignore
      const updatedCheckout = yield call(updateCheckoutSaga, {
        checkoutId: currentCheckout?.id,
        checkoutInputData,
        email,
        hasShippableItems,
        shop,
        storefrontAccessToken,
      });
      if (updatedCheckout?.success) {
        let updatedCheckoutResponse: IGetCheckoutGraphQLResponse;
        // @ts-expect-error
        while (updatedCheckoutResponse?.data?.node?.ready !== true) {
          // @ts-ignore
          updatedCheckoutResponse = yield call(getCheckoutGraphQL, {
            accessToken: storefrontAccessToken,
            checkoutId: currentCheckout?.id || '',
            shop,
            requiresShipping: hasShippableItems,
          });
          if (updatedCheckoutResponse.errors && updatedCheckoutResponse.errors.length > 0) {
            throw new Error(updatedCheckoutResponse.errors[0].message);
          }
          yield delay(1000);
        }
        checkout = updatedCheckoutResponse?.data?.node;
      } else {
        yield put(setBundleCheckout(null));
        throw new Error(updatedCheckout.error);
      }
    }
    // If there was no previous checkout, we need to create a new one
    else {
      // @ts-ignore
      const newCheckout = yield call(createCheckoutIdSaga, { checkoutInputData, hasShippableItems, shop, storefrontAccessToken });
      if (!newCheckout.success) {
        throw new Error(newCheckout.error);
      }
      checkout = newCheckout?.checkout;
    }

    let checkoutResponse: IGetCheckoutGraphQLResponse;
    const isShippingRatesReady = (
      checkout?.availableShippingRates?.ready &&
      checkout?.availableShippingRates?.shippingRates?.length > 0
    );

    // poll for checkout to be ready
    while (
      !checkout?.ready &&
      hasShippableItems &&
      !isShippingRatesReady
    ) {
      // @ts-ignore
      checkoutResponse = yield call(getCheckoutGraphQL, {
        accessToken: storefrontAccessToken,
        checkoutId: checkout?.id || '',
        shop,
        requiresShipping: hasShippableItems,
      });
      if (checkoutResponse.errors && checkoutResponse.errors.length > 0) {
        throw new Error(checkoutResponse.errors[0].message);
      }
      // @ts-expect-error
      checkout = checkoutResponse?.data?.node;
      yield delay(1000);
    }
    // If product has shipping, we need to get the shipping rates and bind them to the checkout
    if (hasShippableItems) {
      if (
        checkout?.availableShippingRates?.shippingRates &&
        checkout?.availableShippingRates?.shippingRates?.length > 0
      ) {
        // grab the first shipping rate by default
        const { handle } = checkout.availableShippingRates.shippingRates[0];
        // @ts-ignore
        const updateCheckoutResponse: IUpdateCheckoutShippingLineResponse = yield call(
          updateCheckoutShippingLine,
          {
            accessToken: storefrontAccessToken,
            checkoutId: checkout?.id || '',
            handle,
            shop,
          },
        );

        if (updateCheckoutResponse.checkoutShippingLineUpdate?.checkoutUserErrors?.length) {
          throw new Error(`Error updating shipping lines: ${updateCheckoutResponse.checkoutShippingLineUpdate?.checkoutUserErrors[0].message}`);
        }
        checkout.ready = updateCheckoutResponse.checkoutShippingLineUpdate?.checkout?.ready || false;

        // poll for checkout to be ready
        while (!checkout?.ready || !checkout?.shippingLine) {
          checkoutResponse = yield call(getCheckoutGraphQL, {
            accessToken: storefrontAccessToken,
            checkoutId: checkout?.id || '',
            shop,
            requiresShipping: hasShippableItems,
          });
          if (checkoutResponse.errors && checkoutResponse.errors.length > 0) {
            throw new Error(checkoutResponse.errors[0].message);
          }
          // @ts-ignore
          checkout = checkoutResponse?.data?.node;
          yield delay(1000);
        }
        yield put(setBundleLoading(false));
        yield put(setBundleCheckout(checkout));
      } else {
        throw new Error('No Shipping Rates available.');
      }
    } else {
      yield put(setBundleLoading(false));
      yield put(setBundleCheckout(checkout));
    }
  } catch (err) {
    yield put(setBundleLoading(false));
    yield put(setBundleError(err.message));
  }
}

export function* createPaymentTokenSaga({ payload }: ICreatePaymentTokenAction) {
  const { element, stripe, paymentMethod } = payload;
  const state: IState = yield select();
  const billingInfo = getBillingInfo(state);
  const shippingAddress = getShippingAddress(state);
  const shopifyPaymentsAccountId: string = yield select(getShopifyPaymentsAccountId);
  const stripeCustomerId: string = yield select(getStripeCustomerId);
  const savedPaymentMethod: PaymentMethod = yield select(getShopifyPaymentMethods);
  const shopInfoData = getShopState(state);
  const email = getUserEmail(state) || '';
  yield put(setBundleLoading(true));

  let token: IStripeTokenResponse | string = '';
  try {
    if (savedPaymentMethod && stripeCustomerId && paymentMethod) {
      // @ts-ignore
      const response: ITokenResponse = yield call(fetchTokenFromReusableSource, {
        account: shopifyPaymentsAccountId,
        cardId: paymentMethod?.id,
        customer: stripeCustomerId,
      });
     token = response?.token;
   } else {
     // @ts-ignore
     const stripeCard = yield call(getStripeCardToken, element, stripe, billingInfo);
     const { token: stripeCardToken, error } = stripeCard;
     if (error) {
       yield put(setBundleError(error));
       yield put(setBundleLoading(false));
       return;
     }
     // @ts-ignore
     const response: ITokenResponse = yield call(fetchTokenFromSingleUseSource, {
       account: shopifyPaymentsAccountId,
       customer: stripeCustomerId,
       tokenId: stripeCardToken?.id,
     });
     const [firstName, lastName] = billingInfo.name.split(' ');

     // @ts-ignore
     yield call(updateSource, {
       accessToken: shopInfoData.shopAccessToken,
       billingData: {
         address: billingInfo.address,
         city: billingInfo.city,
         country: billingInfo.countryCode,
         email: email || 'test@email.com',
         firstName: firstName || shippingAddress.firstName,
         lastName: lastName || shippingAddress.lastName,
         provinceCode: billingInfo.stateCode,
         zip: billingInfo.postalCode,
       },
       cardId: stripeCardToken?.card?.id || '',
       customer: stripeCustomerId,
     });
     token = response?.token;
   }
  yield call(updatePaymentsSaga);
  yield put(setPaymentToken(token));
  } catch (err) {
    yield put(setBundleLoading(false));
    yield put(setBundleError(err.message));
  }
  return token;
}

export function* payBundleItemSaga(paymentToken: string) {
  const state: IState = yield select();
  yield put(setBundleLoading(true));

  const siteId: string = getSiteId(state);
  const shopInfoData = getShopState(state);
  const checkout = getBundleCheckout(state);
  const address = getShippingAddress(state);

  try {
    yield put(setBundleLoading(true));
    if (!checkout || !paymentToken) {
      yield put(setBundleLoading(false));
      yield put(setBundleError('Missing checkout or payment token'));
      return;
    }

    const { shop, shopAccessToken: storefrontAccessToken } = shopInfoData;
    const paymentData: IPaymentStorefrontInput = {
      paymentAmount: {
        amount: checkout.paymentDueV2.amount,
        currencyCode: checkout.paymentDueV2.currencyCode,
      },
      idempotencyKey: uuid(),
      billingAddress: {
        address1: address.address1,
        address2: address.address2,
        city: address.city,
        zip: address.postalCode,
        firstName: address.firstName,
        lastName: address.lastName,
        phone: address.phoneNumber,
        province: address.state,
        country: address.countryCode,
      },
      paymentData: paymentToken,
      type: 'STRIPE_VAULT_TOKEN',
    };

    const paymentResponseData: CheckoutCompleteWithTokenizedPaymentV3Mutation = yield call(completeCheckoutWithTokenGraphQL, {
      accessToken: storefrontAccessToken,
      checkoutId: checkout.id || '',
      payment: paymentData,
      shop,
    });

    // @ts-expect-error
    let payment: IPaymentStorefront = paymentResponseData?.checkoutCompleteWithTokenizedPaymentV3?.payment;
    const paymentErrors = paymentResponseData?.checkoutCompleteWithTokenizedPaymentV3?.checkoutUserErrors;

    if (paymentErrors && paymentErrors.length > 0) {
      const errors: string[] = [];
      const checkouts: ICheckoutLineItem[] = paymentResponseData?.checkoutCompleteWithTokenizedPaymentV3?.checkout?.lineItems?.edges.map((lineItem: { node: ICheckoutLineItem }) => {
        return lineItem.node;
      }) || [];
      paymentResponseData?.checkoutCompleteWithTokenizedPaymentV3?.checkoutUserErrors.map((userError) => {
        switch (userError.code) {
          case 'NOT_ENOUGH_IN_STOCK': {
            if (userError.field) {
              errors.push(`Not enough ${checkouts[+userError.field[1]].title} available`);
            }
            break;
          }
          default:
            errors.push(userError.message);
            break;
        }
      });
      // convert errors array to string
      const errorString = errors.join(', ');
      yield put(setBundleError(errorString));
      yield put(setBundleLoading(false));
      return;
    }
    while (!payment?.ready) {
      const paymentPollResponseData: { node: IPaymentStorefront } = yield call(getPaymentGraphQL, {
        accessToken: storefrontAccessToken,
        paymentId: payment?.id,
        shop,
      });

      payment = paymentPollResponseData.node;
      yield delay(1000);
    }

    if ((!payment.transaction && payment.ready) || payment.transaction?.statusV2 === 'ERROR' || payment.transaction?.statusV2 === 'FAILURE') {
      throw new Error(payment.errorMessage || 'There was an error with your transaction. Please try again.');
    }

    if (payment.transaction?.statusV2 === 'SUCCESS') {
      let total: string = '';
      if (payment.checkout) {
        const { subtotalPriceV2: { amount } } = payment.checkout;
        total = amount.toString();
      }
      yield put(setBundleSuccess(true));
      yield call(createCommission, {
        orderId: payment.checkout?.order?.name,
        shop,
        siteId,
        total: parseFloat(total),
      });
    } else {
      if (payment?.transaction?.statusV2) {
      throw new Error(payment?.transaction?.statusV2);
      }
      throw new Error('Payment Transaction is not successful');
    }
  } catch (err) {
    yield put(setBundleSuccess(false));
    yield put(setBundleError(err.message));
    yield put(setBundleLoading(false));
  }
}

function* shopifySaga() {
  yield call(openShopConnect);
  yield call(setupShopInfo);
  yield call(setupShopifyItemsSaga);
  yield takeLatest(LOG_IN_SUCCESS, openShopConnect);
  yield takeLatest(REMOVE_SHOP, removeShopSaga);
  yield takeLatest(CONNECTING_SHOP, connectShopSaga);
  yield takeLatest(SAVE_BILLING_DATA_TO_SOURCE, saveBillingDataToSourceSaga);

  yield takeLatest(CHECKOUT_BUNDLE_SHOPIFY_ITEMS, createCheckoutBundleShopifyItemsSaga);
  yield takeLatest(CREATE_PAYMENT_TOKEN, createPaymentTokenSaga);
}

export default shopifySaga;
