import { PaymentMethod } from '@stripe/stripe-js';

const prefix = 'self-service-signup';

export interface ISelfServiceSignupActionTypes {
  CREATE_SIGNUP: string;
  PERSIST_FORM_DATA: string;
  RESET_IDEMPOTENCY_KEY: string;
  SET_SELECTED_PLAN_ID: string;
  SET_SIGNUP_LOADING: string;
  SET_SIGNUP_SUCCESS_MSG: string;
}

export const selfServiceSignupActionTypes: ISelfServiceSignupActionTypes = {
  CREATE_SIGNUP: `${prefix}/CREATE_SIGNUP`,
  SET_SIGNUP_SUCCESS_MSG: `${prefix}/SET_SIGNUP_SUCCESS_MSG`,
  SET_SIGNUP_LOADING: `${prefix}/SET_SIGNUP_LOADING`,
  SET_SELECTED_PLAN_ID: `${prefix}/SET_SELECTED_PLAN_ID`,
  PERSIST_FORM_DATA: `${prefix}/PERSIST_FORM_DATA`,
  RESET_IDEMPOTENCY_KEY: `${prefix}/RESET_IDEMPOTENCY_KEY`,
};

export type ISignupAction =
  | ICreateSignupAction
  | ISetSignupSuccessMsgAction
  | ISetSignupLoadingAction
  | ISetSelectedPlanIdAction
  | IPersistFormDataAction
  | IResetIdempotencyKeyAction;

export interface IPersistFormDataPayload {
  email: string;
  password: string;
  siteName: string;
}

export interface ICreateSignupPayload {
  acceptMarketingOpt: boolean;
  email: string;
  gclid: string;
  password: string;
  paymentMethod?: PaymentMethod;
  planId: string;
  recaptchaToken: string;
  siteName: string;
  utmData: Record<string, string>;
}

export interface ICreateSignupAction {
  payload: ICreateSignupPayload;
  type: typeof selfServiceSignupActionTypes.CREATE_SIGNUP;
}

export interface ISetSignupSuccessMsgPayload {
  message: '1' | '2';
}

export interface IResetIdempotencyKeyAction {
  payload: undefined;
  type: typeof selfServiceSignupActionTypes.RESET_IDEMPOTENCY_KEY;
}
export interface ISetSignupSuccessMsgAction {
  payload: ISetSignupSuccessMsgPayload;
  type: typeof selfServiceSignupActionTypes.SET_SIGNUP_SUCCESS_MSG;
}

export interface ISetSignupLoadingAction {
  payload: boolean;
  type: typeof selfServiceSignupActionTypes.SET_SIGNUP_LOADING;
}

export interface ISetSelectedPlanIdAction {
  payload: string;
  type: typeof selfServiceSignupActionTypes.SET_SELECTED_PLAN_ID;
}

export interface IPersistFormDataAction {
  payload: IPersistFormDataPayload;
  type: typeof selfServiceSignupActionTypes.PERSIST_FORM_DATA;
}

export const createSignup = (account: ICreateSignupPayload) => ({
  payload: account,
  type: selfServiceSignupActionTypes.CREATE_SIGNUP,
});

export const setSignupSuccessMsg = (value: ISetSignupSuccessMsgPayload) => ({
  payload: value,
  type: selfServiceSignupActionTypes.SET_SIGNUP_SUCCESS_MSG,
});

export const setSignupLoading = (value: boolean) => ({
  payload: value,
  type: selfServiceSignupActionTypes.SET_SIGNUP_LOADING,
});

export const setSelectedPlanId = (value: string) => ({
  payload: value,
  type: selfServiceSignupActionTypes.SET_SELECTED_PLAN_ID,
});

export const persistFormData = (value: IPersistFormDataPayload) => ({
  payload: value,
  type: selfServiceSignupActionTypes.PERSIST_FORM_DATA,
});

export const resetIdempotencyKey = (): IResetIdempotencyKeyAction => ({
  payload: undefined,
  type: selfServiceSignupActionTypes.RESET_IDEMPOTENCY_KEY,
});
